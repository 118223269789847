import Dashboard from "../Pages/Dashboard/Dashboard";
import AssetCategory from "../Pages/Masters/AssetCategory/AssetCategory";

import AssetStatus from "../Pages/Masters/AssetStatus/AssetStatus";
import AssetType from "../Pages/Masters/AssetType/AssetType";

import Business from "../Pages/Masters/Business/Business";

import Crafts from "../Pages/Masters/Crafts/Crafts";
import FailureClass from "../Pages/Masters/FailureClass/FailureClass";

import JobPlan from "../Pages/Masters/JobPlan/JobPlan";
import JobPlanTaskList from "../Pages/Masters/JobPlan/JobPlanTask/JobPlanTaskList";
import Labour from "../Pages/Masters/Labour/Labour";
import Location from "../Pages/Asset/Location/Location";
import LocationType from "../Pages/Masters/LocationType/LocationType";
import Menus from "../Pages/Masters/Menus/Menus";
import Meter from "../Pages/Masters/Meter/Meter";
import MeterGroup from "../Pages/Masters/MeterGroup/MeterGroup";

import PartsGroup from "../Pages/Masters/PartsGroup/PartsGroup";
import Person from "../Pages/Masters/Person/Person";
import ProblemCode from "../Pages/Masters/Problem/ProblemCode";
import RoleMenu from "../Pages/Masters/RoleMenu/RoleMenu";
import Roles from "../Pages/Masters/Roles/Roles";
import ServiceReq from "../Pages/WorkManagement/ServiceRequest/ServiceReq";
import StockCategory from "../Pages/Masters/StockCategory/StockCategory";

import TaskType from "../Pages/Masters/TaskType/TaskType";
import TechnicalAttr from "../Pages/Masters/TechnicalAttributes/TechnicalAttr";
import TechnicalSpec from "../Pages/Masters/TechnicalSpecification/TechnicalSpec";
import UOM from "../Pages/Masters/UOM/UOM";
import Welcome from "../Pages/Welcome";
import CreatePM from "../Pages/WorkManagement/PMSchedule/CreatePM";
import PM from "../Pages/WorkManagement/PMSchedule/PM";
import PMList from "../Pages/WorkManagement/PMSchedule/PMList";
import CreateGeneral from "../Pages/WorkManagement/WorkOrder/CreateGeneral";
import WO from "../Pages/WorkManagement/WorkOrder/WO";
import WorkOrder from "../Pages/WorkManagement/WorkOrder/WorkOrderList";
import Criticality from "../Pages/Masters/Criticality/Criticality";
import Priority from "../Pages/Masters/Priority/Priority";
import Assets from "../Pages/Asset/Assets/Assets";
import Asset_Details from "../Pages/Asset/Assets/Asset_Details";
import AssetModal from "../Pages/Asset/Assets/AssetModal";
import AssetExpl from "../Pages/Asset/AssetExplore/AssetExpl";
import CausesPro from "../Pages/Masters/Causes/CausesPro";
import Remedy from "../Pages/Masters/Remedies/Remedy";
import StockRoomRow from "../Pages/Inventory/StockRoom/StockRoomAisleRow/StockRoomAisleRow";
import StockRoom from "../Pages/Inventory/StockRoom/StockRoom/StockRoom";
import StockRoomAisle from "../Pages/Inventory/StockRoom/StockRoomAisle/StockRoomAisle";
import StockRoomRowBins from "../Pages/Inventory/StockRoom/StockRoomRowBin/StockRoomRowBins";
import Parts from "../Pages/Inventory/Parts/Parts";
import InventoryCreation from "../Pages/Inventory/InventoryCreation/InventoryCreation";
import Receipt from "../Pages/Inventory/Receipt/Receipt";
import ReceiptItem from "../Pages/Inventory/ReceiptItem/ReceiptItem";
import Issue from "../Pages/Inventory/Issue/IssueTransaction";
import IssueItem from "../Pages/Inventory/IssuedItem/IssueItem";
import GetInventory from "../Pages/Inventory/InventoryCreation/GetAllInventory";
import Site from "../Pages/Masters/Site/Site";
import ReturnTrans from "../Pages/Inventory/ReturnTransaction/ReturnTrans";
import VendorReturnTrans from "../Pages/Inventory/VendorReturnTransaction/VendorReturnTrans";
import RTItem from "../Pages/Inventory/ReturnTransItem/RTItem";
import VRItem from "../Pages/Inventory/VendorReturnItem/VRItem";
import NotAuthenticate from "../Pages/NotAuthenticate";
import Organization from "../Pages/Masters/Organization/Organization";
import TechnicalSpecAttr from "../Pages/Masters/TechSpecAttr/TechSpecAttr";
import AssetMeter from "../Pages/Masters/AssetMeter/AssetMeter";
import SRName from "../Pages/Masters/SRName/SRName";
import PartInventoryView from "../Pages/Inventory/InventoryCreation/PartInventoryView/PartInventoryView";
import InventoryCount from "../Pages/Inventory/InventoryCount/InventoryCount";
import PartView from "../Pages/Inventory/Parts/PartView";
import InspectionCategory from "../Pages/Masters/InspectionCategory/InspectionCategory";
import Question from "../Pages/Masters/Question/Question";
import AssetTypeQuestion from "../Pages/Masters/AssetTypeQues/AssetTypeQuestion";
import Route from "../Pages/WorkManagement/Route/Route";
import AssetRoute from "../Pages/WorkManagement/Route/AssetRoute/AssetRouteList";
import InspScheduleList from "../Pages/WorkManagement/InspectionSchedule/InspScheduleList";
import CreateInsp from "../Pages/WorkManagement/InspectionSchedule/CreateInsp";
import InspView from "../Pages/WorkManagement/InspectionSchedule/InspView";
import InspectionWOView from "../Pages/WorkManagement/InspectionWO/InspectionWOView";
import TransferTransaction from "../Pages/Inventory/TransferTransaction/TransferTransaction";
import PermitWork from "../Pages/HSE/Permit/PermitWork/PermitWork";
import PermitRequest from "../Pages/HSE/Permit/PermitRequest/PermitRequest";
import PermitRequestModal from "../Pages/HSE/Permit/PermitRequest/PermitRequestModal";
import PermitWorkView from "../Pages/HSE/Permit/PermitWork/PermitWorkView";
import KPI from "../Pages/KPIReport/KPI";
import Reports from "../Pages/KPIReport/Reports";
import InvenAdj from "../Pages/Inventory/InventoryAdjustment/InvenAdj";
import LineChart from "../Pages/KPIReport/LineChart";

export const routerLink = [
  {
    path: "/notauthenticate",
    component: NotAuthenticate,
    module: "notauthenticate",
  },
  /////welcome////////////
  {
    path: "/welcome",
    component: Welcome,
  },
  ////////dashboard/////////////
  {
    path: "/dashboard",
    component: Dashboard,
    module: "dashboard",
    action: "view",
  },
  //////masters/////////////////
  {
    path: "/masters/assetCategory",
    component: AssetCategory,
    module: "masters_category",
    action: "view",
  },
  {
    path: "/masters/criticality",
    component: Criticality,
    module: "masters_criticality",
    action: "view",
  },
  {
    path: "/masters/priority",
    component: Priority,
    module: "masters_priority",
    action: "view",
  },
  {
    path: "/masters/assetsStatus",
    component: AssetStatus,
    module: "masters_assetstatus",
    action: "view",
  },
  {
    path: "/masters/assetsType",
    component: AssetType,
    module: "masters_assettype",
    action: "view",
  },
  {
    path: "/masters/locationType",
    component: LocationType,
    module: "masters_locationtype",
    action: "view",
  },
  {
    path: "/masters/technicalSpecification",
    component: TechnicalSpec,
    module: "masters_techspec",
    action: "view",
  },
  {
    path: "/masters/uom",
    component: UOM,
    module: "masters_uom",
    action: "view",
  },
  {
    path: "/masters/failureClass",
    component: FailureClass,
    module: "masters_failureclass",
    action: "view",
  },

  {
    path: "/masters/failureclass/problem",
    component: ProblemCode,
    module: "masters_failureclass",
    action: "view",
  },
  {
    path: "/masters/failureclass/problem/causes",
    component: CausesPro,
    module: "masters_failureclass",
    action: "view",
  },
  {
    path: "/masters/failureclass/problem/causes/remedy",
    component: Remedy,
    module: "masters_failureclass",
    action: "view",
  },
  {
    path: "/masters/meterGroup",
    component: MeterGroup,
    module: "masters_metergroup",
    action: "view",
  },

  {
    path: "/masters/meter",
    component: Meter,
    module: "masters_meter",
    action: "view",
  },
  {
    path: "/masters/stockCategory",
    component: StockCategory,
    module: "masters_stockcat",
    action: "view",
  },
  {
    path: "/masters/partsGroup",
    component: PartsGroup,
    module: "masters_partsgroup",
    action: "view",
  },
  {
    path: "/masters/labour",
    component: Labour,
    module: "masters_labour",
    action: "view",
  },
  {
    path: "/masters/crafts",
    component: Crafts,
    module: "masters_crafts",
    action: "view",
  },
  {
    path: "/masters/person",
    component: Person,
    module: "masters_person",
    action: "view",
  },
  {
    path: "/masters/technicalAttributes",
    component: TechnicalAttr,
    module: "masters_techattr",
    action: "view",
  },
  {
    path: "/masters/business",
    component: Business,
    module: "masters_business",
    action: "view",
  },
  // {
  //   path: "/masters/businesscraft",
  //   component: BusinessCraft,
  //   module:"masters_businesscraft",
  //   action:"view"
  // },
  {
    path: "/masters/taskType",
    component: TaskType,
    module: "masters_tasktype",
    action: "view",
  },
  // {
  //   path: "/masters/stockRoom",
  //   component: StockRoom,
  // },
  // {
  //   path: "/masters/stockroomrows",
  //   component: StockRoomRow,
  // },

  {
    path: "/masters/jobplan",
    component: JobPlan,
    module: "masters_jobplan",
    action: "view",
  },
  {
    path: "/jobplan/jobplantask",
    component: JobPlanTaskList,
    module: "masters_jobplan",
    action: "view",
  },
  // {
  //   path: "/masters/labourCraft",
  //   component: LabourCraft,
  //   module:"masters_labourcraft",
  //   action:"view"
  // },

  {
    path: "/masters/techspecattr",
    component: TechnicalSpecAttr,
    module: "masters_techspec",
    action: "view",
  },
  {
    path: "/masters/assetMeter",
    component: AssetMeter,
    module: "master_assetmeter",
    action: "view",
  },
  {
    path: "/masters/srname",
    component: SRName,
    module: "master_srname",
    action: "view",
  },
  {
    path: "/masters/inspectioncategory",
    component: InspectionCategory,
    module: "master_inspectioncategory",
    action: "view",
  },
  {
    path: "/masters/question",
    component: Question,
    module: "master_question",
    action: "view",
  },
  {
    path: "/masters/assettypequestion",
    component: AssetTypeQuestion,
    module: "master_assettypequestion",
    action: "view",
  },
  {
    path: "/masters/route",
    component: Route,
    module: "master_route",
    action: "view",
  },
  ////////////////////administration/////////////////////
  {
    path: "/administration/site",
    component: Site,
    module: "administration_site",
    action: "view",
  },
  {
    path: "/administration/organization",
    component: Organization,
    module: "administration_organization",
    action: "view",
  },
  {
    path: "/administration/roleMenu",
    component: RoleMenu,
    module: "administration_rolemenu",
    action: "view",
  },
  {
    path: "/administration/menu",
    component: Menus,
    module: "administration_menu",
    action: "view",
  },
  {
    path: "/administration/roles",
    component: Roles,
    module: "administration_roles",
    action: "view",
  },

  //////////////Assets////////////////////
  {
    path: "/asset/location",
    component: Location,
    module: "location",
    action: "view",
  },
  {
    path: "/asset/assets",
    component: Assets,
    module: "assets",
    action: "view",
  },
  {
    path: "/assets/assetDetails",
    component: Asset_Details,
    module: "assets",
    action: "view",
  },
  {
    path: "/asset/create",
    component: AssetModal,
    module: "assets",
    action: "create",
  },
  {
    path: "/asset/assetexplore",
    component: AssetExpl,
    module: "assets_expl",
    action: "view",
  },
  ////////////Work Management////////////////////////////////
  {
    path: "/masters/servicerequest",
    component: ServiceReq,
    module: "servicerequest",
    action: "view",
  },
  {
    path: "/workOrder",
    component: WorkOrder,
    module: "workorder",
    action: "view",
  },
  {
    path: "/workordercreate",
    component: CreateGeneral,
    module: "workorder",
    action: "create",
  },
  {
    path: "/workorderview",
    component: WO,
    module: "workorder",
    action: "view",
  },
  {
    path: "/pm",
    component: PMList,
    module: "pm",
    action: "view",
  },
  {
    path: "/pmcreate",
    component: CreatePM,
    module: "pm",
    action: "create",
  },
  {
    path: "/pmView",
    component: PM,
    module: "pm",
    action: "view",
  },
  {
    path: "/wo/route",
    component: Route,
    module: "wo_route",
    action: "view",
  },
  {
    path: "/route/assetroute",
    component: AssetRoute,
    module: "wo_route",
    action: "view",
  },
  {
    path: "/inspection",
    component: InspScheduleList,
    module: "inspection",
    action: "view",
  },
  {
    path: "/inspectioncreate",
    component: CreateInsp,
    module: "inspection",
    action: "view",
  },
  {
    path: "/inspectionview",
    component: InspView,
    module: "inspection",
    action: "view",
  },
  {
    path: "/inspwoview",
    component: InspectionWOView,
    module: "workorder",
    action: "view",
  },

  //////////////// Inventory//////////////////////
  {
    path: "/stockroom",
    component: StockRoom,
    module: "stockroom",
    action: "view",
  },
  {
    path: "/inventorycount",
    component: InventoryCount,
    module: "inventorycount",
    action: "view",
  },
  {
    path: "/stockroomrow",
    component: StockRoomRow,
    module: "stockroom",
    action: "view",
  },
  {
    path: "/stockroomaisle",
    component: StockRoomAisle,
    module: "stockroom",
    action: "view",
  },
  {
    path: "/stockroomrowbins",
    component: StockRoomRowBins,
    module: "stockroom",
    action: "view",
  },
  {
    path: "/parts",
    component: Parts,
    module: "part",
    action: "view",
  },
  {
    path: "/partview",
    component: PartView,
    module: "part",
    action: "view",
  },
  {
    path: "/inventory/inventorycreation",
    component: InventoryCreation,
    module: "partinventory",
    action: "view",
  },
  {
    path: "/inventory/receipt",
    component: Receipt,
    module: "inventory_receiving_receipt",
    action: "view",
  },
  {
    path: "/receipt/receiptItem",
    component: ReceiptItem,
    module: "inventory_receiving_receipt",
    action: "view",
  },
  {
    path: "/inventory/issue",
    component: Issue,
    module: "inventory_issue",
    action: "view",
  },
  {
    path: "/issue/issueItem",
    component: IssueItem,
    module: "inventory_issue",
    action: "view",
  },
  {
    path: "/allInventory",
    component: GetInventory,
    module: "partinventory",
    action: "view",
  },
  {
    path: "/inventoryview",
    component: PartInventoryView,
    module: "partinventory",
    action: "view",
  },
  {
    path: "/inventory/returntrans",
    component: ReturnTrans,
    module: "inventory_return",
    action: "view",
  },
  {
    path: "/receive/vendortrans",
    component: VendorReturnTrans,
    module: "inventory_receiving_vendor",
    action: "view",
  },
  {
    path: "/issueReturn/issueReturnItem",
    component: RTItem,
    module: "inventory_return",
    action: "view",
  },
  {
    path: "/vendorreturn/returnitem",
    component: VRItem,
    module: "inventory_receiving_vendor",
    action: "view",
  },
  {
    path: "/inventory/transfertransaction",
    component: TransferTransaction,
    module: "inventory_transfertransaction",
    action: "view",
  },
  {
    path: "/inventory/inventoryadjustment",
    component: InvenAdj,
    module: "inventory_inventoryadjustment",
    action: "view",
  },
  ////////////////HSE//////////
  {
    path: "/hse/permit/permitwork",
    component: PermitWork,
    module: "hse_permit_permitwork",
    action: "view",
  },
  {
    path: "/hse/permit/permitwork/view",
    component: PermitWorkView,
    module: "hse_permit_permitwork",
    action: "view",
  },
  {
    path: "/hse/permit/permitrequest",
    component: PermitRequest,
    module: "hse_permit_permitrequest",
    action: "view",
  },
  {
    path: "/hse/permit/permitrequest/modal",
    component: PermitRequestModal,
    module: "hse_permit_permitrequest",
    action: "view",
  },
  ////////KPI Report/////////////////////
  {
    path: "/kpi/kpilist",
    component: KPI,
    module: "kpi_kpilist",
    action: "view",
  },
  {
    path: "/kpi/reports",
    component: Reports,
    module: "kpi_reports",
    action: "view",
  },
  {
    path: "/assethealth",
    component: LineChart,
    module: "assethealth",
    action: "view",
  },
];
