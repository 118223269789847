import React, { useEffect, useState } from "react";
import { color } from "../../../StyleData/Config";
import {
  Grid,
  Typography,
  Paper,
  Select,
  MenuItem,
  TextField,
  Box,
  Divider,
  IconButton,
  Tab,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Autocomplete,
  createFilterOptions,
  CircularProgress,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { axiosPrivate, siteInfo } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { Add, PlaylistAdd } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"

const CreatePM = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch()

  const edit=useSelector(state=>state.editData.pmEditData.edit)
  const siteInfoDetail=useSelector(state=>state.auth.loginInfo)
  
  const editData=useSelector(state=>state.editData.pmEditData.data)
  console.log(edit,editData.pmMeterBasedScheduler!==undefined)
  const [locationId, setLocationId] = useState([]);
  const [assetId, setAssetId] = useState([]);
  const [PMStatus, setPMStatus] = useState([]);
  // const [siteId, setSiteId] = useState([]);
  const [jobPlan, setJobPlan] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [frequnittime, setFrequnittime] = useState([]);
  const [frequnitmeter, setFrequnitmeter] = useState([]);
  const [meter, setMeter] = useState([]);
  const [dateValue,setDateValue]=useState({
    earliestStartDate:null,
    latestEndDate:null
  })

  const [value, setValue] = useState("1");

  const [priority, setPriority] = useState([]);
  const [defaultPri,setDefaultPri]=useState("")
  const [loading, setLoading] = useState(false);
  const [scheduleType, setScheduleType] = useState([]);
  const [aCValue,setACValue]=useState({
    asset:edit?editData.assetCode+" - "+editData.assetName:"",
    location:edit?editData.locationCode+" - "+editData.locationName:"",
    jobplan:edit?editData.jobPlanName:""
  })
  const [assetInfo,setAssetInfo]=useState({})

  useEffect(() => {
    getData()
  }, []);

  const getData=async()=>{
    setLoading(true);
    const locationLink = masterAPI["location"].link;
   await axiosPrivate
      .get(`${locationLink}`)
      .then((res) => {
        console.log(res.data.result);
        setLocationId(res.data.result);
      })
      .catch((e) => {
        setLocationId([])
        console.log(e, "createpm locaion getall");
      });

    const assetLink = masterAPI["assets"].link;
   await axiosPrivate
      .get(`${assetLink}`)
      .then((res) => {
        console.log(res.data.result);
        setAssetId(res.data.result);
      })
      .catch((e) => {
        setAssetId([])
        console.log(e, "createpm asset getall");
      });

  //   const siteLink = masterAPI["site"].link;
  //  await axiosPrivate
  //     .get(`${siteLink}`)
  //     .then((res) => {
  //       console.log(res.data.result);
  //       setSiteId(res.data.result);
  //     })
  //     .catch((e) => {
  //       setSiteId([])
  //       console.log(e, "createpm site getall");
  //     });

    const pmStatusLink = masterAPI["selectList"].pmStatus;
   await axiosPrivate
      .get(`${pmStatusLink}`)
      .then((res) => {
        console.log(res.data);
        setPMStatus(res.data);
      })
      .catch((e) => {
        setPMStatus([])
        console.log(e, "getpmStatus Create pm");
      });

    const jobplanLink = masterAPI["jobPlan"].link;
   await axiosPrivate
      .get(`${jobplanLink}`)
      .then((res) => {
        console.log(res.data.result);
        setJobPlan(res.data.result);
      })
      .catch((e) => {
        setJobPlan([])
        console.log(e, "getJobPlan Create pm");
      });

    const getjobType = masterAPI["selectList"].workordertype;
   await axiosPrivate
      .get(`${getjobType}`)
      .then((res) => {
        console.log(res.data);
        setJobType(res.data);
      })
      .catch((e) => {
        setJobType([])
        console.log(e, "jobtype Create pm");
      });

    const getFreqTime = masterAPI["selectList"].frequencyunitsfortime;

   await axiosPrivate
      .get(`${getFreqTime}`)
      .then((res) => {
        console.log(res.data);
        setFrequnittime(res.data);
      })
      .catch((e) => {
        setFrequnittime([])
        console.log(e, "freq unit time Create pm");
      });

    const getFreqMeter = masterAPI["selectList"].frequencyunitsformeter;

   await axiosPrivate
      .get(`${getFreqMeter}`)
      .then((res) => {
        console.log(res.data);
        setFrequnitmeter(res.data);
      })
      .catch((e) => {
        setFrequnitmeter([])
        console.log(e, "freq unit meter Create pm");
      });

    const meter = masterAPI["meter"].link;

   await axiosPrivate
      .get(`${meter}`)
      .then((res) => {
        console.log(res.data.result);
        setMeter(res.data.result);
      })
      .catch((e) => {
        setMeter([])
        console.log(e, " meter Create pm");
      });

    //////////////////priority////////////////////////////////////////
    const priLink = masterAPI["priority"].link;
   await axiosPrivate
      .get(`${priLink}`)
      .then((res) => {
        console.log(res.data.result);
        setDefaultPri(res.data.result[0].id)
        setPriority(res.data.result);
      })
      .catch((e) => {
        setPriority([])
        console.log(e, "failureClass Create Wo");
      });
    ///////////////////pm Schedule Type/////////////////
    const scheduleLink = masterAPI["selectList"].pmScheduleType;
   await axiosPrivate
      .get(`${scheduleLink}`)
      .then((res) => {
        console.log(res);
        setScheduleType(res.data);
      })
      .catch((e) => {
        setScheduleType([])
        console.log(e, "error pm schedule type");
      });
    setLoading(false);
  }

  const handleCancel = () => {
    navigate("/pm");
  };

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const initialValue = {
    description:edit?editData.description: "",
    name:edit?editData.name:"",
    locationId:edit?editData.locationId: null,
    assetId:edit?editData.assetId: null,
    
    status: edit?editData.status:"NEW",
    priorityId:edit?editData.priority?.id: "",
    siteId:siteInfoDetail.siteId,
    jobPlanId:edit?editData.jobPlanId: null,
    workTypeId:edit?editData.workTypeId: "0434d7ba-92bb-4054-9af2-4086470bbd37",
    pmType:edit?editData.pmType: "fixed",
    scheduleType:edit?editData.scheduleType: "Time",
    pmMeterBasedScheduler: {
      meterId:edit?editData.pmMeterBasedScheduler!==undefined?editData.pmMeterBasedScheduler?.meterId:null: null,
      frequency:edit?editData.pmMeterBasedScheduler!==undefined?editData.pmMeterBasedScheduler?.frequency:0 :0,
      startAtValue:edit?editData.pmMeterBasedScheduler!==undefined?editData.pmMeterBasedScheduler?.startAtValue:0: 0,
      earliestTriggerValue:edit?editData.pmMeterBasedScheduler!==undefined?editData.pmMeterBasedScheduler?.earliestTriggerValue:0: 0,
      currentReading:edit?editData.pmMeterBasedScheduler!==undefined?editData.pmMeterBasedScheduler?.currentReading:0: 0,
      frequencyUnitForMeter:edit?editData.pmMeterBasedScheduler!==undefined?editData.pmMeterBasedScheduler?.frequencyUnitForMeter:0: null,
      triggertype: edit?editData.pmMeterBasedScheduler!==undefined?editData.pmMeterBasedScheduler?.triggertype:"Continuous":"Continuous",
  
     },

    pmTimeBasedScheduler: {
      frequency: edit?editData.pmTimeBasedScheduler!==undefined?editData.pmTimeBasedScheduler?.frequency:0: 0,
      frequencyUnitForTime:edit?editData.pmTimeBasedScheduler!==undefined?editData.pmTimeBasedScheduler?.frequencyUnitForTime:null: null,
      nextDueDate:edit?editData.pmTimeBasedScheduler!==undefined?editData.pmTimeBasedScheduler?.nextDueDate:moment().format("YYYY-MM-DDTHH:mm:ss"): moment().format("YYYY-MM-DDTHH:mm:ss"),
      targetEndDate:edit?editData.pmTimeBasedScheduler!==undefined?editData.pmTimeBasedScheduler?.targetEndDate:moment().add(1, 'days').format("YYYY-MM-DDTHH:mm:ss"): moment().add(1, 'days').format("YYYY-MM-DDTHH:mm:ss"),
      offsetDays:edit?editData.pmTimeBasedScheduler!==undefined?editData.pmTimeBasedScheduler?.offsetDays:"0": "0",
    
    },
  };

  const onSubmit = async(values) => {
    console.log(values);
    console.log(priority[0].id)
    setLoading(true);
    let postData = {
      description: values.description,
      name: values.name,
      locationId: values.locationId,
      assetId: values.assetId,
      status: values.status,
      priorityId: values.priorityId===""?priority[0].id:values.priorityId,
      siteId: values.siteId,
      jobPlanId: values.jobPlanId,
      workTypeId: values.workTypeId,
      pmType: values.pmType,
      scheduleType: values.scheduleType,
    };
    if (values.scheduleType.toUpperCase()==="TIME"||values.scheduleType.toUpperCase()==="MIXED") {
      postData = {
        ...postData,
        pmTimeBasedScheduler: {
          frequency: values.pmTimeBasedScheduler.frequency,
          frequencyUnitForTime:
            values.pmTimeBasedScheduler.frequencyUnitForTime,
          nextDueDate: values.pmTimeBasedScheduler.nextDueDate,
          targetEndDate: values.pmTimeBasedScheduler.targetEndDate,
          offsetDays: values.pmTimeBasedScheduler.offsetDays,
        },
      };
    }
    if (values.scheduleType.toUpperCase()==="METER"||values.scheduleType.toUpperCase()==="MIXED") {
      postData = {
        ...postData,
        pmMeterBasedScheduler: {
          meterId: values.pmMeterBasedScheduler.meterId,
          frequency: values.pmMeterBasedScheduler.frequency,
          startAtValue: values.pmMeterBasedScheduler.startAtValue,
          earliestTriggerValue:
            values.pmMeterBasedScheduler.earliestTriggerValue,
          currentReading: values.pmMeterBasedScheduler.currentReading,
          frequencyUnitForMeter:
            values.pmMeterBasedScheduler.frequencyUnitForMeter,
          triggertype: values.pmMeterBasedScheduler.triggertype,
        },
      };
    }
    console.log(postData);
    
    const pmcreate = masterAPI["PM"].create;
    const pmUpdate=masterAPI["PM"].update
    if(edit){
      const putData={
        id:editData.id,
        ...postData
      }
     await axiosPrivate.put(`${pmUpdate}`,putData).then(res=>{
        console.log(res)
        navigate("/pm")
      }).catch(e=>{
        setLoading(false)
        console.log(e,"pm update error")
      })
    }else{
    
   await axiosPrivate
      .post(`${pmcreate}`, postData)
      .then((res) => {
        console.log(res, "pm create");
        navigate("/pm");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false)
        console.log(e, "pm create error");
      });
    }
  };

  // const handleCheckBox = (e, type) => {
  //   if (e.target.checked) {
  //     setChecked({ ...checked, [type]: true });
  //   } else {
  //     setChecked({ ...checked, [type]: false });
  //   }
  // };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box
        // sx={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "space-between",
        //   width: "100%",
        // }}
        sx={{ mb: "1rem" }}
      >
        <Typography
          sx={{
            // color: color.masterDialogTitleColor,
            // fontSize: color.masterDialogFontSize,
            fontSize: color.AssetTitlesize,
            fontWeight: color.sideFontWeightAsset,
          }}
        >
         {edit?`${editData.code+" - "+editData.name}`:" New PM Schedule"}
        </Typography>
      </Box>
      <Formik initialValues={initialValue} onSubmit={onSubmit} >
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
          
            <TabContext value={value}>
              <Box
                sx={{
                  ".css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                    color: color.TabLabelcolor,
                    // p: 0.5,
                  },
                  display: "flex",
                  justifyContent: "space-between",
                  // width: "98%",
                }}
              >
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{
                    sx: { backgroundColor: color.TabLabelcolor, height: "3px" },
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    label="General"
                    value="1"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    label="Scheduling"
                    value="2"
                    sx={{ textTransform: "none" }}
                  />
                </TabList>

                {/* <IconButton type='submit' sx={{backgroundColor:color.TableButtonColor}}>
          <PlaylistAdd sx={{color:"white",fontSize:"1.7rem"}}/>
        </IconButton> */}
              </Box>
              <Box sx={{ mt: "1rem" }}>
                <TabPanel value="1" sx={{ p: 1 }}>
                  {/* <CreateGeneral jobPlanIden={handleJobPlanId}/> */}
                  <Grid
                    container
                    sx={{
                      boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                      p: 1.5,
                      backgroundColor: "white",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: color.masterDialogTitleColor,
                          fontSize: color.masterDialogFontSize,
                          fontWeight: color.sideWeightFont,
                          mb: "10px",
                        }}
                      >
                        Details
                      </Typography>
                      <Divider
                        sx={{
                          border: "1px solid rgb(0 0 0 / 17%)",
                          mb: "15px",
                        }}
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={"1rem"}>
                     
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Name
                        </Typography>
                        <Field
                          as={TextField}
                          size={"small"}
                          type="text"
                          name="name"
                          placeholder="Enter name"
                          fullWidth
                          sx={{
                            ".MuiInputBase-input": {
                              // letterSpacing: "0.2rem",

                              "&::placeholder": {
                                // color: "green",
                                opacity: 1,
                                color: color.placeholderColor,
                                fontSize: "13px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          PM Description
                        </Typography>
                        <Field
                          as={TextField}
                          size={"small"}
                          type="text"
                          name="description"
                          placeholder="Enter Description"
                          fullWidth
                          sx={{
                            ".MuiInputBase-input": {
                              // letterSpacing: "0.2rem",

                              "&::placeholder": {
                                // color: "green",
                                opacity: 1,
                                color: color.placeholderColor,
                                fontSize: "13px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Asset
                        </Typography>
                        {/* <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="assetId"
               
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.assetId !== null ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {assetId.map((id, i) => (
                  <MenuItem key={i} value={id.id}>{id.assetName}</MenuItem>
                ))}
              </Field> */}
                        <Field
                          name="assetId"
                          //  value={autoValue}
                          // value={values.assetName.code+"_"+values.assetName.assetName}
                          inputValue={aCValue.asset}
                          component={Autocomplete}
                          filterOptions={filterOptions}
                          loading={assetId.length === 0}
                          options={assetId}
                          PaperComponent={CustomPaper}
                          getOptionLabel={(option) =>
                           option.code+" - " +option.assetName || option
                          }
                          freeSolo
                          onChange={(event, value) => {
                            if (value!==null) {
                              // console.log(value);
                              setFieldValue("assetId", value.id);
                              setFieldValue("locationId",value.locationId)
                           setACValue({...aCValue,asset:value.code+" - "+value.assetName,location:value.locationCode+" - "+value.locationName})
                           setAssetInfo(value)
                          }else{
                              setFieldValue("assetId",null)
                              setACValue({...aCValue,asset:""})
                              setAssetInfo({})
                            }
                          }}
                          onInputChange={(e,v)=>{
                            setACValue({...aCValue,asset:v})
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="assetId"
                              placeholder="Search Asset"
                              size="small"
                              // onChange={handleChange}
                              // variant="standard"

                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                    {assetId.length === 0 ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        >
                          {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Location
                        </Typography>
                        {/* <Field
                          as={Select}
                          // label={"Select"}
                          size={"small"}
                          type="text"
                          name="locationId"
                          fullWidth
                          displayEmpty
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "40vh" } },
                          }}
                          renderValue={
                            values.locationId !== null
                              ? undefined
                              : () => (
                                  <Typography
                                    sx={{
                                      color: color.placeholderColor,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Select
                                  </Typography>
                                )
                          }
                        >
                          {locationId.map((id, i) => (
                            <MenuItem key={i} value={id.id}>
                              {id.locationName}
                            </MenuItem>
                          ))}
                        </Field> */}
                         <Field
                          name="locationId"
                          //  value={autoValue}
                          // value={values.assetName.code+"_"+values.assetName.assetName}
                          // value={value}
                          readOnly={assetInfo.locationId!==undefined&&assetInfo.locationId!==null}
                          inputValue={aCValue.location}
                          component={Autocomplete}
                          filterOptions={filterOptions}
                          loading={locationId.length === 0}
                          options={locationId}
                          PaperComponent={CustomPaper}
                          getOptionLabel={(option) =>
                           option.code+" - "+ option.locationName || option
                          }
                          freeSolo
                          onChange={async(event, value) => {
                            if (value!==null) {
                              console.log(value);
                              setFieldValue("locationId", value.id);
                              setFieldValue("assetId",null)
                             const assetByLocation=masterAPI["assets"].assetByLocation
                            await axiosPrivate.get(`${assetByLocation}/${value.id}`).then(res=>{
                                          setAssetId(res.data.result)
                             }).catch(e=>{
                              setAssetId([])
                              console.log(e)
                             })
                           
                              setACValue({...aCValue,location:value.code+" - "+value.locationName,asset:""})
                            }else{
                               setFieldValue("locationId",null)
                               setACValue({...aCValue,location:"",asset:""})
                            }
                          }}
                          onInputChange={(e,v)=>{
                             setACValue({...aCValue,location:v})
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="locationId"
                              placeholder="Search Location"
                              size="small"
                              onChange={handleChange}
                              // variant="standard"

                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                    {locationId.length === 0 ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        >
                          {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                        </Field>
                      </Grid>
                      
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Site
                        </Typography>
                        <Field
                          as={Select}
                          // label={"Select"}
                          size={"small"}
                          type="text"
                          name="siteId"
                          fullWidth
                          displayEmpty
                          readOnly
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "40vh" } },
                          }}
                          renderValue={
                            values.siteId !== null
                              ? undefined
                              : () => (
                                  <Typography
                                    sx={{
                                      color: color.placeholderColor,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Select
                                  </Typography>
                                )
                          }
                        >
                         
                            <MenuItem  value={`${siteInfoDetail.siteId}`}>
                          {siteInfoDetail.siteName}
                            </MenuItem>
                        
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          PM Status
                        </Typography>
                        <Field
                          as={Select}
                          // label={"Select"}
                          size={"small"}
                          type="text"
                          name="status"
                          fullWidth
                          displayEmpty
                          readOnly={!edit}
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "40vh" } },
                          }}
                          renderValue={
                            values.status !== ""
                              ? undefined
                              : () => (
                                  <Typography
                                    sx={{
                                      color: color.placeholderColor,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Select
                                  </Typography>
                                )
                          }
                        >
                          {PMStatus.map((id, i) => (
                            <MenuItem key={i} value={id.value}>
                              {id.text}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Job Type
                        </Typography>
                        <Field
                          as={Select}
                          // label={"Select"}
                          size={"small"}
                          type="text"
                          name="workTypeId"
                          fullWidth
                          displayEmpty
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "40vh" } },
                          }}
                          renderValue={
                            values.workTypeId !== null
                              ? undefined
                              : () => (
                                  <Typography
                                    sx={{
                                      color: color.placeholderColor,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Select
                                  </Typography>
                                )
                          }
                        >
                          {jobType.map((id, i) => (
                            <MenuItem
                              key={i}
                              value={id.value}
                              disabled={id.text !== "PM"}
                            >
                              {id.text}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Job Priority
                        </Typography>
                   
                        <Field
                          as={Select}
                          // label={"Select"}
                          size={"small"}
                          type="text"
                          name="priorityId"
                          value={defaultPri}
                          // value={"31e96f8a-61b0-43c0-8b7d-52910040450d"}
                          fullWidth
                          displayEmpty
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "40vh" } },
                          }}
                          onChange={(e,v)=>{
                          
                            setFieldValue("priorityId",e.target.value)
                            setDefaultPri(e.target.value)
                          }}
                          // renderValue={
                          //   values.priorityId !== ""
                          //     ? undefined
                          //     : () => (
                          //         <Typography
                          //           sx={{
                          //             color: color.placeholderColor,
                          //             fontSize: "13px",
                          //           }}
                          //         >
                          //           Select
                          //         </Typography>
                          //       )
                          // }
                        >
                          {priority.map((id, i) => (
                          
                            <MenuItem key={i}  value={id.id}>
                             {id.code+" - "+id.priorityName}
                             
                            </MenuItem>
                          ))}
                        
                        </Field>

                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Job Plan
                        </Typography>
                        {/* <Field
                          as={Select}
                          // label={"Select"}
                          size={"small"}
                          type="text"
                          name="jobPlanId"
                          fullWidth
                          displayEmpty
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "40vh" } },
                          }}
                          renderValue={
                            values.jobPlanId !== null
                              ? undefined
                              : () => (
                                  <Typography
                                    sx={{
                                      color: color.placeholderColor,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Select
                                  </Typography>
                                )
                          }
                        >
                          {jobPlan.map((id, i) => (
                            <MenuItem key={i} value={id.id}>
                              {id.jobPlanName}
                            </MenuItem>
                          ))}
                        </Field> */}
                         <Field
                          name="jobPlanId"
                          //  value={autoValue}
                          // value={values.assetName.code+"_"+values.assetName.assetName}
                          // value={value}
                          inputValue={aCValue.jobplan}
                          component={Autocomplete}
                          filterOptions={filterOptions}
                          loading={jobPlan.length === 0}
                          options={jobPlan}
                          PaperComponent={CustomPaper}
                          getOptionLabel={(option) =>
                           option.code+" - "+ option.jobPlanName || option
                          }
                          freeSolo
                          onChange={(event, value) => {
                            if (value!==null) {
                              console.log(value);
                              setFieldValue("jobPlanId", value.id);
                              setACValue({...aCValue,jobplan:value.code+" - "+value.jobPlanName})
                            }else{
                               setFieldValue("jobPlanId",null)
                               setACValue({...aCValue,jobplan:""})
                            }
                          }}
                          onInputChange={(e,v)=>{
                             setACValue({...aCValue,jobplan:v})
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="jobPlanId"
                              placeholder="Search JobPlan "
                              size="small"
                              onChange={handleChange}
                              // variant="standard"

                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                    {jobPlan.length === 0 ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        >
                          {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                        </Field>
                      </Grid>
                      {/* <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          PM Counter
                        </Typography>
                        <Field
                          as={TextField}
                          size={"small"}
                          type="text"
                          name="woCounter"
                          placeholder="Enter PM Counter"
                          fullWidth
                          sx={{
                            ".MuiInputBase-input": {
                              // letterSpacing: "0.2rem",

                              "&::placeholder": {
                                // color: "green",
                                opacity: 1,
                                color: color.placeholderColor,
                                fontSize: "13px",
                              },
                            },
                          }}
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2" sx={{ p: 1 }}>
                  {/* <CreateGeneral jobPlanIden={handleJobPlanId}/> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Grid container
                      sx={{
                        boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                        p: 1.5,
                        backgroundColor: "white",
                      }}
                      alignItems={"center"}
                    >
                      <Grid item xs={12} md={3} lg={2}>
                        <Typography
                          sx={{
                            color: color.masterDialogTitleColor,
                            fontSize: color.masterDialogFontSize,
                            fontWeight: color.sideWeightFont,
                            mb: "10px",
                          }}
                        >
                          Schedule Type
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          as={Select}
                          // label={"Select"}
                          size={"small"}
                          type="text"
                          name="scheduleType"
                          fullWidth
                          displayEmpty
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "40vh" } },
                          }}
                          renderValue={
                            values.scheduleType !== ""
                              ? undefined
                              : () => (
                                  <Typography
                                    sx={{
                                      color: color.placeholderColor,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Select
                                  </Typography>
                                )
                          }
                          onChange={(e) => {
                            console.log(e.target.value);
                            if (e.target.value === "Time") {
                              // setChecked({
                              //   time: true,
                              //   continuous: false,
                              //   gauge: false,
                              // });

                              setFieldValue("pmMeterBasedScheduler.meterId",null);
                              setFieldValue("pmMeterBasedScheduler.frequency",0);
                              setFieldValue("pmMeterBasedScheduler.startAtValue",0);
                              setFieldValue("pmMeterBasedScheduler.earliestTriggerValue",0);
                              setFieldValue( "pmMeterBasedScheduler.currentReading",0 );
                             
                              setFieldValue("pmMeterBasedScheduler.frequencyUnitForMeter",null  );
                                
                                
                            
                              setFieldValue("pmMeterBasedScheduler.triggertype","Continuous");
                                
                          
                        
                            } else if (e.target.value === "Meter") {
                              // setChecked({ ...checked, time: false });
                              setFieldValue("pmTimeBasedScheduler.frequency",0);
                              setFieldValue("pmTimeBasedScheduler.frequencyUnitForTime",null);
                              setFieldValue("pmTimeBasedScheduler.nextDueDate",moment().format("YYYY-MM-DDTHH:mm:ss"));
                              setFieldValue("pmTimeBasedScheduler.targetEndDate",moment().format("YYYY-MM-DDTHH:mm:ss"));
                              setFieldValue("pmTimeBasedScheduler.offsetDays",0);
                            }
                            setFieldValue("scheduleType", e.target.value);
                          }}
                        >
                          {scheduleType.map((id, i) => (
                            <MenuItem key={i} value={id.value}>
                              {id.text}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid container
                      sx={{
                        boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                        p: 1.5,
                        backgroundColor: "white",
                        display:values.scheduleType === "" ||values.scheduleType.toUpperCase() === "TIME" ||values.scheduleType.toUpperCase() === "MIXED"||values.scheduleType.toUpperCase()==="NONE"? "default": "none",
                      }}
                    >
                      <Grid item xs={12}>
                        {/* <Box sx={{display:"flex",alignItems:"center"}}> */}
                        {/* <Checkbox checked={checked.time} disabled={values.scheduleType==="Meter"||values.scheduleType===""} onClick={(e)=>{
                      if(values.scheduleType!=="Time"){

                      
                      if(!e.target.checked){
                        setFieldValue("pmTimeBasedScheduler.frequency",0)
                        setFieldValue("pmTimeBasedScheduler.frequencyUnitForTime",null)
                        setFieldValue("pmTimeBasedScheduler.nextDueDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
                        setFieldValue("pmTimeBasedScheduler.targetEndDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
                        setFieldValue("pmTimeBasedScheduler.offsetDays",0)
                      }
                      handleCheckBox(e,"time")}
                      }}/> */}
                        <Typography
                          sx={{
                            color: color.masterDialogTitleColor,
                            fontSize: color.masterDialogFontSize,
                            fontWeight: color.sideWeightFont,
                            mb: "10px",
                          }}
                        >
                          Time Schedule Trigger
                        </Typography>
                        {/* </Box> */}
                        <Divider
                          sx={{
                            border: "1px solid rgb(0 0 0 / 17%)",
                            mb: "15px",
                          }}
                        />
                      </Grid>
                      <Grid container item xs={12} spacing={"1rem"}>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Frequency
                          </Typography>
                          <Field
                            as={TextField}
                            size={"small"}
                            type="text"
                            name="pmTimeBasedScheduler.frequency"
                            placeholder="Enter Frequency"
                            fullWidth
                            // inputProps={{ readOnly: values.scheduleType.toUpperCase()!=="TIME"||values.scheduleType.toUpperCase()!=="MIXED" }}
                            disabled={values.scheduleType.toUpperCase()==="METER"||values.scheduleType.toUpperCase()===""||values.scheduleType.toUpperCase()==="NONE"}
                            sx={{
                              ".MuiInputBase-input": {
                                // letterSpacing: "0.2rem",

                                "&::placeholder": {
                                  // color: "green",
                                  opacity: 1,
                                  color: color.placeholderColor,
                                  fontSize: "13px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Frequency Unit
                          </Typography>
                          <Field
                            as={Select}
                            // label={"Select"}
                            size={"small"}
                            type="text"
                            name="pmTimeBasedScheduler.frequencyUnitForTime"
                            disabled={values.scheduleType.toUpperCase()==="METER"||values.scheduleType.toUpperCase()===""||values.scheduleType.toUpperCase()==="NONE"}
                            fullWidth
                            displayEmpty
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: "40vh" } },
                            }}
                            renderValue={
                              values.pmTimeBasedScheduler
                                .frequencyUnitForTime !== null
                                ? undefined
                                : () => (
                                    <Typography
                                      sx={{
                                        color: color.placeholderColor,
                                        fontSize: "13px",
                                      }}
                                    >
                                      Select
                                    </Typography>
                                  )
                            }
                          >
                            {frequnittime.map((id, i) => (
                              <MenuItem key={i} value={id.value}>
                                {id.text}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sx={{
                            ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              // mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Next Due Date
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                format="DD/MM/YYYY"
                                value={dayjs(
                                  values.pmTimeBasedScheduler.nextDueDate
                                )}
                                //   sx={{".css-1xhypcz-MuiStack-root":{
                                //     padding:0
                                //   }}}
                                disabled={values.scheduleType.toUpperCase()==="METER"||values.scheduleType.toUpperCase()===""||values.scheduleType.toUpperCase()==="NONE"}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    margin: "-8px 0 0 0",
                                    fullWidth: true,
                                  },
                                }}
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  if (newValue !== null) {
                                    if (`${newValue.$d}` !== "Invalid Date") {
                                      const dateFrom = moment(
                                        newValue.$d
                                      ).format("YYYY-MM-DD");
                                      const endFormat=moment(
                                        newValue.$d
                                      ).add(1,"days").format("YYYY-MM-DD");

                                      const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                      const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                      const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                      const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                      const end=endFormat+"T"+hour+":"+min+":"+sec
                                      setFieldValue("pmTimeBasedScheduler.nextDueDate", dateFormat);
                                      setFieldValue("pmTimeBasedScheduler.targetEndDate",end);
                                      setDateValue({
                                        ...dateValue,
                                        earliestStartDate:moment(newValue.$d).subtract(Number(values.pmTimeBasedScheduler.offsetDays), 'days').format("YYYY-MM-DDTHH:mm:ss"),
                                        latestEndDate:moment(end).add(Number(values.pmTimeBasedScheduler.offsetDays), 'days').format("YYYY-MM-DDTHH:mm:ss")
                                      })
                                    }
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onBlur={(e)=>{
                                     console.log(e,"blur")
                                    }}
                                    // inputRef={datePickerRef}
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                      

                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Offset Days
                          </Typography>
                          <Field
                            as={TextField}
                            size={"small"}
                            disabled={values.scheduleType.toUpperCase()==="METER"||values.scheduleType.toUpperCase()===""||values.scheduleType.toUpperCase()==="NONE"}
                            type="text"
                            name="pmTimeBasedScheduler.offsetDays"
                            placeholder="Enter Offset Days"
                            fullWidth
                            onChange={(e)=>{
                                   setFieldValue("pmTimeBasedScheduler.offsetDays",e.target.value)
                                   if(e.target.value!==0){
                                    setDateValue({
                                      earliestStartDate:moment(values.pmTimeBasedScheduler.nextDueDate).subtract(Number(e.target.value), 'days').format("YYYY-MM-DDTHH:mm:ss"),
                                      latestEndDate:moment(values.pmTimeBasedScheduler.targetEndDate).add(Number(e.target.value), 'days').format("YYYY-MM-DDTHH:mm:ss")
                                    })
                                   }else{
                                    setDateValue({
                                      earliestStartDate:null,
                                      latestEndDate:null
                                    })
                                   }
                            }}
                            sx={{
                              ".MuiInputBase-input": {
                                // letterSpacing: "0.2rem",

                                "&::placeholder": {
                                  // color: "green",
                                  opacity: 1,
                                  color: color.placeholderColor,
                                  fontSize: "13px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sx={{
                            ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
                            display:values.pmTimeBasedScheduler.offsetDays==="0"||values.pmTimeBasedScheduler.offsetDays===""?"none":"default"
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              // mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Earliest Start Date
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                format="DD/MM/YYYY"
                                readOnly
                                value={dateValue.earliestStartDate===null?null:dayjs(
                                  dateValue.earliestStartDate
                                )}
                                //   sx={{".css-1xhypcz-MuiStack-root":{
                                //     padding:0
                                //   }}}
                                disabled={values.scheduleType.toUpperCase()==="METER"||values.scheduleType.toUpperCase()===""||values.scheduleType.toUpperCase()==="NONE"}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    margin: "-8px 0 0 0",
                                    fullWidth: true,
                                  },
                                }}
                                // onChange={(newValue) => {
                                //   console.log(newValue);
                                //   if (newValue !== null) {
                                //     if (`${newValue.$d}` !== "Invalid Date") {
                                //       const dateFrom = moment(
                                //         newValue.$d
                                //       ).format("YYYY-MM-DD");
                                //       const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                //       const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                //       const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                //       const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                //       setFieldValue(
                                //         "pmTimeBasedScheduler.targetEndDate",
                                //       dateFormat
                                //       );
                                //     }
                                //   }
                                // }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sx={{
                            ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
                            display:values.pmTimeBasedScheduler.offsetDays==="0"||values.pmTimeBasedScheduler.offsetDays===""?"none":"default"
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              // mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            latest End Date
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                format="DD/MM/YYYY"
                                readOnly
                                value={ dateValue.latestEndDate===null?null:dayjs(
                                  dateValue.latestEndDate
                                )}
                                //   sx={{".css-1xhypcz-MuiStack-root":{
                                //     padding:0
                                //   }}}
                                disabled={values.scheduleType.toUpperCase()==="METER"||values.scheduleType.toUpperCase()===""||values.scheduleType.toUpperCase()==="NONE"}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    margin: "-8px 0 0 0",
                                    fullWidth: true,
                                  },
                                }}
                                // onChange={(newValue) => {
                                //   console.log(newValue);
                                //   if (newValue !== null) {
                                //     if (`${newValue.$d}` !== "Invalid Date") {
                                //       const dateFrom = moment(
                                //         newValue.$d
                                //       ).format("YYYY-MM-DD");
                                //       const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                //       const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                //       const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                //       const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                //       setFieldValue(
                                //         "pmTimeBasedScheduler.targetEndDate",
                                //       dateFormat
                                //       );
                                //     }
                                //   }
                                // }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sx={{
                            ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              // mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Target End Date
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                format="DD/MM/YYYY"
                                value={dayjs(
                                  values.pmTimeBasedScheduler.targetEndDate
                                )}
                                //   sx={{".css-1xhypcz-MuiStack-root":{
                                //     padding:0
                                //   }}}
                                disabled={values.scheduleType.toUpperCase()==="METER"||values.scheduleType.toUpperCase()===""||values.scheduleType.toUpperCase()==="NONE"}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    margin: "-8px 0 0 0",
                                    fullWidth: true,
                                  },
                                }}
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  if (newValue !== null) {
                                    if (`${newValue.$d}` !== "Invalid Date") {
                                      const dateFrom = moment(
                                        newValue.$d
                                      ).format("YYYY-MM-DD");
                                      const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                      const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                      const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                      const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                      setFieldValue(
                                        "pmTimeBasedScheduler.targetEndDate",
                                      dateFormat
                                      );
                                      setDateValue({
                                        ...dateValue,
                                        //earliestStartDate:moment(newValue.$d).subtract(Number(values.pmTimeBasedScheduler.offsetDays), 'days').format("YYYY-MM-DDTHH:mm:ss"),
                                        latestEndDate:moment(newValue.$d).add(Number(values.pmTimeBasedScheduler.offsetDays), 'days').format("YYYY-MM-DDTHH:mm:ss")
                                      })
                                    }
                                  }
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          md={4}
                          alignItems={"flex-end"}
                        >
                          <FormControlLabel
                            value="Fixed"
                            checked={values.pmType.toUpperCase() === "FIXED"}
                            disabled={values.scheduleType.toUpperCase()==="METER"||values.scheduleType.toUpperCase()===""||values.scheduleType.toUpperCase()==="NONE"}
                            control={
                              <Radio
                                onChange={() => {
                                  setFieldValue("pmType", "Fixed");
                                  // setFieldValue("pmType","floating")
                                }}
                              />
                            }
                            label="Fixed"
                          />
                          <FormControlLabel
                            value="Floating"
                            checked={values.pmType.toUpperCase() === "FLOATING"}
                            disabled={values.scheduleType.toUpperCase()==="METER"||values.scheduleType.toUpperCase()===""||values.scheduleType.toUpperCase()==="NONE"}
                            control={
                              <Radio
                                onChange={() => {
                                  setFieldValue("pmType", "Floating");
                                }}
                              />
                            }
                            label="Floating"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container
                      sx={{
                        boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                        p: 1.5,
                        backgroundColor: "white",
                        display:
                          values.scheduleType === "" ||
                          values.scheduleType.toUpperCase() === "METER" ||
                          values.scheduleType.toUpperCase() === "MIXED"||
                          values.scheduleType.toUpperCase() === "NONE"
                            ? "default"
                            : "none",
                      }}
                    >
                      <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            color: color.masterDialogTitleColor,
                            fontSize: color.masterDialogFontSize,
                            fontWeight: color.sideWeightFont,
                            mb: "10px",
                          }}
                        >
                          Continuous Trigger
                        </Typography>
                          {/* <FormControlLabel
                            value="Continuous"
                            checked={
                              values.pmMeterBasedScheduler.triggertype.toUpperCase() ===
                              "CONTINUOUS"
                            }
                            disabled={values.scheduleType===""||values.scheduleType.toUpperCase()==="NONE"||values.scheduleType.toUpperCase()==="TIME"}
                            control={
                              <Radio
                                onChange={(e) => {
                                  // setFieldValue("pmMeterBasedScheduler.meterId",null)
                                  // setFieldValue("pmMeterBasedScheduler.frequency",0)
                                  // setFieldValue("pmMeterBasedScheduler.startAtValue",0)
                                  // setFieldValue("pmMeterBasedScheduler.earliestTriggerValue",0)
                                  // setFieldValue("pmMeterBasedScheduler.currentReading",0)
                                  // setFieldValue("pmMeterBasedScheduler.frequencyUnitForMeter",null)

                                  setFieldValue("pmMeterBasedScheduler.triggertype","Continuous");

                                  // handleCheckBox(e, "continuous");
                                }}
                              />
                            }
                            label={ <Typography
                              sx={{
                                color: color.masterDialogTitleColor,
                                fontSize: color.masterDialogFontSize,
                                fontWeight: color.sideWeightFont,
                               
                              }}
                            >
                            Continuous Trigger
                            </Typography> }
                          /> */}
                          {/* <FormControlLabel
                            value="Gauge"
                            checked={
                              values.pmMeterBasedScheduler.triggertype.toUpperCase() === "GAUGE"
                            }
                            // disabled={true}
                            disabled={values.scheduleType===""||values.scheduleType.toUpperCase()==="NONE"||values.scheduleType.toUpperCase()==="TIME"}
                            control={
                              <Radio
                                onChange={(e) => {
                                  setFieldValue("pmMeterBasedScheduler.meterId",null);
                                  setFieldValue("pmMeterBasedScheduler.frequency",0);
                                  setFieldValue("pmMeterBasedScheduler.startAtValue",0);
                                  setFieldValue("pmMeterBasedScheduler.earliestTriggerValue",0);
                                  setFieldValue("pmMeterBasedScheduler.currentReading",0);
                                  setFieldValue("pmMeterBasedScheduler.frequencyUnitForMeter",null);

                                  setFieldValue("pmMeterBasedScheduler.triggertype","Gauge");

                                  // handleCheckBox(e, "gauge");
                                }}
                              />
                            }
                            label= { <Typography
                            sx={{
                              color: color.masterDialogTitleColor,
                              fontSize: color.masterDialogFontSize,
                              fontWeight: color.sideWeightFont,
                             
                            }}
                          >
                          Gauge Trigger
                          </Typography> }
                          /> */}

                         
                        </Box>

                        <Divider
                          sx={{
                            border: "1px solid rgb(0 0 0 / 17%)",
                            mb: "15px",
                          }}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={"1rem"}
                        sx={{
                          //display:values.pmMeterBasedScheduler.triggertype.toUpperCase() ==="CONTINUOUS"? "default": "none",
                        }}
                      >
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Meter Id
                          </Typography>
                          <Field
                            as={Select}
                            // label={"Select"}
                            size={"small"}
                            type="text"
                            name="pmMeterBasedScheduler.meterId"
                            disabled={values.scheduleType===""||values.scheduleType==="None"||values.scheduleType==="Time"||values.pmMeterBasedScheduler.triggertype.toUpperCase()==="GAUGE"}
                            fullWidth
                            displayEmpty
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: "40vh" } },
                            }}
                            renderValue={
                              values.pmMeterBasedScheduler.meterId !== null
                                ? undefined
                                : () => (
                                    <Typography
                                      sx={{
                                        color: color.placeholderColor,
                                        fontSize: "13px",
                                      }}
                                    >
                                      Select
                                    </Typography>
                                  )
                            }
                          >
                            {meter.map((id, i) => (
                              <MenuItem key={i} value={id.id}>
                                {id.meterName}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Frequency Unit
                          </Typography>
                          <Field
                            as={Select}
                            // label={"Select"}
                            size={"small"}
                            type="text"
                            name="pmMeterBasedScheduler.frequencyUnitForMeter"
                            disabled={values.scheduleType===""||values.scheduleType==="None"||values.scheduleType==="Time"||values.pmMeterBasedScheduler.triggertype.toUpperCase()==="GAUGE"}
                            fullWidth
                            displayEmpty
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: "40vh" } },
                            }}
                            renderValue={
                              values.pmMeterBasedScheduler
                                .frequencyUnitForMeter !== null
                                ? undefined
                                : () => (
                                    <Typography
                                      sx={{
                                        color: color.placeholderColor,
                                        fontSize: "13px",
                                      }}
                                    >
                                      Select
                                    </Typography>
                                  )
                            }
                          >
                            {frequnitmeter.map((id, i) => (
                              <MenuItem key={i} value={id.value}>
                                {id.text}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Frequency
                          </Typography>
                          <Field
                            as={TextField}
                            size={"small"}
                            type="text"
                            name="pmMeterBasedScheduler.frequency"
                            placeholder="Enter Frequency"
                            fullWidth
                            onBlur={(e)=>{
                           console.log(e.target.value,"blured")
                           setFieldValue("pmMeterBasedScheduler.startAtValue",e.target.value)
                            }}
                            disabled={values.scheduleType===""||values.scheduleType==="None"||values.scheduleType==="Time"||values.pmMeterBasedScheduler.triggertype.toUpperCase()==="GAUGE"}
                            sx={{
                              ".MuiInputBase-input": {
                                // letterSpacing: "0.2rem",

                                "&::placeholder": {
                                  // color: "green",
                                  opacity: 1,
                                  color: color.placeholderColor,
                                  fontSize: "13px",
                                },
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            StartAt
                          </Typography>
                          <Field
                            as={TextField}
                            size={"small"}
                            // value={"pmMeterBasedScheduler.startAtValue"}
                            type="text"
                            name="pmMeterBasedScheduler.startAtValue"
                            placeholder="Enter Start Value"
                            fullWidth
                            disabled={values.scheduleType===""||values.scheduleType==="None"||values.scheduleType==="Time"||values.pmMeterBasedScheduler.triggertype.toUpperCase()==="GAUGE"}
                            
                            sx={{
                              ".MuiInputBase-input": {
                                // letterSpacing: "0.2rem",

                                "&::placeholder": {
                                  // color: "green",
                                  opacity: 1,
                                  color: color.placeholderColor,
                                  fontSize: "13px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Earliest Trigger
                          </Typography>
                          <Field
                            as={TextField}
                            size={"small"}
                            type="text"
                            name="pmMeterBasedScheduler.earliestTriggerValue"
                            placeholder="Enter Earliest Trigger Value"
                            fullWidth
                            disabled={values.scheduleType===""||values.scheduleType==="None"||values.scheduleType==="Time"||values.pmMeterBasedScheduler.triggertype.toUpperCase()==="GAUGE"}
                            
                            sx={{
                              ".MuiInputBase-input": {
                                // letterSpacing: "0.2rem",

                                "&::placeholder": {
                                  // color: "green",
                                  opacity: 1,
                                  color: color.placeholderColor,
                                  fontSize: "13px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        {/* <Grid container item xs={12} md={4} alignItems={"flex-end"} >
              
            <FormControlLabel value="Fixed" checked={values.pmType==="fixed"} 
            control={<Radio 
            onChange={()=>{
              setFieldValue("pmType","fixed")
             
            }} 
            />} 
            label="Fixed" />
            <FormControlLabel value="Floating" checked={values.pmType==="floating"} 
            control={<Radio 
              onChange={()=>{
                setFieldValue("pmType","floating")
             
              }} 
               />} 
            label="Floating" />
           
            </Grid> */}
                      </Grid>
                      {/* <Grid
                        container
                        item
                        xs={12}
                        spacing={"1rem"}
                        sx={{
                          display:
                            values.pmMeterBasedScheduler.triggertype.toUpperCase() ===
                            "GAUGE"
                              ? "default"
                              : "none",
                        }}
                      >
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Meter Id
                          </Typography>
                          <Field
                            as={Select}
                            // label={"Select"}
                            size={"small"}
                            type="text"
                            // name="pmMeterBasedScheduler.gaugeTrigger.meterId"
                            disabled={values.scheduleType===""||values.scheduleType==="None"||values.scheduleType==="Time"||values.pmMeterBasedScheduler.triggertype.toUpperCase()==="CONTINUOUS"}
                            
                            fullWidth
                            displayEmpty
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: "40vh" } },
                            }}
                            renderValue={
                              0 !== null
                                ? undefined
                                : () => (
                                    <Typography
                                      sx={{
                                        color: color.placeholderColor,
                                        fontSize: "13px",
                                      }}
                                    >
                                      Select
                                    </Typography>
                                  )
                            }
                          >
                            {meter.map((id, i) => (
                              <MenuItem key={i} value={id.id}>
                                {id.meterName}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            When
                          </Typography>
                          <Box sx={{ display: "flex", gap: "1rem" }}>
                            <Field
                              as={Select}
                              // label={"Select"}
                              size={"small"}
                              type="text"
                              name=""
                              value="1"
                              fullWidth
                              displayEmpty
                              disabled={values.scheduleType===""||values.scheduleType==="None"||values.scheduleType==="Time"||values.pmMeterBasedScheduler.triggertype.toUpperCase()==="CONTINUOUS"}
                            
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: "40vh" } },
                              }}
                              //  renderValue={values.pmMeterBasedScheduler.continourTrigger.meterId !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                              //   fontSize: "13px",}} >Select</Typography> }
                            >
                              <MenuItem value={"1"}>Gallon(g)</MenuItem>
                            </Field>

                            <Field
                              as={Select}
                              // label={"Select"}
                              size={"small"}
                              type="text"
                              name=""
                              value="1"
                              fullWidth
                              disabled={values.scheduleType===""||values.scheduleType==="None"||values.scheduleType==="Time"||values.pmMeterBasedScheduler.triggertype.toUpperCase()==="CONTINUOUS"}
                            
                              displayEmpty
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: "40vh" } },
                              }}
                              //  renderValue={values.pmMeterBasedScheduler.continourTrigger.meterId !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                              //   fontSize: "13px",}} >Select</Typography> }
                            >
                              <MenuItem value={"1"}>Reading less than</MenuItem>
                            </Field>
                            <Field
                              as={TextField}
                              size={"small"}
                              type="number"
                              name="check"
                              value={0}
                              fullWidth
                              disabled={values.scheduleType===""||values.scheduleType==="None"||values.scheduleType==="Time"||values.pmMeterBasedScheduler.triggertype.toUpperCase()==="CONTINUOUS"}
                            
                              sx={{
                                ".MuiInputBase-input": {
                                  // letterSpacing: "0.2rem",

                                  "&::placeholder": {
                                    // color: "green",
                                    opacity: 1,
                                    color: color.placeholderColor,
                                    fontSize: "13px",
                                  },
                                },
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid> */}
                    </Grid>
                  </Box>
                </TabPanel>
              </Box>
            </TabContext>
            <Grid
              container
              item
              xs={11.9}
              justifyContent={"flex-end"}
              gap="1rem"
            >
              <Button
                onClick={handleCancel}
                variant="contained"
                sx={{
                  backgroundColor: color.TableCancelButton,
                  textTransform: "none",
                  color: color.CancelTextColor,
                  ":hover": {
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                type={"submit"}
                variant="contained"
                disabled={loading}
                sx={{
                  textTransform: "none",
                  backgroundColor: loading ? "default" : color.TableButtonColor,
                  "&:hover": {
                    backgroundColor: loading ? "default" : color.buttonDisable,
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CreatePM;
