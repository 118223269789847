import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Field, Form, Formik } from "formik";
  import * as Yup from "yup";
  import { Clear } from "@mui/icons-material";
  import { color } from "../../../../StyleData/Config";
  import { axiosPrivate } from "../../../../axios/axios";
 
import NormalTable from "../../../../Component/NormalTable";
import { masterAPI } from "../../../Masters/dataConfig";
  
  const QuestionList = ({  handleCloseClick,assetInfo }) => {
    const [questionData, setQuestionData] = useState([]);
    
  
    useEffect(() => {
     
     getData()
    }, []);

    const getData=async()=>{
      const assetQuesLink=masterAPI["assetTypeQuestion"].getByAssetTypeId;
     await axiosPrivate
        .get(`${assetQuesLink}?assetstypeid=${assetInfo.assetTypeId}`)
        .then((res) => {
          setQuestionData(res.data.result)
         
        })
        .catch((e) => {
         setQuestionData([])
          console.log(e, "error");
        });
       
    }



    const columns = [
        {
          Header: "Question",
          accessor: "question",
        
        },
        // {
        //   Header: "Asset Name",
        //   accessor: "assetName",
        // },
        // {
        //   Header: "Asset Type",
        //   accessor: "assetTypeName",
        // },
        
      ];
  
  
  
    const handleCancel = () => {
      handleCloseClick();
    };
  
    return (
      <Box sx={{ p: color.masterPageDialogPadding}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
            {assetInfo.assetCode+"-"+assetInfo.assetName +"(Question)"}
          </Typography>
          <IconButton onClick={handleCancel}>
            <Clear />
          </IconButton>
        </Box>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        <Box>
        <NormalTable data={questionData} columns={columns} />
      </Box>
      </Box>
    );
  };
  
  export default QuestionList;
  