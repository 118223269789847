import { Checkbox, Divider, FormControlLabel, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { color } from '../../../../../StyleData/Config'
import { useSearchParams } from 'react-router-dom'

const General = ({data}) => {
   
  return (
    <Paper elevation={3} sx={{p:1}}>
      <Grid container>
        <Grid item xs={6}>
          <Grid container spacing={"1rem"}>
            <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Permit Code :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               {data}
                </Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Permit Request Code :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               PRQ00209
                </Typography>
            </Grid>
          </Grid>
          

        </Grid>
        <Grid item xs={6}>
        <Grid container spacing={"1rem"} justifyContent={"flex-end"}>
            <Grid item xs={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Permit Status :
                </Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               Issued
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Permit Type :
                </Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               Normal
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Certificate :
                </Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
              
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Document Type :
                </Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               Permit
                </Typography>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px",mt:"15px"}} />
        <Grid container spacing={"1rem"}>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Orig. made by :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               SCEENGRD
                </Typography>
            </Grid>
             </Grid>
             <Grid item container xs={4} spacing={"1rem"}>
             <Grid item xs={5}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Telephone :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
              
                </Typography>
            </Grid>
             </Grid>
             <Grid item container xs={3} spacing={"1rem"}>
             <Grid item xs={5}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               User Group :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               SCE
                </Typography>
            </Grid>
             </Grid>
            
           
          </Grid>
          

        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Competence Person :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               SCEENGRD
                </Typography>
            </Grid>
             </Grid>
             <Grid item container xs={4} spacing={"1rem"}>
             <Grid item xs={5}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Telephone :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
              
                </Typography>
            </Grid>
             </Grid>
            
            
           
          </Grid>
          

        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Printed by :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
             
                </Typography>
            </Grid>
             </Grid>
            
            
            
           
          </Grid>
          

        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Required Date :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               09/06/2016 20:33
                </Typography>
            </Grid>
             </Grid>
             <Grid item container xs={4} spacing={"1rem"}>
             <Grid item xs={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Duration,days :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
              12
                </Typography>
            </Grid>
             </Grid>
            
            
           
          </Grid>
          

        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Issue Date :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               09/06/2016 20:33
                </Typography>
            </Grid>
             </Grid>
             <Grid item container xs={4} spacing={"1rem"}>
             <Grid item xs={5}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
              Issued by :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
              SCEENGRD
                </Typography>
            </Grid>
             </Grid>
            
            
           
          </Grid>
          

        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Expire Date :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               21/06/2016 20:33
                </Typography>
            </Grid>
             </Grid>
             <Grid item container xs={4} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
            
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
              Expired
                </Typography>
            </Grid>
             </Grid>
            
            
           
          </Grid>
          

        </Grid>
      
      </Grid>
      <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px",mt:"15px"}} />
        <Grid container spacing={"0.5rem"}>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Contractor :
                </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                }}
                >
                    
                </Typography>
            </Grid>
             </Grid>
             <Grid item container xs={4} spacing={"1rem"}>
             <Grid item xs={5}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Telephone :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
              
                </Typography>
            </Grid>
             </Grid>
             <Grid item container xs={3} spacing={"1rem"}>
             <Grid item xs={12}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               <FormControlLabel  control={<Checkbox />} label="Contractor Briefed" />
                </Typography>
            </Grid>
          
             </Grid>
            
           
          </Grid>
          

        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Holder of permit :
                </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                }}
                >
                  Thenish  
                </Typography>
            </Grid>
             </Grid>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Number of persons :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
              0
                </Typography>
            </Grid>
             </Grid>
             
            
           
          </Grid>
          

        </Grid>
        </Grid>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px",mt:"15px"}} />
        <Grid container spacing={"0.5rem"}>
        
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Location :
                </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                }}
                >
                  FREIGHT  
                </Typography>
            </Grid>
             </Grid>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={5}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Created Date :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
              08/08/2016 20:33
                </Typography>
            </Grid>
             </Grid>
             
            
           
          </Grid>
          

        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Asset :
                </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                }}
                >
                  A0001 
                </Typography>
            </Grid>
             </Grid>
            
             
            
           
          </Grid>
          

        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Work Description :
                </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                }}
                >
                  To replace CT3 pvc infills(isolation needed 9/82016 9am) - WO:67249  
                </Typography>
            </Grid>
             </Grid>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Isolation Description :
                </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                }}
                >
                  
                </Typography>
            </Grid>
             </Grid>
             
            
           
          </Grid>
          

        </Grid>
        </Grid>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px",mt:"15px"}} />
        <Grid container spacing={"0.5rem"}>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={5} spacing={"1rem"}>
             <Grid item xs={12}>
             <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    ml:"1rem",
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               <FormControlLabel  control={<Checkbox />} label="Hot Work" />
                </Typography>
            </Grid>
            
             </Grid>
         
             <Grid item container xs={3} spacing={"1rem"}>
             <Grid item xs={12}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               <FormControlLabel  control={<Checkbox />} label="Confined Space" />
                </Typography>
            </Grid>
          
             </Grid>
            
           
          </Grid>
          

        </Grid>
      
        </Grid>


    </Paper>
  )
}

export default General