import React, { useEffect, useState } from 'react'
import { Paper, Typography,Dialog,Grid,Box,IconButton,Divider } from '@mui/material'
import { color } from "../../../StyleData/Config";
import { DeleteOutline, LibraryAdd } from "@mui/icons-material";

import { masterAPI } from '../../Masters/dataConfig'
import { axiosPrivate } from '../../../axios/axios'
import NormalTable from '../../../Component/NormalTable'
import AssetPart from './AssetPart'

const Parts = ({data}) => {
    
    const [partsData,setPartsData]=useState([])
    const [add,setAdd]=useState(false)

    useEffect(()=>{
  getData()
    },[])

    const getData=async()=>{
        const assetId=masterAPI["assetPart"].assetParts
        console.log(data)
       await axiosPrivate.get(`${assetId}/${data}`).then(res=>{
            console.log(res.data)
            setPartsData(res.data.result)
        }).catch(e=>{
          setPartsData([])
            console.log(e,"assetPartsError")
        })
    }
    const handleAdd=()=>{
        setAdd(true)
          }
        
          const handleClose=()=>{
            setAdd(false)
            getData()
          }
          const handleDelete =async (id) => {
            const deleteLink = masterAPI["assetPart"].delete;
           await axiosPrivate
              .delete(`${deleteLink}/${id}`)
              .then((res) => {
                console.log(res.data);
                getData();
              })
              .catch((e) => {
                console.log(e, "delete assets");
              });
            }
    const columns=[
        {
            Header: "Part Code",
            accessor: "code",
            disableFilters: true,
            Cell:({cell})=>{
                return (
                  <Typography sx={{fontSize:"inherit",fontWeight:"inherit"}}>{cell.value}</Typography>
                  )
              }
        },
        {
            Header: "Part Name",
            accessor: "partName",
            disableFilters: true,
        },
        {
            Header: "Qty",
            accessor:"partQuantity",
            disableFilters: true,
          
        },
        {
            Header: "Actions",
            Cell: ({ cell }) => {
              // console.log(cell)
              return (
                <Box sx={{ whiteSpace: "nowrap" }}>
                  {/* <IconButton>
                    <EditOutlined
                      onClick={() => {
                        handleEdit(cell.row.original);
                      }}
                      sx={{
                        fontSize: color.masterEditDelButton,
                        color: color.masterEditDelButtonColor,
                      }}
                    />
                  </IconButton> */}
                  <IconButton  onClick={() => {
                        handleDelete(cell.row.original.id);
                      }}>
                    <DeleteOutline
                     
                      sx={{
                        fontSize: color.masterEditDelButton,
                        color: color.masterEditDelButtonColor,
                      }}
                    />
                  </IconButton>
                </Box>
              );
            },
          },
    ]

  return (
    <Paper sx={{p:1}}>
    <Grid container>
        <Grid  item xs={12}>
          <Box sx={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:'center'}}>
            <Typography sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}>Parts</Typography>
           <Box sx={{display:"flex",alignItems:"center"}}>
    <IconButton onClick={handleAdd}>
        <LibraryAdd/>
    </IconButton>
    <Typography>Add</Typography>
    </Box>
          </Box>
          <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}}/>
        </Grid>
        <Grid item xs={12}>
      
        <NormalTable columns={columns} data={partsData}/>
        <Dialog
        open={add}
        onClose={handleClose}
        maxWidth={"md"}
       fullWidth
        children={
          <AssetPart
            handleCloseClick={handleClose}
         
          />
        }
      />
   
        </Grid>
    </Grid>
    </Paper>
  )
}

export default Parts