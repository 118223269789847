import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import { Box, Checkbox, Dialog, IconButton, Switch, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import PartsModal from "./PartsModal";
import {useSelector} from "react-redux"


const Parts = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [filterValue, setFilterValue] = useState("enableData");
  const [backUpData, setBackUpData] = useState([]);
  const actionsAllow=useSelector(state=>state.dataRed.actionsAllow)

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["parts"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        res.data.result.sort((a, b) => {
          return a.code.localeCompare(b.code, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        if (filterValue === "enableData") {
          const filterDataResult = res.data.result.filter(
            (fil) => fil.isActive
          );
          console.log(filterDataResult, "filterData");
          setData(filterDataResult);
          setBackUpData(res.data.result);
        } else {
          setData(res.data.result);
          setBackUpData(res.data.result);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "parts");
        setIsLoading(false);
        setData([])
        setBackUpData([])
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  // const handleDelete = (id) => {
  //   const deleteLink = masterAPI["parts"].delete;
  //   axiosPrivate
  //     .delete(`${deleteLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "delete parts");
  //     });
  // };

  const handleDelete = async(e, id) => {
    const enableLink = masterAPI["parts"].enable;
    const disableLink = masterAPI["parts"].disable;
    if (e.target.checked) {
     await axiosPrivate
        .put(`${enableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "enable parts");
        });
    } else {
     await axiosPrivate
        .put(`${disableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "disable parts");
        });
    }
  };

  const columns = [
    {
      accessor: "id",
      disableFilters: true,
      Cell: () => {
        return (
          <Box sx={{width:color.checkBoxWidth }}>
          <Checkbox
            // icon={<RadioButtonUncheckedOutlined />}
            // checkedIcon={<CheckCircle />}
          />
          </Box>
        );
      },
    },
    {
      Header: "Part Code",
      accessor: "code",
      Cell: ({ cell }) => {
        return (
          <Link
            to={`/partview?id=${cell.row.original.id}`}
            
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: "Part Name",
      accessor: "partName",
      // disableFilters: true,
    },
   
    {
      Header: " Part Quantity",
      accessor: "partQuantity",
    },
    {
      Header: "Part Group Name",
      accessor: "partGroupName",
    },
    {
      Header: "Purchased Uom Name",
      accessor: "purchasedUomName",
    },
    {
      Header: "Issued Uom Name",
      accessor: "issuedUomName",
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton
            disabled={!actionsAllow.edit} 
            >
              <EditOutlined
                onClick={() => {
                  handleEdit(cell.row.original);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color:actionsAllow.edit? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton>
            {/* <IconButton
            disabled={!actionsAllow.delete}
            >
              <DeleteOutline
                onClick={() => {
                  handleDelete(cell.row.original.id);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color:actionsAllow.delete? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton> */}
            <Switch
              disabled={!actionsAllow.delete}
              checked={cell.row.original.isActive}
              onChange={(e) => {
                handleDelete(e, cell.row.original.id);
              }}
            />
          </Box>
        );
      },
    },
  ];

  const handleChangeFilter = (e) => {
    if (e === "showAll") {
      setData(backUpData);
    } else {
      const filterDataResult = backUpData.filter((fil) => fil.isActive);

      setData(filterDataResult);
    }
    setFilterValue(e);
  };

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["parts"].title}
        </Typography>
      </Box>
     
        <TableContainer
          columns={columns}
          data={data}
          buttonNew={handleButtonNew}
          loading={isLoading}
          buttonDisable={!actionsAllow.create}
          filterValue={filterValue}
          handleChange={handleChangeFilter}
          filterShow={true}
        />
   

      <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"md"}
        children={
          <PartsModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      />
    </>
  );
};

export default Parts;
