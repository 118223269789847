import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { masterAPI } from "../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import NormalTable from "../../../../Component/NormalTable";
import { color } from "../../../../StyleData/Config";

const LinkedRec = ({ data }) => {
  const [docData, setDocData] = useState([]);
  const [columnType,setColumnType]=useState("")

  useEffect(() => {
  getData()
  }, []);

  const getData=async()=>{
    const linkRec=masterAPI["workOrder"].linkedRec
 await axiosPrivate.get(`${linkRec}/${data.id}`).then(res=>{
    console.log(res)
    if(res.data.result.serviceRequest){
      setColumnType("serviceReq")
       setDocData([res.data.result.serviceRequest])
    }else if(res.data.result.preventiveMaintenance){
      setColumnType("pm")
          setDocData([res.data.result.preventiveMaintenance])
    }
  }).catch(e=>{
    setDocData([])
    setColumnType("")
    console.log(e,"Linked Record")
  })
    // if(data.serviceRequest){
    //   const serviceRequest=masterAPI["serviceRequest"].getByid
    //   axiosPrivate.get(`${serviceRequest}/${data.serviceRequestId}`).then(res=>{
    //     console.log(res.data);
    //     setColumnType("serviceReq")
    //     setDocData(res.data.result)
    //   }).catch(e=>{
    //     console.log(e,"get service request linked Rec")
    //   })
    // }else if(data.preventiveMaintenance){
    //   const pm=masterAPI["PM"].getByid
    //   axiosPrivate.get(`${pm}/${data.pmId}`).then(res=>{
    //     console.log(res.data);
    //     setColumnType("pm")
    //     setDocData(res.data.result)
    //   }).catch(e=>{
    //     console.log(e,"get pm linked Rec")
    //   })
    // }
    //   const data=[{
    //     id:"SPDF2021",
    //     description:"Racking Assemble(A144)",
    //     statusId:"JDF05242.0",
    //     planStartDate:"2023-10-15",
    //     planEndDate:"2023-11-15",
    //     startDate:"2023-10-14",
    //     endDate:"2023-11-14",
    //   },
    //   {
    //     id:"SPDF2021",
    //     description:"Racking Assemble(A144)",
    //     statusId:"JDF05242.0",
    //     planStartDate:"2023-10-15",
    //     planEndDate:"2023-11-15",
    //     startDate:"2023-10-14",
    //     endDate:"2023-11-14",
    //   },
    //   {
    //     id:"SPDF2021",
    //     description:"Racking Assemble(A144)",
    //     statusId:"JDF05242.0",
    //     planStartDate:"2023-10-15",
    //     planEndDate:"2023-11-15",
    //     startDate:"2023-10-14",
    //     endDate:"2023-11-14",
    //   },
    // ]
    //   setWorkData(data)
  }

  const srcolumns = [
      
    {
      Header:"Service Request Code",
      accessor:"code"
    },
    {
      Header:"Service Request Name",
      accessor:"serviceRequestName"
    },
    {
      Header:"Description",
      accessor:"description"
    },
    {
      Header:"Asset Name",
      accessor:"assetName"
    },
    {
      Header:"Loaction",
      accessor:"locationName"
    },
    {
      Header: "Failure Code Name",
      accessor: "failureName",
      // disableFilters: true,
    },
    {
      Header: "Failure Class Name",
      accessor: "failureClassName",
    },
   
  ];

  const pmcolumns = [
  
    {
      Header: "PM Code",
      accessor: "code",
      // disableFilters: true,
      
    },
    {
      Header: "PM Name",
      accessor: "name",
    },
    {
      Header: "Asset",
      accessor: "assetCode",
    },
    {
      Header: "PM Status",
      accessor: "status",
    },
    {
        Header: "Job Plan",
        accessor: "jobPlanCode",
      },
   
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
    <Box>
      <Typography
        sx={{
          color: color.sideTitleColor,
          fontSize: color.fontSizeSide,
          fontWeight: color.sideWeightFont,
        }}
      >
        Linked Records
      </Typography>
    </Box>
    <Paper>
     {docData.length===0?<Typography sx={{m:1,textAlign:"center"}}>No Record</Typography>:
     <NormalTable columns={columnType=="pm"?pmcolumns:srcolumns} data={docData} />}
      
    </Paper>
    </Box>
  );
};

export default LinkedRec;
