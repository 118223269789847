import React from 'react';
import {InputAdornment, TextField} from "@mui/material"
import { CalendarViewMonth, CalendarViewMonthTwoTone } from '@mui/icons-material';

export const Filter = ({ column }) => {
  
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter? column.render('Filter'):<div style={{height:"2.3em"}}></div>}
    </div>
  );
};

export const GlobalFilter=({filter,setFilter})=>{
  // console.log(filter)
  return(
    <TextField
    variant='standard'
    size='small'
      value={filter || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <CalendarViewMonthTwoTone
              
            />
          </InputAdornment>
        ),
      }}
      // placeholder={`${window.location.pathname}lll`}
      placeholder={`Search`}
      sx={{
        ".css-nz481w-MuiInputBase-input-MuiInput-input": {
         
          // letterSpacing: "0.2rem",
         
          "&::placeholder": {
            // color: "green",
      opacity:1,
         color:"#7A7A7A",
            fontSize: "1rem",
          },
        },
        
      }}
    />
  )

}

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
    Header
  },
 
}) => {

  return (
    <TextField
    variant='standard'
    size='small'
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // placeholder={`${window.location.pathname}`}
      placeholder={`${Header}`}
      sx={{
        ".css-nz481w-MuiInputBase-input-MuiInput-input": {
         
          // letterSpacing: "0.2rem",
         
          "&::placeholder": {
            // color: "green",
      opacity:1,
         color:"#7A7A7A",
            fontSize: "0.9rem",
          },
        },
        
      }}
    />
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <TextField
      id='custom-select'
      type='select'
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value=''>All</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </TextField>
  );
};
