import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Add, Clear, DeleteOutlined, EditOutlined, Refresh } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";
import moment from "moment";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import NormalTable from "../../../Component/NormalTable";

const ReceiptModal = ({ mode, handleCloseClick, editData }) => {
  const [businessData, setBusinessId] = useState([]);
  const [resetData,setResetData]=useState(false)
  const [receiptItem,setReceiptItem]=useState({
    partId: null,
    stockRoomId: null,
  stockRoomRowId: null,
  stockRoomRowAisleId: null,
  stockRoomRowBinId: null,
  receivedQuantity: 0,
  inventoryQuantity: 0,
  unitPrice: 0,
  isQualityChecked: true,
  conversionFactor: 1,
  receivedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
  stockAisle:[],
  stockRow:[],
  stockBin:[],
  stockRoom:[]
  })

  const [partData, setPartData] = useState([]);


  const [addRI, setAddRI] = useState([]);
  const [partSelect, setPartSel] = useState("");
  const [srSelect, setSRSel] = useState("");
  const [srAisleSelect, setSRAisleSel] = useState("");
  const [srRowSelect, setSRRowSel] = useState("");
  const [srBinSelect, setSRBinSel] = useState("");

  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    
  getData()
  }, []);

  const getData=async()=>{
    setLoading(true);

    //////// business ///////

    const link = masterAPI["business"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setLoading(false);
        setBusinessId(res.data.result);
      })
      .catch((e) => {
        setLoading(false)
        setBusinessId([])
        console.log(e, "create BusinessetAll");
      });

      const partLink=masterAPI["parts"].link
     await axiosPrivate.get(`${partLink}`).then(res=>{
        console.log(res,"partLink")
        setPartData(res.data.result)
      }).catch(e=>{
        setPartData([])
         console.log(e,"error part Data")
      })
     
      // const stockRoomLink=masterAPI["stockRoom"].link
      // axiosPrivate.get(`${stockRoomLink}`).then(res=>{
      //   console.log(res,"stockRoomLink")
      //   setStockRoomData(res.data.result)
      // }).catch(e=>{
      //    console.log(e,"error stockRoom Data")
      // })
  }

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const initialValue = {
    businessId: mode === "Edit" ? editData.businessId : null,
    doNumber: mode === "Edit" ? editData.doNumber : "",
    poNumber: mode === "Edit" ? editData.poNumber : "",
    totalReceivedPrice: mode === "Edit" ? editData.totalReceivedPrice : "",

    receivedDate: mode === "Edit" ? editData.receivedDate : moment().format("YYYY-MM-DDTHH:mm:ss"),
    glAccount: mode === "Edit" ? editData.glAccount : "",
   
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["receipt"].create;
    const updateLink = masterAPI["receipt"].update;
    if (mode === "Create") {
      const postData={
        ...value,
        receiptItemsList:addRI
      }
      console.log(postData)
     await axiosPrivate
        .post(`${createLink}`, postData)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "create Receipt");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "update receipt");
        });
    }
  };

  const handlePart=async(value)=>{
    // setCauseSel(value.causeCode+"_"+value.causeName)
    // setPartInfo(value)
    setResetData(false)
    const srLink=masterAPI["selectList"].stockRoomByPart
   await axiosPrivate.get(`${srLink}/${value.id}`).then(res=>{
      console.log(res)
      const storeRI={
        partId:value.id,
        partCode:value.code,
        partName:value.partName,
        stockRoom:res.data,
        stockRoomId: null,
        stockRoomRowId: null,
        stockRoomRowAisleId: null,
        stockRoomRowBinId: null,
        receivedQuantity: 0,
        inventoryQuantity: 0,
        unitPrice: 0,
        isQualityChecked: true,
        conversionFactor: 1,
        receivedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        stockAisle:[],
        stockRow:[],
        stockBin:[]
     
      }
      setSRAisleSel("")
      setSRBinSel("")
      setSRRowSel("")
      setSRSel("")
      setReceiptItem(storeRI)
    }).catch(e=>{
      console.log(e)
    })
   
    
    }

    const handleAddRI=(e,totalReceivedPrice)=>{
      const {name,value,checked}=e.target
      if(name==="receivedQuantity"){
        const storeRI={
          ...receiptItem,
          [name]:name==="isQualityChecked"?checked:value,
          inventoryQuantity:value,
          unitPrice:totalReceivedPrice/value
       
        }
        setReceiptItem(storeRI)

      }else{
        const storeRI={
          ...receiptItem,
          [name]:name==="isQualityChecked"?checked:value,
        
       
        }
      
      
      setReceiptItem(storeRI)
    }
     
    }

  const handleCancel = () => {
    handleCloseClick();
  };

  const handleStockRoom=async(value)=>{
    if(resetData){
      const getFullSRA=masterAPI["selectList"].stockRoomAisle
     await axiosPrivate.get(`${getFullSRA}/${value.value}`).then(res=>{
        const storeStockRoom={
          ...receiptItem,
          stockRoomId:value.value,
          // stockRoomCode:value.stockRoomCode,
          stockRoomName:value.text,
          stockAisle:res.data
        }
  
      
        setReceiptItem(storeStockRoom)
      }).catch(e=>{
        console.log(e)
      })
    }else{
    const getAisleLink=masterAPI["selectList"].aisleByPartAndRoom
   await axiosPrivate.get(`${getAisleLink}/${receiptItem.partId}/${value.value}`).then(res=>{
      const storeStockRoom={
        ...receiptItem,
        stockRoomId:value.value,
        // stockRoomCode:value.stockRoomCode,
        stockRoomName:value.text,
        stockAisle:res.data
      }

    
      setReceiptItem(storeStockRoom)
    }).catch(e=>{
      console.log(e,"error")
    })
  }
  }
  const handleStockAisle=async(value)=>{
    if(resetData){
      const getFullSRAR=masterAPI["selectList"].stockRoomAisleRow
     await axiosPrivate.get(`${getFullSRAR}/${value.value}`).then(res=>{
        const storeStockRoom={
          ...receiptItem,
          stockRoomRowAisleId:value.value,
         
          aisleName:value.text,
          stockRow:res.data
        }
  
      
        setReceiptItem(storeStockRoom)
      }).catch(e=>{
        console.log(e)
      })
    }else{
    const getRowLink=masterAPI["selectList"].rowByPartAndAisle
   await axiosPrivate.get(`${getRowLink}/${receiptItem.partId}/${value.value}`).then(res=>{
     
      const storeStockRoom={
        ...receiptItem,
        stockRoomRowAisleId:value.value,
       
        aisleName:value.text,
        stockRow:res.data
      }

    
      setReceiptItem(storeStockRoom)
    }).catch(e=>{
      console.log(e,"error")
    })
  }
  }
  const handleStockRow=async(value)=>{
    if(resetData){
      const getFullSRARB=masterAPI["selectList"].stockRoomAisleRowBin
     await axiosPrivate.get(`${getFullSRARB}/${value.value}`).then(res=>{
        const storeStockRoom={
          ...receiptItem,
          stockRoomRowId:value.value,
         
          rowName:value.text,
          stockBin:res.data
        }
  
      
        setReceiptItem(storeStockRoom)
      }).catch(e=>{
        console.log(e)
      })
    }else{

    
    const getBinLink=masterAPI["selectList"].binByPartAndRow
   await axiosPrivate.get(`${getBinLink}/${receiptItem.partId}/${value.value}`).then(res=>{
      const storeStockRoom={
        ...receiptItem,
        stockRoomRowId:value.value,
       
        rowName:value.text,
        stockBin:res.data
      }

    
      setReceiptItem(storeStockRoom)
    }).catch(e=>{
      console.log(e,"error")
    })
  }
  }
  const handleStockBin=(value)=>{
   
    const storeStockRoom={
      ...receiptItem,
      stockRoomRowBinId:value.value,
      // binCode:value.binCode,
      binName:value.text
      
    }

  
    setReceiptItem(storeStockRoom)
   
  }

  const handleRIList=()=>{
    const addlist=[...addRI,receiptItem]
    console.log(addlist)
    setAddRI(addlist)
    setResetData(false)
    setReceiptItem({
      partId: null,
      stockRoomId: null,
    stockRoomRowId: null,
    stockRoomRowAisleId: null,
    stockRoomRowBinId: null,
    receivedQuantity: 0,
    inventoryQuantity: 0,
    unitPrice: 0,
    isQualityChecked: true,
    conversionFactor: 1,
    receivedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    stockAisle:[],
    stockRow:[],
    stockBin:[],
    stockRoom:[]
    })
    setPartSel("")
    setSRAisleSel("")
    setSRBinSel("")
    setSRRowSel("")
    setSRSel("")
  }

  const handlePartEdit=(data)=>{
    const filterAddIssue=addRI.filter((fil,i)=>i!==Number(data.row.id))
    setReceiptItem({...data.row.original})
    setSRSel(data.row.original.stockRoomName)
    setPartSel(data.row.original.partCode+"-"+data.row.original.partName)
    setSRAisleSel(data.row.original.aisleName)
    setSRRowSel(data.row.original.rowName)
    setSRBinSel(data.row.original.binName)
    setAddRI(filterAddIssue)
  }

 

  const partColumn = [
    {
      Header: "Part",
      accessor: "partCode",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              color: "#0d6efd",
              fontSize: "inherit",
              fontWeight: 600,
              cursor: "pointer",
            }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.value+" - "+cell.row.original.partName}
          </Typography>
        );
      },
      // disableFilters:true,
    },
  {
      Header: "Stock Name",
      accessor: "stockRoomName",
 
    },
    {
      Header: "Aisle",
      accessor: "aisleName",
    },
    {
      Header: "Row",
      accessor: "rowName",
    },
    {
      Header: "Bin",
      accessor: "binName",
    },
    {
      Header: "Received Quantity",
      accessor: "receivedQuantity",
      // disableFilters:true,
    }, 
    {
      Header: "Inventory Quantity",
      accessor: "inventoryQuantity",}, 
    {
      Header: "Unit Price",
      accessor: "unitPrice",
 
    }, 
    {
      Header: "Quality Checked",
      accessor: "isQualityChecked",
      Cell:({cell})=>{
        return (
          <Typography sx={{fontSize:"inherit",color:"inherit",fontWeight:"inherit"}}>
           {cell.value?"Yes":"No"}
          </Typography>
        )
      }
    }, 
  
    {
      Header: "Conversion Factor",
      accessor: "conversionFactor",

    },
    {
      Header: "Received Date",
      accessor: "receivedDate",

    },
    
 

    // {
    //   Header: "Remedy",
    //   accessor: "remedyList",
    //   Cell:({cell})=>{
        
    //     return (

    //       <Autocomplete
       
    //       //inputValue={cell.row.original.remedyCode+"_"+cell.row.original.remedyName}
    //       value={cell.row.original.remedyId===null?null:cell.row.original}
    //       fullWidth
    //       filterOptions={filterOptions}
    //       // loading={causeLoading}
    //       options={cell.value}
    //       PaperComponent={CustomPaper}
    //       getOptionLabel={(option) =>
    //         option.remedyCode + "_" + option.remedyName || option
    //       }
    //       freeSolo
    //       onChange={(event, value) => {
    //         console.log(value,event)
    //         if(value!==null){
    //          handleRemedy(value)
    //         }else{
    //          removeRemedy(cell.row.original.causeId)
    //           // setCauseSel("")
    //           // setCauseInfo({})
    //         }
           
    //       }}
    //       renderInput={(params) => (
    //         <TextField
    //           {...params}
    //           name="remedy"
    //           placeholder="Select Remedy"
    //           //  onChange={handleChange}
    //           variant="outlined"
    //           size="small"
    //           fullWidth
    //           sx={{width:"20vw"}}
    //           InputProps={{
    //             ...params.InputProps,
    //             endAdornment: (
    //               <React.Fragment>
    //                 {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
    //                 {false ? (
    //                   <CircularProgress color="inherit" size={20} />
    //                 ) : null}
    //                 {params.InputProps.endAdornment}
    //               </React.Fragment>
    //             ),
    //           }}
    //         />
    //       )}
    //     />
    //     )
    //   },
    //   disableFilters:true,
    // },
    {
      Header:"Action",
      accessor:"partId",
      Cell:({cell})=>{
        return (
          <>
        <IconButton >
              <EditOutlined
                onClick={() => {
                  handlePartEdit(cell);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton>
          <IconButton
          onClick={() => {
            deleteRI(cell.row.original.partId);
          }}
          >
          <DeleteOutlined
           
            sx={{
              fontSize: color.masterEditDelButton,
              color: color.masterEditDelButtonColor,
            }}
          />
        </IconButton>
        </>
        )
      },
      disableFilters:true,
    }
  ];

  const deleteRI=(id)=>{
    const delRI=addRI.filter(fil=>fil.partId!==id)
    setAddRI(delRI)
  }

  const handleRefresh=async()=>{
    const getFullSR=masterAPI["selectList"].stockRoom
   await axiosPrivate.get(`${getFullSR}`).then(res=>{
       setResetData(true)
        setReceiptItem({
         ...receiptItem,
          stockRoomId: null,
        stockRoomRowId: null,
        stockRoomRowAisleId: null,
        stockRoomRowBinId: null,
       
        stockAisle:[],
        stockRow:[],
        stockBin:[],
        stockRoom:res.data
        })
        setSRAisleSel("")
      setSRBinSel("")
      setSRRowSel("")
      setSRSel("")
       
    }).catch(e=>{
      console.log(e)
    })
  }



  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          New Receipt
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Business
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="businessId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.businessId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {businessData.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.businessName}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  DO Number
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="doNumber"
                  placeholder="Enter DO Number"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  PO Number
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="poNumber"
                  placeholder="Enter PO Number"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Total Received Price
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="number"
                  name="totalReceivedPrice"
                  placeholder="Enter Total Received Price"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Received Date
                </Typography>
               
           
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                  // views={['year', 'day', 'hours', 'minutes', 'seconds']}
               
                  format="DD/MM/YYYY hh:mm:ss A"
                    value={values.receivedDate===null?null:dayjs(values.receivedDate)}
                   
                   
                     readOnly
                    slotProps={{
                      textField: {
                        size: "small",
                        
                        fullWidth: true,
                      },
                    }}
                    fullWidth
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                          // "YYYY-MM-DDTHH:mm:ss" date format
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                             "YYYY-MM-DD"
                          );
                          const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                           setFieldValue("receivedDate",dateFormat)
                        }
                      }
                    }}
                  />
                </DemoItem>
              </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Gl Account
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="glAccount"
                  placeholder="Enter GL Account"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
              <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          Create Receipt Item
        </Typography>
        <Box sx={{display:"flex",gap:"1rem",alignItems:"center"}}>
        <IconButton onClick={handleRefresh} sx={{cursor:"pointer",backgroundColor:color.TableButtonColor}}>
           <Refresh sx={{color:"white"}} />
        </IconButton>
        <IconButton
         sx={{backgroundColor:color.TableButtonColor}} 
        onClick={handleRIList}
        >
          <Add sx={{color:"white"}}/>
        </IconButton>
        </Box>
        </Box>
        <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
              </Grid>
            
              <Grid container item xs={12} spacing={"1rem"} >
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Part
                </Typography>
                <Autocomplete
                // value={remedySelect}
                inputValue={partSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                loading={partData.length===0}
                options={partData}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.code + " - " + option.partName || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handlePart(value);
                    
                  }else{
                    setPartSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setPartSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="partId"
                    placeholder="Select Part"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                          {partData.length===0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Stock Room
                </Typography>
              
                
                 <Autocomplete
                inputValue={srSelect}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={receiptItem.stockRoom}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                   option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handleStockRoom(value);
                  }else{
                    setSRSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomId"
                    placeholder="Select Stock Room"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />

              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Aisle
                </Typography>
                {/* <Select
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="stockRoomRowAisleId"
                  value={receiptItem.stockRoomRowAisleId}
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    receiptItem.stockRoomRowAisleId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                  onChange={(e)=>{handleStockAisle(e.target.value)}}
                >
                  {receiptItem.stockAisle.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.aisleCode+"_"+id.aisleName}
                    </MenuItem>
                  ))}
                </Select> */}
                     <Autocomplete
                // value={remedySelect}
                inputValue={srAisleSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={receiptItem.stockAisle}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                   option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handleStockAisle(value);
                  }else{
                    setSRAisleSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRAisleSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomRowAisleId"
                    placeholder="Select Aisle"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Row
                </Typography>
                {/* <Select
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="stockRoomRowId"
                  value={receiptItem.stockRoomRowId}
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    receiptItem.stockRoomRowId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                  onChange={(e)=>{handleStockRow(e.target.value)}}
                >
                  {receiptItem.stockRow.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.rowCode+"_"+id.rowName}
                    </MenuItem>
                  ))}
                </Select> */}
                     <Autocomplete
                // value={remedySelect}
                inputValue={srRowSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={receiptItem.stockRow}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handleStockRow(value);
                  }else{
                    setSRRowSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRRowSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomRowId"
                    placeholder="Select Row"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Bin
                </Typography>
                {/* <Select
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="stockRoomRowBinId"
                  value={receiptItem.stockRoomRowBinId}
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    receiptItem.stockRoomRowBinId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                  onChange={(e)=>{handleStockBin(e.target.value)}}
                >
                  {receiptItem.stockBin.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.binCode+"_"+id.binName}
                    </MenuItem>
                  ))}
                </Select> */}
                     <Autocomplete
                // value={remedySelect}
                inputValue={srBinSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={receiptItem.stockBin}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handleStockBin(value);
                  }else{
                    setSRBinSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRBinSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomRowBinId"
                    placeholder="Select Bin"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Received Quantity
                </Typography>
                <TextField
                  size={"small"}
                  type="number"
                  name="receivedQuantity"
                  value={receiptItem.receivedQuantity}
                  placeholder="Enter Received Quantity"
                  fullWidth
                  onChange={(e)=>{handleAddRI(e,values.totalReceivedPrice)
                   
                  }}
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Inventory Quantity
                </Typography>
                <TextField
                  size={"small"}
                  type="number"
                  name="inventoryQuantity"
                  value={receiptItem.inventoryQuantity}
                  placeholder="Enter Inventory Quantity"
                  fullWidth
                  onChange={handleAddRI}
                  InputProps={{readOnly:true}}
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                    Conversion Factor
                </Typography>
                <TextField
                  size={"small"}
                  type="number"
                  name="conversionFactor"
                  value={receiptItem.conversionFactor}
                  placeholder="Enter Conversion Factor"
                  fullWidth
                  onChange={handleAddRI}
                  InputProps={{readOnly:true}}
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                    Unit Price
                </Typography>
                <TextField
                  size={"small"}
                  type="number"
                  name="unitPrice"
                  value={receiptItem.unitPrice}
                  placeholder="Enter Unit Price"
                  fullWidth
                  onChange={handleAddRI}
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                    Quality Check
                </Typography>
                <Switch checked={receiptItem.isQualityChecked} name="isQualityChecked" onChange={(e)=>{handleAddRI(e)}}  />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Received Date
                </Typography>
               
           
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                  // views={['year', 'day', 'hours', 'minutes', 'seconds']}
                 
                  format="DD/MM/YYYY hh:mm:ss A"
                    value={receiptItem.receivedDate===null?null:dayjs(receiptItem.receivedDate)}
                    
                    

                    slotProps={{
                      textField: {
                        size: "small",
                      
                        fullWidth: true,
                      },
                    }}
                    fullWidth
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                          // "YYYY-MM-DDTHH:mm:ss" date format
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                             "YYYY-MM-DD"
                          );
                          const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          const storeDate={
                            ...receiptItem,
                            receivedDate:dateFormat
                          }
                          setReceiptItem(storeDate)
                        }
                      }
                    }}
                  />
                </DemoItem>
              </LocalizationProvider>
              </Grid>
           
              </Grid>
              <Grid item xs={12}>
              <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          Receipt Item List
        </Typography>
      
        </Box>
        <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
              </Grid>
              <Grid item xs={12}>
              <NormalTable
          data={addRI}
          columns={partColumn}
          filt={true}
        />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading||addRI.length===0}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ReceiptModal;
