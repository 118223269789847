const Reducer = (
  state = {
    assetEditData: {
      edit: false,
      data: {},
    },
    workOrderEditData: {
      edit: false,
      data: {},
    },
    pmEditData: {
      edit: false,
      data: {},
    },
    inspScheduleData:{
      edit:false,
      data:{}
    }
  },
  actions
) => {
  switch (actions.type) {
    case "assetEditData":
      return { ...state, assetEditData: actions.payload };
    case "woEditData":
      return { ...state, workOrderEditData: actions.payload };
    case "pmEditData":
      return { ...state, pmEditData: actions.payload };
    case "inspScheduleData":
      return { ...state, inspScheduleData: actions.payload };

    default:
      return state;
  }
};

export const formReducer = Reducer;
