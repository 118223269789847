import React from "react";
import Chart from "react-apexcharts";

const MTTRChart = ({ dashBoardDatamttXaxis, dashBoardDatamttYaxis, type }) => {
  return (
    <Chart
      height={
        type
          ? dashBoardDatamttXaxis.length
            ? `${32 * dashBoardDatamttYaxis.length + 200}px`
            : "100%"
          : 200
      }
      width={"100%"}
      options={{
        responsive: [
          {
            breakpoint: 768, // Change the breakpoint as needed
            options: {
              chart: {
                width: 600,
              },
            },
          },
        ],
        series: [
          {
            data: dashBoardDatamttXaxis,
          },
        ],
        title: {
          text: "Mean Time To Repair",
          style: {
            fontFamily: "Nunito Sans, sans-serif",
            color: "#2362B4",
          },
        },

        chart: {
          type: "bar",
          toolbar: {
            export: {
              csv: {
                filename: "MTTR",
              },
              svg: {
                filename: "MTTR",
              },
              png: {
                filename: "MTTR",
              },
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                  fontFamily: "Nunito Sans, sans-serif",
                },
              },
            },
          },
          noData: {
            text: "No Result",
            style: {
              fontSize: "1.5rem",
              color: "grey",
              fontFamily: "Nunito Sans, sans-serif",
            },
          },

          stroke: {
            width: 1,
            colors: ["#fff"],
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "Asset",
          },
          labels:{
            show:type
          }
        },
        xaxis: {
          title: {
            text: "Hours",
          },
          categories: dashBoardDatamttYaxis,
          // labels: {
          //   style: {
          //     fontFamily: "Nunito Sans, sans-serif",
          //   },
          // },
        },
      }}
      type="bar"
      series={[{ data: dashBoardDatamttXaxis }]}
    />
  );
};

export default MTTRChart;
