import {
    Box,
    Button,
    Checkbox,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Field, Form, Formik } from "formik";
  import * as Yup from "yup";
  import { Clear, Save } from "@mui/icons-material";
  import { color } from "../../../StyleData/Config";
  import { axiosPrivate } from "../../../axios/axios";
  import { masterAPI } from "../../Masters/dataConfig";
import NormalTable from "../../../Component/NormalTable";
import { useSearchParams } from "react-router-dom";
  
  const TechSpecMulti = ({ mode, handleCloseClick,editData }) => {
    const [techspecId, setTechspecId] = useState([]);
    const [loading,setLoading]=useState(false)
    const [checkedList,setCheckedList]=useState([])
    const [searchParams,setSearchParams]=useSearchParams()
  
    useEffect(() => {
     
      getData()
    }, []);

    const getData=async()=>{
      setLoading(true)
      const link = masterAPI["selectList"].techspecbyType;
     await axiosPrivate
        .get(`${link}/Asset`)
        .then((res) => {
          console.log(res)
          setLoading(false)
          setTechspecId(res.data);
        })
        .catch((e) => {
          setTechspecId([])
          console.log(e, "createtechnicalSpecificationgetAll");
        });
    }
  
  
  
    const onSubmit = async() => {
      
      setLoading(true)
      const createLink = masterAPI["assetTechSpec"].bulkinsert;
      const data=[]
    checkedList.map((d,i)=>{
      const postData={
        assetId:searchParams.get("id"),
        techspecId:d
      }
     data.push(postData)
    })
 
     console.log(data)
    await axiosPrivate.post(`${createLink}`,data).then(res=>{
      console.log(res)
      handleCloseClick()
    }).catch(e=>{
      console.log(e,"bulkTechspec")
    })
    };
  
   

    const handleChecked=(value)=>{
      
      if(checkedList.includes(value)){
        const removeData=checkedList.filter(l=>l!==value)
        setCheckedList(removeData)
      }else{
       setCheckedList([...checkedList,value])
      }
    }

    const columns=[
      {
        // Header:"Id",
        accessor:"id",
        Cell:({cell})=>{
          return (
            <Checkbox checked={checkedList.includes(cell.row.original.value)} onClick={()=>{handleChecked(cell.row.original.value)}}  />
          )
        }
      },
      {
        Header:"Tech Code",
        accessor:"selected",
        Cell:({cell})=>{
          return (
          <Typography sx={{fontSize:"inherit",fontWeight:"inherit"}}>{cell.row.original.text.split("-")[1]}</Typography>
          )
        }
      },
      {
        Header:"Tech Name",
        accessor:"text",
        Cell:({cell})=>{
          return (
          <Typography sx={{fontSize:"inherit",fontWeight:"inherit"}}>{cell.value.split("-")[0]}</Typography>
          )
        }
      },
    ]
  
    return (
      <Box sx={{ p: color.masterPageDialogPadding}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
            New Technical Specification
          </Typography>
           <Box>
            <IconButton onClick={onSubmit}>
                <Save />
            </IconButton>
            <IconButton onClick={()=>{handleCloseClick()}}>
                <Clear/>
            </IconButton>
         </Box>
        </Box>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        <NormalTable data={techspecId} columns={columns}/>
       
      </Box>
    );
  };
  
  export default TechSpecMulti;
  