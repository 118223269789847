import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  createFilterOptions,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  FormControl,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Clear, Refresh } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";

const TransferTransactionModal = ({ mode, handleCloseClick, editData }) => {
  const [partData, setPartId] = useState([]);
  const [stockAisleData, setStockAisleData] = useState([]);
  const [stockRowData, setStockRowData] = useState([]);
  const [stockBinData, setStockBinData] = useState([]);
  const [stockRoomData, setStockRoomData] = useState([]);
  const [partSelect, setPartSel] = useState("");
  const [srSelect, setSRSel] = useState("");
  const [srAisleSelect, setSRAisleSel] = useState("");
  const [srRowSelect, setSRRowSel] = useState("");
  const [srBinSelect, setSRBinSel] = useState("");
  const [resetData, setResetData] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [roomData, setStockRoomDatas] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [aileData, setaileData] = useState([]);
  const [selectedaile, setSelectedaile] = useState("");
  const [rowData, setrowData] = useState([]);
  const [selectedrow, setSelectedrow] = useState("");
  const [binData, setbinData] = useState([]);
  const [selectedbin, setSelectedbin] = useState("");

  const [loading, setLoading] = useState(false);

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
    if (mode === "Edit") {
      setPartSel(editData.partName || "");
      setSRSel(editData.fromStockRoomName || "");
      setSRAisleSel(editData.fromAisleName || "");
      setSRRowSel(editData.fromRowName || "");
      setSRBinSel(editData.fromBinName || "");
      setSelectedStatus(editData.status || "");
      setSelectedRoom(editData.toStockRoomName || "");
      setSelectedaile(editData.toAisleName || "");
      setSelectedrow(editData.toRowName || "");
      setSelectedbin(editData.toBinName || "");
    }
  }, [mode, editData]);

  

  useEffect(() => {
    getData()
  }, []);

  const getData=async()=>{
    setLoading(true);

    //////// Part Inventory ///////

    const link = masterAPI["selectList"].partsInventory;
    await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setLoading(false);
        setPartId(res.data);
      })
      .catch((e) => {
        setPartId([]);
        setLoading(false);
        console.log(e, "createPartgetAll");
      });

      const inventoryusageStatus = masterAPI["selectList"].inventoryusageStatus;
     await axiosPrivate
      .get(inventoryusageStatus)
      .then((response) => {
        console.log(response.data);
        setStatusData(response.data);
      })
      .catch((error) => {
        console.log(error, "create inventory usage status");
      });

    const room = masterAPI["selectList"].stockRoom;
   await axiosPrivate
      .get(room)
      .then((response) => {
        console.log(response.data);
        setStockRoomDatas(response.data);
      })
      .catch((error) => {
        console.log(error, "create inventory usage status");
      });
  }

  const initialValue = {
    remarks: mode === "Edit" ? editData.remarks : "",
    quantity: mode === "Edit" ? editData.quantity : "",
    partsId: mode === "Edit" ? editData.partsId : "",
    fromBinId: mode === "Edit" ? editData.fromBinId : "",

    fromRowId: mode === "Edit" ? editData.fromRowId : "",
    fromStockRoomId: mode === "Edit" ? editData.fromStockRoomId : "",
    fromAisleId: mode === "Edit" ? editData.fromAisleId : "",
    status: mode === "Edit" ? editData.status : null,
    code: mode === "Edit" ? editData.code : "",
    toStockRoomId: mode === "Edit" ? editData.toStockRoomId : "",
    toAisleId: mode === "Edit" ? editData.toAisleId : "",
    toRowId: mode === "Edit" ? editData.toRowId : "",
    toBinId: mode === "Edit" ? editData.toBinId : "",
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["partmovement"].create;
    const updateLink = masterAPI["partmovement"].update;

    if (mode === "Create") {
     await axiosPrivate
        .post(`${createLink}`, value)
        .then((res) => {
          console.log(res.data);
          toast.success("Transfer Transaction Created Successfully");
          setLoading(false);
          setResetData(false);
          setPartSel("");
          setSRAisleSel("");
          setSRBinSel("");
          setSRRowSel("");
          setSRSel("");
          setSelectedStatus("");
          setSelectedRoom("");
          setSelectedaile("");
          setSelectedrow("");
          setSelectedbin("");
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          toast.error("Failed to create item");
          console.log(e, "create inventory");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          toast.success("Transfer Transaction Updated Successfully");
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          toast.error("Failed to create item");
          console.log(e, "update inventory");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  const handleRoomChange = async(srId) => {
    const srAisleLink = masterAPI["selectList"].stockRoomAisle;
   await axiosPrivate
      .get(`${srAisleLink}/${srId}`)
      .then((res) => {
        console.log(res, "srAisle");
        setaileData(res.data);
      })
      .catch((e) => {
        setaileData([]);
        console.log(e, "error aisle");
      });
  };
  const handleaileChange = async(srAisleId) => {
    const srAisleRowLink = masterAPI["selectList"].stockRoomAisleRow;
   await axiosPrivate
      .get(`${srAisleRowLink}/${srAisleId}`)
      .then((res) => {
        console.log(res, "srRow");
        setrowData(res.data);
      })
      .catch((e) => {
        setrowData([]);
        console.log(e, "error Row");
      });
    //setSelectedaile(event.target.value);
  };
  const handlRowChange = async(srRowId) => {
    const srBinLink = masterAPI["selectList"].stockRoomAisleRowBin;
    await axiosPrivate
      .get(`${srBinLink}/${srRowId}`)
      .then((res) => {
        console.log(res, "srBin");
        setbinData(res.data);
      })
      .catch((e) => {
        setbinData([]);
        console.log(e, "error Bin");
      });
    //setSelectedrow(event.target.value);
  };
  const handlBinChange = (value) => {
    setSelectedbin(value);
  };

  const handlStatusChange = (value) => {
    setSelectedStatus(value);
  };
  const handleStockRoom = async(partId, srId) => {
    if (resetData) {
      const srAisleLink = masterAPI["selectList"].stockRoomAisle;
     await axiosPrivate
        .get(`${srAisleLink}/${srId.value}`)
        .then((res) => {
          console.log(res, "srAisle");
          setStockAisleData(res.data);
        })
        .catch((e) => {
          setStockAisleData([]);
          console.log(e, "error aisle");
        });
    } else {
      const getAisleLink = masterAPI["selectList"].aisleByPartAndRoom;
     await axiosPrivate
        .get(`${getAisleLink}/${partId}/${srId.value}`)
        .then((res) => {
          setStockAisleData(res.data);
        })
        .catch((e) => {
          setStockAisleData([]);
          console.log(e, "error");
        });
    }
  };
  const handleStockRoomAisle = async(partId, srAisleId) => {
    if (resetData) {
      const srAisleRowLink = masterAPI["selectList"].stockRoomAisleRow;
     await axiosPrivate
        .get(`${srAisleRowLink}/${srAisleId.value}`)
        .then((res) => {
          console.log(res, "srRow");
          setStockRowData(res.data);
        })
        .catch((e) => {
          setStockRowData([]);
          console.log(e, "error Row");
        });
    } else {
      const getRowLink = masterAPI["selectList"].rowByPartAndAisle;
     await axiosPrivate
        .get(`${getRowLink}/${partId}/${srAisleId.value}`)
        .then((res) => {
          setStockRowData(res.data);
        })
        .catch((e) => {
          setStockRowData([]);
          console.log(e, "error");
        });
    }
  };
  const handleStockRoomRow = async(partId, srRowId) => {
    if (resetData) {
      const srBinLink = masterAPI["selectList"].stockRoomAisleRowBin;
     await axiosPrivate
        .get(`${srBinLink}/${srRowId.value}`)
        .then((res) => {
          console.log(res, "srBin");
          setStockBinData(res.data);
        })
        .catch((e) => {
          setStockBinData([]);
          console.log(e, "error Bin");
        });
    } else {
      const getBinLink = masterAPI["selectList"].binByPartAndRow;
     await axiosPrivate
        .get(`${getBinLink}/${partId}/${srRowId.value}`)
        .then((res) => {
          setStockBinData(res.data);
        })
        .catch((e) => {
          setStockBinData([]);
          console.log(e, "error");
        });
    }
  };

  // const handleRefresh = () => {
  //   const getFullSR = masterAPI["selectList"].stockRoom;
  //   axiosPrivate
  //     .get(`${getFullSR}`)
  //     .then((res) => {
  //       setResetData(true);
  //       setStockRoomData(res.data);
  //       setSRAisleSel("");
  //       setSRBinSel("");
  //       setSRRowSel("");
  //       setSRSel("");
  //     })
  //     .catch((e) => {
  //       setStockRoomData([]);
  //       console.log(e);
  //     });
  // };

  const handlePart = async(value) => {
    // setCauseSel(value.causeCode+"_"+value.causeName)
    // setPartInfo(value)
    setResetData(false);
    const srLink = masterAPI["selectList"].stockRoomByPart;
   await axiosPrivate
      .get(`${srLink}/${value.value}`)
      .then((res) => {
        console.log(res);
        setStockRoomData(res.data);
        setSRAisleSel("");
        setSRBinSel("");
        setSRRowSel("");
        setSRSel("");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          Transfer Transaction Creation
        </Typography>
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          {/* <IconButton onClick={handleRefresh} sx={{ cursor: "pointer" }}>
            <Refresh sx={{ color: "inherit" }} />
          </IconButton> */}
          <IconButton onClick={handleCancel}>
            <Clear />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Transfer Transaction Code
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="code"
                  placeholder="Enter Code"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        // opacity: 1,
                        //color: color.placeholderColor,
                        //fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Part
                </Typography>
                <Autocomplete
                  // value={remedySelect}
                  inputValue={partSelect}
                  //value={causeInfo.id===null?null:causeInfo}
                  fullWidth
                  filterOptions={filterOptions}
                  loading={partData.length === 0}
                  options={partData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.text || option}
                  freeSolo
                  onChange={(event, value) => {
                    console.log(value);
                    if (value !== null) {
                      handlePart(value);
                      setFieldValue("partsId", value.value);
                    } else {
                      setPartSel("");
                      // setPartInfo({})
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    console.log(newInputValue);
                    setPartSel(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="partId"
                      placeholder="Select Part"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {partData.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  From Stock Room
                </Typography>

                <Autocomplete
                  inputValue={srSelect}
                  fullWidth
                  filterOptions={filterOptions}
                  // loading={partData.length===0}
                  options={stockRoomData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.text || option}
                  freeSolo
                  onChange={(event, value) => {
                    console.log(value);
                    if (value !== null) {
                      handleStockRoom(values.partsId, value);
                      setFieldValue("fromStockRoomId", value.value);
                    } else {
                      setSRSel("");
                      // setPartInfo({})
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    console.log(newInputValue);
                    setSRSel(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="fromStockRoomId"
                      placeholder="Select Stock Room"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        //     {partData.length===0 ? (
                        //       <CircularProgress color="inherit" size={20} />
                        //     ) : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  From Aisle
                </Typography>
                <Autocomplete
                  // value={remedySelect}
                  inputValue={srAisleSelect}
                  //value={causeInfo.id===null?null:causeInfo}
                  fullWidth
                  filterOptions={filterOptions}
                  // loading={partData.length===0}
                  options={stockAisleData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.text || option}
                  freeSolo
                  onChange={(event, value) => {
                    console.log(value);
                    if (value !== null) {
                      handleStockRoomAisle(values.partsId, value);
                      setFieldValue("fromAisleId", value.value);
                    } else {
                      setSRAisleSel("");
                      // setPartInfo({})
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    console.log(newInputValue);
                    setSRAisleSel(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="stockRoomRowAisleId"
                      placeholder="Select Aisle"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        //     {partData.length===0 ? (
                        //       <CircularProgress color="inherit" size={20} />
                        //     ) : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  From Row
                </Typography>

                <Autocomplete
                  // value={remedySelect}
                  inputValue={srRowSelect}
                  //value={causeInfo.id===null?null:causeInfo}
                  fullWidth
                  filterOptions={filterOptions}
                  // loading={partData.length===0}
                  options={stockRowData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.text || option}
                  freeSolo
                  onChange={(event, value) => {
                    console.log(value);
                    if (value !== null) {
                      handleStockRoomRow(values.partsId, value);
                      setFieldValue("fromRowId", value.value);
                    } else {
                      setSRRowSel("");
                      // setPartInfo({})
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    console.log(newInputValue);
                    setSRRowSel(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="stockRoomRowId"
                      placeholder="Select Row"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        //     {partData.length===0 ? (
                        //       <CircularProgress color="inherit" size={20} />
                        //     ) : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  From Bin
                </Typography>

                <Autocomplete
                  // value={remedySelect}
                  inputValue={srBinSelect}
                  //value={causeInfo.id===null?null:causeInfo}
                  fullWidth
                  filterOptions={filterOptions}
                  // loading={partData.length===0}
                  options={stockBinData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.text || option}
                  freeSolo
                  onChange={(event, value) => {
                    console.log(value);
                    if (value !== null) {
                      setFieldValue("fromBinId", value.value);
                    } else {
                      setSRBinSel("");
                      // setPartInfo({})
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    console.log(newInputValue);
                    setSRBinSel(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="fromBinId"
                      placeholder="Select Bin"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        //     {partData.length===0 ? (
                        //       <CircularProgress color="inherit" size={20} />
                        //     ) : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Quantity
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="quantity"
                  placeholder="Enter Quantity"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        //opacity: 1,
                        //color: color.placeholderColor,
                        //fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  To Room
                </Typography>
                <Autocomplete
                  // value={remedySelect}
                  inputValue={selectedRoom}
                  //value={causeInfo.id===null?null:causeInfo}
                  fullWidth
                  filterOptions={filterOptions}
                  // loading={partData.length===0}
                  options={roomData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.text || option}
                  freeSolo
                  onChange={(event, value) => {
                    console.log(value);
                    if (value !== null) {
                      handleRoomChange(value.value);
                      setFieldValue("toStockRoomId", value.value);
                    } else {
                      setSelectedRoom("");
                      // setPartInfo({})
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    console.log(newInputValue);
                    setSelectedRoom(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="toStockRoomId"
                      placeholder="Select Room"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        //     {partData.length===0 ? (
                        //       <CircularProgress color="inherit" size={20} />
                        //     ) : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  To Aisle
                </Typography>
                <Autocomplete
                  // value={remedySelect}
                  inputValue={selectedaile}
                  //value={causeInfo.id===null?null:causeInfo}
                  fullWidth
                  filterOptions={filterOptions}
                  // loading={partData.length===0}
                  options={aileData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.text || option}
                  freeSolo
                  onChange={(event, value) => {
                    console.log(value);
                    if (value !== null) {
                      handleaileChange(value.value);
                      setFieldValue("toAisleId", value.value);
                    } else {
                      setSelectedaile("");
                      // setPartInfo({})
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    console.log(newInputValue);
                    setSelectedaile(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="toAisleId"
                      placeholder="Select Aisle"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        //     {partData.length===0 ? (
                        //       <CircularProgress color="inherit" size={20} />
                        //     ) : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                    />
                  )}
                />
                {/* <FormControl fullWidth size="small">
                  <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={selectedaile}
                    onChange={handleaileChange}
                    placeholder="Aile"
                    sx={{
                      ".MuiInputBase-input": {
                        fontSize: "13px",
                      },
                    }}
                  >
                    {aileData.map((aile) => (
                      <MenuItem key={aile.value} value={aile.value}>
                        {aile.text} {/* Use 'text' for display */}
                {/* </MenuItem>
                    ))}
                  </Select>
                </FormControl>  */}
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  To Row
                </Typography>
                <Autocomplete
                  // value={remedySelect}
                  inputValue={selectedrow}
                  //value={causeInfo.id===null?null:causeInfo}
                  fullWidth
                  filterOptions={filterOptions}
                  // loading={partData.length===0}
                  options={rowData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.text || option}
                  freeSolo
                  onChange={(event, value) => {
                    console.log(value);
                    if (value !== null) {
                      handlRowChange(value.value);
                      setFieldValue("toRowId", value.value);
                    } else {
                      setSelectedrow("");
                      // setPartInfo({})
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    console.log(newInputValue);
                    setSelectedrow(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="toRowId"
                      placeholder="Select Row"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        //     {partData.length===0 ? (
                        //       <CircularProgress color="inherit" size={20} />
                        //     ) : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                    />
                  )}
                />
                {/* <FormControl fullWidth size="small">
                  <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={selectedrow}
                    onChange={handlRowChange}
                    placeholder="Aile"
                    sx={{
                      ".MuiInputBase-input": {
                        fontSize: "13px",
                      },
                    }}
                  >
                    {rowData.map((row) => (
                      <MenuItem key={row.value} value={row.value}>
                        {row.text} {/* Use 'text' for display */}
                {/* </MenuItem>
                    ))}
                  </Select>
                </FormControl>  */}
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  To Bin
                </Typography>
                <Autocomplete
                  // value={remedySelect}
                  inputValue={selectedbin}
                  //value={causeInfo.id===null?null:causeInfo}
                  fullWidth
                  filterOptions={filterOptions}
                  // loading={partData.length===0}
                  options={binData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.text || option}
                  freeSolo
                  onChange={(event, value) => {
                    console.log(value);
                    if (value !== null) {
                      handlBinChange(value.value);
                      setFieldValue("toBinId", value.value);
                    } else {
                      setSelectedbin("");
                      // setPartInfo({})
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    console.log(newInputValue);
                    setSelectedbin(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="toBinId"
                      placeholder="Select Bin"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        //     {partData.length===0 ? (
                        //       <CircularProgress color="inherit" size={20} />
                        //     ) : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                    />
                  )}
                />
                {/* <FormControl fullWidth size="small">
                  <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={selectedbin}
                    onChange={handlBinChange}
                    placeholder="Bin"
                    sx={{
                      ".MuiInputBase-input": {
                        fontSize: "13px",
                      },
                    }}
                  >
                    {binData.map((bin) => (
                      <MenuItem key={bin.value} value={bin.value}>
                        {bin.text} {/* Use 'text' for display */}
                {/* </MenuItem>
                    ))}
                  </Select>
                </FormControl>  */}
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Remarks
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="remarks"
                  placeholder="Enter Remarks"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        //opacity: 1,
                        //color: color.placeholderColor,
                        //fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Status
                </Typography>
                <Autocomplete
                  // value={remedySelect}
                  inputValue={selectedStatus}
                  //value={causeInfo.id===null?null:causeInfo}
                  fullWidth
                  filterOptions={filterOptions}
                  // loading={partData.length===0}
                  options={statusData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.text || option}
                  freeSolo
                  onChange={(event, value) => {
                    console.log(value);
                    if (value !== null) {
                      handlStatusChange(value.value);
                      setFieldValue("status", value.value);
                    } else {
                      setSelectedStatus("");
                      // setPartInfo({})
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    console.log(newInputValue);
                    setSelectedStatus(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="status"
                      placeholder="Select Status"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        //     {partData.length===0 ? (
                        //       <CircularProgress color="inherit" size={20} />
                        //     ) : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                    />
                  )}
                />
                {/* <FormControl fullWidth size="small">
                  <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    placeholder="Status"
                    sx={{
                      ".MuiInputBase-input": {
                        fontSize: "13px",
                      },
                    }}
                  >
                    {statusData.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.text} {/* Use 'text' for display */}
                {/* </MenuItem>
                    ))}
                  </Select>
                </FormControl>  */}
              </Grid>

              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default TransferTransactionModal;
