import { Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { color } from "../../../../StyleData/Config";

const General = ({ data }) => {
  return (
    
    <Grid container spacing={"1rem"}>
      <Grid item xs={12} md={6}>
        <Paper sx={{ width: "100%", p: 1.5,height:"100%" }}>
        <Grid container spacing={"0.5rem"}>
            <Grid item xs={12} >
                <Typography
                  sx={{
                    color: color.masterDialogTitleColor,
                    fontSize: color.masterDialogFontSize,
                    fontWeight: color.sideWeightFont,
                    mb: "10px",
                  }}
                >
                 Basic
                </Typography>
                <Divider
                  sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Part Code
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.code}
                  </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Part Name
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.partName}
                  </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                   Stockroom Code
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.stockRoomCode}
                  </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                   Stockroom Name
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.stockRoomName}
                  </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                   Stock Category
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.stockcategory}
                  </Typography>
            </Grid>
        </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ width: "100%", p: 1.5,height:"100%" }}>
        <Grid container spacing={"0.5rem"}>
            <Grid item xs={12} >
                <Typography
                  sx={{
                    color: color.masterDialogTitleColor,
                    fontSize: color.masterDialogFontSize,
                    fontWeight: color.sideWeightFont,
                    mb: "10px",
                  }}
                >
                 Reorder
                </Typography>
                <Divider
                  sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Safety Stock Level
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.safetyStockLevel}
                  </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Economic reorder qty
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.economicReOrderQuantity}
                  </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                   Reorder Point
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.reOrderPoint}
                  </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                   Max Quantity
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.maxQuantity}
                  </Typography>
            </Grid>

            </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ width: "100%", p: 1.5,height:"100%" }}>
        <Grid container spacing={"0.5rem"}>
            <Grid item xs={12} >
                <Typography
                  sx={{
                    color: color.masterDialogTitleColor,
                    fontSize: color.masterDialogFontSize,
                    fontWeight: color.sideWeightFont,
                    mb: "10px",
                  }}
                >
                 Balance Summary
                </Typography>
                <Divider
                  sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Qty on hand
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.totalQuantity}
                  </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Last Count Date
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {/* {data.economicReOrderQuantity} */}
                    ----
                  </Typography>
            </Grid>

            </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ width: "100%", p: 1.5,height:"100%" }}>
        <Grid container spacing={"0.5rem"}>
            <Grid item xs={12} >
                <Typography
                  sx={{
                    color: color.masterDialogTitleColor,
                    fontSize: color.masterDialogFontSize,
                    fontWeight: color.sideWeightFont,
                    mb: "10px",
                  }}
                >
                 Inventory Cost
                </Typography>
                <Divider
                  sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                   Standard Price
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.standardPrice}
                  </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Average Price
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.averagePrice}
                  </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Last Receipt Price
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.lastReceiptPrice}
                  </Typography>
            </Grid>
            </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default General;
