import {
    Box,
    Divider,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
    Select,
    MenuItem,
    Tab,
    Menu,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Field, Formik, useFormik, Form } from "formik";
  
  import { color } from "../../../../StyleData/Config";
  import { Circle, Clear, MoreHoriz } from "@mui/icons-material";
  import { axiosPrivate } from "../../../../axios/axios";
  import { masterAPI } from "../../../Masters/dataConfig";
  import { TabContext, TabList, TabPanel } from "@mui/lab";
 
  import { useSearchParams } from "react-router-dom";
  
import PermitWorkTab from './PermitWorkTabs/General'
import ListMenuPermit from './ListMenuPermit/ListMenuPermit'
import General from './PermitWorkTabs/General'
import CompeletionInfo from "./PermitWorkTabs/CompeletionInfo";
import Isolation from "./PermitWorkTabs/Isolation";
import Normalisation from "./PermitWorkTabs/Normalisation";
import SafetyPrecaution from "./PermitWorkTabs/SafetyPrecaution";
import Activities from "./PermitWorkTabs/Activities";

const PermitWorkView = () => {
    const [value, setValue] = useState("1");
  const [direction, setDirection] = useState("row");
  const [searchParams,setSearchParams]=useSearchParams()
   const [pmData,setPmData]=useState({})
   const [isLoading,setIsLoading]=useState(false)
 

  

//   useEffect(()=>{
//     getData()
//     },[])

//     const getData=async()=>{
//       setIsLoading(true)
 
//       const id=searchParams.get("id")
//       const pmLink=masterAPI["PM"].getByid
//      await axiosPrivate.get(`${pmLink}/${id}`).then(res=>{
//        console.log(res,"pm id")
//        setPmData(res.data.result)
//       }).catch(e=>{
//        setPmData([])
//       console.log(e,"error pm view")
//       })
      
   
    
//    setIsLoading(false)
//     }


const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const contentPosition = (flex) => {
    setDirection(flex);
  };
  return (
   <Grid container spacing={"1rem"}>
     <Grid
          item
          xs={direction === "column" ? 6 : 9.5}
          md={direction ==="column"? 6:10.5}
          lg={direction === "column" ? 6 : 11}
        >
           <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            mb: "1rem",
            gap: "1rem",
          }}
          // sx={{mb:"1rem"}}
        >
          <Typography
            sx={{
              // color: color.masterDialogTitleColor,
              // fontSize: color.masterDialogFontSize,
              fontSize: color.AssetTitlesize,
              fontWeight: color.sideFontWeightAsset,
            }}
          >
            {searchParams.get("id")}
          </Typography>
          {/* <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              <Circle sx={{ fontSize: "0.5rem", color: "green" }} />
              <Typography>{woData.status}</Typography>
            </Box>
            <MoreHoriz onClick={handleDot} sx={{ cursor: "pointer" }} />
              <Menu anchorEl={dotBL} open={Boolean(dotBL)} onClose={handleDot}>
                <MenuItem>Option 1</MenuItem>
                <MenuItem>Option 2</MenuItem>
                <MenuItem>Option 3</MenuItem>
              </Menu>
            <Box></Box>
          </Box> */}
        </Box>
        <TabContext value={value}>
          <Box
            sx={{
              ".css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                color: color.TabLabelcolor,
                // p: 0.5,
              },
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                sx: { backgroundColor: color.TabLabelcolor, height: "3px" },
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="General" value="1" sx={{ textTransform: "none" }} />
              <Tab label="Isolation" value="2" sx={{ textTransform: "none" }} />
              <Tab
                label="Normalisation"
                value="3"
                sx={{ textTransform: "none" }}
              />

              <Tab
                label="Completion Info"
                value="4"
                sx={{ textTransform: "none" }}
              />
              <Tab
                    label="Safety Precautions"
                    value="5"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    label="Activities"
                    value="6"
                    sx={{ textTransform: "none" }}
                  />
            </TabList>
          </Box>
          <Box sx={{ mt: "1rem" }}>
            <TabPanel value="1" sx={{ p: 1 }}>
              {/* <CreateGeneral jobPlanIden={handleJobPlanId}/> */}
              <General data={searchParams.get("id")}/>
            </TabPanel>
            <TabPanel value="2">
               <Isolation data={searchParams.get("id")}/>
            </TabPanel>
            <TabPanel value="3">
               <Normalisation data={searchParams.get("id")} />
            </TabPanel>
            <TabPanel value="4">
              <CompeletionInfo data={searchParams.get("id")}/>
            </TabPanel>
            <TabPanel value="5">
              <SafetyPrecaution data={searchParams.get("id")}/>
            </TabPanel>
            <TabPanel value="6">
              <Activities data={searchParams.get("id")}/> 
            </TabPanel>
          </Box>
        </TabContext>
        
            </Grid>
            <Grid
          container
          justifyContent={"flex-end"}
          item
          xs={direction === "column" ? 6 : 2.5}
          md={direction === "column" ? 6 : 1.5}
          lg={direction === "column" ? 6 : 1}
          sx={{boxShadow:direction ==="column"?"-1px 1px 7px 2px rgb(0 0 0 / 32%)":0,minHeight:"92vh", backgroundColor:"transparent"}}
        >
          <ListMenuPermit
          contentPosition={contentPosition} 
          data={searchParams.get("id")} 
          direction={direction} 
          // getData={getData}
          />
        </Grid>
            </Grid>
  )
}

export default PermitWorkView