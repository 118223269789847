import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";
import { color } from "../../../../StyleData/Config";
import { axiosPrivate } from "../../../../axios/axios";
import { masterAPI } from "../../../Masters/dataConfig";
import { useSearchParams } from "react-router-dom";

const StockRoomRowBinsModal = ({ mode, handleCloseClick, editData }) => {
  // const [stockRoomRowId, setStockRoomRowId] = useState([]);
  const [searchParams,setSearchParams]=useSearchParams()
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   const link = masterAPI["stockRoomRow"].link;
  //   axiosPrivate
  //     .get(`${link}`)
  //     .then((res) => {
  //       setLoading(false);
  //       setStockRoomId(res.data.result);
  //     })
  //     .catch((e) => {
  //       console.log(e, "create stockRoom getAll");
  //     });
  // }, []);

  const initialValue = {
    code: mode === "Edit" ? editData.code : "",
    binName: mode === "Edit" ? editData.binName : "",
    stockRoomRowId: mode === "Edit" ? editData.stockRoomRowId : searchParams.get("id"),
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["stockRoomRowBin"].create;
    const updateLink = masterAPI["stockRoomRowBin"].update;
    if (mode === "Create") {
      const postData={
        ...value,
      
      }
     await axiosPrivate
        .post(`${createLink}`, postData)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "create stockRoomRowBin");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "update stockRoomRowBin");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode === "Edit"
            ? editData.code + " - " + editData.binName
            : "New Stock Room Row Bin"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Bin Code
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="code"
                  placeholder="Enter Bin Code"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Bin Name
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="binName"
                  placeholder="Enter Bin Name"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Stock Room
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="stockRoomId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.stockRoomId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {stockRoomId.map((id, i) => (
                    <MenuItem value={id.id}>{id.stockRoomName}</MenuItem>
                  ))}
                </Field>
              </Grid> */}
              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default StockRoomRowBinsModal;
