import { Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import NormalTable from "../../../Component/NormalTable";
import moment from "moment";

const WorkTab = ({ data }) => {
  const [workData, setWorkData] = useState([]);

  useEffect(() => {
  getData()
  //   const data=[{
  //     id:"SPDF2021",
  //     description:"Racking Assemble(A144)",
  //     status:"JDF05242.0",
  //     planStartDate:"2023-10-15",
  //     planEndDate:"2023-11-15",
  //     actualStartDate:"2023-10-14",
  //     actualEndDate:"2023-11-14",
  //   },
  //   {
  //     id:"SPDF2021",
  //     description:"Racking Assemble(A144)",
  //     statusId:"JDF05242.0",
  //     planStartDate:"2023-10-15",
  //     planEndDate:"2023-11-15",
  //     startDate:"2023-10-14",
  //     endDate:"2023-11-14",
  //   },
  //   {
  //     id:"SPDF2021",
  //     description:"Racking Assemble(A144)",
  //     statusId:"JDF05242.0",
  //     planStartDate:"2023-10-15",
  //     planEndDate:"2023-11-15",
  //     startDate:"2023-10-14",
  //     endDate:"2023-11-14",
  //   },
  // ]
  //   setWorkData(data)
  }, []);

  const getData=async()=>{
    const searchLink=masterAPI["workOrder"].search
    console.log(data)
    const searchData={assetId:data}
    console.log(searchLink,searchData)
   await axiosPrivate.post(`${searchLink}`,searchData).then(res=>{
        console.log(res.data)
        // setPartsData(res.data.result)
        setWorkData(res.data.result)
    }).catch(e=>{
        setWorkData([])
        console.log(e,"AssetWorkOrderError")
    })
  }

  const columns = [
    {
      Header: "WO Code",
      accessor: "code",
      Cell:({cell})=>{
        return (
          <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"blue"}}>{cell.value}</Typography>
          )
      }
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Status Id",
      accessor: "status",
    },
    {
      Header: "Planned Start Date",
      accessor: "plannedStartDate",
      Cell:({cell})=>{
        return (
          <Typography sx={{fontSize:"inherit",color:"inherit",fontWeight:'inherit'}}>
            {moment(cell.value).format("DD/MM/YYY HH:mm")}
          </Typography>
        )
  }
    },
    {
      Header: "Planned Start Date",
      accessor: "plannedEndDate",
      Cell:({cell})=>{
            return (
              <Typography sx={{fontSize:"inherit",color:"inherit",fontWeight:'inherit'}}>
                {moment(cell.value).format("DD/MM/YYY HH:mm")}
              </Typography>
            )
      }
    },
    {
      Header: "Actual Start Date",
      accessor: "actualStartDate",
      Cell:({cell})=>{
        return (
          <Typography sx={{fontSize:"inherit",color:"inherit",fontWeight:'inherit'}}>
            {moment(cell.value).format("DD/MM/YYY HH:mm")}
          </Typography>
        )
  }
  },
  {
    Header: "Actual End Date",
    accessor: "actualEndDate",
    Cell:({cell})=>{
      return (
        <Typography sx={{fontSize:"inherit",color:"inherit",fontWeight:'inherit'}}>
          {moment(cell.value).format("DD/MM/YYY HH:mm")}
        </Typography>
      )
}
},
  ];

  return (
    <Paper>
      <NormalTable columns={columns} data={workData} />
    </Paper>
  );
};

export default WorkTab;
