import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  createFilterOptions,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear, FileUploadOutlined } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../dataConfig";

const AssetTypeQuesModal = ({ mode, handleCloseClick, editData }) => {
  const [assetType, setAssetType] = useState([]);
  const [question, setQuestion] = useState([]);
  const [loading, setLoading] = useState(false);
  const [autoCom, setAutoCom] = useState({
    assetTypeName: mode === "Edit" ? editData.assetTypeName : "",
    questionName: mode === "Edit" ? editData.question : "",
  });

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
   getData()
  }, []);

  const getData=async()=>{
    setLoading(true);
    const assetTypeLink = masterAPI["assetType"].link;
   await axiosPrivate
      .get(`${assetTypeLink}`)
      .then((res) => {
        setLoading(false);
        setAssetType(res.data.result);
      })
      .catch((e) => {
        setAssetType([]);
        console.log(e, "assetType assetTypeQues");
      });
    const questionLink = masterAPI["question"].link;
   await axiosPrivate
      .get(`${questionLink}`)
      .then((res) => {
        console.log(res.data.result)
        setLoading(false);
        setQuestion(res.data.result);
      })
      .catch((e) => {
        setQuestion([]);
        console.log(e, "question assetTypeQues");
      });
  }

  const initialValue = {
    assetTypeId: mode === "Edit" ? editData.assetTypeId : null,
    questionId: mode === "Edit" ? editData.questionId : null,
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["assetTypeQuestion"].create;
    const updateLink = masterAPI["assetTypeQuestion"].update;
    if (mode === "Create") {
     await axiosPrivate
        .post(`${createLink}`, value)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "assetTypeQues");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "assetTypeQues");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode === "Edit"
            ? "Update Asset Type Question"
            : "New Asset Type Question"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Asset Type
                </Typography>

                <Field
                  name="assetTypeId"
                  inputValue={autoCom.assetTypeName}
                  component={Autocomplete}
                  filterOptions={filterOptions}
                  loading={assetType.length === 0}
                  options={assetType}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.code+"-"+option.assetTypeName || option}
                  getOptionDisabled={(option) =>
                    editData.id === option.id && mode === "Edit"
                  }
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      console.log(value);

                      setFieldValue("assetTypeId", value.id);
                      setAutoCom({...autoCom,assetTypeName:value.code+"-"+value.assetTypeName});
                    } else {
                      setFieldValue("assetTypeId", null);
                      setAutoCom({...autoCom,assetTypeName:""});
                    }
                  }}
                  onInputChange={(e, v) => {
                    setAutoCom({...autoCom,assetTypeName:v});
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="assetTypeId"
                      placeholder="Search Asset Type"
                      onChange={handleChange}
                      size="small"
                      // variant="standard"

                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {assetType.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                >
                  {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Question
                </Typography>
                <Field
                  name="questionId"
                  inputValue={autoCom.questionName}
                  component={Autocomplete}
                  filterOptions={filterOptions}
                  loading={question.length === 0}
                  options={question}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.question || option}
                  getOptionDisabled={(option) =>
                    editData.id === option.id && mode === "Edit"
                  }
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      console.log(value);

                      setFieldValue("questionId", value.id);
                      setAutoCom({...autoCom,questionName:value.question});
                    } else {
                      setFieldValue("questionId", null);
                      setAutoCom({...autoCom,questionName:""});
                    }
                  }}
                  onInputChange={(e, v) => {
                    setAutoCom({...autoCom,questionName:v});
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="questionId"
                      placeholder="Search Question"
                      onChange={handleChange}
                      size="small"
                      // variant="standard"

                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {question.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                >
                  
                </Field>
              </Grid>

              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AssetTypeQuesModal;
