import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NormalTable from "../../../Component/NormalTable";
import { color } from "../../../StyleData/Config";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import {
  FileUploadOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

const Completion = ({ woData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataList, setDataList] = useState([]);
  const [ansQues, setAnsQues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateList,setUpdateList]=useState(false)

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true)
    const getQuestionUpdate=masterAPI["inspWo"].getInspectionWo
    
    await axiosPrivate
      .get(`${getQuestionUpdate}/${woData.id}`)
      .then((res) => {
        console.log(res)
        if(res.data.result.inspectionWorkOrderAssetsList===undefined){
            const createQuestionList=masterAPI["inspWo"].getQuestionByInsWo
            axiosPrivate.get(`${createQuestionList}/${woData.id}`).then(res=>{
              console.log(res)
              setUpdateList(false)
              setDataList(res.data.result)
              setLoading(false)
            }).catch(e=>{
              console.log(e)
            })
        }else{
          console.log("Check 3")
          setUpdateList(true)
          setLoading(false)
          setDataList(res.data.result.inspectionWorkOrderAssetsList);
        }
      
      })
      .catch((e) => {
        setDataList([]);
        console.log(e, "error");

      });
      



  };

  const handleQuestionAnswer = (inputValue, rowValue, name) => {
    // let index = null;
    const updataList=dataList
    // dataList.filter((fil, i) => {
    //   if (
    //     fil.questionId === rowValue.questionId &&
    //     fil.assetId === rowValue.assetId
    //   ) {
    //     index = i;
    //   }
    // });
    // if (index !== null) {
    //   let updateAnsQues = ansQues;
    //   updateAnsQues[Number(index)] = {
    //     ...ansQues[Number(index)],
    //     [name]: inputValue,
    //   };
    //   setAnsQues(updateAnsQues);
    // } else {
    //   let updateData = {
    //     inspectionWorkOrderId: searchParams.get("id"),
    //     assetId: rowValue.assetId,
    //     questionId: rowValue.questionId,
    //     [name]: inputValue,
    //   };
    //   setAnsQues([...ansQues, updateData]);
    // }

    for(let i=0;updataList.length>i;i++){
      if(updataList[i].assetId===rowValue.inspectionWoAssetId){
           for(let j=0;updataList[i].inspectionWorkOrderQuestionList.length>j;j++){
                  if(updataList[i].inspectionWorkOrderQuestionList[j].questionId===rowValue.questionId){
                    updataList[i].inspectionWorkOrderQuestionList[j]={
                      ...updataList[i].inspectionWorkOrderQuestionList[j],
                      answer:inputValue
                    }
                    break
                  }
           }
           break
      }
     
    }
    console.log(updataList)
  };

  const columns = [
    {
      accessor: "assetId",
      header: () => null,
      Cell: ({ row }) => {
        const [arrowLoading, setArrowLoading] = useState(false);
        return (
          <IconButton
            onClick={async () => {
              if (!row.isExpanded) {
                // const questionLink =
                //   masterAPI["assetTypeQuestion"].getByAssetTypeId;
                if (
                  row.original.inspectionWorkOrderQuestionList !== undefined &&
                  row.original.inspectionWorkOrderQuestionList.length !== 0
                ) {
                  setArrowLoading(true);
                  // await axiosPrivate
                  //   .get(
                  //     `${questionLink}?assetstypeid=${row.original.assetTypeId}`
                  //   )
                  //   .then((res) => {
                  //     // let addAssetId = res.data.result.map((obj) => ({
                  //     //   ...obj,
                  //     //   assetId: row.original.assetId,
                  //     // }));
                      
                  //   })
                  //   .catch((e) => {
                  //     setArrowLoading(false);
                  //     console.log(e);
                  //   });
                  row.original.inspectionWorkOrderQuestionList = row.original.inspectionWorkOrderQuestionList.filter(
                    (fil) => fil.questionType?.toUpperCase() !== "IMAGE"
                  );
                  row.toggleRowExpanded();
                  setArrowLoading(false);
                } else {
                  row.toggleRowExpanded();
                }
              } else {
                row.toggleRowExpanded();
              }
            }}
          >
            {arrowLoading ? (
              <CircularProgress size={25} />
            ) : row.isExpanded ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
        );
      },
    },
    {
      Header: "Asset Code",
      accessor: "assetCode",
    },
    {
      Header: "Asset Name",
      accessor: "assetName",
    },
    // {
    //   Header: "Asset Type",
    //   accessor: "assetTypeName",
    // },
  ];

  const questionColumns = [
    {
      Header: "Question",
      accessor: "questionName",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              width: "27vw",
              fontSize: "inherit",
              fontWeight: "inherit",
            }}
          >
            {cell.value}
          </Typography>
        );
      },
    },
    {
      accessor: "questionType",
      Cell: ({ cell }) => {
        const [textValue, setTextValue] = useState(cell.row.original.answer);
        const [meterReading, setMeterReading] = useState(
          cell.row.original.answer
        );
        const [multipleChoice, setMultiChoice] = useState(
          cell.row.original.answer || ""
        );
        return (
          <>
            <TextField
              fullWidth
              type={"text"}
              size="small"
              placeholder="Enter Feedback"
              value={textValue}
              sx={{
                display:
                  cell.row.original?.questionType.toUpperCase() === "TEXT"
                    ? "block"
                    : "none",
              }}
              onChange={(e) => {
                setTextValue(e.target.value);
                cell.row.original.answer = e.target.value;
              }}
              onBlur={(e) =>
                handleQuestionAnswer(
                  e.target.value,
                  cell.row.original,
                  "answer"
                )
              }
            />
            <TextField
              fullWidth
              type={"text"}
              size="small"
              placeholder="Enter Feedback"
              value={meterReading}
              sx={{
                display:
                  cell.row.original?.questionType.toUpperCase() ===
                  "METERREADING"
                    ? "block"
                    : "none",
              }}
              onChange={(e) => {
                setMeterReading(e.target.value);
                cell.row.original.answer = e.target.value;
              }}
              onBlur={(e) =>
                handleQuestionAnswer(
                  e.target.value,
                  cell.row.original,
                  "answer"
                )
              }
            />
            <Select
              size="small"
              renderValue={(value) =>
                value === "" ? (
                  <Typography
                    sx={{
                      opacity: 1,
                      color: "#A9A9A9",
                      fontSize: "inherit",
                    }}
                  >
                    Select
                  </Typography>
                ) : (
                  value
                )
              }
              fullWidth
              value={multipleChoice}
              onChange={(e) => {
                setMultiChoice(e.target.value);
                cell.row.original.answer = e.target.value;
              }}
              onBlur={(e) =>
                handleQuestionAnswer(
                  e.target.value,
                  cell.row.original,
                  "answer"
                )
              }
              sx={{
                display:
                  cell.row.original.questionType.toUpperCase() === "MULTICHOICE"
                    ? "block"
                    : "none",
                boxSizing: "border-box",
              }}
            >
              {cell.row.original.listOfChoices !== undefined
                ? cell.row.original.listOfChoices.map((list, i) => (
                    <MenuItem key={i} value={list}>
                      {list}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <Box
              sx={{
                display:
                  cell.row.original.questionType.toUpperCase() === "IMAGE"
                    ? "block"
                    : "none",
              }}
            >
              <Button component="label" variant="contained">
                <FileUploadOutlined />{" "}
                <Typography sx={{ fontSize: "0.9rem" }}>Add File</Typography>
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    if (e.target.files[0] !== undefined) {
                      const file = e.target.files[0];
                      let reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = function () {
                        const dataSplit = reader.result.split("base64,")[1];
                        // Process the base64 data here
                      };
                      reader.onerror = function (error) {};
                    }
                  }}
                />
              </Button>
              <Typography
                sx={{
                  fontSize: color.labelFont,
                }}
              ></Typography>
            </Box>
          </>
        );
      },
    },
  ];

  const renderRowSubComponent = (row) => {
    console.log(row.row.original.inspectionWorkOrderQuestionList)
    return (
      <NormalTable data={row.row.original.inspectionWorkOrderQuestionList} columns={questionColumns} />
    );
  };

  const formatDataForSubmission = (dataList, ansQues) => {
    console.log(dataList)
    const formattedData = dataList.map((asset) => ({
      inspectionWorkOrderId: searchParams.get("id"),
      
      assetId: asset.assetId,
      inspectionWorkOrderQuestionList: asset.inspectionWorkOrderQuestionList
        .filter((ans) => ans.inspectionWoAssetId === asset.assetId)
        .map((ans) => ({
          id:ans.id!==null&&ans.id!==undefined?ans.id:null,
          questionId: ans.questionId,
          answer: ans.answer,
        })),
    }));
    return formattedData;
  };

  const onSubmit = async () => {
    setLoading(true);
    const formattedData = formatDataForSubmission(dataList, ansQues);
    const createInspectionWorkorderQuestion = masterAPI["inspWo"].bulkinsert;
    const updateInspectionWorkorderQuestion = masterAPI["inspWo"].bulkupdate
    console.log(formattedData)
    if(updateList){
      console.log("check 2",updateList)

      await axiosPrivate
      .post(`${updateInspectionWorkorderQuestion}`, formattedData)
      .then((res) => {
        console.log(res);
        getData()
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
    }else{
      console.log("check 1")
      await axiosPrivate
      .post(`${createInspectionWorkorderQuestion}`, formattedData)
      .then((res) => {
        console.log(res);
        getData()
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
    }
    
  };

  return (
    <Box sx={{ p: 1 }}>
      <Paper elevation={7} sx={{ p: "1.5rem" }}>
        <Box>
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
              fontWeight: 400,
            }}
          >
            Details
          </Typography>
        </Box>
        <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)" }} />
        <Box>
          <NormalTable
            data={dataList}
            columns={columns}
            renderRowSubComponent={renderRowSubComponent}
          />
        </Box>
      </Paper>
      <Grid
        container
        item
        xs={12}
        md={11.6}
        justifyContent={"flex-end"}
        gap={"1rem"}
        sx={{ mt: "1rem" }}
      >
        <Button
          variant="contained"
          onClick={() => {
            setDataList([]);
            setAnsQues([]);
            setSearchParams({});
          }}
        >
          Clear
        </Button>
        <Button disabled={loading} variant="contained" onClick={onSubmit}>
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </Grid>
    </Box>
  );
};

export default Completion;
