import { Box, Button, Dialog, Divider, Paper, Typography,IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { masterAPI } from "../../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../../axios/axios";
import NormalTable from "../../../../../Component/NormalTable";
import { color } from "../../../../../StyleData/Config";
import { DeleteOutline, UploadFileRounded } from "@mui/icons-material";
import { toast } from "react-toastify";
import { contextualMenuWO } from "../../../../../SampleData/PermitRequestData";

const Workorder = ({ data }) => {
  const [docData, setDocData] = useState([]);
  const [doc, setDoc] = useState(false);

  useEffect(() => {
   getData()
   
  }, []);

  const getData=async()=>{
    const sampleData=contextualMenuWO
    setDocData(sampleData)
  }


  const handleUpload = async(value) => {
    const doclink = masterAPI["assetDocument"].uploadDoc;
    const uploadData = {
      assetId: data,
      ...value,
    };
    console.log(uploadData, "upload doc");
   await axiosPrivate
      .post(`${doclink}`, uploadData)
      .then((res) => {
        console.log(res);
        toast.success(res.data.message);
        handleClose();
      })
      .catch((e) => {
        console.log(e, "error doc wo ");
      });
  };

  const handleDelete =async (id) => {
    const deleteLink = masterAPI["assetDocument"].delete;
   await axiosPrivate
      .delete(`${deleteLink}/${id}`)
      .then((res) => {
        console.log(res.data);
        getData();
      })
      .catch((e) => {
        console.log(e, "delete assetsDoc");
      });
  };

  const handleDownload=async(id)=>{
    const downloadLink=masterAPI["assetDocument"].download
    console.log(id)
   await axiosPrivate.get(`${downloadLink}/${id}`).then(res=>{
      console.log(res)
      //  window.open(`https://demo.orienseam.com/${res.data.result.documentName}`)
      window.open(res.data.result.documentPath)
    }).catch(e=>{
      console.log(e)
    })
  
  }

  const columns = [
    // {
    //   Header: "Doc ID",
    //   accessor: "id",
    //   Cell: ({ cell }) => {
    //     return (
    //       <Typography
    //         sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue" }}
    //       >
    //         {cell.value}
    //       </Typography>
    //     );
    //   },
    // },
    {
      Header: "Workorder Code",
      accessor: "workOrderCode",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "inherit", fontWeight: "inherit",color: "blue",cursor:'pointer'  }} onClick={()=>{handleDownload(cell.row.original.id)}}>
            {cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Workorder Name",
      accessor: "workOrderName",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Work Type",
      accessor: "workType",
    },
    // {
    //   Header: "Actions",
    //   Cell: ({ cell }) => {
    //     // console.log(cell)
    //     return (
    //       <Box sx={{ whiteSpace: "nowrap" }}>
           
    //         <IconButton  onClick={() => {
    //               handleDelete(cell.row.original.id);
    //             }}>
    //           <DeleteOutline
               
    //             sx={{
    //               fontSize: color.masterEditDelButton,
    //               color: color.masterEditDelButtonColor,
    //             }}
    //           />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
  ];

  const handleFile = () => {
    setDoc(true);
  };

  const handleClose = () => {
    setDoc(false);
    getData();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
    <Box>
      <Typography
        sx={{
          color: color.sideTitleColor,
          fontSize: color.fontSizeSide,
          fontWeight: color.sideWeightFont,
        }}
      >
        Work Order
      </Typography>
    </Box>
    <Paper>
    {/* <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            p: 1,
          }}
        >
          
          <Button variant="contained" onClick={handleFile}>
            <UploadFileRounded />
            File Upload
          </Button>
        </Box> */}
        <Divider />
      <NormalTable columns={columns} data={docData} />
    </Paper>
    {/* <Dialog
        open={doc}
        onClose={handleClose}
        fullWidth
        maxWidth={"xs"}
        children={
          <DocUpload
            handleCloseClick={handleClose}
            handleUpload={handleUpload}
          />
        }
      /> */}
    </Box>
  );
};

export default Workorder;
