import React, { useEffect, useState } from 'react'
import { masterAPI } from '../../Masters/dataConfig'
import TableContainerComp from '../../../Component/TableContainer'
import { color } from '../../../StyleData/Config';
import { CheckCircle, DeleteOutline, EditOutlined, RadioButtonUncheckedOutlined } from '@mui/icons-material';
import { Box, Checkbox, IconButton, Switch, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../../../axios/axios';
import { pmSample } from '../../../SampleData/PMSample';
import { toast } from "react-toastify"
import {useDispatch, useSelector} from "react-redux"

const PMList = () => {
    const [isLoading,setLoading]=useState(false)
    const [data,setData]=useState([])
    const [pmGenerate,setPmGen]=useState([])
    const [filterValue, setFilterValue] = useState("enableData");
    const [backUpData, setBackUpData] = useState([]);

    const navigate=useNavigate()
    const dispatch=useDispatch()
    const actionsAllow=useSelector(state=>state.dataRed.actionsAllow) 
  
  
    useEffect(()=>{
        getData()
    },[])

const getData=async()=>{
  dispatch({type:"pmEditData",payload:{edit:false,data:{}}})
  const pmLink=masterAPI["PM"].link
 await axiosPrivate.get(`${pmLink}`).then(res=>{
    res.data.result.sort((a, b) => {
      return a.code.localeCompare(b.code, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    });
    if (filterValue === "enableData") {
      const filterDataResult = res.data.result.filter(
        (fil) => fil.isActive
      );
      console.log(filterDataResult, "filterData");
      setData(filterDataResult);
      setBackUpData(res.data.result);
    } else {
      setData(res.data.result);
      setBackUpData(res.data.result);
    }
  }).catch(e=>{
    setData([])
    setBackUpData([])
    console.log(e,'error pm list error')
  })
}

const handleButtonNew = () => {
    // setMode("Create");
    // setCreate(true);
    navigate("/pmcreate");
  };

    const columns = [
        {
          accessor: "id",
          disableFilters: true,
          Cell: ({cell}) => {
            return (
              <Box sx={{ width: color.checkBoxWidth }}>
                <Checkbox
                  disabled={cell.row.original.status==="CAN"}
                  // icon={<RadioButtonUncheckedOutlined />}
                  // checkedIcon={<CheckCircle />}
                  checked={pmGenerate.includes(cell.value)}
                  onClick={(e)=>{handleCheck(e,cell.value)}}
                />
              </Box>
            );
          },
        },
        {
          Header: "PM Code",
          accessor: "code",
          // disableFilters: true,
          Cell: ({ cell }) => {
            return (
              <Link
                to={`/pmView?id=${cell.row.original.id}`}
                style={{
                  color: "#0d6efd",
                  textDecorationLine: "underline",
                  fontWeight: 800,
                }}
              >
                {cell.value}
              </Link>
            );
          },
        },
        {
          Header: "PM Name",
          accessor: "name",
        },
        {
          Header: "Asset",
          accessor: "assetName",
        },
        {
          Header: "PM Status",
          accessor: "status",
        },
        {
          Header: "Job Type",
          accessor: "workType",
        },
        {
            Header: "Job Plan",
            accessor: "jobPlanName",
          },
        {
          Header: "Actions",
          Cell: ({ cell }) => {
            // console.log(cell)
            return (
              <Box sx={{ whiteSpace: "nowrap" }}>
                <IconButton 
                disabled={!actionsAllow.edit|| cell.row.original.status==="CAN"}
                  onClick={() => {
                      handleEdit(cell.row.original);
                    }}
                >
                  <EditOutlined
                  
                    sx={{
                      fontSize: color.masterEditDelButton,
                      color: actionsAllow.edit&& !cell.row.original.status==="CAN"? color.masterEditDelButtonColor:"default",
                    }}
                  />
                </IconButton>
                {/* <IconButton disabled={!actionsAllow.delete}>
                  <DeleteOutline
                    onClick={() => {
                      handleDelete(cell.row.original.id);
                    }}
                    sx={{
                      fontSize: color.masterEditDelButton,
                      color: actionsAllow.delete? color.masterEditDelButtonColor:"default",
                    }}
                  />
                </IconButton> */}
                <Switch
              disabled={
                !actionsAllow.delete || cell.row.original.status !== "NEW"
              }
              checked={cell.row.original.isActive}
              onChange={(e) => {
                handleDelete(e, cell.row.original.id);
              }}
            />
              </Box>
            );
          },
        },
      ];

      const handleEdit = (data) => {
        dispatch({type:"pmEditData",payload:{edit:true,data:data}})
        navigate("/pmcreate")
        // setEditData(data);
        // setMode("Edit");
        // setCreate(true);
      };

      const handleCheck=(e,value)=>{
        if(e.target.checked){
          setPmGen([...pmGenerate,value])
        }else{
          const filPm=pmGenerate.filter(fil=>fil!==value)
          setPmGen(filPm)
        }
      }
    
      // const handleDelete = (id) => {
      //   const deleteLink = masterAPI["PM"].delete;
      //   axiosPrivate
      //     .delete(`${deleteLink}/${id}`)
      //     .then((res) => {
      //       console.log(res.data);
      //       getData();
      //     })
      //     .catch((e) => {
      //       console.log(e, "delete assets");
      //     });
      // };

      const handleDelete = async(e, id) => {
        const enableLink = masterAPI["PM"].enable;
        const disableLink = masterAPI["PM"].disable;
        if (e.target.checked) {
         await axiosPrivate
            .put(`${enableLink}/${id}`)
            .then((res) => {
              console.log(res.data);
              getData();
            })
            .catch((e) => {
              console.log(e, "enable pm");
            });
        } else {
         await axiosPrivate
            .put(`${disableLink}/${id}`)
            .then((res) => {
              console.log(res.data);
              getData();
            })
            .catch((e) => {
              console.log(e, "disable pm");
            });
        }
      };

      const handleGenerate=async()=>{
       console.log(pmGenerate)
       if(pmGenerate.length===0){
        toast.error("Please Select Pm")
      
       }else{
        const genWo=masterAPI["PM"].generateWO
       await axiosPrivate.post(`${genWo}`,pmGenerate).then(res=>{
          console.log(res,"genWO")
          toast.success("Successfully Converted")
          getData()
          setPmGen([])
        }).catch(e=>{
          console.log(e,"error generate workorder pm")
        })
       }
      }

      const handleChangeFilter = (e) => {
        if (e === "showAll") {
          setData(backUpData);
        } else {
          const filterDataResult = backUpData.filter((fil) => fil.isActive);
    
          setData(filterDataResult);
        }
        setFilterValue(e);
      };
      
  return (
    <>
    <Box>
      <Typography
        sx={{
          color: color.masterPageTitleColor,
          fontSize: color.masterPageTitleFontSize,
          fontWeight: color.masterPageTitleFontWeight,
          mb: color.masterPageTitlemd,
        }}
      >
        {masterAPI["PM"].title}
      </Typography>
    </Box>

    <TableContainerComp
      columns={columns}
      data={data}
      buttonNew={handleButtonNew}
      loading={isLoading}
      generate={true}
      handleGenerate={handleGenerate}
      buttonDisable={!actionsAllow.create}
      filterValue={filterValue}
      handleChange={handleChangeFilter}
      filterShow={true}
    />
    {/* <Dialog
      open={create}
      onClose={handleCreateClose}
      children={
        <CreateWO
          handleCloseClick={handleCreateClose}
          mode={mode}
          editData={editData}
        />
      }
    /> */}
  </>
  )
}

export default PMList