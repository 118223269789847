import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import {
  Box,
  Checkbox,
  Dialog,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import { useSelector } from "react-redux";
import { EditOutlined } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import TransferTransactionModal from "./TransferTransactionModal";

const TransferTransaction = () => {
  const actionsAllow = useSelector((state) => state.dataRed.actionsAllow);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["partmovement"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        console.log("API Data:", res.data.result);
        res.data.result.sort((a, b) => {
          return a.code.localeCompare(b.code, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        });
        setData(res.data.result);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "partmovement");
        setIsLoading(false);
        setData([]);
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };

  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  const handleDelete = async(e, id) => {
    const enableLink = masterAPI["partmovement"].enable;
    const disableLink = masterAPI["partmovement"].disable;
    if (e.target.checked) {
     await axiosPrivate
        .put(`${enableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "enable PartsMovement");
        });
    } else {
     await axiosPrivate
        .put(`${disableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "disable PartsMovement");
        });
    }
  };

  const columns = [
    {
      accessor: "id",
      disableFilters: true,
      Cell: () => {
        return (
          <Box sx={{ width: color.checkBoxWidth }}>
            <Checkbox />
          </Box>
        );
      },
    },
    {
      Header: "Part Name",
      accessor: "partName",
    },
    {
      Header: "From Stock Room Name",
      accessor: "fromStockRoomName",
    },
    
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "To Stock Room Name",
      accessor: "toStockRoomName",
    },
    {
      Header: "Status",
      accessor: "status",
    },

    {
      Header: "Actions",
      Cell: ({ cell }) => {
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton disabled={!actionsAllow.edit}>
              <EditOutlined
                onClick={() => {
                  handleEdit(cell.row.original);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: actionsAllow.edit
                    ? color.masterEditDelButtonColor
                    : "default",
                }}
              />
            </IconButton>

            <Switch
              disabled={!actionsAllow.delete}
              checked={cell.row.original.isActive}
              onChange={(e) => {
                handleDelete(e, cell.row.original.id);
              }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["partmovement"].title}
        </Typography>
      </Box>

      <TableContainer
        columns={columns}
        data={data}
        buttonNew={handleButtonNew}
        loading={isLoading}
        buttonDisable={!actionsAllow.create}
      />

      <Dialog open={create} onClose={handleCreateClose} maxWidth={"md"}>
        <TransferTransactionModal
          handleCloseClick={handleCreateClose}
          mode={mode}
          editData={editData}
          fetchData={getData} 
        />
      </Dialog>
    </>
  );
};

export default TransferTransaction;
