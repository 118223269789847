import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Grid,
  IconButton,
  Divider,
  Box,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { axiosPrivate } from "../../../../axios/axios";
import { masterAPI } from "../../../Masters/dataConfig";
import { Save, Clear } from "@mui/icons-material";
import { color } from "../../../../StyleData/Config";

const EditMeterDialog = ({ open, onClose, assetId, meterId, editData, meterCode, meterName }) => {
  const [date, setDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const initialValues = {
    comments: editData ? editData.comments : "",
    installationDate: editData ? dayjs(editData.installationDate) : date,
    readingValue: editData ? editData.readingValue : 0,
    dateOfReading: editData ? dayjs(editData.dateOfReading) : dayjs(),
  };

  const onSubmit =async (values) => {
    setLoading(true);
    const createReadingLink = masterAPI.assetMeter.createreading;
    const dataToSend = [
      {
        assetId,
        meterId,
        // comments: values.comments,
        installationDate: values.installationDate,
        readingValue: values.readingValue,
        dateOfReading: values.dateOfReading,
      },
    ];
   await axiosPrivate
      .post(`${createReadingLink}`, dataToSend)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        onClose();
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, "create meter reading");
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          Edit Meter - {meterCode} {meterName}
          <Box>
            <IconButton type="submit" form="edit-meter-form">
              <Save sx={{ color: "#2362B4" }} />
            </IconButton>
            <IconButton onClick={onClose}>
              <Clear />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ setFieldValue }) => (
          <Form id="edit-meter-form">
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Reading Value
                  </Typography>
                  <Field
                    as={TextField}
                    size="small"
                    type="text"
                    name="readingValue"
                    placeholder="Enter Reading"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        "&::placeholder": {
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Date of Reading
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={date}
                      onChange={(newValue) => {
                        handleDateChange(newValue);
                        setFieldValue("installationDate", newValue);
                      }}
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0" },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditMeterDialog;
