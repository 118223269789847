import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import { Box, Checkbox, Dialog, IconButton, Switch, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import { useSelector } from "react-redux";
import {
  AddHomeWork,
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import FailureCodeModal from "./ServiceReqModal";
import ServiceReqModal from "./ServiceReqModal";
import { toast } from "react-toastify";
import FeedBack from "../../../Component/FeedBack";
import SRView from "./SRView";

const ServiceReq = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const actionsAllow = useSelector((state) => state.dataRed.actionsAllow);
  const [statusList, setStatusList] = useState([]);
  const [selectSR, setSelectSR] = useState("");
  const [feedBack, setFeedBack] = useState(false);

  const [statusMenuDetail, setStatusDetail] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [SRID,setSRID]=useState("")
  const [view,setView]=useState(false)
  const [filterValue,setFilterValue]=useState("enableData")
  const [backUpData,setBackUpData]=useState([])

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["serviceRequest"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        res.data.result.sort((a, b) => {
          return b.code.localeCompare(a.code, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        if(filterValue==="enableData"){
          const filterDataResult=res.data.result.filter(fil=>fil.isActive)
          console.log(filterDataResult, "locationresult");
          setData(filterDataResult);
          setBackUpData(res.data.result)
        }else{
          setData(res.data.result)
          setBackUpData(res.data.result)
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "serviceRequest");
        setIsLoading(false);
        setData([])
        setBackUpData([])
      });

    const statusData = masterAPI["selectList"].serviceRequestStatus;
   await axiosPrivate
      .get(`${statusData}`)
      .then((res) => {
        console.log(res);
        const filterStatus=res.data.filter((fil)=>fil.text.toUpperCase()==="CANCELLED")
        setStatusList(filterStatus);
      })
      .catch((e) => {
        setStatusList([])
        console.log(e, "error");
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    setEditData({})
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  const handleWO = async(val) => {
    const convertlink = masterAPI["serviceRequest"].coverttowo;
   await axiosPrivate
      .put(`${convertlink}/${val.id}`)
      .then((res) => {
        console.log(res);
        getData();
        toast.success("Successfully Converted");
      })
      .catch((e) => {
        console.log(e, "sr error");
      });
  };

  // const handleDelete = (id) => {
  //   const deleteLink = masterAPI["serviceRequest"].delete;
  //   axiosPrivate
  //     .delete(`${deleteLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "delete serviceRequest");
  //     });
  // };
  
  const handleDelete = async(e,id) => {
    const enableLink = masterAPI["serviceRequest"].enable;
    const disableLink = masterAPI["serviceRequest"].disable;
    if(e.target.checked){
     await axiosPrivate
      .put(`${enableLink}/${id}`)
      .then((res) => {
        console.log(res.data);
        getData();
      })
      .catch((e) => {
        console.log(e, "enable sr");
      });
    }else{
     await axiosPrivate
      .put(`${disableLink}/${id}`)
      .then((res) => {
        console.log(res.data);
        getData();
      })
      .catch((e) => {
        console.log(e, "disable sr");
      });
    }
  };
  
  const handleCheckList = (e, v) => {
    //  if(e.target.checked){
    //   setSelectSR([...selectSR,v])
    //  }else{
    //   const filterSR=selectSR.filter(fil=>fil!==v)
    //   setSelectSR(filterSR)
    //  }
    if (e.target.checked) {
      setSelectSR(v);
    } else {
      setSelectSR("");
    }
  };

  const handleView=(id)=>{
    setView(true)
    setSRID(id)
  }

  const handleCloseView=()=>{
    setView(false)
    setSRID("")
    getData()
  }

  const columns = [
    {
      accessor: "id",
      disableFilters: true,
      Cell: ({ cell }) => {
        return (
          <Box sx={{ width: color.checkBoxWidth }}>
            <Checkbox
              disabled={cell.row.original.status.toUpperCase()==="CAN"}
              // icon={<RadioButtonUncheckedOutlined />}
              // checkedIcon={<CheckCircle />}
              checked={selectSR.includes(cell.value)}
              onClick={(e) => {
                handleCheckList(e, cell.value);
              }}
            />
          </Box>
        );
      },
    },
    {
      Header: "Service Request Code",
      accessor: "code",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              fontSize: "inherit",
              fontWeight: "inherit",
              color: "#0d6efd",
              textDecoration:"underline",
              cursor:"pointer"
            }}
            onClick={()=>handleView(cell.row.original.id)}
          >
            {cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Service Request Name",
      accessor: "serviceRequestName",
    },
    // {
    //   Header:"Description",
    //   accessor:"description"
    // },
    {
      Header: "Asset Name",
      accessor: "assetName",
    },
    {
      Header: "Loaction",
      accessor: "locationName",
    },
    // {
    //   Header: "Problem Name",
    //   accessor: "problemName",
    //   // disableFilters: true,
    // },
    // {
    //   Header: "Failure Class Name",
    //   accessor: "failureClassName",
    // },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton
              disabled={cell.row.original.status.toUpperCase() !== "NEW"}
            >
              <AddHomeWork
                onClick={() => {
                  handleWO(cell.row.original);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color:
                    cell.row.original.status.toUpperCase() !== "NEW"
                      ? "default"
                      : color.masterEditDelButtonColor,
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                handleEdit(cell.row.original);
              }}
              disabled={
                cell.row.original.status.toUpperCase() !== "NEW" ||
                !actionsAllow.edit
              }
            >
              <EditOutlined
                sx={{
                  fontSize: color.masterEditDelButton,
                  color:
                    cell.row.original.status.toUpperCase() !== "NEW" ||
                    !actionsAllow.edit
                      ? "default"
                      : color.masterEditDelButtonColor,
                }}
              />
            </IconButton>
            {/* <IconButton
              disabled={
                cell.row.original.status.toUpperCase() !== "NEW" ||
                !actionsAllow.delete
              }
              onClick={() => {
                handleDelete(cell.row.original.id);
              }}
            >
              <DeleteOutline
                sx={{
                  fontSize: color.masterEditDelButton,
                  color:
                    cell.row.original.status.toUpperCase() !== "NEW" ||
                    !actionsAllow.delete
                      ? "default"
                      : color.masterEditDelButtonColor,
                }}
              />
            </IconButton> */}
            <Switch
             disabled={
              cell.row.original.status.toUpperCase() !== "NEW" ||
              !actionsAllow.delete
            }
            checked={cell.row.original.isActive}
            onChange={(e)=>{handleDelete(e,cell.row.original.id)}}
           
             />
          </Box>
        );
      },
    },
  ];

  const handleMenuSelect = async(menuData,reason) => {
    setFeedBack(false);
    const putData = {
      id: selectSR,
      status: menuData.value,
      reasontoCancel: reason,
    };
    console.log(putData);
    const statusLink = masterAPI["serviceRequest"].updateStatus;
   await axiosPrivate
      .put(`${statusLink}`, putData)
      .then((res) => {
        console.log(res);
        setSelectSR("");
        getData();
      
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const menuStatusHandle = (menuData) => {
    console.log(menuData);
    setStatusDetail(menuData);
    // const putData=[]
    // for(let i=0;selectSR.length>i;i++){
    //   putData.push({
    //     id:selectSR[i],
    //     status:menuData.value
    //   })
    // }
    if (menuData.value === "CAN") {
      setFeedBack(true);
    } else {
      handleMenuSelect(menuData,"");
    }
  };

  const handleChangeFilter=(e)=>{
   
    if(e==="showAll"){
      setData(backUpData)
    }else{
        const filterDataResult=backUpData.filter(fil=>fil.isActive)
        
        setData(filterDataResult);
    }
    setFilterValue(e)   
  }
 

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["serviceRequest"].title}
        </Typography>
      </Box>

      <TableContainer
        columns={columns}
        data={data}
        buttonNew={handleButtonNew}
        loading={isLoading}
        buttonDisable={!actionsAllow.create}
        statusList={statusList}
        menuStatusHandle={menuStatusHandle}
        changeStatusOption={selectSR.length!==0}
        filterValue={filterValue}
        handleChange={handleChangeFilter}
        filterShow={true}
      />

      <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"md"}
        children={
          <ServiceReqModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      />
      <Dialog
        open={view}
        onClose={handleCloseView}
        PaperProps={{
          sx: {
            maxWidth: '70vw',
            width:"65vw"
          },
        }}
        children={
          <SRView
            handleCloseClick={handleCloseView}
            viewId={SRID}
          />
        }
      />

      <Dialog
        open={feedBack}
        onClose={() => setFeedBack(false)}
        maxWidth={"lg"}
        children={
          <FeedBack
            handleCloseClick={(e) => handleMenuSelect(statusMenuDetail,e)}
        
            onCancel={() => {
              setFeedBack(false);
            }}
          />
        }
      />
    </>
  );
};

export default ServiceReq;
