import React, { useEffect, useState } from "react";
import TableContainer from "../../Component/TableContainer";
import {
  Box,
  Checkbox,
  Dialog,
  IconButton,
  Typography,
  Paper,
  Grid,
  Button,
  Select,
  MenuItem,
  Switch,
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { masterAPI } from "../../Pages/Masters/dataConfig";
import { axiosPrivate } from "../../axios/axios";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
  RemoveRedEye,
} from "@mui/icons-material";
import { color } from "../../StyleData/Config";

import NormalTable from "../../Component/NormalTable";

const KPI = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [menusId, setMenusId] = useState([]);
  const [backupMenu, setBackUpMenu] = useState([]);
  const [rolesId, setRolesId] = useState([]);
  const [alreadyHaveMenu,setAlreadyHaveMenu]=useState([])
  const [sel, setSel] = useState({
    autoRole: "",
    selectedRole: "",
    selectmenuId: [],
    selectedDetails: [],
    roleDetail:null
  });

  useEffect(() => {
    getData();
  }, []);

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const getData=async()=>{
    const KPIData=[
        {
            KPIName:"Open WO"
        },
        {
            KPIName:"Overdue"
        },
        {
            KPIName:"Service Request"
        },
        {
            KPIName:"Cost"
        },
        {
            KPIName:"Mean Time Before Failure"
        },
        {
            KPIName:"Mean Time To Repair"
        },
        {
            KPIName:"PM Complaince"
        },
        {
            KPIName:"Failure Report"
        },
        {
            KPIName:"Problem Report"
        },
        {
            KPIName:"Causes Report"
        },
        {
            KPIName:"Remedy Report"
        },
    ]
    setMenusId(KPIData)
  }



  const columns = [
    

    {
      
      accessor: "KPIName",
      Cell:({cell})=>{
        return(
            <Typography sx={{fontSize:"inherit",fontWeight:"600"}}>
               {cell.value}
            </Typography>
        )
      }
    },
    {
        Header: "Actions",
        Cell: ({ cell }) => {
          // console.log(cell)
          return (
            <Box sx={{ whiteSpace: "nowrap" }}>
              <IconButton 
            //   disabled={!actionsAllow.edit|| cell.row.original.status==="CAN"}
            //     onClick={() => {
            //         handleEdit(cell.row.original);
            //       }}
              >
                <EditOutlined
                
                  sx={{
                    fontSize: color.masterEditDelButton,
                    //color: actionsAllow.edit&& !cell.row.original.status==="CAN"? color.masterEditDelButtonColor:"default",
                  }}
                />
              </IconButton>
              <IconButton >
                <RemoveRedEye
                
                  sx={{
                    fontSize: color.masterEditDelButton,
                    //color: actionsAllow.delete? color.masterEditDelButtonColor:"default",
                  }}
                />
              </IconButton>
            
          
            </Box>
          );
        },
      },
   
  ];



  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          KPI 
        </Typography>
      </Box>
    
      <Paper sx={{ p: 1 }}>
        <Grid container spacing={"1rem"}>
       
          <Grid item xs={12}>
            <NormalTable data={menusId} columns={columns} />
          </Grid>
        
        </Grid>
      </Paper>
    </>
  );
};

export default KPI;
