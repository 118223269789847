import { Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { color } from '../../../../../StyleData/Config'

const SafetyPrecaution = ({data}) => {

    const  sampleData=[
        {
            label:"Safety Helmet",
            checked:true
        },
        {
            label:"Respirator",
            checked:false
        },
        {
            label:"Breathing Apparatus",
            checked:false
        },
        {
            label:"Safety Cones",
            checked:false
        },
        {
            label:"Rescue Boat",
            checked:false
        },
        {
            label:"Safety Shoes",
            checked:true
        },
        {
            label:"Safety Glasses/Goggles",
            checked:true
        },
        {
            label:"Flourescent Vest",
            checked:false
        },
        {
            label:"Temporary Barrier",
            checked:false
        },
        {
            label:"Cutting/Grinding",
            checked:false
        },
        {
            label:"Ear Plug/Muff",
            checked:false
        },
        {
            label:"Face Shield",
            checked:false
        },
        {
            label:"Life Jacket",
            checked:false
        },
        {
            label:"Warning Sign",
            checked:false
        },
        {
            label:"Arc Weld Eqpt",
            checked:false
        },
        {
            label:"Gloves (Cotton/Leather)",
            checked:false
        },
        {
            label:"Chemical Gloves",
            checked:false
        },
        {
            label:"Coverall",
            checked:true
        },
        {
            label:"Extra Ligthing",
            checked:true
        },
        {
            label:"Chemical Apron",
            checked:false
        },
        {
            label:"Gas Detector",
            checked:false
        },
        {
            label:"Safety Harness",
            checked:true
        },
        {
            label:"Heat Glove",
            checked:false
        },
        {
            label:"Walkie Talkie",
            checked:false
        },
        {
            label:"Medical Oxygen Cylinder",
            checked:false
        },
        {
            label:"ELCB",
            checked:false
        },
        {
            label:"No Manual Handling",
            checked:false
        },
        {
            label:"Ladder",
            checked:true
        },
        {
            label:"Ambulance",
            checked:false
        },
        {
            label:"Doctors Advice",
            checked:false
        },
        {
            label:"Extra Ventilation",
            checked:false
        },

    ]
  return (
    <Paper elevation={3} sx={{p:1}}>
        <Grid container spacing={"1rem"}>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={4} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Permit Code :
                </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                }}
                >
                  {data}  
                </Typography>
            </Grid>
             </Grid>
             </Grid>
          

        </Grid>
        
        <Grid item container xs={12}>
            {
                sampleData.map((samData,i)=>(
                    <Grid item xs={3} key={i}>
                        <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    mt:-1
                  }}
                >
               <FormControlLabel  control={<Checkbox checked={samData.checked} />} label={samData.label} />
                </Typography>
                        </Grid>
                ))
            }
            <Grid item xs={12} >
                        <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    mt:-1
                  }}
                >
               <FormControlLabel  control={<Checkbox checked={true} />} label={"House Keeping to be performing by end of each shit /day inc.scheduled waste disposal"} />
                </Typography>
                        </Grid>
                        <Grid item xs={3} >
                        <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    mt:-1
                  }}
                >
               <FormControlLabel  control={<Checkbox checked={true} />} label={"Lock out Tag out"} />
                </Typography>
                        </Grid>
                        <Grid item xs={3} >
                        <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    mt:-1
                  }}
                >
               <FormControlLabel  control={<Checkbox checked={false} />} label={"Other Percautions"} />
                </Typography>
                        </Grid>
        </Grid>

        </Grid>

    </Paper>
  )
}

export default SafetyPrecaution