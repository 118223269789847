import React from 'react'
import Chart from "react-apexcharts";

const FailureClassChart = ({failureClassXaxis,failureClassYaxis,type}) => {
  return (
    <Chart
    height={type?
     ( failureClassYaxis.length
        ? `${32 * failureClassYaxis.length + 200}px`
        : "100%"):200
    }
    width={"100%"}
    options={{
      
      responsive: [
        {
          breakpoint: 768, // Change the breakpoint as needed
          options: {
            chart: {
              width: 600,
            },
          },
        },
      ],
      series: [
        {
          data: failureClassXaxis,
        },
      ],
      title: {
        text: "Failure Report",
        style: {
          fontFamily: "Nunito Sans, sans-serif",
          color: "#2362B4",
        },
      },

      chart: {
        type: "bar",
        toolbar: {
          export: {
            csv: {
              filename: "Failure",
            },
            svg: {
              filename: "Failure",
            },
            png: {
              filename: "Failure",
            },
          },
        },
      
      },
    //   grid:{
    //     padding:{
    //         bottom:-10
    //     }
    //   },

      plotOptions: {
        bar: {

          borderRadius: 4,
          horizontal: false,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
                fontFamily: "Nunito Sans, sans-serif",
              },
            },
          },
        },
        noData: {
          text: "No Result",
          style: {
            fontSize: "1.5rem",
            color: "grey",
            fontFamily: "Nunito Sans, sans-serif",
          },
        },

        stroke: {
          width: 1,
          colors: ["#fff"],
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        title: {
          text: "No of Records",
          // style:{
          //  padding:10
          // }
        },
        labels:{
            show:type
        }
      },
      xaxis: {
        title: {
          text: "Failure",
          style: {
            fontSize: '11px' // Set the font size of the x-axis title
          }
        },
        // labels:{
        //     show:false
        // },
        categories: failureClassXaxis,

        
      },
    }}
    type="bar"
    series={[{ data: failureClassYaxis }]}
  />
  )
}

export default FailureClassChart
