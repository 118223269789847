// import { Box, Icon, IconButton, InputAdornment, List, TextField, Typography } from "@mui/material";
// import React, { useEffect, useRef, useState } from "react";
// import { Tree } from "react-arborist";
// import "./treeStructure.css";
// import {
//   Accessibility,
//   AccountTree,
//   BiotechRounded,
//   ChevronRight,
//   ChevronRightRounded,
//   ExpandMore,
//   ExpandMoreRounded,
//   LocationCity,
//   LocationCityRounded,
//   StoreRounded,
//   TocRounded,
// } from "@mui/icons-material";

// import assetIcon from "../../../images/assetIcon.png"
// import locationIcon from "../../../images/locationIcon.png"
// import siteIcon from "../../../images/siteIcon.png"
// import { color } from "../../../StyleData/Config";
// import { masterAPI } from "../../Masters/dataConfig";
// import { axiosPrivate } from "../../../axios/axios";

// const TreeStructureExpl = () => {
//   const [search, setSearch] = useState(null);
//   const [loading,setLoading]=useState(false)
//   const [treeLoading,setTreeLoading]=useState(false)
//   // const treeRef = useRef(null);
//   const data1 = [
//     { id: "1", name: "Unread" },
//     { id: "2", name: "Threads" },
//     // {
//     //   id: "3",
//     //   name: "Chat Rooms",
//     //   children: [
//     //     {
//     //       id: "c1",
//     //       name: "General",
//     //       children: [
//     //         { id: "e1", name: "General" },
//     //         { id: "e2", name: "Random" },
//     //         { id: "e3", name: "Open Source Projects" },
//     //         { id: "e4", name: "ka bo" },
//     //       ],
//     //     },
//     //     { id: "c2", name: "Random" },
//     //     { id: "c3", name: "Open Source Projects" },
//     //     { id: "c4", name: "ka bo" },
//     //   ],
//     // },
//     // {
//     //   id: "4",
//     //   name: "Direct Messages",
//     //   children: [
//     //     { id: "d1", name: "Alice" },
//     //     { id: "d2", name: "Bob" },
//     //     { id: "d3", name: "Charlie" },
//     //   ],
//     // },
//   ];
//   const [data,setData]=useState([])
 

// useEffect(()=>{
//  getLocationData()
// },[])

// // const getObject=(dataTree)=>{
  
// //   if(dataTree.childLocationList===undefined||dataTree.childLocationList===null||dataTree.childLocationList===""){
// //   let structureWithoutChild={
// //   id:dataTree.id,
// //   locationId:dataTree.id,
// //   isAssetAvailable:dataTree.isAssetAvailable,
// //   name:dataTree.locationCode+"_"+dataTree.locationName,
// //   icon:LocationCityRounded,
// //   type:"icon"
// //  }
// //  return structureWithoutChild
// // }else{
// // const childData=[]
// //   dataTree.childLocationList.map((dt,i)=>{
// //     console.log(dt,i)
// //     const getChildData= getObject(dt)
// //     childData.push(getChildData)
// //   })
  
// //   let structureWithChild={
// //     id:dataTree.id,
// //     locationId:dataTree.id,
// //     isAssetAvailable:dataTree.isAssetAvailable,
// //     name:dataTree.locationCode+"_"+dataTree.locationName,
// //     children:childData,
// //     icon:LocationCityRounded,
// //     type:"icon"
// //    }
// //    return structureWithChild
// // }

// // }
// const getObject=(dataTree)=>{
//   const childData=[]
//   if(dataTree.childLocationList!==undefined&&dataTree.childLocationList!==null){
   
//     dataTree.childLocationList.map((dt,i)=>{
//       console.log(dt,i)
//       const getChildData= getObject(dt)
//       childData.push(getChildData)
//     })
//   }
  
//   let structureWithoutChild={
//   id:dataTree.id,
//   locationId:dataTree.id,
//   isAssetAvailable:dataTree.isAssetAvailable,
//   name:dataTree.locationCode+" - "+dataTree.locationName,
//   icon:LocationCityRounded,
//   children:dataTree.childLocationList!==undefined&&dataTree.childLocationList!==null?childData:dataTree.isAssetAvailable?[]:null,
//   type:"icon"
//  }
//  return structureWithoutChild


  
//   // let structureWithChild={
//   //   id:dataTree.id,
//   //   locationId:dataTree.id,
//   //   isAssetAvailable:dataTree.isAssetAvailable,
//   //   name:dataTree.locationCode+"_"+dataTree.locationName,
//   //   children:childData,
//   //   icon:LocationCityRounded,
//   //   type:"icon"
//   //  }
//   //  return structureWithChild


// }

// const handleTreeAsset=(id,assetChildren,filterData)=>{
//   const updateChildren=[]
//   for(let i=0;filterData.length>i;i++){
//     console.log(id,filterData[i],i)
//     if(filterData[i].locationId===id){
//     console.log("check1")
//       updateChildren.push({
//         ...filterData[i],
//         children:assetChildren
//       })


//     }else{

//       if(filterData[i].children!==null&&filterData[i].length!==0){
//         console.log(updateChildren,"check2")
//         const getChildrenData=handleTreeAsset(id,assetChildren,filterData[i].children)
//         console.log(getChildrenData,"check3")
//         updateChildren.push({...filterData[i],children:getChildrenData})
//       }else{
//         console.log(updateChildren,"check4")
//          updateChildren.push(filterData[i])
//       }




//     }
//   }
//   console.log(updateChildren,"finale")
//   return updateChildren;
// }

// const getLocationData=()=>{
//   setLoading(true)
// const linkLoc=masterAPI["TreeView"].location
// axiosPrivate.get(`${linkLoc}`).then(res=>{
  
//  const resData=res.data.result
//  console.log(resData)
// //  getLocationData()
// const mainData=[]
// //  for(let i=0;resData.length>0;i++){
// //    const arrayData=getObject(resData[i])
// //    mainData.push(arrayData)
// //  }
//  resData.map((data,i)=>{
//   console.log("check",i)
//    const arrayData=getObject(data)
//    mainData.push(arrayData)
//  })
//  console.log(mainData,"mainData")
//  const siteAssign=[
//   {
//     id:"2b143042-85e1-4edb-be93-ddfc87905275",
//     name:"Shah Alam",
//     children:mainData,
//     icon:siteIcon,
//     type:"img"
//   }
//  ]
//  setData(siteAssign)
//  setLoading(false)
// }).catch(e=>{
//   console.log(e,"LocationTree Error")
// })
// }

//   function Node({ node, style, dragHandle }) {
//     /* This node instance can do many things. See the API reference. */
//     // console.log(node);
  
//     return (
//       <div
//         style={{
//           ...style,
//         //  whiteSpace:"break-spaces"
//         }}
//         className="nodeCss"
//         ref={dragHandle}
//         onClick={() => {
//           if (node.children === null && !node.data.isAssetAvailable) {
//             console.log(node)
//             alert("success");

//           } else {
//             console.log(node.isOpen)
//             if(node.data.isAssetAvailable&&!node.isOpen){
             
//             const getAssetLink=masterAPI["assets"].assetByLocation
//             axiosPrivate.get(`${getAssetLink}/${node.data.locationId}`).then(res=>{
//               console.log(res,"assetBylocation")
//              const childrenAsset=[]
//              for(let i=0;res.data.result.length>i;i++){
//               childrenAsset.push({id:res.data.result[i].id,name:res.data.result[i].assetCode+"_"+res.data.result[i].assetName,type:"img",icon:assetIcon})
//              }
//             const assetTreeChild=handleTreeAsset(node.data.locationId,childrenAsset,data)
//             setData(assetTreeChild)
//             console.log(assetTreeChild)

//             }).catch(e=>{
//               console.log(e,"Error tree assetbylocation")
//             })}
//             node.toggle();
//           }
//           // console.log(node);
         
//         }}
//       >
//         {node.isLeaf && !node.data.isAssetAvailable ? (
//           // <BiotechRounded sx={{ fontSize: "1.3rem",color:"" }}/>
//         //  <img src={assetIcon} alt="assetIcon" style={{width:"1.3rem",height:"1.3rem"}}/>
//         <Box sx={{pl:3}}>
//           {node.data.type==="icon"? 
//           <node.data.icon sx={{ fontSize: "1.5rem",color:"#83817A"  }} />:
       
//         <img src={node.data.icon} alt="locationIcon" style={{width:"1.4rem",height:"1.4rem"}}/>
//   }
//         </Box>
//         ) : (
//           <div style={{ display: "flex", alignItems: "flex-end",paddingLeft:3 }}>
//             <div>
//               {node.isOpen ? (
//                 <ExpandMoreRounded sx={{ fontSize: "1.4rem",color:"#828079" }} />
//               ) : (
//                 <ChevronRightRounded sx={{ fontSize: "1.4rem" ,color:"#828079" }} />
//               )}
//             </div>
//             <div>
//             {node.data.type==="icon"? 
//           <node.data.icon sx={{ fontSize: "1.5rem",color:"#83817A"  }} />:
       
//         <img src={node.data.icon} alt="locationIcon" style={{width:"1.4rem",height:"1.4rem"}}/>}
//             </div>
//           </div>
//         )}
//         <Typography sx={{fontSize:"0.8rem",fontFamily:"'Nunito', sans-serif",fontWeight:600,whiteSpace:"nowrap"}}>{node.data.name}</Typography>
//       </div>
//     );
//   }

 

//   return (
//     <Box>
     
//       <TextField
//         onChange={(e) => {
//           setSearch(e.target.value);
//         }}
//         placeholder="Filter Search"
//         variant="standard"
//         fullWidth
//         InputProps={{
//           endAdornment: <InputAdornment position="end"><IconButton>
//              <TocRounded
//                             sx={{ color: color.loginIcon, fontWeight: 1800 }}
//                           />
//             </IconButton></InputAdornment>,
//         }}
//       />
//      {loading?null:
//       <Tree
//       // ref={treeRef}
      
//         initialData={data}
//         openByDefault={false}
//         width={"100%"}
//         height={600}
//         indent={24}
//         rowHeight={36}
//         paddingTop={30}
//         paddingBottom={10}
//         padding={25 /* sets both */}
//         searchTerm={search}
//         searchMatch={(node, term) =>
//           node.data.name.toLowerCase().includes(term.toLowerCase())
//         }
      
//       >
//         {Node}
//       </Tree>
// }
//     </Box>
//   );
// };

// export default TreeStructureExpl;

import * as React from 'react';
import {Box,Typography,TextField} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useEffect } from 'react';
import axios from "axios"
import { useState } from 'react';

import { masterAPI } from '../../Masters/dataConfig';
import assetIcon from "../../../images/assetIcon.png"
import siteIcon from "../../../images/siteIcon.png"
import locationIcon from "../../../images/locationIcon.jpg"
import { axiosPrivate, siteInfo } from '../../../axios/axios';
import {useSelector} from "react-redux"



export default function TreeStructureExpl({handleAsset}) {
    const [data,setData]=useState([])
    const [dataBackUp,setDataBackUp]=useState([])
    const [loading,setLoading]=useState(true)
    const [expanded,setExpand]=useState([])
    const siteInfoDetail=useSelector(state=>state.auth.loginInfo)
    useEffect(()=>{
        getLocationData()
    },[])
    const getObject=(dataTree)=>{
        const childData=[]
        if(dataTree.childLocationList!==undefined&&dataTree.childLocationList!==null){
         
          dataTree.childLocationList.map((dt,i)=>{
            console.log(dt,i)
            const getChildData= getObject(dt)
            childData.push(getChildData)
          })
        }
        
        let structureWithoutChild={
        id:dataTree.id,
        // locationId:dataTree.id,
        isAssetAvailable:dataTree.isAssetAvailable,
        name:dataTree.code+" - "+dataTree.locationName,
        icon:locationIcon,
        children:dataTree.childLocationList!==undefined&&dataTree.childLocationList!==null&&dataTree.childLocationList.length!==0?childData:dataTree.isAssetAvailable?[{}]:null,
        type:"icon",
        method:"location"
       }
       return structureWithoutChild
      
      
        
        // let structureWithChild={
        //   id:dataTree.id,
        //   locationId:dataTree.id,
        //   isAssetAvailable:dataTree.isAssetAvailable,
        //   name:dataTree.locationCode+"_"+dataTree.locationName,
        //   children:childData,
        //   icon:LocationCityRounded,
        //   type:"icon"
        //  }
        //  return structureWithChild
      
      
      }

      const getObjectAsset=(dataTree)=>{
        console.log(dataTree)
        const childData=[]
        if(dataTree.childAssetsList!==undefined&&dataTree.childAssetsList!==null){
         
          dataTree.childAssetsList.map((dt,i)=>{
            console.log(dt,i)
            const getChildData= getObjectAsset(dt)

            childData.push(getChildData)
          })
        }
        
        let structureWithoutChild={
          
            id:dataTree.id,
            name:dataTree.code+"_"+dataTree.assetName,
            type:"img",
            icon:assetIcon,
            children:dataTree.childAssetsList!==undefined&&dataTree.childAssetsList!==null&&dataTree.childAssetsList.length!==0?childData:null,
            isAssetAvailable:dataTree.childAssetsList===undefined||dataTree.childAssetsList.length===0?false:true,
            method:"asset"
          
       }
       return structureWithoutChild
      
      
        
        // let structureWithChild={
        //   id:dataTree.id,
        //   locationId:dataTree.id,
        //   isAssetAvailable:dataTree.isAssetAvailable,
        //   name:dataTree.locationCode+"_"+dataTree.locationName,
        //   children:childData,
        //   icon:LocationCityRounded,
        //   type:"icon"
        //  }
        //  return structureWithChild
      
      
      }
      
      const handleTreeAsset=(nodes,assetChildren,filterData)=>{
       
        const updateChildren=[]
        for(let i=0;filterData.length>i;i++){
          console.log(nodes,filterData[i],i)
          if(filterData[i].id===nodes.id){
          console.log("check1",assetChildren)
            updateChildren.push({
              ...filterData[i],
              children:filterData[i].children[0].id!==undefined?[...filterData[i].children,...assetChildren]:assetChildren
            })
      
      
          }else{
           if(filterData[i].children!==null){
            if(filterData[i].children[0].id!==undefined){
              console.log(updateChildren,"check2")
              const getChildrenData=handleTreeAsset(nodes,assetChildren,filterData[i].children)
              console.log(getChildrenData,"check3")
              updateChildren.push({...filterData[i],children:getChildrenData})
            }else{
              console.log(updateChildren,"check4")
               updateChildren.push(filterData[i])
            }}else{
                console.log(updateChildren,"check5")
                updateChildren.push(filterData[i])
            }

      
      
      
      
          }
        }
        console.log(updateChildren,"finale")
        return updateChildren;
      }
      
      const getLocationData=async()=>{
        setLoading(true)
      const locationtree=masterAPI["TreeView"].location
        await axiosPrivate.get(`${locationtree}`).then(res=>{
        
       const resData=res.data.result
       console.log(resData)
      //  getLocationData()
      const mainData=[]
      //  for(let i=0;resData.length>0;i++){
      //    const arrayData=getObject(resData[i])
      //    mainData.push(arrayData)
      //  }
       resData.map((data,i)=>{
        console.log("check",i)
         const arrayData=getObject(data)
         mainData.push(arrayData)
       })
       console.log(mainData,"mainData")
       const siteAssign=
        {
          id:siteInfoDetail.siteId,
          name:siteInfoDetail.siteName,
          children:mainData,
          icon:siteIcon,
          type:"img"
        }
       
       console.log(siteAssign)
       setData(siteAssign)
       setDataBackUp(siteAssign)
       setLoading(false)
      }).catch(e=>{
        console.log(e,"LocationTree Error")
      })
      }

      const handleItem=async(node)=>{
        console.log(node)
       if(node.method==="asset"){
        setExpand([...expanded,node.id])
             handleAsset(node.id)
          } else {
            handleAsset("")
            if(node.isAssetAvailable&&node.method==="location",!expanded.includes(node.id)){
             
            const assetByLocation=masterAPI["assets"].treeView
           await axiosPrivate.get(`${assetByLocation}/${node.id}`).then((res)=>{
              console.log(res,"assetBylocation")
              const assetChildData=[]
              for(let i=0;res.data.result.length>i;i++){
                const childrenAsset=getObjectAsset(res.data.result[i])
                assetChildData.push(childrenAsset)
              }
             
             console.log(assetChildData)
            //  for(let i=0;res.data.result.length>i;i++){
            //   childrenAsset.push(
            //     {
            //       id:res.data.result[i].id,
            //       name:res.data.result[i].assetCode+"_"+res.data.result[i].assetName,
            //       type:"img",icon:assetIcon,
            //       children:res.data.result[i].childAssetsList,
            //       isAssetAvailable:res.data.result[i].childAssetsList===undefined||res.data.result[i].childAssetsList.length===0?false:true,
            //       method:"asset"
            //     })
            //  }
            const assetTreeChild=handleTreeAsset(node,assetChildData,[data])
            setData(assetTreeChild[0])
            setDataBackUp(assetTreeChild[0])
            setExpand([...expanded,node.id])
            console.log(assetTreeChild)

            }).catch(e=>{
              console.log(e,"Error tree assetbylocation")
            })}
          
          }
      }

  const renderTree = (nodes) => {
    // console.log(nodes)
    return(
        
  
    <TreeItem key={nodes.id}  nodeId={nodes.id} label={
        <Box
        sx={{
          display: 'flex',
          alignItems: "flex-start",
          p: 1,
          pr: 0,
          gap:"0.5rem"
        }}
      >
       
       
       <img src={nodes.icon}  style={{width:"1.4rem",height:"1.4rem"}}/>
        <Typography variant="body2" sx={{ fontWeight:"inherit", flexGrow: 1,fontFamily:"inherit",fontSize:"0.89rem" }}>
          {nodes.name}
        </Typography>
        
      </Box>
    }  onClick={()=>{
       
       
        handleItem(nodes)
        
        }}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  )
      }
    
      const searchHandle=(dataSearch,e)=>{
        const search=handleSearch(dataSearch,e)
        setData(...search)
       }
     
       const handleSearch=(dataSearch,e)=>{
       
           return dataSearch.reduce((filtered, item) => {
               if (item.name && item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
                   filtered.push(item);
               } else if (item.children) {
                   const nestedItems = handleSearch(item.children, e);
                   if (nestedItems.length > 0) {
                       filtered.push({ ...item, children: nestedItems });
                   }
               }
               return filtered;
           }, []);
     }

  return (
    <>
    <TextField sx={{mb:1}} placeholder='Search' fullWidth size='small' onChange={(e)=>{searchHandle([dataBackUp],e)}}/>
    <Box sx={{height:"79vh", flexGrow: 1,overflow:"auto" }}>
     
      <TreeView
        aria-label="Location Tree"
        defaultCollapseIcon={<ExpandMoreIcon sx={{ color:"#828079" }} />}
        //  defaultExpanded={expanded}
        defaultExpandIcon={<ChevronRightIcon sx={{ color:"#828079" }} />}
        sx={{
            "& .MuiTreeItem-root": {
              "& .MuiTreeItem-content": {
                display:"flex",
                
                alignItems:"flex-start",
                "& .MuiTreeItem-iconContainer": {
                  mt:"0.5rem",
                },
              },
            },
          }}
      >
        {renderTree(data)}
      </TreeView>

    </Box>
    </>
  );
}



