import {combineReducers} from "redux"
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, PERSIST,REGISTER, } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { formReducer } from "./formData";
import { authReducer } from "./authentication";
import { dataReducer } from "./data";



const rootReducer=combineReducers({
   editData:formReducer,
   auth:authReducer,
   dataRed:dataReducer
})

const persistConfig = {
    key: 'root',
    storage,
    autoMergeLevel2: true,
  }
   
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST, REGISTER],
      },
      immutableCheck: {
        ignoredPaths: ['items.data']
      }
    }),
  
  });
  
  export let persistor = persistStore(store);
  // persistor.purge(); 
  // persistor.flush();
