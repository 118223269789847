import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear, FileUploadOutlined } from "@mui/icons-material";
import { color } from "../../../../StyleData/Config";
import { axiosPrivate } from "../../../../axios/axios";
import { masterAPI } from "../../../Masters/dataConfig";
import NormalTable from "../../../../Component/NormalTable";

const AssetRouteModal = ({ mode, handleCloseClick, editData }) => {
  const [asset, setAsset] = useState([]);
  const [selectedAsset, setSelAsset] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [assetTypeData, setAssetTypeData] = useState([]);
  const [selData, setSelData] = useState({
    locationId: null,
    assetTypeId: null,
  });
  const [loading, setLoading] = useState(false);
  const [autoCom, setAutoCom] = useState({
    assetTypeName: "",
    locationName: "",
  });

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    // const assetLink = masterAPI["assets"].link;
    // axiosPrivate
    //   .get(`${assetLink}`)
    //   .then((res) => {
    //     setAsset(res.data.result);
    //   })
    //   .catch((e) => {
    //     setAsset([]);
    //     console.log(e);
    //   });
    const assetTypeLink = masterAPI["assetType"].link;
   await axiosPrivate
      .get(`${assetTypeLink}`)
      .then((res) => {
        setAssetTypeData(res.data.result);
      })
      .catch((e) => {
        setAssetTypeData([]);
        console.log(e);
      });
    const locationLink = masterAPI["location"].link;
   await axiosPrivate
      .get(`${locationLink}`)
      .then((res) => {
        setLocationData(res.data.result);
      })
      .catch((e) => {
        setLocationData([]);
        console.log(e);
      });
  };

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const assetIdData = [];
      for (let i = 0; asset.length > i; i++) {
        assetIdData.push(asset[i].id);
      }
      setSelAsset(assetIdData);
    } else {
      setSelAsset([]);
    }
  };

  const columns = [
    {
      Header: "checkBox",
      accessor: "id",
      disableFilters:true,
      Cell: ({ cell }) => {
        return (
          <Checkbox
            checked={selectedAsset.includes(cell.row.original.id)}
            onClick={(e) => {
              handleAssetChecked(e, cell.row.original.id);
            }}
          />
        );
      },
    },
    {
      Header: "Asset Code",
      accessor: "code",
    },
    {
      Header: "Asset Name",
      accessor: "assetName",
    },
    {
      Header: "Asset Type",
      accessor: "assetTypeName",
      disableFilters:true
    },
    {
      Header: "location",
      accessor: "locationName",
      disableFilters:true
    },
  ];
  // const initialValue = {
  //   code: mode === "Edit" ? editData.code : "",
  //   routeName: mode === "Edit" ? editData.routeName : null,

  // };

  const onSubmit = async() => {
    setLoading(true);
    const createLink = masterAPI["assetRoute"].bulkinsert;
    const postData = [];
    for (let i = 0; selectedAsset.length > i; i++) {
      postData.push({
        routeId: editData,
        assetId: selectedAsset[i],
      });
    }
    console.log(postData);
   await axiosPrivate
      .post(`${createLink}`, postData)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        handleCloseClick();
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, "asset route");
      });
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  const handleAssetChecked = (e, id) => {
    if (e.target.checked) {
      setSelAsset([...selectedAsset, id]);
    } else {
      const filtSelAsset = selectedAsset.filter((fil) => fil !== id);
      setSelAsset(filtSelAsset);
    }
  };

  const onSearch=async()=>{
    const searchLink=masterAPI["assets"].getAssetByLocAndAssettype
   await axiosPrivate.post(`${searchLink}`,selData).then(res=>{
    console.log(res)
      setAsset(res.data.result)
    }).catch(e=>{
      console.log(e)
      setAsset([])
    })
  }



  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          New Asset Route
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Grid container>
        <Grid item xs={12}>
            
            
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem",width:"100%" }}>
            <Autocomplete
              inputValue={autoCom.assetTypeName}
              filterOptions={filterOptions}
              loading={assetTypeData.length === 0}
              options={assetTypeData}
              PaperComponent={CustomPaper}
              sx={{
                width:"20%"
              }}
              getOptionLabel={(option) =>
                option.code + "-" + option.assetTypeName || option
              }
              freeSolo
              onChange={(event, value) => {
                if (value) {
                  console.log(value);

                  setSelData({...selData,assetTypeId: value.id,});
                  setAutoCom({...autoCom,assetTypeName: value.code + "-" + value.assetTypeName,});
                } else {
                  setSelData({...selData,assetTypeId: null,});
                  setAutoCom({ ...autoCom,assetTypeName: "",});
                }
              }}
              onInputChange={(e, v) => {
                setAutoCom({...autoCom,assetTypeName: v,});
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Asset Type"
                  size="small"
                  // variant="standard"

                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        {assetTypeData.length === 0 ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
             <Autocomplete
             sx={{
                width:"20%"
              }}
              inputValue={autoCom.locationName}
              filterOptions={filterOptions}
              loading={locationData.length === 0}
              options={locationData}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) =>
                option.code + "-" + option.locationName || option
              }
              freeSolo
              onChange={(event, value) => {
                if (value) {
                  console.log(value);

                  setSelData({...selData,locationId: value.id,});
                  setAutoCom({...autoCom,locationName: value.code + "-" + value.assetTypeName,});
                } else {
                  setSelData({...selData,locationId: null,});
                  setAutoCom({ ...autoCom,locationName: "",});
                }
              }}
              onInputChange={(e, v) => {
                setAutoCom({...autoCom,locationName: v,});
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Location"
                  size="small"
                  // variant="standard"

                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        {locationData.length === 0 ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
           
          <Button
            type={"button"}
            onClick={onSearch}
            variant="contained"
            disabled={loading}
            sx={{
              textTransform: "none",
              backgroundColor: loading ? "default" : color.TableButtonColor,
              "&:hover": {
                backgroundColor: loading ? "default" : color.buttonDisable,
              },
            }}
          >
            Search
          </Button>
            </Box>
          
        </Grid>
        <Grid item xs={12}>
          <NormalTable
            data={asset}
            columns={columns}
            handleCheckAll={handleCheckAll}
            filt={true}
          />
        </Grid>

        <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
          <Button
            onClick={handleCancel}
            variant="contained"
            sx={{
              backgroundColor: color.TableCancelButton,
              textTransform: "none",
              color: color.CancelTextColor,
              ":hover": {
                backgroundColor: color.TableCancelButton,
                textTransform: "none",
                color: color.CancelTextColor,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            type={"submit"}
            onClick={onSubmit}
            variant="contained"
            disabled={loading}
            sx={{
              textTransform: "none",
              backgroundColor: loading ? "default" : color.TableButtonColor,
              "&:hover": {
                backgroundColor: loading ? "default" : color.buttonDisable,
              },
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetRouteModal;
