import React, { useEffect, useState } from "react";
import TableContainer from "../../../../Component/TableContainer";
import { Box, Button, Checkbox, Dialog, Divider, IconButton, Switch, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { masterAPI } from "../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import {useSelector} from "react-redux"
import {
  AddOutlined,
  ArrowBack,
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { color } from "../../../../StyleData/Config";
import AssetRouteModal from "./AssetRouteModal";
import NormalTable from "../../../../Component/NormalTable";

const AssetRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate=useNavigate()
  const actionsAllow=useSelector(state=>state.dataRed.actionsAllow)

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [filterValue,setFilterValue]=useState("enableData")
  const [backUpData,setBackUpData]=useState([])

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["assetRoute"].getByRouteId;
   await axiosPrivate
      .get(`${link}?routeid=${searchParams.get("id")}`)
      .then((res) => {
        res.data.result.sort((a, b) => {
          return a.code.localeCompare(b.code, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        if(filterValue==="enableData"){
          const filterDataResult=res.data.result.filter(fil=>fil.isActive)
          console.log(filterDataResult, "asset route");
          setData(filterDataResult);
          setBackUpData(res.data.result)
        }else{
          setData(res.data.result)
          setBackUpData(res.data.result)
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "asset route");
        setIsLoading(false);
        setData([])
        setBackUpData([])
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  const handleDelete = async(id) => {
    const deleteLink = masterAPI["assetRoute"].delete;
   await axiosPrivate
      .delete(`${deleteLink}/${id}`)
      .then((res) => {
        console.log(res.data);
        getData();
      })
      .catch((e) => {
        console.log(e, "delete asset Route");
      });
  };

  // const handleDelete = (e,id) => {
  //   const enableLink = masterAPI["assetRoute"].enable;
  //   const disableLink = masterAPI["assetRoute"].disable;
  //   if(e.target.checked){
  //     axiosPrivate
  //     .put(`${enableLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "enable ");
  //     });
  //   }else{
  //     axiosPrivate
  //     .put(`${disableLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "disable ");
  //     });
  //   }
  // };

  const columns = [
    // {
    //   accessor: "id",
    //   disableFilters: true,
    //   Cell: () => {
    //     return (
    //       <Box sx={{width:color.checkBoxWidth }}>
    //       <Checkbox
    //         // icon={<RadioButtonUncheckedOutlined />}
    //         // checkedIcon={<CheckCircle />}
    //       />
    //       </Box>
    //     );
    //   },
    // },
   
    {
      Header: "Asset Code",
      accessor: "assetCode",
    },
    {
      Header: "Asset Name",
      accessor: "assetName",
    },
    {
      Header: "Asset Type",
      accessor: "assetTypeName",
    },
    {
      Header: "Location",
      accessor: "assetLocationName",
    },
    
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            {/* <IconButton disabled={!actionsAllow.edit}>
              <EditOutlined
                onClick={() => {
                  handleEdit(cell.row.original);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: actionsAllow.edit? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton> */}
            <IconButton disabled={!actionsAllow.delete}>
              <DeleteOutline
                onClick={() => {
                  handleDelete(cell.row.original.id);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: actionsAllow.delete? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton>
            {/* <Switch
            disabled={!actionsAllow.delete}
            checked={cell.row.original.isActive}
            onChange={(e)=>{handleDelete(e,cell.row.original.id)}}
           
             /> */}
          </Box>
        );
      },
    },
  ];

  const handleChangeFilter=(e)=>{
   
    if(e==="showAll"){
      setData(backUpData)
    }else{
        const filterDataResult=backUpData.filter(fil=>fil.isActive)
        
        setData(filterDataResult);
    }
    setFilterValue(e)   
  }

  return (
    <>
    <Box sx={{display:'flex',alignItems:"center",justifyContent:"space-between"}}>
      <Box sx={{display:"flex",alignItems:"center",}}>
      <IconButton onClick={()=>{navigate("/wo/route")}}>
          <ArrowBack/>
        </IconButton>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
          }}
        >
          {masterAPI["assetRoute"].title}
        </Typography>
      </Box>
      <Button
              variant="contained"
              sx={{
                backgroundColor: color.TableButtonColor,
                fontSize: color.fontSize,
                borderRadius: "2px",
                boxShadow: "0px 5px 10px #00000033",
              }}
              onClick={handleButtonNew}
            >
              <AddOutlined sx={{ fontSize: "16px" }} /> New
            </Button>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px",mt:"6px" }} />
     
        <NormalTable data={data} columns={columns} />
   

      <Dialog
        open={create}
        onClose={handleCreateClose}
        PaperProps={{
          sx: {
            maxWidth: "100vw",
            width:"100vw"
          },
        }}
        children={
          <AssetRouteModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={searchParams.get("id")}
          />
        }
      />
    </>
  );
};

export default AssetRoute;
