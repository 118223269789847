import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { color } from "../../../StyleData/Config";

import product_image from "../../../images/product2.jpg"
import Loading from "../../../Component/Loading";

const General = ({ assetData,direction,assetImage }) => {
  
  return (
    <Box
    sx={{
      display: "flex",
      // alignItems: "center",
      gap: "1.5rem",
      flexWrap: "noWrap",
      flexDirection:direction,
      width:"95%"
    }}
  >
    <Paper sx={{ flex:"0 0 49%"}}>
      <Box sx={{p:1.5}}>
      <Typography
        sx={{
          color: color.Basiccolor,
          fontSize: color.BasicfontSize,
          fontWeight: color.Basicfontweight,
        }}
      >
        Basic
      </Typography>
      </Box>
      <Divider />
      <Box sx={{display:"flex",width:"100%",p:1.5}}>
        <Grid container sx={{}} spacing={"1rem"}>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Asset Type Id
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.assetTypeName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Asset Name
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.assetName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Priority Id
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.priorityName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Asset Desc
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.assetName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Criticality Id
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.criticalityName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Parent Id
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.parentAssetName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Failure Classes Id
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.failureClassName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Locaiton
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.locationName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Rotaing
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.assetPriorityId}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Asset Category Id
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.categoryName}
            </Typography>
          </Grid>
        </Grid>
         <Paper elevation={3} sx={{width:"12vw",height:"19vh",p:0.5}}>
          <img src={assetImage} alt="productImage" style={{width: "100%",height:"100%"}}/> 
          </Paper>
     
      </Box>
    </Paper>
    <Paper sx={{ flex:"0 0 49%"}}>
      <Box sx={{p:1.5}}>
      <Typography
        sx={{
          color: color.Basiccolor,
          fontSize: color.BasicfontSize,
          fontWeight: color.Basicfontweight,
        }}
      >
        Additional Info
      </Typography>
      </Box>
      <Divider />
      <Box sx={{display:"flex",width:"100%",p:1.5}}>
        <Grid container sx={{}} spacing={"1rem"}>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Serial Number
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.serialNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              Manufacturer
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.manufacturerName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Model
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.model}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              YTD Cost
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {/* {assetData.assetName} */}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
          Purchase Price
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.purchasePrice}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             LTD Cost
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {/* {assetData.parentAssetName} */}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             Installation Date
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.installationDate}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
             YTD MTBF
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {/* {assetData.locationName} */}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: color.TabDataTitlecolor,
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
            Supplier
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {assetData.supplierName}
            </Typography>
          </Grid>
          
        </Grid>
       
      </Box>
    </Paper>
  </Box>
  );
};

export default General;
