import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../dataConfig";
import { useSearchParams } from "react-router-dom";

const RemedyModal = ({ mode, handleCloseClick,editData }) => {
  const [parentId, setParentId] = useState([]);
  const [code,setCode]=useState([])
  const [loading,setLoading]=useState(false)
  const [codeType,setCodeType]=useState([])
  const [searchParams, setSearchParams] = useSearchParams();
  const problemId=searchParams.get("id")

  // useEffect(() => {
  //  setLoading(true)
  //   // const link = masterAPI["failureClass"].link;
  //   // axiosPrivate
  //   //   .get(`${link}`)
  //   //   .then((res) => {
  //   //     setLoading(false)
  //   //     setParentId(res.data.result);
  //   //   })
  //   //   .catch((e) => {
  //   //     console.log(e, "createfailureCodegetAll");
  //   //   });
  //     // const codeLink = masterAPI["selectList"].problem;
  //     // axiosPrivate
  //     //   .get(`${codeLink}`)
  //     //   .then((res) => {
  //     //     setLoading(false)
  //     //     setCode(res.data);
  //     //   })
  //     //   .catch((e) => {
  //     //     console.log(e, "create problem getAll");
  //     //   });
  //       // const codeTypeLink = masterAPI["selectList"].failureCodeType;
  //       // axiosPrivate
  //       //   .get(`${codeTypeLink}`)
  //       //   .then((res) => {
  //       //     setLoading(false)
  //       //     setCodeType(res.data);
  //       //   })
  //       //   .catch((e) => {
  //       //     console.log(e, "createfailureCodegetAll");
  //       //   });
  // }, []);

  const initialValue = {
    remedyName: mode==="Edit"?editData.remedyName: "",
    causeId:mode==="Edit"?editData.causeId: problemId,
    code:mode ==="Edit"?editData.code:"",
    remedyDescription:mode ==="Edit"?editData.remedyDescription:"",
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true)
    const createLink = masterAPI["Remedies"].create;
    const updateLink=masterAPI["Remedies"].update
    if(mode==="Create"){
     await axiosPrivate.post(`${createLink}`,value).then(res=>{
        console.log(res.data)
        setLoading(false)
        handleCloseClick()
      }).catch(e=>{
        setLoading(false)
        console.log(e,"create remedy")
      })
    }else{
      const updateAC={
        id:editData.id,
        ...value
      }
     await axiosPrivate.put(`${updateLink}`,updateAC).then(res=>{
        console.log(res.data)
        setLoading(false)
        handleCloseClick()
      }).catch(e=>{
        setLoading(false)
        console.log(e,"update remedy")
      })
    }
   
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode === "Edit"?editData.code+"-"+editData.remedyName:"New Remedy"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
      {({ handleChange, setFieldValue, values , resetForm }) => (
        <Form>
          <Grid container spacing={"1rem"}>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Remedy Name
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="remedyName"
                placeholder="Enter Remedy Name"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Remedy Code
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="code"
                placeholder="Enter Remedy Code"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Remedy Description
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="remedyDescription"
                placeholder="Enter Remedy Description"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Failure Class
                </Typography>
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="failureClassId"
                
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.failureClassId !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {parentId.map((id, i) => (
                  <MenuItem value={id.id}>{id.failureClassName}</MenuItem>
                ))}
              </Field>
            </Grid> */}
          
            <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
              <Button
                onClick={handleCancel}
                variant="contained"
                sx={{
                  backgroundColor: color.TableCancelButton,
                  textTransform: "none",
                  color: color.CancelTextColor,
                  ":hover":{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type={"submit"}
                variant="contained"
                disabled={loading}
                sx={{
                      
                  textTransform: "none",
                  backgroundColor: loading ? "default" : color.TableButtonColor,
                  "&:hover": {
                    backgroundColor: loading
                      ? "default"
                      : color.buttonDisable,
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
      </Formik>
    </Box>
  );
};

export default RemedyModal;
