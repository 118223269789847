import React, { Fragment, useState } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useGlobalFilter,
} from "react-table";
import {
  TableRow,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Box,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Menu,
  TableFooter,
  CircularProgress,
  TableContainer,
  Badge,
  Tooltip,
} from "@mui/material";
import { Filter, DefaultColumnFilter, GlobalFilter } from "./filters";
import {
  AddOutlined,
  ExitToAppOutlined,
  ExpandLess,
  ExpandMore,
  ExploreTwoTone,
  HomeWork,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  MoreHorizOutlined,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import { color } from "../StyleData/Config";
import Loading from "./Loading";

const TableContainerComp = ({
  columns, buttonDisable, data, buttonNew, loading, display, generate, handleGenerate, statusList,
  menuStatusHandle, changeStatusOption, filterValue, filterShow, clone, handleCloneButton, handleChange,
  iconBadge, iconWOCount, iconInspCount, woScheduleTypeChange
}) => {
  const [exportBL, setExportBL] = useState(null);
  const [dotBL, setDotBL] = useState(null);
  const [statusBL, setStatusBL] = useState(null);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    rows,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    flatRows,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  // console.log(flatRows)

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const handleExport = (event) => {
    if (!exportBL) {
      setExportBL(event.currentTarget);
    } else {
      setExportBL(null);
    }
  };
  const handleDot = (event) => {
    if (!dotBL) {
      setDotBL(event.currentTarget);
    } else {
      setDotBL(null);
    }
  };
  const handleStatus = (event) => {
    if (!statusBL) {
      setStatusBL(event.currentTarget);
    } else {
      setStatusBL(null);
    }
  };

  const handleButton = () => {
    buttonNew();
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: color.TableContainerSearchBgColor,
          mb: "1.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            gap: "1rem",
            p: 1,
          }}
        >
          <Box
            sx={{
              width: "80%",
              display: "flex",
              gap: "0.5rem",
            }}
          >
            {headerGroups.map((headerGroup, i) => (
              <Fragment key={i}>
                {headerGroup.headers.map((column, index) => (
                  <Fragment key={index}>
                    <Filter
                      column={column}
                      placeholder={{ placeholder: column.render("Header") }}
                    />
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </Box>
          <Box sx={{ width: "20%", justifyContent: "flex-end" }}>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: color.TableContainerSecBgColor,
            p: 1,
            boxShadow: "3px 3px 6px #00000029",
            borderRadius: "3px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "1.5rem",width:"55%" }}>
            <Button
              variant="contained"
              disabled={buttonDisable}
              sx={{
                backgroundColor: color.TableButtonColor,
                fontSize: color.fontSize,
                borderRadius: "2px",
                boxShadow: "0px 5px 10px #00000033",
              }}
              onClick={handleButton}
            >
              <AddOutlined sx={{ fontSize: "16px" }} /> New
            </Button>
            <Box
              sx={{ display: display === "notVisible" ? "none" : "visible" }}
            >
              <Typography
                sx={{
                  whiteSpace:"nowrap",
                  fontSize: color.fontSize,cursor: "pointer",
                  color: changeStatusOption? "#2362B4": color.TableChangeStatusColor,
                }}
                onClick={(e) => {
                  if (statusList !== undefined && changeStatusOption) {
                    handleStatus(e);
                  }
                }}
              >
                Change Status
              </Typography>
              <Menu
                anchorEl={statusBL}
                open={Boolean(statusBL)}
                onClose={handleStatus}
              >
                {statusList !== undefined
                  ? statusList.map((list, i) => (
                      <MenuItem
                        key={i}
                        value={list.value}
                        onClick={() => {
                          setStatusBL(null);
                          menuStatusHandle(list);
                        }}
                      >
                        {list.text}
                      </MenuItem>
                    ))
                  : null}
              </Menu>
            </Box>
            {filterShow?
            <Select
              fullWidth
              size="small"
              value={filterValue}
              sx={{width:"20%"}}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            >
              <MenuItem value="showAll">Show All</MenuItem>
              <MenuItem value="enableData">Active</MenuItem>
            </Select>
            :null
            }
            {generate ? (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: color.TableButtonColor,
                  fontSize: color.fontSize,
                  borderRadius: "2px",
                  boxShadow: "0px 5px 10px #00000033",
                }}
                onClick={handleGenerate}
              >
                Generate
              </Button>
            ) : null}
            {clone?
            <Button
            variant="contained"
            
            sx={{
              backgroundColor: color.TableButtonColor,
              fontSize: color.fontSize,
              borderRadius: "2px",
              boxShadow: "0px 5px 10px #00000033",
            }}
            onClick={handleCloneButton}
          >
            Clone
          </Button>:null}
            <Box
              sx={{
                display: display === "notVisible" ? "none" : "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleExport}
            >
              <ExitToAppOutlined
                sx={{ fontSize: "16px", color: color.TableButtonColor }}
              />
              <Typography
                sx={{
                  fontSize: color.fontSize,
                  ml: "0.2rem",
                  color: color.TableButtonColor,
                  fontWeight: 600,
                }}
              >
                Export
              </Typography>
              {Boolean(exportBL) ? (
                <ExpandLess sx={{ fontSize: "17px" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px" }} />
              )}
              <Menu
                anchorEl={exportBL}
                open={Boolean(exportBL)}
                onClose={handleExport}
              >
                <MenuItem>CSV</MenuItem>
                <MenuItem>XML</MenuItem>
                <MenuItem>PDF</MenuItem>
              </Menu>
            </Box>
            {iconBadge?
            <Box sx={{display:"flex",gap:"0.1rem",alignItems:'center'}}>
              <Tooltip title="Work Order">
            <IconButton onClick={()=>{woScheduleTypeChange("WO")}} >
           <Badge badgeContent={iconWOCount} color="primary">
           <img src="/imagesIcon/workorder.png" style={{width:"1.5rem",height:"1.5rem"}}/>
           </Badge>
           </IconButton>
           </Tooltip>
           <Tooltip title="Inspection">
           <IconButton onClick={()=>{woScheduleTypeChange("INSP")}} >
           <Badge badgeContent={iconInspCount} color="primary">
            <img src="/imagesIcon/inspection.png" style={{width:"1.5rem",height:"1.5rem"}}/>
           </Badge>
           </IconButton>
          </Tooltip>
           </Box>
    :null}
            
          </Box>
          {/* <Box>
      <MoreHorizOutlined sx={{color:color.TableButtonColor,fontSize:"1.5rem",mr:"2rem",cursor:"pointer"}} onClick={handleDot}/>
      <Menu   anchorEl={dotBL}
          open={Boolean(dotBL)}
          onClose={handleDot}>
          
          <MenuItem>Option 1</MenuItem>
          <MenuItem>Option 2</MenuItem>
          <MenuItem>Option 3</MenuItem>
        </Menu>
      </Box> */}
        </Box>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <TableContainer
          sx={{
            backgroundColor: color.TableBgColor,
            boxShadow: "0px 5px 10px #00000033",
            border: "0.5px solid rgb(187 187 187 / 31%)",
            borderRadius: "2px",
          }}
        >
          <Table {...getTableProps()}>
            {/* <Filter  column={"CHeck"} /> */}
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...column.getHeaderProps()}
                      sx={{
                        backgroundColor: "transparent",
                        padding: "1rem 0.4rem",
                        fontSize: "13px",
                        whiteSpace: "nowrap",
                        fontWeight: 700,
                      }}
                    >
                      <Box
                        sx={{
                          display:
                            column.render("Header") === "reopenCount"
                              ? "none"
                              : "visible",
                        }}
                      >
                        <div {...column.getSortByToggleProps()}>
                          {column.render("Header")}
                          {generateSortingIndicator(column)}
                        </div>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {page.map((row) => {
                //  setRowCountnumber(page)
                // setRowCountnumber([0,1])

                prepareRow(row);
                return (
                  <TableRow key={row.getRowProps().key}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                            fontWeight: 600,
                            color: "#797979",
                            border: "none",
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            padding: "0.4rem",
                          }}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box
            sx={{
              backgroundColor: color.TableBgColor,
              // boxShadow:"0px 5px 10px #00000033",
              // border:"0.5px solid rgb(187 187 187 / 31%)",
              // borderRadius:"2px"
            }}
          >
            {/* <Box sx={{display:"flex",gap:{xs:"5px",md:"2rem"},justifyContent:"flex-end",alignItems:"center",p:"0.4rem",whiteSpace:"nowrap"}}> */}
            <Box
              sx={{
                display: "flex",
                gap: { xs: "5px", md: "2rem" },
                justifyContent: "flex-end",
                alignItems: "center",
                p: "0.4rem",
                whiteSpace: "nowrap",
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <Typography sx={{ display: { xs: "none", md: "block" } }}>
                  Rows:
                </Typography>
                <Select
                  variant="standard"
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <MenuItem key={pageSize} value={pageSize}>
                      {pageSize}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <Typography>
                  {pageIndex + 1 + " of " + pageOptions.length}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <IconButton
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <SkipPrevious
                    sx={{
                      color: !canPreviousPage ? "gray" : "blue",
                      fontSize: "1.5rem",
                    }}
                  />
                </IconButton>
                <IconButton onClick={previousPage} disabled={!canPreviousPage}>
                  <KeyboardArrowLeft
                    sx={{
                      color: !canPreviousPage ? "gray" : "blue",
                      fontSize: "1.5rem",
                    }}
                  />
                </IconButton>

                <IconButton onClick={nextPage} disabled={!canNextPage}>
                  <KeyboardArrowRight
                    sx={{
                      color: !canNextPage ? "gray" : "blue",
                      fontSize: "1.5rem",
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <SkipNext
                    sx={{
                      color: !canNextPage ? "gray" : "blue",
                      fontSize: "1.5rem",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </TableContainer>
      )}

      {/* <Row style={{ maxWidth: 1000, margin: '0 auto', textAlign: 'center' }}>
        <Col md={3}>
          <Button
            color='primary'
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </Button>
          <Button
            color='primary'
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {'<'}
          </Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col md={2}>
          <Input
            type='number'
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>
        <Col md={2}>
          <CustomInput
            type='select'
            value={pageSize}
            onChange={onChangeInSelect}
          >
            
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </CustomInput>
        </Col>
        <Col md={3}>
          <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
            {'>'}
          </Button>
          <Button
            color='primary'
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </Button>
        </Col>
      </Row> */}
    </Fragment>
  );
};

export default TableContainerComp;
