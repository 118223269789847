export const color={
    sideNavPL:7,
    sideFontSize:"12.8px",
    sideFontWeight:700,
    sideFontFamily:"'Nunito', sans-serif",
    sideFontColor:"#656565",
    sideDisplay:"flex",
    sideDisplayGap:"0.4rem",
    sideDisplayAlginItem:"center",
    sideNavWholeGap:"0.6rem",
    sideSubListIconColor:"#8F8F8F",
    sideSubListDisplayGap:"0.9rem",

    TableContainerSearchBgColor:"#F0F0F1",
    TableContainerSecBgColor:"white",
    TableBgColor:"white",
    TableButtonColor:"#2362B4",
    fontSize:"13px",
    TableChangeStatusColor:"#9A9994",

    masterPageTitleColor:"#4F4F51",
    masterPageTitleFontSize:"1.2rem",
    masterPageTitleFontWeight:500,
    masterPageTitlemd:"1rem",
    masterEditDelButton:"1.3rem",
    masterEditDelButtonColor:"#515152",

    masterPageDialogPadding:"1rem",
    masterDialogTitleColor:"#2362B4",
    masterDialogFontSize:"17px",
    placeholderColor:"#666666",
    TableCancelButton:"#F0F0F0",
    CancelTextColor:"black",
    buttonDisable:"#2362B4",
    checkBoxWidth:"1.5rem",

    labelFontWeight:500,
    labelFont:"14px",
    labelmb:"0.5rem",
    labelColor:"#ADAAAA",

    AssetTitlesize:"1.3rem",
    sideFontWeightAsset:500,

    TabLabelcolor:"#2362b4",
    BasicfontSize: "1.05rem",
    Basiccolor: "#0F6CBC",
    Basicfontweight: 600,
    TabDatafontsize: "13px",
    TabDataTitlecolor: "#919191",
    TabDatamarginbottom: "2px",

    sideTabColor:"#677B93",
    label:"12px",
    iconSize:"1.2rem",
    sideTitleColor:"#4F4F51",
    fontSizeSide:"17px",
    sideWeightFont:600,
    sideTitle2Color:"#0F6CBC",
    sideLabelColor:"#919191",
    sideLabelFont:"13px",

    

}