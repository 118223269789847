import { CheckBox, Clear, Save } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NormalTable from "../../../../Component/NormalTable";
import { masterAPI } from "../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import { color } from "../../../../StyleData/Config";
import TableContainerComp from "../../../../Component/TableContainer";
import { json } from "react-router-dom";

const LabourEdit = ({ editData, handleCloseClick }) => {
  const [labourData, setLabourData] = useState([]);
  const [labourSelect, setLabourSelect] = useState(editData);

  const [quantity, setQuantity] = useState(editData.quantity);
  const [planHours, setPlanHours] = useState("0");

  useEffect(() => {
    getData()
  }, []);

  const getData=async()=>{
    const labourLink = masterAPI["labourCraft"].link;
   await axiosPrivate
      .get(`${labourLink}`)
      .then((res) => {
        
        const filterData = res.data.result.filter(
          (fil) => editData.craftId === fil.craftId
        );
        console.log(filterData);
        setLabourData(filterData);
      })
      .catch((e) => {
        setLabourData([]);
        console.log(e, "labourCraft WorkOrder");
      });
      const hours = Math.floor(editData.plannedHours);
      const minutes = Math.round((editData.plannedHours - hours) * 60);
       setPlanHours(`${hours}:${minutes < 10 ? '0' : ''}${minutes}`)
  }

  const columns = [
    {
      accessor: "id",
      disableFilters: true,
      Cell: ({ cell }) => {
        return (
          <Radio
            checked={
              labourSelect.laburId === cell.row.original.labourId &&
              labourSelect.craftId === cell.row.original.craftId
            }
            onClick={() => {
              handleCheck(cell.row.original);
            }}
          />
        );
      },
    },
    {
      Header: "Labour Name",
      accessor: "labourName",
      // disableFilters: true,
    },
    {
      Header: "Craft Name",
      accessor: "craftName",
      // disableFilters: true,
    },
  ];

  const handleCheck = (value) => {
    setLabourSelect(value);
  };

  const handleLabourSubmit = async() => {
      const labupdatelink = masterAPI["inspWoLabour"].update;
      let decimalPlanHours=Number(planHours.split(":")[0])+(Number(planHours.split(":")[1])/60)
      
      const putData = {
        id: editData.id,
        labourId: labourSelect.labourId,
        craftId: labourSelect.craftId,
        inspectionWorkOrderId:editData.inspectionWorkOrderId,
        quantity: quantity,
        plannedHours: decimalPlanHours,
      };
      console.log(putData);

     await axiosPrivate
        .put(`${labupdatelink}`, putData)
        .then((res) => {
          console.log(res);
          handleCloseClick();
        })
        .catch((e) => {
          console.log(e, "createwotaskLabour");
        });
   
  };

  return (
    <Grid container sx={{ p: 1 }} spacing={"1.5rem"}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: "1.1rem",
              fontWeight: 500,
            }}
          >
            Labour
          </Typography>
          <Box>
            <IconButton
              onClick={() => {
                handleLabourSubmit();
              }}
            >
              <Save sx={{ color: "#2362B4" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                handleCloseClick();
              }}
            >
              <Clear sx={{ color: "#2362B4" }} />
            </IconButton>
          </Box>
        </Box>
        <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)" }} />
      </Grid>
      <Grid container item xs={12} spacing={"1.5rem"}>
        <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                // mb: color.labelmb,
                fontSize: color.labelFont,
                // color: color.labelColor,
                whiteSpace: "nowrap",
              }}
            >
              Quantity :
            </Typography>
            <TextField
              name="qauntity"
              size="small"
              placeholder="Enter Quantity"
              value={quantity}
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                // mb: color.labelmb,
                fontSize: color.labelFont,
                // color: color.labelColor,
                whiteSpace: "nowrap",
              }}
            >
              Planned Hours(HH:MM) :
            </Typography>
            <TextField
              name="plannedHours"
              size="small"
              placeholder="Enter Planned Hours"
              value={planHours}
              onChange={(e) => {
                setPlanHours(e.target.value);
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <NormalTable data={labourData} columns={columns} filt={true} />
      </Grid>
    </Grid>
  );
};

export default LabourEdit;
