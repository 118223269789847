import React, { useEffect, useState } from "react";
import { color } from "../../../StyleData/Config";
import {
  Grid,
  Typography,
  Paper,
  Select,
  MenuItem,
  TextField,
  Box,
  Divider,
  IconButton,
  Tab,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Autocomplete,
  createFilterOptions,
  CircularProgress,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { axiosPrivate, siteInfo } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { Add, PlaylistAdd } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"

const CreateInsp = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch()

  const edit=useSelector(state=>state.editData.inspScheduleData.edit)
  
  const editData=useSelector(state=>state.editData.inspScheduleData.data)
 

  const [frequnittime, setFrequnittime] = useState([]);
  const [workType,setWorkType]=useState([])
  const [route,setRoute]=useState([])
  const [inspCat,setInspCat]=useState([])
  const [autoCom,setAutoCom]=useState({
    route:edit?editData.routeCode+"-"+editData.routeName:"",
    inspectCat:edit?editData.inspectionCategoryCode+"-"+editData.inspectionCategoryName:""
  })


  const [value, setValue] = useState("1");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData()
  }, []);

  const getData=async()=>{
    setLoading(true);
  

    
    const getjobType = masterAPI["selectList"].workordertype;
   await axiosPrivate
      .get(`${getjobType}`)
      .then((res) => {
        console.log(res.data);
        setWorkType(res.data);
      })
      .catch((e) => {
        setWorkType([])
        console.log(e, "jobtype Create insp");
      });

    const getFreqTime = masterAPI["selectList"].frequencyunitsfortime;

   await axiosPrivate
      .get(`${getFreqTime}`)
      .then((res) => {
        console.log(res.data);
        setFrequnittime(res.data);
      })
      .catch((e) => {
        setFrequnittime([])
        console.log(e, "freq unit time Create insp");
      });

      const routeLink=masterAPI["route"].link
     await axiosPrivate.get(`${routeLink}`).then(res=>{
        setRoute(res.data.result)
      }).catch(e=>{
        setRoute([])
        console.log(e)
      })

      const inspCatLink=masterAPI["inspectionCat"].link
     await axiosPrivate.get(`${inspCatLink}`).then(res=>{
        setInspCat(res.data.result)
      }).catch(e=>{
        setInspCat([])
        console.log(e)
      })

    
    setLoading(false);
  }

  const handleCancel = () => {
    navigate("/inspection");
  };

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const initialValue = {
    code:edit?editData.code:"",
    description:edit?editData.description: "",
    name:edit?editData.name:"",
    workTypeId:edit?editData.workTypeId: "6a119f11-8973-4d33-b461-e53d7e134e33",
    frequencyUnitForTime:edit?editData.frequencyUnitForTime:null,
    nextDueDate:edit?editData.nextDueDate:moment().format("YYYY-MM-DDTHH:mm:ss"),
    targetEndDate:edit?editData.targetEndDate: moment().add(1, 'days').format("YYYY-MM-DDTHH:mm:ss"),
    // offsetDays:edit?editData.offsetDays:0,
    routeId:edit?editData.routeId:null,
    inspectionCategoryId:edit?editData.inspectionCategoryId:null,
    timeFrequency:edit?editData.timeFrequency:0
  };

  const onSubmit = async(values) => {
    console.log(values);
   
    setLoading(true);
    // let postData = {
    //   description: values.description,
    //   name: values.name,
    //   locationId: values.locationId,
    //   assetId: values.assetId,
    //   status: values.status,
    //   priorityId: values.priorityId===""?priority[0].id:values.priorityId,
    //   siteId: values.siteId,
    //   jobPlanId: values.jobPlanId,
    //   workTypeId: values.workTypeId,
    //   pmType: values.pmType,
    //   scheduleType: values.scheduleType,
    // };
    // if (values.scheduleType.toUpperCase()==="TIME"||values.scheduleType.toUpperCase()==="MIXED") {
    //   postData = {
    //     ...postData,
    //     pmTimeBasedScheduler: {
    //       frequency: values.pmTimeBasedScheduler.frequency,
    //       frequencyUnitForTime:
    //         values.pmTimeBasedScheduler.frequencyUnitForTime,
    //       nextDueDate: values.pmTimeBasedScheduler.nextDueDate,
    //       targetEndDate: values.pmTimeBasedScheduler.targetEndDate,
    //       offsetDays: values.pmTimeBasedScheduler.offsetDays,
    //     },
    //   };
    // }
    // if (values.scheduleType.toUpperCase()==="METER"||values.scheduleType.toUpperCase()==="MIXED") {
    //   postData = {
    //     ...postData,
    //     pmMeterBasedScheduler: {
    //       meterId: values.pmMeterBasedScheduler.meterId,
    //       frequency: values.pmMeterBasedScheduler.frequency,
    //       startAtValue: values.pmMeterBasedScheduler.startAtValue,
    //       earliestTriggerValue:
    //         values.pmMeterBasedScheduler.earliestTriggerValue,
    //       currentReading: values.pmMeterBasedScheduler.currentReading,
    //       frequencyUnitForMeter:
    //         values.pmMeterBasedScheduler.frequencyUnitForMeter,
    //       triggertype: values.pmMeterBasedScheduler.triggertype,
    //     },
    //   };
    // }
    // console.log(postData);
    
    const inspcreate = masterAPI["inspSchedule"].create;
    const inspUpdate=masterAPI["inspSchedule"].update
    if(edit){
      const putData={
        id:editData.id,
        ...values
      }
     await axiosPrivate.put(`${inspUpdate}`,putData).then(res=>{
        console.log(res)
        navigate("/inspection")
      }).catch(e=>{
        setLoading(false)
        console.log(e," update error")
      })
    }else{
    
   await axiosPrivate
      .post(`${inspcreate}`, values)
      .then((res) => {
        console.log(res, "inspection create");
        navigate("/inspection");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false)
        console.log(e, " create error");
      });
    }
  };

  // const handleCheckBox = (e, type) => {
  //   if (e.target.checked) {
  //     setChecked({ ...checked, [type]: true });
  //   } else {
  //     setChecked({ ...checked, [type]: false });
  //   }
  // };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box
        // sx={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "space-between",
        //   width: "100%",
        // }}
        sx={{ mb: "1rem" }}
      >
        <Typography
          sx={{
            // color: color.masterDialogTitleColor,
            // fontSize: color.masterDialogFontSize,
            fontSize: color.AssetTitlesize,
            fontWeight: color.sideFontWeightAsset,
          }}
        >
         {edit?`${editData.code+" - "+editData.name}`:" New Inspection Schedule"}
        </Typography>
      </Box>
      <Formik initialValues={initialValue} onSubmit={onSubmit} >
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
   
              <Paper sx={{ mt: "1rem",p:"1.5rem" }}>
             
                  <Grid
                    container
                   
                    spacing={"1rem"}
                  >
                    
                   
                    <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Code
                        </Typography>
                        <Field
                          as={TextField}
                          size={"small"}
                          type="text"
                          name="code"
                          placeholder="Enter code"
                          fullWidth
                          sx={{
                            ".MuiInputBase-input": {
                              // letterSpacing: "0.2rem",

                              "&::placeholder": {
                                // color: "green",
                                opacity: 1,
                                color: color.placeholderColor,
                                fontSize: "13px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Name
                        </Typography>
                        <Field
                          as={TextField}
                          size={"small"}
                          type="text"
                          name="name"
                          placeholder="Enter name"
                          fullWidth
                          sx={{
                            ".MuiInputBase-input": {
                              // letterSpacing: "0.2rem",

                              "&::placeholder": {
                                // color: "green",
                                opacity: 1,
                                color: color.placeholderColor,
                                fontSize: "13px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                           Description
                        </Typography>
                        <Field
                          as={TextField}
                          size={"small"}
                          type="text"
                          name="description"
                          placeholder="Enter Description"
                          fullWidth
                          sx={{
                            ".MuiInputBase-input": {
                              // letterSpacing: "0.2rem",

                              "&::placeholder": {
                                // color: "green",
                                opacity: 1,
                                color: color.placeholderColor,
                                fontSize: "13px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Work Type
                        </Typography>
                        <Field
                          as={Select}
                          // label={"Select"}
                          size={"small"}
                          type="text"
                          name="workTypeId"
                          fullWidth
                          displayEmpty
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "40vh" } },
                          }}
                          renderValue={
                            values.workTypeId !== null
                              ? undefined
                              : () => (
                                  <Typography
                                    sx={{
                                      color: color.placeholderColor,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Select
                                  </Typography>
                                )
                          }
                        >
                          {workType.map((id, i) => (
                            <MenuItem
                              key={i}
                              value={id.value}
                              disabled={id.text !== "PM-INS"}
                            >
                              {id.text}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      
                      {/* <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          PM Counter
                        </Typography>
                        <Field
                          as={TextField}
                          size={"small"}
                          type="text"
                          name="woCounter"
                          placeholder="Enter PM Counter"
                          fullWidth
                          sx={{
                            ".MuiInputBase-input": {
                              // letterSpacing: "0.2rem",

                              "&::placeholder": {
                                // color: "green",
                                opacity: 1,
                                color: color.placeholderColor,
                                fontSize: "13px",
                              },
                            },
                          }}
                        />
                      </Grid> */}

                    
                    <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Route
                        </Typography>
                        
                         <Field
                          name="routeId"
                          //  value={autoValue}
                          // value={values.assetName.code+"_"+values.assetName.assetName}
                          // value={value}
                          inputValue={autoCom.route}
                          component={Autocomplete}
                          filterOptions={filterOptions}
                          loading={route.length === 0}
                          options={route}
                          PaperComponent={CustomPaper}
                          getOptionLabel={(option) =>
                           option.code+" - "+ option.routeName || option
                          }
                          freeSolo
                          onChange={(event, value) => {
                            if (value!==null) {
                              console.log(value);
                              setFieldValue("routeId", value.id);
                              setAutoCom({...autoCom,route:value.code+" - "+value.routeName})
                            }else{
                               setFieldValue("routeId",null)
                               setAutoCom({...autoCom,route:""})
                            }
                          }}
                          onInputChange={(e,v)=>{
                             setAutoCom({...autoCom,route:v})
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="routeId"
                              placeholder="Search Route "
                              size="small"
                              onChange={handleChange}
                              // variant="standard"

                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                    {route.length === 0 ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        >
                          {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Inspection Category
                        </Typography>
                        
                         <Field
                          name="inspectionCategoryId"
                          //  value={autoValue}
                          // value={values.assetName.code+"_"+values.assetName.assetName}
                          // value={value}
                          inputValue={autoCom.inspectCat}
                          component={Autocomplete}
                          filterOptions={filterOptions}
                          loading={inspCat.length === 0}
                          options={inspCat}
                          PaperComponent={CustomPaper}
                          getOptionLabel={(option) =>
                           option.code+" - "+ option.categoryName || option
                          }
                          freeSolo
                          onChange={(event, value) => {
                            if (value!==null) {
                              console.log(value);
                              setFieldValue("inspectionCategoryId", value.id);
                              setAutoCom({...autoCom,inspectCat:value.code+" - "+value.categoryName})
                            }else{
                               setFieldValue("inspectionCategoryId",null)
                               setAutoCom({...autoCom,inspectCat:""})
                            }
                          }}
                          onInputChange={(e,v)=>{
                             setAutoCom({...autoCom,inspectCat:v})
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="inspectionCategoryId"
                              placeholder="Search inspectionCategory "
                              size="small"
                              onChange={handleChange}
                              // variant="standard"

                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                    {inspCat.length === 0 ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        >
                          {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                        </Field>
                      </Grid>
                       <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Time Frequency
                          </Typography>
                          <Field
                            as={TextField}
                            size={"small"}
                            type="text"
                            name="timeFrequency"
                            placeholder="Enter Time Frequency"
                            fullWidth
                            
                            sx={{
                              ".MuiInputBase-input": {
                                // letterSpacing: "0.2rem",

                                "&::placeholder": {
                                  // color: "green",
                                  opacity: 1,
                                  color: color.placeholderColor,
                                  fontSize: "13px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Frequency Unit
                          </Typography>
                          <Field
                            as={Select}
                            // label={"Select"}
                            size={"small"}
                            type="text"
                            name="frequencyUnitForTime"
                            fullWidth
                            displayEmpty
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: "40vh" } },
                            }}
                            renderValue={
                              values.frequencyUnitForTime !== null
                                ? undefined
                                : () => (
                                    <Typography
                                      sx={{
                                        color: color.placeholderColor,
                                        fontSize: "13px",
                                      }}
                                    >
                                      Select
                                    </Typography>
                                  )
                            }
                          >
                            {frequnittime.map((id, i) => (
                              <MenuItem key={i} value={id.value}>
                                {id.text}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sx={{
                            ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              // mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Next Due Date
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                format="DD/MM/YYYY"
                                value={dayjs(
                                  values.nextDueDate
                                )}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    margin: "-8px 0 0 0",
                                    fullWidth: true,
                                  },
                                }}
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  if (newValue !== null) {
                                    if (`${newValue.$d}` !== "Invalid Date") {
                                      const dateFrom = moment(
                                        newValue.$d
                                      ).format("YYYY-MM-DD");
                                      const endFormat=moment(
                                        newValue.$d
                                      ).add(1,"days").format("YYYY-MM-DD");

                                      const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                      const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                      const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                      const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                      const end=endFormat+"T"+hour+":"+min+":"+sec
                                      setFieldValue("nextDueDate", dateFormat);
                                      setFieldValue("targetEndDate",end);
                                      
                                    }
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onBlur={(e)=>{
                                     console.log(e,"blur")
                                    }}
                                    // inputRef={datePickerRef}
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sx={{
                            ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              // mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Target End Date
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                format="DD/MM/YYYY"
                                value={dayjs(
                                  values.targetEndDate
                                )}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    margin: "-8px 0 0 0",
                                    fullWidth: true,
                                  },
                                }}
                                onChange={(newValue) => {
                                  console.log(newValue);
                                  if (newValue !== null) {
                                    if (`${newValue.$d}` !== "Invalid Date") {
                                      const dateFrom = moment(
                                        newValue.$d
                                      ).format("YYYY-MM-DD");
                                      const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                      const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                      const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                      const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                      setFieldValue(
                                        "targetEndDate",
                                      dateFormat
                                      );
                                     
                                    }
                                  }
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                      {/* <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Offset Days
                          </Typography>
                          <Field
                            as={TextField}
                            size={"small"}
                           type="text"
                            name="offsetDays"
                            placeholder="Enter Offset Days"
                            fullWidth
                            onChange={(e)=>{
                                   setFieldValue("offsetDays",e.target.value)
                                  
                            }}
                            sx={{
                              ".MuiInputBase-input": {
                                // letterSpacing: "0.2rem",

                                "&::placeholder": {
                                  // color: "green",
                                  opacity: 1,
                                  color: color.placeholderColor,
                                  fontSize: "13px",
                                },
                              },
                            }}
                          />
                        </Grid> */}
                       
                      
                    </Grid>
                    
                  </Paper>
               
             
           
            <Grid
              container
              item
              xs={12}
              justifyContent={"flex-end"}
              gap="1rem"
            >
              <Button
                onClick={handleCancel}
                variant="contained"
                sx={{
                  backgroundColor: color.TableCancelButton,
                  textTransform: "none",
                  color: color.CancelTextColor,
                  ":hover": {
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                type={"submit"}
                variant="contained"
                disabled={loading}
                sx={{
                  textTransform: "none",
                  backgroundColor: loading ? "default" : color.TableButtonColor,
                  "&:hover": {
                    backgroundColor: loading ? "default" : color.buttonDisable,
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateInsp;
