import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../dataConfig";

const CreateAssetMeter = ({ mode, handleCloseClick, editData }) => {
  const [assetData, setAssetData] = useState([]);
  const [meterData, setMeterData] = useState([]);
  const [auto, setAuto] = useState({ assetName: "", meterName: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData()
  }, []);

  const getData=async()=>{
    setLoading(true);
    const link = masterAPI["assets"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setLoading(false);
        setAssetData(res.data.result);
      })
      .catch((e) => {
        setAssetData([])
        setLoading(false);
        console.log(e, "create Asset getAll");
      });
    const meterLink = masterAPI["meter"].link;
   await axiosPrivate
      .get(`${meterLink}`)
      .then((res) => {
        setLoading(false);
        setMeterData(res.data.result);
      })
      .catch((e) => {
        setMeterData([])
        setLoading(false);
        console.log(e, "create Meter getAll");
      });
  }

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const initialValue = {
    assetId: mode === "Edit" ? editData.assetId : null,
    meterId: mode === "Edit" ? editData.meterId : null,
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["assetMeter"].create;
    const updateLink = masterAPI["assetMeter"].update;
    if (mode === "Create") {
     await axiosPrivate
        .post(`${createLink}`, value)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "create assetMeter");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "update assetMeter");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode === "Edit" ? "Update Asset Meter" : "New Asset Meter"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Asset
                </Typography>
                <Field
                  name="assetId"
                  //  value={autoValue}
                  // value={values.assetName.code+"_"+values.assetName.assetName}
                  inputValue={auto.assetName}
                  component={Autocomplete}
                  filterOptions={filterOptions}
                  loading={assetData.length === 0}
                  options={assetData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) =>
                    option.code + " - " + option.assetName || option
                  }
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      console.log(value);

                      setFieldValue("assetId", value.id);
                      setAuto({
                        ...auto,
                        assetName: value.code + " - " + value.assetName,
                      });
                    } else {
                      setFieldValue("assetId", null);
                      setAuto({ ...auto, assetName: "" });
                    }
                  }}
                  onInputChange={(e, v) => {
                    setAuto({ ...auto, assetName: v });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="assetId"
                      placeholder="Search Asset"
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {assetData.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                >
                  {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                </Field>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Meter
                </Typography>
                <Field
                  name="meterId"
                  
                  inputValue={auto.meterName}
                  component={Autocomplete}
                  filterOptions={filterOptions}
                  loading={meterData.length === 0}
                  options={meterData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) =>
                    option.code + " - " + option.meterName || option
                  }
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      console.log(value);

                      setFieldValue("meterId", value.id);
                      setAuto({
                        ...auto,
                        meterName: value.code + " - " + value.meterName,
                      });
                    } else {
                      setFieldValue("meterId", null);
                      setAuto({ ...auto, meterName: "" });
                    }
                  }}
                  onInputChange={(e, v) => {
                    setAuto({ ...auto, meterName: v });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="meterId"
                      placeholder="Search Meter"
                      onChange={handleChange}
                      fullWidth
                      size="small"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {meterData.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                >
                  {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                </Field>
              </Grid>

              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateAssetMeter;
