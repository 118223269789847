import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import { Box, Checkbox, Dialog, IconButton, Typography } from "@mui/material";
import { useSearchParams,useNavigate } from "react-router-dom";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import {
  ArrowBack,
  CheckCircle,
  DeleteOutline,
  DeleteOutlined,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";

import { color } from "../../../StyleData/Config";
import ReceiptModal from "./ReceiptItemModal";
import {useSelector} from "react-redux"



const ReceiptItem = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate=useNavigate()
  const actionsAllow=useSelector(state=>state.dataRed.actionsAllow)
  const receiptDetail=useSelector(state=>state.dataRed.receiptItem)

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["receipt"].receiptList;
   await axiosPrivate
      .get(`${link}/${searchParams.get("id")}`)
      .then((res) => {
        console.log(res.data, "receipt Item result");
        setData(res.data.result);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "receipt Item");
        setData([])
        setIsLoading(false);
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  const handleDelete = async(id) => {
    const deleteLink = masterAPI["receipt"].delRecList;
    await axiosPrivate
      .delete(`${deleteLink}/${id}`)
      .then((res) => {
        console.log(res.data);
        getData();
      })
      .catch((e) => {
        console.log(e, "delete receiptItem");
      });
  };

  const columns = [
    {
      Header: "Part",
      accessor: "code",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              color: "#0d6efd",
              fontSize: "inherit",
              fontWeight: 600,
              cursor: "pointer",
            }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.value+"-"+cell.row.original.partName}
          </Typography>
        );
      },
      // disableFilters:true,
    },
  {
      Header: "Stock Name",
      accessor: "stockRoomName",
      Cell: ({ cell }) => {
        return (
          <Typography
           sx={{
            fontSize:"inherit",
            fontWeight:"inherit"
           }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.row.original.stockRoomCode+"-"+cell.value}
          </Typography>
        );
      },
 
    },
    {
      Header: "Aisle",
      accessor: "aisleName",
      Cell: ({ cell }) => {
        return (
          <Typography
           sx={{
            fontSize:"inherit",
            fontWeight:"inherit"
           }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.row.original.aisleCode+"-"+cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Row",
      accessor: "rowName",
      Cell: ({ cell }) => {
        return (
          <Typography
           sx={{
            fontSize:"inherit",
            fontWeight:"inherit"
           }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.row.original.rowCode+"-"+cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Bin",
      accessor: "binName",
      Cell: ({ cell }) => {
        return (
          <Typography
           sx={{
            fontSize:"inherit",
            fontWeight:"inherit"
           }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.row.original.binCode+"-"+cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Received Quantity",
      accessor: "receivedQuantity",
      // disableFilters:true,
    }, 
    {
      Header: "Inventory Quantity",
      accessor: "inventoryQuantity",}, 
    {
      Header: "Unit Price",
      accessor: "unitPrice",
 
    }, 
    {
      Header: "Quality Checked",
      accessor: "isQualityChecked",
      Cell:({cell})=>{
        return (
          <Typography sx={{fontSize:"inherit",color:"inherit",fontWeight:"inherit"}}>
           {cell.value?"Yes":"No"}
          </Typography>
        )
      }
    }, 
  
    {
      Header: "Conversion Factor",
      accessor: "conversionFactor",

    },
    {
      Header: "Received Date",
      accessor: "receivedDate",

    },
    // {
    //   Header:"Action",
    //   accessor:"partId",
    //   Cell:({cell})=>{
    //     return (
    //       <IconButton
    //       disabled={!actionsAllow.delete}
    //       onClick={() => {
    //         handleDelete(cell.row.original.partId);
    //       }}
    //       >
    //       <DeleteOutlined
           
    //         sx={{
    //           fontSize: color.masterEditDelButton,
    //           color:actionsAllow.delete? color.masterEditDelButtonColor:"default",
    //         }}
    //       />
    //     </IconButton>
    //     )
    //   },
    //   disableFilters:true,
    // }
  ];

  return (
    <>
      <Box sx={{display:"flex",alignItems:"center"}}>
        <IconButton onClick={()=>{navigate("/inventory/receipt")}}>
          <ArrowBack/>
        </IconButton>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            // mb: color.masterPageTitlemd,
          }}
        >
          {receiptDetail.code+"-"+receiptDetail.businessName}
        </Typography>
      </Box>

      <TableContainer
        columns={columns}
        data={data}
        buttonNew={handleButtonNew}
        loading={isLoading}
        buttonDisable={!actionsAllow.create}
      />

      {/* <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"xl"}
        children={
          <ReceiptModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      /> */}
    </>
  );
};

export default ReceiptItem;
