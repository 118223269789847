import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import { Box, Checkbox, Dialog, IconButton, Switch, Typography } from "@mui/material";
import { useSearchParams,useNavigate, Link } from "react-router-dom";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  ListAlt,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";

import { color } from "../../../StyleData/Config";
import ReceiptModal from "./ReceiptModal";
import {useDispatch, useSelector} from "react-redux"



const Receipt = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate=useNavigate()
  const actionsAllow=useSelector(state=>state.dataRed.actionsAllow)
  const dispatch=useDispatch()

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [filterValue, setFilterValue] = useState("enableData");
  const [backUpData, setBackUpData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["receipt"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        res.data.result.sort((a, b) => {
          return a.code.localeCompare(b.code, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        if (filterValue === "enableData") {
          const filterDataResult = res.data.result.filter(
            (fil) => fil.isActive
          );
          console.log(filterDataResult, "filterData");
          setData(filterDataResult);
          setBackUpData(res.data.result);
        } else {
          setData(res.data.result);
          setBackUpData(res.data.result);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "parts");
        setIsLoading(false);
        setData([])
        setBackUpData([])
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  // const handleDelete = (id) => {
  //   const deleteLink = masterAPI["receipt"].delete;
  //   axiosPrivate
  //     .delete(`${deleteLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "delete receipt");
  //     });
  // };

  const handleDelete = async(e, id) => {
    const enableLink = masterAPI["receipt"].enable;
    const disableLink = masterAPI["receipt"].disable;
    if (e.target.checked) {
     await axiosPrivate
        .put(`${enableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "enable receipt");
        });
    } else {
     await axiosPrivate
        .put(`${disableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "disable receipt");
        });
    }
  };
  
  const columns = [
    {
      accessor: "id",
      disableFilters: true,
      Cell: () => {
        return (
          <Box sx={{ width: color.checkBoxWidth }}>
            <Checkbox
              // icon={<RadioButtonUncheckedOutlined />}
              // checkedIcon={<CheckCircle />}
            />
          </Box>
        );
      },
    },
    {
      Header:"Code",
      accessor:"code",
      Cell: ({ cell }) => {
        return (
          <Link
            to={`/receipt/receiptItem?id=${cell.row.original.id}`}
            onClick={()=>{
              dispatch({type:"storeDisplayListName",payload:{name:"receiptItem",data:cell.row.original}})
            }}
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: "DO",
      accessor: "doNumber",
      // disableFilters: true,
    },

    {
      Header: "PO",
      accessor: "poNumber",
    },
    {
      Header: "Business Name",
      accessor: "businessName",
    },
    {
      Header: "Total Received Price",
      accessor: "totalReceivedPrice",
    },
    {
      Header: "Received Date",
      accessor: "receivedDate",
    },
    // {
    //   Header: "Actions",
    //   Cell: ({ cell }) => {
    //     // console.log(cell)
    //     return (
    //       <Box sx={{ whiteSpace: "nowrap" }}>
    //         {/* <IconButton>
    //           <EditOutlined
    //             onClick={() => {
    //               handleEdit(cell.row.original);
    //             }}
    //             sx={{
    //               fontSize: color.masterEditDelButton,
    //               color: color.masterEditDelButtonColor,
    //             }}
    //           />
    //         </IconButton> */}
    //           {/* <IconButton >
    //           <ListAlt
    //             onClick={() => {
    //              navigate(`/receipt/receiptItem?id=${cell.row.original.id}`)
    //             }}
    //             sx={{
    //               fontSize: color.masterEditDelButton,
    //               color: color.masterEditDelButtonColor,
    //             }}
    //           />
    //         </IconButton> */}
    //         {/* <IconButton
    //         disabled={!actionsAllow.delete}
    //         onClick={() => {
    //           handleDelete(cell.row.original.id);
    //         }}>
    //           <DeleteOutline
              
    //             sx={{
    //               fontSize: color.masterEditDelButton,
    //               color:actionsAllow.delete? color.masterEditDelButtonColor:"default",
    //             }}
    //           />
    //         </IconButton> */}
    //         {/* <Switch
    //           disabled={
    //             !actionsAllow.delete 
    //           }
    //           checked={cell.row.original.isActive}
    //           onChange={(e) => {
    //             handleDelete(e, cell.row.original.id);
    //           }}
    //         /> */}
    //       </Box>
    //     );
    //   },
    // },
  ];

  const handleChangeFilter = (e) => {
    if (e === "showAll") {
      setData(backUpData);
    } else {
      const filterDataResult = backUpData.filter((fil) => fil.isActive);

      setData(filterDataResult);
    }
    setFilterValue(e);
  };

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["receipt"].title}
        </Typography>
      </Box>

      <TableContainer
        columns={columns}
        data={data}
        buttonNew={handleButtonNew}
        loading={isLoading}
        buttonDisable={!actionsAllow.create}
        filterValue={filterValue}
        handleChange={handleChangeFilter}
      />

      <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"xl"}
        children={
          <ReceiptModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      />
    </>
  );
};

export default Receipt;
