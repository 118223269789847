import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Button, Collapse, Divider, Grid, IconButton, Paper, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from 'moment';
import Chart from "react-apexcharts";
import React, { useEffect, useState } from 'react'
import Loading from "../../Component/Loading";
import NormalTable from "../../Component/NormalTable";
import { color } from "../../StyleData/Config";
import { masterAPI } from "../Masters/dataConfig";
import { axiosPrivate } from "../../axios/axios";

const Reports = () => {
  const [open,setOpen]=useState(false)
  const [chartData,setChartData]=useState({
    totalbreakdown:[],
    locationCode:[]
  })
  const [chartList,setChartList]=useState([])
  const [getDateFrom, setGetDateFrom] = useState(moment().subtract(3, "months").format("YYYY-MM-DDTHH:mm:ss"));
  const [getDateTo, setGetDateTo] = useState(moment().format("YYYY-MM-DDTHH:mm:ss"));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
   getData()
  },[])

  const getData=async()=>{
    setIsLoading(true);
    const chartDataLink=masterAPI["KPIReport"].getChartLocation
    const chartListLink=masterAPI["KPIReport"].getCloseWOListWithLocation
    const dateFromandTo = {
      createdDateFrom: getDateFrom,
      createdDateTo: getDateTo,
    };


    await axiosPrivate
      .post(`${chartDataLink}`, dateFromandTo)
      .then((res) => {
        console.log(res.data.result);
        const resData = res.data.result;
        setChartData(resData)
        

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        setChartData({
          totalbreakdown:[],
          locationCode:[]
        })
      });

      await axiosPrivate
      .post(`${chartListLink}`, dateFromandTo)
      .then((res) => {
        console.log(res)
        const resData = res.data.result;
        setChartList(resData)
        

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        setChartList([])
      });
  
  
 
  
  
    
  }

  const columns=[
    {
      Header:"WorkOrder Code",
      accessor:"workOrderCode"
    },
    {
      Header:"WorkOrder Name",
      accessor:"workOrderName"
    },
    {
      Header:"Asset Code",
      accessor:"assetCode"
    },
    {
      Header:"Asset Name",
      accessor:"assetName"
    },
    {
      Header:"Actual Start Date",
      accessor:"actualStartDate",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>
            {cell.value !== null && cell.value !== undefined
              ? moment(cell.value).format("DD/MM/YYYY")
              : "---"}
          </Typography>
        );
      },
    },
    {
      Header:"Actual End Date",
      accessor:"actualEndDate",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>
            {cell.value !== null && cell.value !== undefined
              ? moment(cell.value).format("DD/MM/YYYY")
              : "---"}
          </Typography>
        );
      },
    },
    {
      Header:"Down Time",
      accessor:"stopBeginDate",
      Cell:({cell})=>{
        return(
          <Typography sx={{fontSize:"inherit",fontWeight:"inherit"}}>
            {cell.row.original.stopEndDate !== null && cell.row.original.stopEndDate !== undefined?handleDownTime(cell.value,cell.row.original.stopEndDate):"---"}
          </Typography>
        )
      }
    },
  ]

  const handleDownTime = (endDate, beginDate) => {
    let downTime=0
    var date1 = new Date(beginDate);
    var date2 = new Date(endDate);
    var diff = date2.getTime() - date1.getTime();
    var msec = diff;
    var days = Math.floor(diff / 1000 / 60 / (60 * 24));
    msec-=days * 24 * 60 * 60 * 1000
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    // var ss = Math.floor(msec / 1000);
    // msec -= ss * 1000;
    
   
     downTime=beginDate!==null&&endDate!==null?days+" days "+hh+" hours "+mm+" minutes ":""

    return downTime

    
  };

  const handleSearch = () => {
    getData();
  };

  return (
    <Grid container spacing={"1rem"} padding={"1rem"}>
    <Grid item xs={12}>
      <Paper sx={{ padding: "1rem" }}>
        <Grid container alignItems={"center"}>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: "#2362B4",
                fontSize: "1.1rem",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  setOpen(!open);
                }}
              >
                {open ? (
                  <KeyboardArrowUpIcon
                    sx={{ fontSize: "1.7rem", color: "#2362B4" }}
                  />
                ) : (
                  <KeyboardArrowDown
                    sx={{ fontSize: "1.7rem", color: "#2362B4" }}
                  />
                )}{" "}
              </IconButton>
              Filter
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#2362B4", color: "white" }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        <Collapse in={open} timeout={"auto"} unmountOnExit>
          <Grid container spacing={"1.5rem"}>
            <Grid
              container
              item
              xs={12}
              md={3}
              spacing={"1rem"}
              alignItems={"center"}
              // justifyContent={"center"}
            >
              <Grid item xs={12} justifyContent={"flex-end"}>
                <Typography
                  sx={{
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: 600,
                    color: "#656565",
                  }}
                >
                  Date Range
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DesktopDatePicker
                      label="Date From"
                      format="DD/MM/YYYY"
                      value={dayjs(getDateFrom)}
                      slotProps={{
                        textField: { size: "small", fullWidth: true },
                      }}
                      onChange={(newValue) => {
                        setGetDateFrom(newValue);
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            setGetDateFrom(dateFrom);
                            // getData(dateFrom, getDateTo);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DesktopDatePicker
                      label="Date To"
                      format="DD/MM/YYYY"
                      value={dayjs(getDateTo)}
                      slotProps={{
                        textField: { size: "small", fullWidth: true },
                      }}
                      onChange={(newValue) => {
                        setGetDateTo(newValue);
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateTo = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            setGetDateTo(dateTo);
                            // getData(getDateFrom, dateTo);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
            </Grid>

          
          </Grid>
        </Collapse>
      </Paper>
    </Grid>

    {isLoading ? (
      <Loading />
    ) : (<>
    <Grid item xs={12}>
      <Paper sx={{p:"1rem"}}>
    <Chart
    height={
     chartData.totalbreakdown.length
        ? `${32 * chartData.totalbreakdown.length + 200}px`
        : "100%"
    }
    width={"100%"}
    options={{
      
      responsive: [
        {
          breakpoint: 768, // Change the breakpoint as needed
          options: {
            chart: {
              width: 600,
            },
          },
        },
      ],
      series: [
        {
          data: chartData.locationCode,
        },
      ],
      title: {
        text: "Break Down",
        style: {
          fontFamily: "Nunito Sans, sans-serif",
          color: "#2362B4",
        },
      },

      chart: {
        type: "bar",
        toolbar: {
          export: {
            csv: {
              filename: "Break down",
            },
            svg: {
              filename: "Break down",
            },
            png: {
              filename: "Break down",
            },
          },
        },
      
      },
    //   grid:{
    //     padding:{
    //         bottom:-10
    //     }
    //   },

      plotOptions: {
        bar: {

          borderRadius: 4,
          horizontal: false,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
                fontFamily: "Nunito Sans, sans-serif",
              },
            },
          },
        },
        noData: {
          text: "No Result",
          style: {
            fontSize: "1.5rem",
            color: "grey",
            fontFamily: "Nunito Sans, sans-serif",
          },
        },

        stroke: {
          width: 1,
          colors: ["#fff"],
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        title: {
          text: "No.of Work Order",
          // style:{
          //  padding:10
          // }
        },
        // labels:{
        //     show:type
        // }
      },
      xaxis: {
        title: {
          text: "Location",
          style: {
            fontSize: '11px' // Set the font size of the x-axis title
          }
        },
      //   labels:{
      //     show:type
      // },
        // labels:{
        //     show:false
        // },
        categories: chartData.locationCode,

        
      },
    }}
    type="bar"
    series={[{ data: chartData.totalbreakdown }]}
  />
  </Paper>

    </Grid>
    {chartList.map((dataList,i)=>(
      <Grid item xs={12} key={i}>
        <Paper sx={{p:"1rem"}}>
          <Typography sx={{
                color: color.masterDialogTitleColor,
                fontSize: color.masterDialogFontSize,
              }}>
            {dataList.locationCode+"-"+dataList.locationName}
          </Typography>
          <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mt: "15px" }} />
          <NormalTable data={dataList.woList} columns={columns} />
        </Paper>
      </Grid>
    ))}
    </>)
  }
  </Grid>
  )
}

export default Reports