import { Box, Grid, Paper } from "@mui/material";
import React, { useState } from "react";

import TreeStructureExpl from "./TreeStructureExpl";
import Asset_Details from "../Assets/Asset_Details";

const AssetExpl = () => {
  const [assetId,setAssetId]=useState("")
  return (
    <Grid container sx={{ p: 1 }} spacing={"1rem"}>
      <Grid item xs={3.5}>
        <Paper
          elevation={0}
          sx={{
            boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
            p: 1.5,
            backgroundColor: "white",
          }}
        >
          <TreeStructureExpl handleAsset={(e)=>{setAssetId(e)}} />
        </Paper>
      </Grid>
      <Grid item xs={8.5} >
      {assetId!==""?
         <Paper
          elevation={0}
          sx={{
            boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
            p: 1.5,
            backgroundColor: "white",
          }}
        >
      
          <Asset_Details  assetExpl={{page:true,assetId:assetId}}/>
          </Paper>
          :null}
      </Grid>

      
    </Grid>
  );
};

export default AssetExpl;
