import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { color } from "../../../../../StyleData/Config";
import NormalTable from "../../../../../Component/NormalTable";
import { isolation } from "../../../../../SampleData/PermitWork";

const Isolation = ({ data }) => {
  const [sampleData, setSampleData] = useState([]);

  useEffect(() => {
    setSampleData(isolation)
  }, []);

  const columns = [
    {
      Header: "List pos.in iso",
      accessor: "List_pos_in_iso",
    },
    {
      Header: "List pos.in norm",
      accessor: "List_pos_in_norm",
    },
    {
      Header: "Location1",
      accessor: "Location1",
    },
    {
      Header: "Asset",
      accessor: "Asset",
    },
    {
      Header: "Isol.meas.lock key",
      accessor: "Isol_meas_lock_key",
    },
    {
      Header: "Lock required",
      accessor: "Lock_required",
    },
    {
      Header: "Electrical",
      accessor: "Electrical",
    },
    {
      Header: "Mechanical",
      accessor: "Mechanical",
    },
    {
      Header: "ISO Condition",
      accessor: "ISO_Condition",
    },
  ];
  return (
    <Paper elevation={3} sx={{ p: 1 }}>
      <Grid container spacing={"1rem"}>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
            <Grid item container xs={4} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  Permit Code :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                >
                  {data}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
            <Grid item container xs={4} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  Lock out board :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                >
                  LOB1
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={5} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    mt: -1,
                  }}
                >
                  <FormControlLabel control={<Checkbox />} label="Cross lock" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
            <Grid item container xs={4} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  AP KEY :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                >
                  A24
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={4} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  CP KEY 3 :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                ></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
            <Grid item container xs={4} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  CP KEY 1 :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                >
                  B39
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={4} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  CP KEY 4 :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                ></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
            <Grid item container xs={4} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  CP KEY 2 :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                ></Typography>
              </Grid>
            </Grid>
            <Grid item container xs={4} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  CP KEY 5 :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                ></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <NormalTable data={sampleData} columns={columns} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Isolation;
