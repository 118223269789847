import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { color } from "../../../../../StyleData/Config";
import { useSearchParams } from "react-router-dom";

const General = ({ data }) => {
  return (
    <Paper elevation={3} sx={{ p: 1 }}>
      <Grid container>
        <Grid item xs={6}>
          <Grid container spacing={"1rem"}>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,

                  fontSize: color.labelFont,
                  color: color.labelColor,
                  textAlign: "right",
                }}
              >
                Permit Code :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                }}
              >
                {data}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,

                  fontSize: color.labelFont,
                  color: color.labelColor,
                  textAlign: "right",
                }}
              >
                Required Date :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                }}
              >
                09/06/2016 20:33
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,

                  fontSize: color.labelFont,
                  color: color.labelColor,
                  textAlign: "right",
                }}
              >
                Duration Days :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                }}
              >
                1
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={"1rem"} justifyContent={"flex-end"}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,

                  fontSize: color.labelFont,
                  color: color.labelColor,
                  textAlign: "right",
                }}
              >
                Permit Type :
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                }}
              >
                Normal
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,

                  fontSize: color.labelFont,
                  color: color.labelColor,
                  textAlign: "right",
                }}
              >
                Certificate :
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                }}
              >
                Hot Work
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,

                  fontSize: color.labelFont,
                  color: color.labelColor,
                  textAlign: "right",
                }}
              >
                Document Type
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                }}
              >
                Request
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px", mt: "15px" }}
      />
      <Grid item xs={12}>
        <Grid container spacing={"1rem"}>
          <Grid item container xs={4.5} spacing={"1rem"}>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,

                  fontSize: color.labelFont,
                  color: color.labelColor,
                  textAlign: "right",
                }}
              >
                Requested By:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                }}
              >
                ECIENG
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={4.5} spacing={"1rem"}>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,

                  fontSize: color.labelFont,
                  color: color.labelColor,
                  textAlign: "right",
                }}
              >
                Telephone :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                }}
              ></Typography>
            </Grid>
          </Grid>
          <Grid item container xs={3} spacing={"1rem"}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,

                  fontSize: color.labelFont,
                  color: color.labelColor,
                  textAlign: "right",
                }}
              >
                PTW Request Status :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                }}
              >
                Active
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px", mt: "15px" }}
      />

      <Grid item xs={12}>
        <Grid container spacing={"1rem"}>
          <Grid item container xs={5} spacing={"1rem"}>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,

                  fontSize: color.labelFont,
                  color: color.labelColor,
                  textAlign: "right",
                }}
              >
                Contractor :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                }}
              >
                SCEENGRD
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={5} spacing={"1rem"}>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,

                  fontSize: color.labelFont,
                  color: color.labelColor,
                  textAlign: "right",
                }}
              >
                Telephone :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                }}
              ></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px", mt: "15px" }}
      />
      <Grid container spacing={"1rem"}>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"} alignItems="center">
            <Grid item container xs={5.5} spacing={"1rem"} alignItems="center">
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  Location :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                >
                  FREIGHT
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={6.5} spacing={"1rem"} alignItems="center">
              <Grid item xs={11}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "left", // Align text to the left
                    whiteSpace: "nowrap", // Prevent text wrapping
                    color: "blue",
                  }}
                >
                  HVAC SYSTEM FOR CONVENTIONAL AREA
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
            <Grid item container xs={5.5} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  Asset :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                >
                  A0001
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={6.5} spacing={"1rem"} alignItems="center">
              <Grid item xs={11}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "left", // Align text to the left
                    whiteSpace: "nowrap", // Prevent text wrapping
                    color: "blue",
                  }}
                >
                  HVAC SYSTEM FOR CONVENTIONAL AREA
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={5.5} spacing={"1rem"}>
          <Grid item xs={4}>
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,

                fontSize: color.labelFont,
                color: color.labelColor,
                textAlign: "right",
              }}
            >
              Work Description :
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
              }}
            >
              ST Excitation room ASUC # 1
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
            <Grid item container xs={5.5} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  Created Date :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                >
                  21/06/2016 20:33
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={6} spacing={"1rem"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign: "right",
                  }}
                >
                  Allocated to Permit :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                  }}
                >
                  PTW00005
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px", mt: "15px" }}
      />
      <Typography
        sx={{
          fontWeight: color.labelFontWeight,
          ml: "1rem",
          fontSize: color.labelFont,
          color: color.labelColor,
        }}
      >
        Documents
      </Typography>

      <Grid container spacing={"0.5rem"}>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
            <Grid item container xs={5} spacing={"1rem"}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    ml: "1rem",
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  <FormControlLabel control={<Checkbox />} label="Hot Work" />
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={3} spacing={"1rem"}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,

                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Confined Space"
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default General;
