import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Add, Clear, DeleteOutline } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../dataConfig";

const CreateQuestion = ({ mode, handleCloseClick,editData }) => {

  const [loading,setLoading]=useState(false)
  const [questionType,setQuestionType]=useState([])
  const [multipleChoice,setMultipleChoice]=useState(editData.listOfChoices||[])

  useEffect(()=>{
   getData()
  },[])

  const getData=async()=>{
    const questionTypeLink=masterAPI["selectList"].questionType
   await axiosPrivate.get(`${questionTypeLink}`).then(res=>{
      setQuestionType(res.data)
    }).catch(e=>{
      console.log(e)
      setQuestionType([])
    })
  }


  const initialValue = {
    // code: mode==="Edit"?editData.code: "",
    question: mode==="Edit"?editData.question: "",
    questionType: mode === "Edit"?editData.questionType:''
    // parentCategoryId:mode==="Edit"?editData.parentCategoryId: null,
  };

  const onSubmit = async(value) => {
    console.log(value);
    const valueData={
      ...value,
      listOfChoices:multipleChoice
    }
    setLoading(true)
    const createLink = masterAPI["question"].create;
    const updateLink=masterAPI["question"].update
    if(mode==="Create"){
     await axiosPrivate.post(`${createLink}`,valueData).then(res=>{
        console.log(res.data)
        setLoading(false)
        handleCloseClick()
      }).catch(e=>{
        setLoading(false)
        console.log(e,"create question")
      })
    }else{
      const updateAC={
        id:editData.id,
        ...value,
        listOfChoices:multipleChoice
      }
     await axiosPrivate.put(`${updateLink}`,updateAC).then(res=>{
        console.log(res.data)
        setLoading(false)
        handleCloseClick()
      }).catch(e=>{
        setLoading(false)
        console.log(e,"update question")
      })
    }
   
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  const handleRemoveItem = (index) => {
    const newItems = [...multipleChoice];
    newItems.splice(index, 1);
    setMultipleChoice(newItems);
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode === "Edit"?editData.question:"New Question"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
      {({ handleChange, setFieldValue, values , resetForm }) => (
        <Form>
          <Grid container spacing={"1rem"}>
          {/* <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Code
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="code"
                placeholder="Enter  Code"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Question
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="question"
                placeholder="Enter Question"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Question Type
                </Typography>
                
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="questionType"
                
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.questionType !== '' ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
               
                {questionType.map((id, i) => (
                  <MenuItem value={id.value}
                  onClick={()=>{
                    if(id.text.toLowerCase()==="multichoice"){
                      setMultipleChoice([""])
                    }else{
                      setMultipleChoice([])
                    }
                  }}
                   >
                    {id.text}
                    </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid container item xs={12} md={6} sx={{display:values?.questionType.toLowerCase()==="multichoice"?"default":"none"}}>
                      <Box sx={{display:"flex",width:"100%",justifyContent:"space-between"}}>
                      <Typography
                        sx={{
                          fontWeight: color.labelFontWeight,
                          mb: color.labelmb,
                          fontSize: color.labelFont,
                          color: color.labelColor,
                        }}
                      >
                        Choice
                      </Typography>
                    
                          <Add sx={{cursor:"pointer",color: color.labelColor,}} onClick={()=>{
                          setMultipleChoice([...multipleChoice,""])
                         }}/>
                      
                      </Box>
                        
                    
                      <Grid item xs={12}>
                      {multipleChoice.map((choice,i)=>(
                      
                  <Box sx={{display:"flex",gap:"0.2rem",width:"100%"}}>
                      <Field
                        as={TextField}
                        size={"small"}
                        type="text"
                        value={choice}
                        placeholder="Enter Choice"
                        fullWidth
                        onChange={(e)=>{
                          const mcData=[...multipleChoice]
                          mcData[i]=e.target.value
                          console.log(mcData)
                          setMultipleChoice(mcData)
                        }}
                        sx={{
                          ".MuiInputBase-input": {
                            // letterSpacing: "0.2rem",

                            "&::placeholder": {
                              // color: "green",
                              opacity: 1,
                              color: color.placeholderColor,
                              fontSize: "13px",
                            },
                          },
                        }}
                      />
                      <IconButton onClick={()=>{
                        if(multipleChoice.length!==1){
                          handleRemoveItem(i)
                          }
                          }}>
                        <DeleteOutline/>
                      </IconButton>
                      </Box>
                      ))}
                      </Grid>
                    
                </Grid>
            
           
            <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
              <Button
                onClick={handleCancel}
                variant="contained"
                sx={{
                  backgroundColor: color.TableCancelButton,
                  textTransform: "none",
                  color: color.CancelTextColor,
                  ":hover":{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type={"submit"}
                variant="contained"
                disabled={loading}
                sx={{
                      
                  textTransform: "none",
                  backgroundColor: loading ? "default" : color.TableButtonColor,
                  "&:hover": {
                    backgroundColor: loading
                      ? "default"
                      : color.buttonDisable,
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
      </Formik>
    </Box>
  );
};

export default CreateQuestion;
