import {
    Box,
    Divider,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
    Select,
    MenuItem,
    Tab,
    Menu,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Field, Formik, useFormik, Form } from "formik";
  
  import { color } from "../../../StyleData/Config";
  import { Circle, Clear, MoreHoriz } from "@mui/icons-material";
  import { axiosPrivate } from "../../../axios/axios";
  import { masterAPI } from "../../Masters/dataConfig";
  import { TabContext, TabList, TabPanel } from "@mui/lab";
  import { useSearchParams } from "react-router-dom";
import General from "./General";
import AssetList from "./AssetList/AssetList";
import WorkAssignment from "./WOAssignment/WorkAssignment";
import Completion from "./Completion";
  
  const InspectionWOView = ({ mode, editData, handleCloseClick }) => {
    const [value, setValue] = useState("1");
    const [jobPlanId, setJobPlanId] = useState("");
    const [workOrderId, setWorkOrderId] = useState("");
    const [woData, setWoData] = useState({});
    const [dotBL, setDotBL] = useState(null);
    const [direction, setDirection] = useState("row");
    const [searchParams, setSearchParams] = useSearchParams();
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    useEffect(() => {
     getData()
    }, []);
    const getData=async()=>{
      const id = searchParams.get("id");
      const wolink = masterAPI["inspWo"].getByid;
     await axiosPrivate
        .get(`${wolink}/${id}`)
        .then((res) => {
          console.log(res);
          // DataWO(res.data.result)
          setWoData(res.data.result);
          setJobPlanId(res.data.result.jobPlanId);
          setWorkOrderId(res.data.result.id);
        })
        .catch((e) => {
          setWoData([])
          setJobPlanId([])
          setWorkOrderId([])
          console.log(e, "workorderview");
        });
    }
  
    const handleDot = (event) => {
      if (!dotBL) {
        setDotBL(event.currentTarget);
      } else {
        setDotBL(null);
      }
    };
  
    const contentPosition = (flex) => {
      setDirection(flex);
    };
    return (
      <Grid container spacing={"1rem"}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              mb: "1rem",
              gap: "1rem",
            }}
            // sx={{mb:"1rem"}}
          >
            <Typography
              sx={{
                // color: color.masterDialogTitleColor,
                // fontSize: color.masterDialogFontSize,
                fontSize: color.AssetTitlesize,
                fontWeight: color.sideFontWeightAsset,
              }}
            >
              Work Order : {woData.code} : {woData.inspectionName}
            </Typography>
            {/* <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                <Circle sx={{ fontSize: "0.5rem", color: "green" }} />
                <Typography>{woData.status}</Typography>
              </Box>
              <MoreHoriz onClick={handleDot} sx={{ cursor: "pointer" }} />
                <Menu anchorEl={dotBL} open={Boolean(dotBL)} onClose={handleDot}>
                  <MenuItem>Option 1</MenuItem>
                  <MenuItem>Option 2</MenuItem>
                  <MenuItem>Option 3</MenuItem>
                </Menu>
              <Box></Box>
            </Box> */}
          </Box>
          <TabContext value={value}>
            <Box
              sx={{
                ".css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                  color: color.TabLabelcolor,
                  // p: 0.5,
                },
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <TabList
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: { backgroundColor: color.TabLabelcolor, height: "3px" },
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="General" value="1" sx={{ textTransform: "none" }} />
                <Tab label="Asset" value="2" sx={{ textTransform: "none" }} />
                <Tab
                  label="Work Assignment"
                  value="3"
                  sx={{ textTransform: "none" }}
                />
  
                <Tab
                  label="Completion"
                  value="4"
                  sx={{ textTransform: "none" }}
                />
                {/* <Tab
                      label="Costing"
                      value="5"
                      sx={{ textTransform: "none" }}
                    /> */}
              </TabList>
            </Box>
            <Box sx={{ mt: "1rem" }}>
              <TabPanel value="1" sx={{ p: 1 }}>
                <General data={woData} />
              </TabPanel>
              <TabPanel value="2" sx={{ p: 1 }}>
                <AssetList woData={woData}/>
              </TabPanel>
              <TabPanel value="3" sx={{ p: 1 }}>
                <WorkAssignment woData={woData}/>
              </TabPanel>
              <TabPanel value="4" sx={{ p: 1 }}>
              <Completion woData={woData} />
              </TabPanel>
              
            </Box>
          </TabContext>
        </Grid>
        {/* <Grid
          container
          justifyContent={"flex-end"}
          item
          xs={direction === "column" ? 6 : 2.5}
          md={direction === "column" ? 6 : 1.5}
          lg={direction === "column" ? 6 : 1}
          sx={{
            boxShadow:
              direction === "column" ? "-1px 1px 7px 2px rgb(0 0 0 / 32%)" : 0,
            minHeight: "92vh",
            backgroundColor: "transparent",
          }}
        >
          <ListMenuWO
            contentPosition={contentPosition}
            data={woData}
            direction={direction}
            getData={getData}
          />
        </Grid> */}
      </Grid>
    );
  };
  
  export default InspectionWOView;
  