import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { color } from "../../StyleData/Config";
import { Box, Button, Dialog, Grid, Paper, Typography } from "@mui/material";
import { masterAPI } from "../Masters/dataConfig";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { axiosPrivate } from "../../axios/axios";
import LineChartValue from "./LineChartValue";
import { toast } from "react-toastify";

const LineChart = ({ assetInfo }) => {
  const [dateTime, setDateTime] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss")
  );
  const [data, setData] = useState({
    yaxis: [],
    xaxis: [],
  });
  const [status, setStatus] = useState("");
  const [create, setCreate] = useState(false);
  const [loading, setLoading] = React.useState(false);
  // Define pmGenerate state here
  const [pmGenerate, setPmGenerate] = useState({
    PMIdList: [],
    // Add other necessary properties for pmGenerate if needed
  });

  useEffect(() => {
    getData();
    const intervalCall = setInterval(() => {
      getData();
    }, 5000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);
  const getData = () => {
    const dataLink = masterAPI["LineChart"].getIotData;
    console.log(moment().format("YYYY-MM-DDTHH:mm:ss"));
    axiosPrivate
      .post(`${dataLink}?datetime=${moment().format("YYYY-MM-DDTHH:mm:ss")}`)
      .then((res) => {
        console.log(res);
        const xaxisReverse = res.data.result.data.xAxis.reverse();
        if (
          xaxisReverse[xaxisReverse.length - 1] >= 45 &&
          xaxisReverse[xaxisReverse.length - 1] <= 55
        ) {
          console.log("check");
          setStatus("Warning");
        } else if (xaxisReverse[xaxisReverse.length - 1] > 55) {
          setStatus("Critical");
        } else {
          setStatus("Normal");
        }

        setData({
          xaxis: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
          yaxis: xaxisReverse,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleClose = () => {
    setCreate(false);
  };

  const handleGenerate = async () => {
    const pmIdList = ["36b27f85-4d5f-4902-b6d5-9eec70469997"];

    if (pmIdList.length === 0) {
      toast.error("Please Select Pm");
      return;
    }

    console.log("API Payload:", pmIdList);

    const genWo = masterAPI["PM"].generateWO;

    await axiosPrivate
      .post(`${genWo}`, pmIdList)
      .then((res) => {
        console.log(res, "genWO");
        toast.success("Successfully Converted");
        getData();
      })
      .catch((e) => {
        console.log(e, "error generate workorder pm");
      });
  };
  const onSubmit = async () => {
    setLoading(true);
    const createLink = masterAPI["serviceRequest"].create;

    // Define the payload
    const payload = {
      serviceRequestNameId: "7f1380ff-08d2-4a72-a4fd-0f3f91bb0590",
      problemId: "a18b74e0-08d2-448f-9d38-15035eeeef8b",
      failureClassId: "8d2cb386-ac2f-4073-ac92-7b4cc41b8d91",
      locationId: "6e8ff4cc-2e5e-44f5-b211-ec07348a2b4d",
      assetId: "ed79e16f-20cb-4aaf-8b88-f7cf279c1397",
      assetName: "",
      serviceRequestName: "Pump sudden Stop",
      description: "Pump sudden Stop",
      reasontoCancel: "",
      workTypeId: "ae08a3b7-831d-478f-a70a-8d181a970520",
      requestedDate: "2024-10-02T09:44:12",
      stopBeginDate: "2024-10-02T09:44:12",
      closingDate: "2024-10-02T09:44:12",
      approvalDate: null,
      serviceRequestDocumentsList: [],
    };

    console.log("Payload for Create:", payload);

    try {
      const res = await axiosPrivate.post(createLink, payload);
      console.log(res.data);
      toast.success("Service request created successfully!"); // Success toast
    } catch (e) {
      console.error(e, "create serviceRequest");
      toast.error("Failed to create service request. Please try again."); // Error toast
    } finally {
      setLoading(false); // Ensure loading state is reset after the request
      // handleCloseClick(); // Close modal or clear form, if applicable
    }
  };
  return (
    <>
      {/* <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["LineChart"].lineChartGraph}
        </Typography>
      </Box> */}
      <Paper sx={{ p: "1rem" }}>
        <Grid container spacing={"1rem"}>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#2362B4", color: "white" }}
                onClick={() => {
                  setCreate(true);
                }}
              >
                Add Value
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Health Status :
                </Typography>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    // color: color.labelColor,
                  }}
                >
                  {status}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Box sx={{display:"flex",gap:"1rem",alignItems:"center"}}> 
          <Typography
                    sx={{
                      fontFamily: "Nunito Sans, sans-serif",
                      fontWeight: 600,
                      color: "#656565",
                    }}
                  >
                    Date Range
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
               
                 
                
                  value={dateTime!==null?dayjs(dateTime):dateTime}
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  // timezone=""
                  // sx={{".css-1xhypcz-MuiStack-root":{
                  //   padding:0
                  // }}}

                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                      fullWidth: true,
                    },
                  }}
                  fullWidth
                  onChange={(newValue) => {
                    console.log(newValue);
                    if (newValue !== null) {
                      if (`${newValue.$d}` !== "Invalid Date") {
                        const dateFrom = moment(newValue.$d).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        );
                        setDateTime(dateFrom)
                        
                        // 2022-04-17T15:30
                        // console.log(dateFrom+"T"+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds())
                        // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                        // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                        // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                        // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                        
                       
                       
                      }
                    }
                  }}
                />
              </DemoItem>
            </LocalizationProvider>
            </Box>
          </Grid>  */}
          <Grid item xs={12}>
            <Chart
              height={350}
              width={"100%"}
              options={{
                chart: {
                  height: 350,
                  type: "line",
                  id: "Line",
                  toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: true,
                      selection: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      pan: false,
                      reset: false,
                      customIcons: [],
                    },
                  },
                },

                annotations: {
                  yaxis: [
                    //   {
                    //   y: 3,
                    //   borderColor: '#00E396',
                    //   label: {
                    //     borderColor: '#00E396',
                    //     style: {
                    //       color: '#fff',
                    //       background: '#00E396',
                    //     },
                    //     text: 'Support',
                    //   }
                    // },
                    {
                      y: 0,
                      y2: 45,
                      borderColor: "#000",
                      fillColor: "#8FEBFE",
                      opacity: 0.2,
                      label: {
                        borderColor: "#333",
                        style: {
                          fontSize: "10px",
                          color: "#333",
                          background: "#8FEBFE",
                        },
                        text: "Normal",
                      },
                    },
                    {
                      y: 45,
                      y2: 55,
                      borderColor: "#000",
                      fillColor: "#FFD064",
                      opacity: 0.2,
                      label: {
                        borderColor: "#333",
                        style: {
                          fontSize: "10px",
                          color: "#333",
                          background: "#FFD064",
                        },
                        text: "Warning",
                      },
                    },
                    {
                      y: 55,
                      y2: 100,
                      borderColor: "#000",
                      fillColor: "#F597C0",
                      opacity: 0.2,
                      label: {
                        borderColor: "#333",
                        style: {
                          fontSize: "10px",
                          color: "#333",
                          background: "#F597C0",
                        },
                        text: "Cirtical",
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                },
                grid: {
                  padding: {
                    right: 30,
                    left: 20,
                  },
                },
                title: {
                  text: "Degradating level log data",
                  align: "left",
                },
                labels: data.xaxis,
                xaxis: {
                  title: {
                    text: "Measurement Time (min)",
                    style: {
                      fontSize: "11px", // Set the font size of the x-axis title
                    },
                  },
                  type: "number",
                  labels: {
                    show: true,
                  },
                },
                yaxis: {
                  min: 0,
                  max: 100,
                  title: {
                    text: "Degradation Level",
                    style: {
                      fontSize: "11px", // Set the font size of the x-axis title
                    },
                  },
                },
              }}
              type="line"
              series={[{ data: data.yaxis }]}
            />
          </Grid>
          <Grid item xs={12} sx={{ ml: "3rem" }}>
            <Box
              sx={{
                width: "25%",
                backgroundColor: "#FFD064",
                p: 0.5,
                mb: "0.5rem",
              }}
            >
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,
                  // mb: color.labelmb,
                  fontSize: color.labelFont,
                  // color: color.labelColor,
                }}
              >
                Alarm threshold (Warning) : 45
              </Typography>
            </Box>
            <Box sx={{ width: "25%", backgroundColor: "#F597C0", p: 0.5 }}>
              <Typography
                sx={{
                  fontWeight: color.labelFontWeight,
                  // mb: color.labelmb,
                  fontSize: color.labelFont,
                  // color: color.labelColor,
                }}
              >
                Alarm threshold (Critical) : 55
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={"1rem"}
          style={{
            height: "10vh",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Grid item xs={6} container spacing={2}>
            {" "}
            {/* Use spacing here for space between items */}
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#2362B4",
                  color: "white",
                  width: "100%",
                }}
                onClick={handleGenerate}
              >
                OPC PM
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#2362B4",
                  color: "white",
                  width: "100%",
                }}
                onClick={onSubmit}
              >
                OPC CM
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={create}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: { xs: "100vw", md: "50vw" },
            width: { xs: "100vw", md: "50vw" },
          },
        }}
        children={<LineChartValue handleCloseClick={handleClose} />}
      />
    </>
  );
};

export default LineChart;
