import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
    Autocomplete,
    CircularProgress,
    createFilterOptions,
    Paper,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Field, Form, Formik } from "formik";
  import * as Yup from "yup";
  import { Add, Clear, DeleteOutlined, EditOutlined } from "@mui/icons-material";
  import { color } from "../../../StyleData/Config";
  import { axiosPrivate } from "../../../axios/axios";
  import { masterAPI } from "../../Masters/dataConfig";
  import moment from "moment";
  import dayjs from "dayjs";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { DemoItem } from "@mui/x-date-pickers/internals/demo";
  import {
    DatePicker,
    DateTimePicker,
    LocalizationProvider,
    renderTimeViewClock,
  } from "@mui/x-date-pickers";
  import NormalTable from "../../../Component/NormalTable";
  
  const ReturnTransModal = ({ mode, handleCloseClick, editData }) => {
   const [issueList,setIssueList]=useState([])
    const [issueSel,setIssueSel]=useState("")
    const [issueTypeData, setIssueTypeData] = useState([]);
    const [returnType,setReturnType]=useState("wo")
    const [issueItem,setIssueItem]=useState({
    partId: null,
    stockRoomId: null,
    stockRoomRowId: null,
    stockRoomRowAisleId: null,
    stockRoomRowBinId: null,
    quantity: 0,
    unitCost: 0,
    lineCost: 0,
    itemReturnedDate:moment().format("YYYY-MM-DDTHH:mm:ss")
    })
  
    const [partData, setPartData] = useState([]);
  
   const [personData,setPersonData]=useState([])
    const [addIssue, setAddIssue] = useState([]);
    const [partSelect, setPartSel] = useState("");
    const [srSelect, setSRSel] = useState("");
    const [srAisleSelect, setSRAisleSel] = useState("");
    const [srRowSelect, setSRRowSel] = useState("");
    const [srBinSelect, setSRBinSel] = useState("");
  
  
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      getData()
    }, []);

    const getData=async()=>{
      setLoading(true);
      const issueLink=masterAPI["issue"].link
     await axiosPrivate.get(`${issueLink}`).then(res=>{
         setIssueList(res.data.result)
         const filterWO=res.data.result.filter(fil=>fil.workOrderId!==undefined&&fil.workOrderId!==null&&fil.workOrderId!=="")
             setIssueTypeData(filterWO)
         setLoading(false)
      }).catch(e=>{
         setLoading(false)
         setIssueTypeData([])
         console.log(e)
      })
 
      const personLink=masterAPI["person"].link
     await axiosPrivate.get(`${personLink}`).then(res=>{
       setPersonData(res.data.result)
      }).catch(e=>{
       setPersonData([])
       console.log(e,'error')
      })
     
   
    }
  
    const OPTIONS_LIMIT = 10;
    const defaultFilterOptions = createFilterOptions();
  
    const filterOptions = (options, state) => {
      return defaultFilterOptions(options, state);
    };
    const CustomPaper = (props) => {
      return <Paper elevation={8} {...props} />;
    };
  
    const initialValue = {
  
      issueId: null,
      // invUsageCode: "",
      returnedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      returnedById:null
    };
  
    const onSubmit = async(value) => {
      console.log(value);
      setLoading(true);
      const createLink = masterAPI["issueReturn"].create;
    //   const updateLink = masterAPI["issue"].update;
     
        const postData={
          ...value,
          issuedRetunItemVMList:addIssue
        }
        console.log(postData)
       await axiosPrivate
          .post(`${createLink}`, postData)
          .then((res) => {
            console.log(res.data);
            setLoading(false);
            handleCloseClick();
          })
          .catch((e) => {
            setLoading(false);
            console.log(e, "create IssueReturn");
          });
      }
    
  
    const handlePart=(value)=>{
      // setCauseSel(value.causeCode+"_"+value.causeName)
      // setPartInfo(value)
      setSRAisleSel(value.aisleName)
      setSRSel(value.stockRoomName)
      setSRRowSel(value.rowName)
      setSRBinSel(value.binName)
      setPartSel(value.code+" - "+value.partName)
      const itemData={
        ...issueItem,
        issueItemId:value.id,
        partId:value.partId,
        partCode:value.code,
        partName:value.partName,
        stockRoomId: value.stockRoomId,
        stockRoomRowId: value.stockRoomRowId,
        stockRoomRowAisleId: value.stockRoomRowAisleId,
        stockRoomRowBinId: value.stockRoomRowBinId,
        stockRoomName:value.stockRoomName,
        aisleName:value.aisleName,
        rowName:value.rowName,
        binName:value.binName,
        quantity: value.quantity,
        unitCost: value.unitCost,
        lineCost: value.lineCost,
      }
     setIssueItem(itemData)
     
      }
  
      const handleAddIssue=(e)=>{
        const {name,value,checked}=e.target
        if(name==="unitCost"){
          const storeRI={
            ...issueItem,
            [name]:value,
            lineCost:issueItem.quantity*value
         
          }
          setIssueItem(storeRI)
        }else{
        const storeRI={
          ...issueItem,
          [name]:value
       
        }
        setIssueItem(storeRI)
      }
       
      }
  
    const handleCancel = () => {
      handleCloseClick();
    };
  
    // const handleStockRoom=(value)=>{
    //   const getAisleLink=masterAPI["selectList"].aisleByPartAndRoom
    //   axiosPrivate.get(`${getAisleLink}/${issueItem.partId}/${value.value}`).then(res=>{
    //     const storeStockRoom={
    //       ...issueItem,
    //       stockRoomId:value.value,
     
    //       stockRoomName:value.text,
    //       stockAisle:res.data
    //     }
  
      
    //     setIssueItem(storeStockRoom)
    //   }).catch(e=>{
    //     console.log(e,"error")
    //   })
    // }
    // const handleStockAisle=(value)=>{
    //   const getRowLink=masterAPI["selectList"].rowByPartAndAisle
    //   axiosPrivate.get(`${getRowLink}/${issueItem.partId}/${value.value}`).then(res=>{
       
    //     const storeStockRoom={
    //       ...issueItem,
    //       stockRoomRowAisleId:value.value,
         
    //       aisleName:value.text,
    //       stockRow:res.data
    //     }
  
      
    //     setIssueItem(storeStockRoom)
    //   }).catch(e=>{
    //     console.log(e,"error")
    //   })
    // }
    // const handleStockRow=(value)=>{
    //   const getBinLink=masterAPI["selectList"].binByPartAndRow
    //   axiosPrivate.get(`${getBinLink}/${issueItem.partId}/${value.value}`).then(res=>{
    //     const storeStockRoom={
    //       ...issueItem,
    //       stockRoomRowId:value.value,
          
    //       rowName:value.text,
    //       stockBin:res.data
    //     }
  
      
    //     setIssueItem(storeStockRoom)
    //   }).catch(e=>{
    //     console.log(e,"error")
    //   })
    // }
    // const handleStockBin=(value)=>{
     
    //   const storeStockRoom={
    //     ...issueItem,
    //     stockRoomRowBinId:value.value,
        
    //     binName:value.text
        
    //   }
  
    
    //   setIssueItem(storeStockRoom)
     
    // }
  
    const handleIssueList=()=>{
      const addlist=[...addIssue,issueItem]
      console.log(addlist)
      setAddIssue(addlist)
      setIssueItem({
        partId: null,
        stockRoomId: null,
      stockRoomRowId: null,
      stockRoomRowAisleId: null,
      stockRoomRowBinId: null,
      quantity: 0,
      unitCost: 0,
      lineCost: 0,
      itemReturnedDate:moment().format("YYYY-MM-DDTHH:mm:ss")
      })
      setPartSel("")
      setSRAisleSel("")
      setSRBinSel("")
      setSRRowSel("")
      setSRSel("")
    }
   
    const handlePartEdit=(data)=>{
      const filterAddIssue=addIssue.filter((fil,i)=>i!==Number(data.row.id))
      setIssueItem({...data.row.original})
      setSRSel(data.row.original.stockRoomName)
      setPartSel(data.row.original.partCode+"-"+data.row.original.partName)
      setSRAisleSel(data.row.original.aisleName)
      setSRRowSel(data.row.original.rowName)
      setSRBinSel(data.row.original.binName)
      setAddIssue(filterAddIssue)
    }
   
  
    const partColumn = [
      {
        Header: "Part",
        accessor: "partCode",
        Cell: ({ cell }) => {
          return (
            <Typography
              sx={{
                color: "#0d6efd",
                fontSize: "inherit",
                fontWeight: 600,
                cursor: "pointer",
              }}
              // onClick={() => {
              //   handleCause(cell.row.original.id);
              // }}
            >
              {cell.value+" - "+cell.row.original.partName}
            </Typography>
          );
        },
        // disableFilters:true,
      },
    {
        Header: "Stock Name",
        accessor: "stockRoomName",
   
      },
      {
        Header: "Aisle",
        accessor: "aisleName",
      },
      {
        Header: "Row",
        accessor: "rowName",
      },
      {
        Header: "Bin",
        accessor: "binName",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        // disableFilters:true,
      }, 
      {
        Header: "Unit Cost",
        accessor: "unitCost",}, 
      {
        Header: "Line Cost",
        accessor: "lineCost",
   
      }, 
      {
        Header: "Item Returned Date",
        accessor: "itemReturnedDate",
  
      },
      
  
      {
        Header:"Action",
        accessor:"partId",
        Cell:({cell})=>{
          return (
            <>
           <IconButton >
              <EditOutlined
                onClick={() => {
                  handlePartEdit(cell);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton>
            <IconButton
            onClick={() => {
              deleteIssue(cell.row.original.partId);
            }}
            >
            <DeleteOutlined
             
              sx={{
                fontSize: color.masterEditDelButton,
                color: color.masterEditDelButtonColor,
              }}
            />
          </IconButton>
          </>
          )
        },
        disableFilters:true,
      }
    ];
  
    const deleteIssue=(id)=>{
      const delIssue=addIssue.filter(fil=>fil.partId!==id)
      setAddIssue(delIssue)
    }

    const handleReturnType=(e)=>{
        setReturnType(e.target.value)
        if(e.target.value==="asset"){
            const filterAsset=issueList.filter(fil=>fil.assetId!==undefined&&fil.assetId!==null&&fil.assetId!=="")
            setIssueTypeData(filterAsset)
        }else{
            const filterWO=issueList.filter(fil=>fil.workOrderId!==undefined&&fil.workOrderId!==null&&fil.workOrderId!=="")
            setIssueTypeData(filterWO)
        }
    }
   
  
  
  
    return (
      <Box sx={{ p: color.masterPageDialogPadding }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
            New Reutrn Issue
          </Typography>
          <IconButton onClick={handleCancel}>
            <Clear />
          </IconButton>
        </Box>
        <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
        <Formik initialValues={initialValue} onSubmit={onSubmit}>
          {({ handleChange, setFieldValue, values, resetForm }) => (
            <Form>
              <Grid container spacing={"1rem"}>
                <Grid item xs={12} md={6}>
                <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                  Return Type
                  </Typography>
                <Select fullWidth size="small" value={returnType} onChange={handleReturnType}>
                <MenuItem value="asset">Asset</MenuItem>
                <MenuItem value="wo">Work Order</MenuItem>
                </Select>
                </Grid> 
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                  {returnType==="asset"?"Asset":"Work Order"}
                  </Typography>
                  <Autocomplete
                  // value={remedySelect}
                  inputValue={issueSel}
                  //value={causeInfo.id===null?null:causeInfo}
                  fullWidth
                  filterOptions={filterOptions}
                  loading={issueTypeData.length===0}
                  options={issueTypeData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) =>{if(returnType==="wo"){
                   return option.workOrderCode + " - " + option.workOrderName || option
                  }else{
                    return  option.assetCode + " - " + option.assetName || option
                  }
                }
                   
                  }
                  freeSolo
                  onChange={async(event, value) => {
                    console.log(value)
                    if(value!==null){
                      // handleWo(value);
                      console.log(value)
                      setFieldValue("issueId",value.id)
                      const issueItemLink=masterAPI["issue"].issueList
                     await axiosPrivate.get(`${issueItemLink}/${value.id}`).then(res=>{
                        setPartData(res.data.result)
                      }).catch(e=>{
                        console.log(e,"error")
                      })
                    }else{
                      setIssueSel("")
                      setFieldValue("issueId",null)
                      setPartData([])
                      // setPartInfo({})
                    }
                   
                  }}
                  onInputChange={(event,newInputValue)=>{
                    console.log(newInputValue)
                    setIssueSel(newInputValue)
                    
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="issueId"
                      placeholder="Select Issue"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                 
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {issueTypeData.length===0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                </Grid>
              
                {/* <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    InvUsage Code
                  </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="invUsageCode"
                    placeholder="Enter InvUsage Code"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid> */}
               
                <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Returned By
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="returnedById"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.returnedById !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {personData.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.personName}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                   Transaction Date
                  </Typography>
                 
             
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                    // views={['year', 'day', 'hours', 'minutes', 'seconds']}
                    readOnly
                    format="DD/MM/YYYY hh:mm:ss A"
                      value={values.returnedDate===null?null:dayjs(values.returnedDate)}
                     
                     
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
  
                      slotProps={{
                        textField: {
                          size: "small",
                        
                          fullWidth: true,
                        },
                      }}
                      fullWidth
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                            // "YYYY-MM-DDTHH:mm:ss" date format
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                               "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                             setFieldValue("returnedDate",dateFormat)
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
                </Grid>
           
                <Grid item xs={12}>
                <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
            Create Return Issued Item
          </Typography>
          <IconButton
           sx={{backgroundColor:color.TableButtonColor}} 
          onClick={handleIssueList}
          >
            <Add sx={{color:"white"}}/>
          </IconButton>
          </Box>
          <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
                </Grid>
              
                <Grid container item xs={12} spacing={"1rem"} >
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                   Part
                  </Typography>
                  <Autocomplete
                  // value={remedySelect}
                  inputValue={partSelect}
                  //value={causeInfo.id===null?null:causeInfo}
                  fullWidth
                  filterOptions={filterOptions}
                  loading={partData.length===0}
                  options={partData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) =>
                    option.code + " - " + option.partName || option
                  }
                  freeSolo
                  onChange={(event, value) => {
                    console.log(value)
                    if(value!==null){
                      handlePart(value);
                    }else{
                      setPartSel("")
                      // setPartInfo({})
                    }
                   
                  }}
                  onInputChange={(event,newInputValue)=>{
                    console.log(newInputValue)
                    setPartSel(newInputValue)
                    
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="partId"
                      placeholder="Select Part"
                      //  onChange={handleChange}
                      variant="outlined"
                      size="small"
                 
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {partData.length===0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                  Stock Room
                  </Typography>
                 <TextField 
                 value={srSelect}
                 size={"small"} 
                 InputProps={{readOnly:true}}
                 fullWidth
                 />
  
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                  Aisle
                  </Typography>
                  <TextField 
                 value={srAisleSelect}
                 size={"small"} 
                 InputProps={{readOnly:true}}
                 fullWidth
                 />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                  Row
                  </Typography>
                  <TextField 
                 value={srRowSelect}
                 size={"small"} 
                 InputProps={{readOnly:true}}
                 fullWidth
                 />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                  Bin
                  </Typography>
                  <TextField 
                 value={srBinSelect}
                 size={"small"} 
                 InputProps={{readOnly:true}}
                 fullWidth
                 />
                </Grid>
  
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Quantity
                  </Typography>
                  <TextField
                    size={"small"}
                    type="number"
                    name="quantity"
                    value={issueItem.quantity}
                    placeholder="Enter Quantity"
                    fullWidth
                    onChange={handleAddIssue}
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Unit Cost
                  </Typography>
                  <TextField
                    size={"small"}
                    type="number"
                    name="unitCost"
                    value={issueItem.unitCost}
                    InputProps={{readOnly:true}}
                    placeholder="Enter Unit Cost"
                    fullWidth
                    onChange={handleAddIssue}
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                     Line Cost
                  </Typography>
                  <TextField
                    size={"small"}
                    type="number"
                    name="lineCost"
                    value={issueItem.lineCost}
                    InputProps={{readOnly:true}}
                    placeholder="Enter Line Cost"
                    fullWidth
                    onChange={handleAddIssue}
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
              
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                  Item Returned Date
                  </Typography>
                 
             
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                    // views={['year', 'day', 'hours', 'minutes', 'seconds']}
                   
                    format="DD/MM/YYYY"
                      value={issueItem.itemReturnedDate===null?null:dayjs(issueItem.itemReturnedDate)}
                    
                    
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
  
                      slotProps={{
                        textField: {
                          size: "small",
                        
                          fullWidth: true,
                        },
                      }}
                      fullWidth
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                            // "YYYY-MM-DDTHH:mm:ss" date format
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                               "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            const storeDate={
                              ...issueItem,
                              itemReturnedDate:dateFormat
                            }
                            setIssueItem(storeDate)
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
                </Grid>
             
                </Grid>
                <Grid item xs={12}>
                <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
            Issue Item List
          </Typography>
        
          </Box>
          <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
                </Grid>
                <Grid item xs={12}>
                <NormalTable
            data={addIssue}
            columns={partColumn}
            filt={true}
          />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"flex-end"}
                  gap="1rem"
                >
                  <Button
                    onClick={handleCancel}
                    variant="contained"
                    sx={{
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                      ":hover": {
                        backgroundColor: color.TableCancelButton,
                        textTransform: "none",
                        color: color.CancelTextColor,
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    variant="contained"
                    disabled={loading||addIssue.length===0}
                    sx={{
                      textTransform: "none",
                      backgroundColor: loading
                        ? "default"
                        : color.TableButtonColor,
                      "&:hover": {
                        backgroundColor: loading
                          ? "default"
                          : color.buttonDisable,
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };
  
  export default ReturnTransModal;
  