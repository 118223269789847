import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import { Box, Checkbox, Dialog, IconButton, Typography } from "@mui/material";
import { Link, useSearchParams,useNavigate } from "react-router-dom";

import { axiosPrivate } from "../../../axios/axios";
import {
    ArrowBack,
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import {useDispatch} from "react-redux"



import { masterAPI } from "../../Masters/dataConfig";
import { color } from "../../../StyleData/Config";

const GetInventory = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch=useDispatch()
  const navigate=useNavigate()

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["inventory"].search;
   await axiosPrivate
      .post(`${link}`,{partId:searchParams.get("id"),stockRoomId:searchParams.get("stockRoomId")})
      .then((res) => {
        console.log(res.data, "allinventory result");
      
        setData(res.data.result);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "allinven");
        setIsLoading(false);
        setData([])
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  const handleDelete = async(id) => {
    const deleteLink = masterAPI["stockRoom"].delete;
   await axiosPrivate
      .delete(`${deleteLink}/${id}`)
      .then((res) => {
        console.log(res.data);
        getData();
      })
      .catch((e) => {
        console.log(e, "delete stockRoom");
      });
  };

  const columns = [
    // {
    //   accessor: "id",
    //   disableFilters: true,
    //   Cell: () => {
    //     return (
    //       <Box sx={{width:color.checkBoxWidth }}>
    //       <Checkbox
    //         icon={<RadioButtonUncheckedOutlined />}
    //         checkedIcon={<CheckCircle />}
    //       />
    //       </Box>
    //     );
    //   },
    // },
    // {
    //   Header: "Stock Room Code",
    //   accessor: "stockRoomCode",
    //   Cell: ({ cell }) => {
    //     return (
    //       <Link
    //         to={`/stockroomaisle?id=${cell.row.original.id}`}
    //         onClick={()=>{dispatch({type:"stockRoom",name:"stockRoom",payload:cell.row.original.id})}}
    //         style={{
    //           color: "#0d6efd",
    //           textDecorationLine: "underline",
    //           fontWeight: 800,
    //         }}
    //       >
    //         {cell.value}
    //       </Link>
    //     );
    //   },
    //   // disableFilters: true,
    // },
    {
        Header: "Part",
        accessor: "partName",
        Cell:({cell})=>{
          return (
              <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>{cell.row.original.code+" - "+cell.value}</Typography>
          )
        }
        // disableFilters: true,
      },
    {
      Header: "Stock Room Name",
      accessor: "stockRoomName",
      Cell:({cell})=>{
        return (
            <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>{cell.row.original.stockRoomCode+" - "+cell.value}</Typography>
        )
      }
      // disableFilters: true,
    },
    {
        Header: "Aisle",
        accessor: "aisleName",
        Cell:({cell})=>{
          return (
              <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>{cell.row.original.aisleCode+" - "+cell.value}</Typography>
          )
        }
        // disableFilters: true,
      },
      {
        Header: "Row",
        accessor: "rowName",
        Cell:({cell})=>{
          return (
              <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>{cell.row.original.rowCode+" - "+cell.value}</Typography>
          )
        }
        // disableFilters: true,
      },
      {
        Header: "Bin",
        accessor: "binName",
        Cell:({cell})=>{
          return (
              <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>{cell.row.original.binCode+" - "+cell.value}</Typography>
          )
        }
        // disableFilters: true,
      },
      {
        Header: "Qty On Hand In Room",
        accessor: "quantityOnHandInRoom",
        // Cell:({cell})=>{
        //   return (
        //       <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>{cell.row.original.stockRoomCode+"_"+cell.value}</Typography>
        //   )
        // }
        // disableFilters: true,
      },
      {
        Header: "Qty On Hand In Bin",
        accessor: "quantityOnHandInBin",
      },
      {
        Header: "Economic ReOrder Qty",
        accessor: "economicReOrderQuantity",
      
        // disableFilters: true,
      },
      {
        Header: "Average Price",
        accessor: "averagePrice",
      
        // disableFilters: true,
      },
      {
        Header: "Reorder Point",
        accessor: "reOrderPoint",
      
        // disableFilters: true,
      },
      // {
      //   Header: "Max Qty",
      //   accessor: "maxQuantity",
      
      //   // disableFilters: true,
      // },
      
    // // {
    // //   Header: "Aisle",
    // //   accessor: "aisle",
    // //   // disableFilters: true,
    // // },
    // // {
    // //   Header: "Row Name",
    // //   accessor: "rowName",
    // //   // disableFilters: true,
    // // },
    // // {
    // //   Header: "Bin No",
    // //   accessor: "binNo",
    // //   // disableFilters: true,
    // // },
    // // {
    // //   Header: "Quantity On Hand",
    // //   accessor: "quantityOnHand",
    // //   // disableFilters: true,
    // // },
    
    // {
    //   Header: "Actions",
    //   Cell: ({ cell }) => {
    //     // console.log(cell)
    //     return (
    //       <Box sx={{ whiteSpace: "nowrap" }}>
    //         <IconButton
    //          onClick={() => {
    //           handleEdit(cell.row.original);
    //         }}
    //         >
    //           <EditOutlined
               
    //             sx={{
    //               fontSize: color.masterEditDelButton,
    //               color: color.masterEditDelButtonColor,
    //             }}
    //           />
    //         </IconButton>
    //         <IconButton
    //          onClick={() => {
    //           handleDelete(cell.row.original.id);
    //         }}
    //         >
    //           <DeleteOutline
               
    //             sx={{
    //               fontSize: color.masterEditDelButton,
    //               color: color.masterEditDelButtonColor,
    //             }}
    //           />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Box sx={{display:"flex",alignItems:"center"}}>
        <IconButton onClick={()=>{navigate("/inventory/inventorycreation")}}>
          <ArrowBack/>
        </IconButton>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            // mb: color.masterPageTitlemd,
          }}
        >
          {"Inventory"}
        </Typography>
      </Box>
     
        <TableContainer
          columns={columns}
          data={data}
          buttonNew={handleButtonNew}
          loading={isLoading}
          buttonDisable={true}
        />
   

      {/* <Dialog
        open={create}
        onClose={handleCreateClose}
        children={
          <StockRoomModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      /> */}
    </>
  );
};

export default GetInventory;
