import { Box, Dialog, Divider, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NormalTable from "../../../../Component/NormalTable";
import { axiosPrivate } from "../../../../axios/axios";

import QuestionList from "./QuestionList";
import { masterAPI } from "../../../Masters/dataConfig";
import { Link } from "react-router-dom";

const AssetList = ({ woData }) => {
  const [dataList, setDataList] = useState([]);
  const [assetInfo,setAssetInfo]=useState({})
  const [viewQuestion,setViewQuestion]=useState(false)

  useEffect(() => {
getData()
  }, []);

  const getData=async()=>{
    const arLink=masterAPI["assetRoute"].getByRouteId

  await axiosPrivate.get(`${arLink}?routeId=${woData.routeId}`).then(res=>{
    console.log(res.data.result)
    setDataList(res.data.result)
}).catch(e=>{
  setDataList([])
    console.log(e,"error")
})
  }

  // const handleEdit=(value)=>{
  // setEditLabour(true)
  // setEditData(value)
  // }

  const handleClose=()=>{
    setViewQuestion(false)
    setAssetInfo({})
  }

  const onHandleQuestion=(assetData)=>{
    setViewQuestion(true)
    setAssetInfo(assetData)
  }

  const handleDelete=async(id)=>{
    const labourDelete=masterAPI["workOrderTaskLabour"].delete
   await axiosPrivate.delete(`${labourDelete}/${id}`).then(res=>{
     console.log(res,"labour success")
     getData()
    }).catch(e=>{
      console.log(e,"error labour delete")
    })
  }

  const columns = [
    {
      Header: "Asset Code",
      accessor: "assetCode",
      Cell: ({ cell }) => {
        return (
          <Link
            // to={`/inspectionview?id=${cell.row.original.id}`}
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
            onClick={()=>{onHandleQuestion(cell.row.original)}}
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: "Asset Name",
      accessor: "assetName",
    },
    {
      Header: "Asset Type",
      accessor: "assetTypeName",
    },
    // {
    //     Header: "Business",
    //     accessor: "businessName",
    //   },
    // {
    //   Header: "Planned Hours",
    //   accessor: "plannedHours",
    // },
    // {
    //   Header: "Planned Start",
    //   accessor: "plannedStart",
    // },
    // {
    //     Header: "Actions",
    //     Cell: ({ cell }) => {
    //       // console.log(cell)
    //       return (
    //         <Box sx={{ whiteSpace: "nowrap" }}>
    //           <IconButton>
    //             <EditOutlined
    //               onClick={() => {
    //                 handleEdit(cell.row.original);
    //               }}
    //               sx={{
    //                 fontSize: color.masterEditDelButton,
    //                 color: color.masterEditDelButtonColor,
    //               }}
    //             />
    //           </IconButton>
    //           <IconButton>
    //                       <DeleteOutline
    //                         onClick={() => {
    //                           handleDelete(cell.row.original.id);
    //                         }}
    //                         sx={{
    //                           fontSize: color.masterEditDelButton,
    //                           color: color.masterEditDelButtonColor,
    //                         }}
    //                       />
    //                     </IconButton>
    //         </Box>
    //       );
    //     },
    //   },
  ];
  return (
    <Box
      sx={{
        boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
        p: 1.5,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {/* <Box>
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
            fontWeight: 400,
          }}
        >
          Details
        </Typography>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)" }} /> */}
      <Box>
        <NormalTable data={dataList} columns={columns} />
      </Box>
      <Dialog
            open={viewQuestion}
            onClose={handleClose}
            fullWidth
            maxWidth={"md"}
            children={
              <QuestionList
                handleCloseClick={handleClose}
                assetInfo={assetInfo}
               
              />
            }
          />
    </Box>
  );
};

export default AssetList;
 
