import React from "react";
import Chart from "react-apexcharts";

const PMComplChart = ({ dashBoardDataComplaianceXaxis, dashBoardDataComplaianceYaxis, type }) => {
  return (
    <Chart
                height={type?300:200}
                width={"100%"}
                options={{
                  responsive: [
                    {
                      breakpoint: 768, // Change the breakpoint as needed
                      options: {
                        chart: {
                          width: 600, // Change the height as needed
                        },
                      },
                    },
                  ],
                  series: dashBoardDataComplaianceXaxis,
                  labels: dashBoardDataComplaianceYaxis,
                  title: {
                    text: "PM Complaince",
                    style: {
                      fontFamily: "Nunito Sans, sans-serif",
                      color: "#2362B4",
                    },
                  },
                  colors: [
                    "#1395CF",
                    "#ff5c75",
                    "#BCDBAF",
                    "#BECB23",
                    "#E0DD95",
                    "#FED7A6",
                    "#F15325",
                    "#F9ADA8",
                    "#A71D48",
                    "#CBAFD5",
                    "#453191",
                    "#B4BFDD",
                    "#3D5EAC",
                    "#62B146",
                    "#D0DD37",
                    "#F3EC3A",
                    "#F9BD19",
                    "#F99B1E",
                    "#F15523",
                    "#7C3697",
                  ],

                  chart: {
                    type: "pie",
                    toolbar: {
                      show: true,
                      export: {
                        csv: {
                          filename: "Complain",
                        },
                        svg: {
                          filename: "Complain",
                        },
                        png: {
                          filename: "Complain",
                        },
                      },
                    },
                  },
                  plotOptions: {
                    pie: {
                      borderRadius: 4,
                      horizontal: true,
                      dataLabels: {
                        total: {
                          enabled: false,
                          offsetX: 0,
                          style: {
                            fontSize: "13px",
                            fontWeight: 900,
                            fontFamily: "Nunito Sans, sans-serif",
                          },
                        },
                      },
                    },
                    noData: {
                      text: "No Result",
                      style: {
                        fontSize: "1.5rem",
                        color: "grey",
                        fontFamily: "Nunito Sans, sans-serif",
                      },
                    },

                    stroke: {
                      width: 1,
                      colors: ["#fff"],
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  yaxis: {
                    title: {
                      text: "",
                    },
                  },
                  xaxis: {
                    title: {
                      text: "",
                    },
                    // labels: dashBoardDataComplaianceYaxis,
                    // labels: {
                    //   style: {
                    //     fontFamily: "Nunito Sans, sans-serif",
                    //   },
                    // },
                  },
                }}
                type="pie"
                series={dashBoardDataComplaianceXaxis}
              />
  );
};

export default PMComplChart;
