import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import {
  Box,
  Checkbox,
  Dialog,
  IconButton,
  Typography,
  Paper,
  Grid,
  Button,
  Select,
  MenuItem,
  Switch,
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { masterAPI } from "../dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import RoleMenuModal from "./RoleMenuModal";
import NormalTable from "../../../Component/NormalTable";

const RoleMenu = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [menusId, setMenusId] = useState([]);
  const [backupMenu, setBackUpMenu] = useState([]);
  const [rolesId, setRolesId] = useState([]);
  const [alreadyHaveMenu,setAlreadyHaveMenu]=useState([])
  const [sel, setSel] = useState({
    autoRole: "",
    selectedRole: "",
    selectmenuId: [],
    selectedDetails: [],
    roleDetail:null
  });

  useEffect(() => {
    getData();
  }, []);

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const getData = async() => {
    setLoading(true);

    const link = masterAPI["menus"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setLoading(false);
        console.log(res.data.result)
        setMenusId(res.data.result);
        setBackUpMenu(res.data.result);
      })
      .catch((e) => {
        setMenusId([])
        setBackUpMenu([])
        console.log(e, "createroleMenugetAll");
      });
    const roleLink = masterAPI["roles"].link;
   await axiosPrivate
      .get(`${roleLink}`)
      .then((res) => {
        setLoading(false);
        setRolesId(res.data.result);
      })
      .catch((e) => {
        setRolesId([])
        console.log(e, "createrolesgetAll");
      });
  };
  const handleSwitch = (e, menuValue, name) => {
    console.log(e.target.checked, menuValue);
    const storeAction = [];
    for (let i = 0; menusId.length > i; i++) {
      if (menusId[i].id === menuValue.id) {
        storeAction.push({ ...menusId[i], [name]: e.target.checked });
      } else {
        storeAction.push(menusId[i]);
      }
    }
    setMenusId(storeAction);
  };
  // const updateMyData = (rowIndex, columnId, value) => {
  //   setMenusId(
  //     menusId.map((row, index) => {
  //       if (index === rowIndex) {
  //         return { ...row, [columnId]: value };
  //       }
  //       return row;
  //     })
  //   );
  // };
  const handleCheck = (e, menuValue) => {
    if (e.target.checked) {
      setSel({
        ...sel,
        selectmenuId: [...sel.selectmenuId, menuValue.id],
        selectedDetails: [
          ...sel.selectedDetails,
          {
            menuId: menuValue.id,
            roleId: sel.selectedRole,
            view: menuValue.view !== undefined ? menuValue.view : false,
            create: menuValue.create !== undefined ? menuValue.create : false,
            edit: menuValue.edit !== undefined ? menuValue.edit : false,
            delete: menuValue.delete !== undefined ? menuValue.delete : false,
          },
        ],
      });
    } else {
      const filselectId = sel.selectmenuId.filter(
        (fil) => fil !== menuValue.id
      );
      const filselectDetails = sel.selectedDetails.filter(
        (fil) => fil.menuId !== menuValue.id
      );
      setSel({
        ...sel,
        selectmenuId: filselectId,
        selectedDetails: filselectDetails,
      });
    }
  };

  const columns = [
    {
      accessor: "id",
      disableFilters: true,
      Cell: ({ cell }) => {
        return (
          <Box sx={{ width: color.checkBoxWidth }}>
            <Checkbox
              checked={sel.selectmenuId.includes(cell.value)||alreadyHaveMenu.includes(cell.value)}
              disabled={sel.selectedRole===""||alreadyHaveMenu.includes(cell.value)}
              onChange={(e) => {
                handleCheck(e, cell.row.original);
              }}
           
            />
          </Box>
        );
      },
    },

    {
      Header: "Menu Name",
      accessor: "name",
    },
    {
      Header: "View",
      accessor: "view",
      Cell: ({ cell,row }) => {
        // console.log(cell)
        return (
          <Switch
            sx={{ ml: -2 }}
            checked={cell.value !== undefined && cell.value}
            onChange={(e) => {
              handleSwitch(e, cell.row.original, "view");
            }}
          />
        );
      },
    },
    {
      Header: "Create",
      accessor: "create",
      Cell: ({ cell, row }) => {
        // console.log(cell.value)
        return (
          <Switch
            sx={{ ml: -2 }}
            checked={cell.value !== undefined && cell.value}
            onChange={(e) => {
              handleSwitch(e, cell.row.original, "create");
            }}
          />
        );
      },
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Switch
            sx={{ ml: -2 }}
            checked={cell.value !== undefined && cell.value}
            onChange={(e) => {
              handleSwitch(e, cell.row.original, "edit");
            }}
          />
        );
      },
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Switch
            sx={{ ml: -2 }}
            checked={cell.value !== undefined && cell.value}
            onChange={(e) => {
              handleSwitch(e, cell.row.original, "delete");
            }}
          />
        );
      },
    },
  ];

  const handleSubmit = async() => {
    console.log(sel.selectedDetails);
    const createLink = masterAPI["roleMenu"].bulkinsert;
   await axiosPrivate
      .post(`${createLink}`, sel.selectedDetails)
      .then((res) => {
        console.log(res);
        handleCancel();
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const handleCancel = () => {
    setSel({
      autoRole: "",
      selectedRole: "",
      selectmenuId: [],
      selectedDetails: [],
      roleDetail:null
    });
    setMenusId(backupMenu);
    setAlreadyHaveMenu([])
  };
  const handleRoleMenu=async(roleValue)=>{
    console.log(roleValue.id)
    const getbyRoleId=masterAPI["roleMenu"].getbyRoleId
    await axiosPrivate.get(`${getbyRoleId}/${roleValue.id}`).then(res=>{
      
      const menubackup=[...backupMenu]
      const haveMenu=[]
      for(let i=0;res.data.result.length>i;i++){
         for(let j=0;menubackup.length>j;j++){
          if(menubackup[j].id===res.data.result[i].menuId){
            haveMenu.push(menubackup[j].id)
            menubackup[j]=
              {
                ...menubackup[j],
                create:res.data.result[i].create,
                edit:res.data.result[i].edit,
                view:res.data.result[i].view,
                delete:res.data.result[i].delete
              }
          }
         }
      }
      console.log(menubackup)
     setAlreadyHaveMenu(haveMenu)
     setMenusId(menubackup)
     setSel({ ...sel, selectedRole: roleValue.id,roleDetail:roleValue });
    }).catch(e=>{
      setAlreadyHaveMenu([])
      console.log(backupMenu)
      setMenusId(backupMenu)
      console.log(e)
    })
  }

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["roleMenu"].title}
        </Typography>
      </Box>
    
      <Paper sx={{ p: 1 }}>
        <Grid container spacing={"1rem"}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
              Role
            </Typography>
            {/* <Select
                // label={"Select"}
                size={"small"}
                type="text"
                name="roleId"
                value={sel.selectedRole}
                fullWidth
                displayEmpty
                onChange={(e)=>{
                  setSel({...sel,selectedRole:e.target.value})
                }}
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={sel.selectedRole !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {rolesId.map((id, i) => (
                  <MenuItem value={id.id}>{id.name}</MenuItem>
                ))}
              </Select> */}
            <Autocomplete
              // value={remedySelect}
              inputValue={sel.autoRole}
              value={sel.roleDetail}
              fullWidth
              filterOptions={filterOptions}
              loading={rolesId.length === 0}
              options={rolesId}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.name}
              freeSolo
              onChange={(event, value) => {
                console.log(value);
                if (value !== null) {
                 
                  handleRoleMenu(value)
                } else {
                  setSel({ ...sel, selectedRole: "", autoRole: "",roleDetail:null });
                }
              }}
              onInputChange={(event, newInputValue) => {
                setSel({ ...sel, autoRole: newInputValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Role"
                  //  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                        {rolesId.length === 0 ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <NormalTable data={menusId} columns={columns} />
          </Grid>
          <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
            <Button
              onClick={handleCancel}
              variant="contained"
              sx={{
                backgroundColor: color.TableCancelButton,
                textTransform: "none",
                color: color.CancelTextColor,
                ":hover": {
                  backgroundColor: color.TableCancelButton,
                  textTransform: "none",
                  color: color.CancelTextColor,
                },
              }}
            >
              Clear
            </Button>
            <Button
              type={"submit"}
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
              sx={{
                textTransform: "none",
                backgroundColor: loading ? "default" : color.TableButtonColor,
                "&:hover": {
                  backgroundColor: loading ? "default" : color.buttonDisable,
                },
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default RoleMenu;
