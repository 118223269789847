import React, { useEffect, useState } from 'react'
import { color } from '../../../StyleData/Config'
import { Grid, Typography,Paper,Select,MenuItem,TextField,Box,Divider, IconButton, Tab, Autocomplete, CircularProgress, createFilterOptions, Button, } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { axiosPrivate, siteInfo } from '../../../axios/axios'
import { masterAPI } from '../../Masters/dataConfig'
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, renderTimeViewClock,DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import { Add, PlaylistAdd } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const CreateGeneral = ({jobPlanIden}) => {
 
  
  const navigate=useNavigate()

  const editData=useSelector(state=>state.editData.workOrderEditData.data)
  const edit=useSelector(state=>state.editData.workOrderEditData.edit)
  const siteInfoDetail=useSelector(state=>state.auth.loginInfo)
  

    const [locationId,setLocationId]=useState([])
    const [assetId,setAssetId]=useState([])
    const [JPStatus,setJPStatus]=useState([])
    const [priorityJob,setPriorityJob]=useState([])
    const [jobPlan,setJobPlan]=useState([])
    const [requestByUser,setRequestByUser]=useState([])
    const [personData,setPersonData]=useState([])
    const [businessData,setBusinessData]=useState([])
    const [value,setValue]=useState("1")
    const [failureClass,setFailureClass]=useState([])
    const [priority,setPriority]=useState([])
    // const [site,setSite]=useState([])
    const [workType,setWorkType]=useState([])
    const [probleCode,setProblemCode]=useState([])
    const [loading,setLoading]=useState(false)
    const [auto,setAuto]=useState({
      asset:edit?editData.assetCode+" - "+editData.assetName:"",
      location:edit?editData.locationCode+" - "+editData.locationName:"",
      workResponsible:edit?editData.responsiblePersonName:""
    })
    const [assetInfo,setAssetInfo]=useState({})

    useEffect(()=>{
     getDataList()
        },[])

        const getDataList=async()=>{
          setLoading(true)
          getData()
            const locationLink=masterAPI["location"].link
           await axiosPrivate.get(`${locationLink}`).then(res=>{
                console.log(res.data.result)
                setLocationId(res.data.result)
            }).catch(e=>{
                setLocationId([])
                console.log(e,"createwo locaion getall")
            })
    
          //   const siteLink=masterAPI["site"].link
          //  await axiosPrivate.get(`${siteLink}`).then(res=>{
          //     console.log(res.data.result)
          //     setSite(res.data.result)
          // }).catch(e=>{
          //     setSite([])
          //     console.log(e,"createwo locaion getall")
          // })
          
            const jobPlanStatusLink=masterAPI["selectList"].woStatus
           await axiosPrivate.get(`${jobPlanStatusLink}`).then(res=>{
                console.log(res.data)
                setJPStatus(res.data)
            }).catch(e=>{
                setJPStatus([])
                console.log(e,"getJOBPlanStatus Create Wo")
            })
            const jobPriorityLink=masterAPI["selectList"].jobPriority
           await axiosPrivate.get(`${jobPriorityLink}`).then(res=>{
                console.log(res.data)
                setPriorityJob(res.data)
            }).catch(e=>{
                setPriorityJob([])
                console.log(e,"getPriority Create Wo")
            })
            const jobplanLink=masterAPI["jobPlan"].link
           await axiosPrivate.get(`${jobplanLink}`).then(res=>{
              console.log(res.data.result)
              setJobPlan(res.data.result)
            }).catch(e=>{
              setJobPlan([])
              console.log(e,"getJobPlan Create Wo")
            })
            //////////////User getUserBysite/////////////////////
            const getUserLink=masterAPI["user"].getUserBySite
           await axiosPrivate.get(`${getUserLink}/2b143042-85e1-4edb-be93-ddfc87905275`).then(res=>{
        console.log(res.data.result)
              setRequestByUser(res.data.result)
            }).catch(e=>{
              setRequestByUser([])
              console.log(e,"requesByUser Create Wo")
            })
    
             ////////////// labour /////////////////////
             const labourLink=masterAPI["labour"].link
            await axiosPrivate.get(`${labourLink}`).then(res=>{
         console.log(res.data.result)
               setPersonData(res.data.result)
             }).catch(e=>{
               setPersonData([])
               console.log(e,"labour Create Wo")
             })
    
             ////////////// Business /////////////////////
             const businessLink=masterAPI["business"].link
            await axiosPrivate.get(`${businessLink}`).then(res=>{
         console.log(res.data.result)
               setBusinessData(res.data.result)
             }).catch(e=>{
              setBusinessData([])
               console.log(e,"business Create Wo")
             })
    /////////////failureClass///////////////////////////
    
    const fcLink=masterAPI["failureClass"].link
   await axiosPrivate.get(`${fcLink}`).then(res=>{
    console.log(res.data.result)
      setFailureClass(res.data.result)
    }).catch(e=>{
      setFailureClass([])
      console.log(e,"failureClass Create Wo")
    })
    if(edit){
    /////////////////////////problem Code//////////////////
    const pcLink=masterAPI["problems"].problembyFailureClass
   await axiosPrivate.get(`${pcLink}/${editData.failureClassId}`).then(res=>{
    console.log(res.data.result)
      setProblemCode(res.data.result)
    }).catch(e=>{
      setProblemCode([])
      console.log(e,"Problem Create Wo")
    })
    }
    //////////////////priority////////////////////////////////////////
    const priLink=masterAPI["priority"].link
   await axiosPrivate.get(`${priLink}`).then(res=>{
    console.log(res.data.result)
      setPriority(res.data.result)
    }).catch(e=>{
      setPriority([])
      console.log(e,"failureClass Create Wo")
    })
    ////////////////workordertype/////////////////
    const wotypeLink=masterAPI["selectList"].workordertype
   await axiosPrivate.get(`${wotypeLink}`).then(res=>{
    console.log(res.data.result)
      setWorkType(res.data)
    }).catch(e=>{
      setWorkType([])
      console.log(e,"work type Create Wo")
    })
    setLoading(false)
    
        }

        const getData=async()=>{
            
        const assetLink=masterAPI["assets"].link
       await axiosPrivate.get(`${assetLink}`).then(res=>{
            console.log(res.data.result)
            setAssetId(res.data.result)
        }).catch(e=>{
          setAssetId([])
            console.log(e,"createwo asset getall")
        })
        }

        const handleCancel=()=>{
          navigate("/workOrder")
        }

    const initialValue={
        description:editData.description!==""&&editData.description!==undefined&&editData.description!==null?editData.description:"",
        workOrderName:editData.workOrderName!==""&&editData.workOrderName!==undefined&&editData.workOrderName!==null?editData.workOrderName:"",
        locationId:editData.locationId!==""&&editData.locationId!==undefined&&editData.locationId!==null?editData.locationId:null,
        assetId:editData.assetId!==""&&editData.assetId!==undefined&&editData.assetId!==null?editData.assetId:null,
        status:editData.status!==""&&editData.status!==undefined&&editData.status!==null?editData.status:"NEW",
        failureClassId:editData.failureClassId!==""&&editData.failureClassId!==undefined&&editData.failureClassId!==null?editData.failureClassId:null,
        priorityId:editData.priorityId!==""&&editData.priorityId!==undefined&&editData.priorityId!==null?editData.priorityId:null,
        problemId:editData.problemId!==""&&editData.problemId!==undefined&&editData.problemId!==null?editData.problemId:null,
        siteId:siteInfoDetail.siteId,
        glAccount:editData.glAccount!==""&&editData.glAccount!==undefined&&editData.glAccount!==null?editData.glAccount:"",
        jobPlanId:editData.jobPlanId!==""&&editData.jobPlanId!==undefined&&editData.jobPlanId!==null?editData.jobPlanId:null,
        actualStartDate:editData.actualStartDate!==""&&editData.actualStartDate!==undefined&&editData.actualStartDate!==null?editData.actualStartDate:null,
        actualEndDate:editData.actualEndDate!==""&&editData.actualEndDate!==undefined&&editData.actualEndDate!==null?editData.actualEndDate:null,
        targetStartDate:editData.targetStartDate!==""&&editData.targetStartDate!==undefined&&editData.targetStartDate!==null?editData.targetStartDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        targetEndDate:editData.targetEndDate!==""&&editData.targetEndDate!==undefined&&editData.targetEndDate!==null?editData.targetEndDate:  moment().add(1, 'days').format("YYYY-MM-DDTHH:mm:ss"),
        plannedStartDate:editData.plannedStartDate!==""&&editData.plannedStartDate!==undefined&&editData.plannedStartDate!==null?editData.plannedStartDate:null,
        plannedEndDate:editData.plannedEndDate!==""&&editData.plannedEndDate!==undefined&&editData.plannedEndDate!==null?editData.plannedEndDate:null,
        //stopBeginDate:editData.stopBeginDate!==""&&editData.stopBeginDate!==undefined&&editData.stopBeginDate!==null?editData.stopBeginDate:null,
        duration:editData.duration!==""&&editData.duration!==undefined&&editData.duration!==null?editData.duration:"",
        earliestStartDate:editData.earliestStartDate!==""&&editData.earliestStartDate!==undefined&&editData.earliestStartDate!==null?editData.earliestStartDate:null,
        latestEndDate:editData.latestEndDate!==""&&editData.latestEndDate!==undefined&&editData.latestEndDate!==null?editData.latestEndDate:null,
        createdById:editData.createdById!==""&&editData.createdById!==undefined&&editData.createdById!==null?editData.createdById:null,
        createdDate:editData.createdDate!==""&&editData.createdDate!==undefined&&editData.createdDate!==null?editData.createdDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        responsiblePersonId:editData.responsiblePersonId!==""&&editData.responsiblePersonId!==undefined&&editData.responsiblePersonId!==null?editData.responsiblePersonId:null,
        jobPlanName:editData.jobPlanName!==""&&editData.jobPlanName!==undefined&&editData.jobPlanName!==null?editData.jobPlanName:"",
        workTypeId:editData.workTypeId!==""&&editData.workTypeId!==undefined&&editData.workTypeId!==null?editData.workTypeId:"",
       
       }
     
       const onSubmit=async(values)=>{
         console.log(values)
         setLoading(true)
        // jobPlanIden(values.jobPlanId,"fcc97ac9-741a-459c-b3d8-9afb210f9d69")
         const createWO=masterAPI["workOrder"].create
         const editWO=masterAPI["workOrder"].update
         if(edit){
          const putData={
            id:editData.id,
            ...values
          }
          console.log(putData)
         await axiosPrivate.put(`${editWO}`,putData).then(res=>{
            console.log(res)
            // navigate(`/workorderview?id=${res.data.id}`)
            navigate(`/workOrder`)
            setLoading(false)
            // console.log(values.jobPlanId)
            // jobPlanIden(values.jobPlanId,"fcc97ac9-741a-459c-b3d8-9afb210f9d69")
  
           }).catch(e=>{
            setLoading(false)
            console.log(e,"create wo")
           })
         }else{
          const splitData=auto.asset.split("-")
          const postSysPro={
            JobDescription: values.description,
            AssetCode: splitData[0]+"-"+splitData[1],
            AssetName: splitData[2],
            CreateDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
            TargetStartDate: values.targetStartDate,
            TargetEndDate: values.targetEndDate
            }

            console.log(splitData,postSysPro)
         await axiosPrivate.post('https://connector.orienseam.com/api/SysProXml/PostWorkOrder',postSysPro).then(async(res)=>{
            const postData={
              ...values,
              notes:res.data
            }
            
           
          await axiosPrivate.post(`${createWO}`,postData).then(res=>{
            console.log(res)
            navigate(`/workorderview?id=${res.data.id}`)
            setLoading(false)
            // console.log(values.jobPlanId)
            // jobPlanIden(values.jobPlanId,"fcc97ac9-741a-459c-b3d8-9afb210f9d69")
  
           }).catch(e=>{
            setLoading(false)
            console.log(e,"create wo")
           })
          }).catch(e=>{
            console.log("syspro error",e)
          })
        
        //   const postData={
        //     ...values,
        //     notes:"test"
        //   }
         
        //  axiosPrivate.post(`${createWO}`,postData).then(res=>{
        //   console.log(res)
        //   navigate(`/workorderview?id=${res.data.id}`)
        //   setLoading(false)
        //   // console.log(values.jobPlanId)
        //   // jobPlanIden(values.jobPlanId,"fcc97ac9-741a-459c-b3d8-9afb210f9d69")

        //  }).catch(e=>{
        //   setLoading(false)
        //   console.log(e,"create wo")
        //  })
        }
        
       }
       const OPTIONS_LIMIT = 10;
       const defaultFilterOptions = createFilterOptions();
     
       const filterOptions = (options, state) => {
         return defaultFilterOptions(options, state);
       };
       const CustomPaper = (props) => {
         return <Paper elevation={8} {...props} />;
       };

       const handleFailureProblem=async(fcid)=>{
        const pcLink=masterAPI["problems"].problembyFailureClass
       await axiosPrivate.get(`${pcLink}/${fcid}`).then(res=>{  
        console.log(res.data.result)
          setProblemCode(res.data.result)
        }).catch(e=>{
          setProblemCode([])
          console.log(e,"Problem Create Wo")
        })
       }
  return (
    <Box sx={{p:1}}>
    <Box
        // sx={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "space-between",
        //   width: "100%",
        // }}
        sx={{mb:"1rem"}}
        >
        <Typography
          sx={{
            // color: color.masterDialogTitleColor,
            // fontSize: color.masterDialogFontSize,
            fontSize: color.AssetTitlesize,
            fontWeight: color.sideFontWeightAsset,
          }}
        >
          {edit?editData.code+"-"+editData.workOrderName:" New Work Order"}
         
        </Typography>
      </Box>
    <Formik initialValues={initialValue} onSubmit={onSubmit}>
    {({ handleChange, setFieldValue, values, resetForm }) => (
      <Form>
    <TabContext value={value}>
              <Box
                sx={{
                  ".css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                    color: color.TabLabelcolor,
                    // p: 0.5,
                  },
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                }}
              >
                <TabList
                  // onChange={handleChange}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{
                    sx: { backgroundColor: color.TabLabelcolor, height: "3px" },
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    label="General"
                    value="1"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    label="Work Plan"
                    value="2"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab label="Work Assignment" value="3" sx={{ textTransform: "none" }} />
               
               
                   <Tab
                    label="Completion"
                    value="4"
                    sx={{ textTransform: "none" }}
                  />
                   <Tab
                    label="Costing"
                    value="5"
                    sx={{ textTransform: "none" }}
                  />
                  
                
                </TabList>
                
        {/* <IconButton type='submit' sx={{backgroundColor:color.TableButtonColor}}>
          <PlaylistAdd sx={{color:"white",fontSize:"1.7rem"}}/>
        </IconButton> */}
       
              </Box>
              <Box sx={{mt:"1rem"}}>
              <TabPanel value="1" sx={{ p: 1 }}>
               {/* <CreateGeneral jobPlanIden={handleJobPlanId}/> */}
    
      
      <Grid container gap={"1.5rem"}>
    
        <Grid item xs={5.5} sx={{boxShadow:"-1px 1px 7px 2px rgb(0 0 0 / 32%)",p:1.5,backgroundColor:"white"}}  >
    
        <Box>
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        > Basic </Typography>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        </Box>
    <Grid container spacing={"1rem"}>
           <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Work Order Name
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="workOrderName"
                variant="standard"
                placeholder="Enter Work Order Name"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Description
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="description"
                variant="standard"
                placeholder="Enter Description"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Location
                </Typography>
              {/* <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="locationId"
                variant="standard"
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.locationId !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {locationId.map((id, i) => (
                  <MenuItem value={id.id}>{id.locationCode+"-"+id.locationName}</MenuItem>
                ))}
              </Field> */}
                <Field
                       name="locationId"
                      //  value={autoValue}
                      // value={values.assetName.code+"_"+values.assetName.assetName}
                     inputValue={auto.location}
                     readOnly={assetInfo.locationId!==undefined&&assetInfo!==null}
                           component={Autocomplete}
                           filterOptions={filterOptions}
                           loading={locationId.length === 0&&loading}
                           options={locationId} 
                           PaperComponent={CustomPaper}
                           getOptionLabel={(option) => option.code+" - "+option.locationName || option}
                           freeSolo
                         onChange={async(event,value) => {
                          
                       if(value){
                        console.log(value)
                        const assetByLocation=masterAPI["assets"].assetByLocation
                       await axiosPrivate.get(`${assetByLocation}/${value.id}`).then(res=>{
                          setAssetId(res.data.result)
                        }).catch(e=>{
                          setAssetId([])
                          console.log(e,"error")
                        })
                        setFieldValue("locationId",value.id)
                        setAuto({...auto,asset:"",location:value.code+ " - "+value.locationName})
                      
                    
                       }else{
                        setFieldValue("locationId",null)
                        setAuto({...auto,location:"",asset:""})
                        getData()
                        // setFieldValue("failureClassId",null)
                       }
                        
                        }}
                        onInputChange={(e,v)=>{
                          setAuto({...auto,location:v})
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="locationId"
                           placeholder="Search location"
                            onChange={handleChange}
                            variant="standard"
                           
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                  {locationId.length === 0&&loading === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>),
                            }}
                           />
                           )}
                      >
                      
                        {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                      </Field>
            </Grid>
            <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Asset
                </Typography>
              {/* <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="assetId"
                variant="standard"
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.assetId !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {assetId.map((id, i) => (
                  <MenuItem value={id.id}>{id.assetCode+"-"+id.assetName}</MenuItem>
                ))}
              </Field> */}
              
           
                   <Field
                       name="assetId"
                      //  value={autoValue}
                      // value={values.assetName.code+"_"+values.assetName.assetName}
                     inputValue={auto.asset}
                           component={Autocomplete}
                           filterOptions={filterOptions}
                           loading={assetId.length === 0&&loading}
                           options={assetId} 
                           PaperComponent={CustomPaper}
                           getOptionLabel={(option) => option.code+" - "+option.assetName || option}
                           freeSolo
                         onChange={(event,value) => {
                          
                       if(value){
                        console.log(value)
                        setFieldValue("assetId",value.id)
                        setFieldValue("locationId",value.locationId)
                        setFieldValue("failureClassId",value.failureClassesId)
                        setFieldValue("priorityId",value.priorityId)
                        setAuto({...auto,asset:value.code+ " - "+value.assetName,location:value.locationCode+" - "+value.locationName})
                        setAssetInfo(value)
                        handleFailureProblem(value.failureClassesId)
                      
                    
                       }else{
                        setFieldValue("assetId",null)
                        // setFieldValue("locationId",null)
                        // setFieldValue("failureClassId",null)
                        setAuto({...auto,asset:""})
                        setAssetInfo({})
                        setProblemCode([])
                       }
                        
                        }}
                        onInputChange={(e,v)=>{
                          setAuto({...auto,asset:v})
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="assetId"
                           placeholder="Search Asset"
                            onChange={handleChange}
                            variant="standard"
                           
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                  {assetId.length === 0 && loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>),
                            }}
                           />
                           )}
                      >
                      
                        {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                      </Field>
            </Grid>
            <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Site
                </Typography>
              {/* <Field
                as={TextField}
                size={"small"}
                type="text"
                name="siteId"
                variant="standard"
                placeholder="Enter siteId"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              /> */}
                 <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="siteId"
                variant="standard"
                readOnly
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.siteId !== null ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
               
                  <MenuItem value={`${siteInfoDetail.siteId}`}>{siteInfoDetail.siteName}</MenuItem>
              
              </Field>
            </Grid>
            <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                    GL Account
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="glAccount"
                variant="standard"
                placeholder="Enter GL Account "
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
         
            </Grid>
         
            </Grid>
            <Grid item xs={5.5} sx={{boxShadow:"-1px 1px 7px 2px rgb(0 0 0 / 32%)",p:1.5,backgroundColor:"white"}}>
  
      <Box>
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        > Job Info</Typography>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        </Box>
    <Grid container spacing={"1rem"}>
    <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               Job Status
                </Typography>
              <Field
                as={Select}
                // label={"Select"}
                readOnly={!edit}
                size={"small"}
                type="text"
                name="status"
                variant="standard"
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.status !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {JPStatus.map((id, i) => (
                  <MenuItem value={id.value}>{id.text}</MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
              Work Type
                </Typography>
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="workTypeId"
                variant="standard"
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.workTypeId !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {workType.map((id, i) => (
                  <MenuItem value={id.value}disabled={id.value.toUpperCase()==="PM"}>{id.text}</MenuItem>
                ))}
              </Field>
            </Grid>
           
            {/* <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               Job Priority
                </Typography>
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="priority"
                variant="standard"
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.priority !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {priorityJob.map((id, i) => (
                  <MenuItem value={id.value}>{id.text}</MenuItem>
                ))}
              </Field>
            </Grid> */}
            {/* <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Failure Class
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="failureClassName"
                variant="standard"
                placeholder="Enter Failure class"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid> */}
              <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               Failure Class
                </Typography>
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="failureClassId"
                variant="standard"
                fullWidth
                displayEmpty
                readOnly={assetInfo.failureClassesId!==undefined&&assetInfo.failureClassesId!==null}
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                onChange={(e)=>{
                  setFieldValue("failureClassId",e.target.value)
                  handleFailureProblem(e.target.value)
                }}
               renderValue={values.failureClassId !== null&&values.failureClassId !== undefined ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {failureClass.map((id, i) => (
                  <MenuItem value={id.id}>{id.failureClassName}</MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Problem Code
                </Typography>
                <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="problemId"
                variant="standard"
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.problemId !== null ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {probleCode.map((id, i) => (
                  <MenuItem value={id.id}>{id.code+" - "+id.problemName}</MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
              Job Priority
                </Typography>
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="priorityId"
                variant="standard"
                fullWidth
                readOnly={edit?false:editData.priorityId!==undefined}
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.priorityId !== null ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {priority.map((id, i) => (
                  <MenuItem value={id.id}>{id.priorityName}</MenuItem>
                ))}
              </Field>
            </Grid>
            </Grid>
            
            </Grid>
            <Grid item xs={5.5} sx={{boxShadow:"-1px 1px 7px 2px rgb(0 0 0 / 32%)",p:1.5,backgroundColor:"white"}}>
  
  <Box>
    <Typography
      sx={{
        color: color.masterDialogTitleColor,
        fontSize: color.masterDialogFontSize,
      }}
    > Planning</Typography>
    <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
    </Box>
<Grid container spacing={"1.5rem"}>
<Grid item xs={12} md={12}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
           Job Plan ID
            </Typography>
          {/* <Field
            as={Select}
            // label={"Select"}
            size={"small"}
            type="text"
            name="jobPlanId"
            variant="standard"
            fullWidth
            displayEmpty
            MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
           renderValue={values.jobPlanId !== null ? undefined : () =><Typography sx={{  color: color.placeholderColor,
            fontSize: "13px",}} >Select</Typography> }
          >
            
            {jobPlan.map((id, i) => (
              <MenuItem value={id.id}>{id.jobPlanName}</MenuItem>
            ))}
          </Field> */}
             <Field
                       name="jobPlanId"
                      //  value={autoValue}
                      value={values.jobPlanName}
                           component={Autocomplete}
                           filterOptions={filterOptions}
                           loading={jobPlan.length === 0}
                           options={jobPlan}
                           PaperComponent={CustomPaper}
                           getOptionLabel={(option) => option.jobPlanName || option}
                           freeSolo
                         onChange={(event,value) => {
                          
                       if(value){
                        console.log(value)
                        setFieldValue("jobPlanId",value.id)
                        setFieldValue("jobPlanName",value.jobPlanName)
                      
                    
                       }
                        
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="jobPlanId"
                           placeholder="Search Job Plan"
                            onChange={handleChange}
                            variant="standard"
                           
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                  {jobPlan.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>),
                            }}
                           />
                           )}
                      >
                      
                        {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                      </Field>
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Actual Start Date
                </Typography>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                  
                    <DemoItem>
                    <DateTimePicker 
                
                                  
                    ampm={false}
                     format="DD/MM/YYYY HH:mm"
                     readOnly
                     viewRenderers={{
                       hours: renderTimeViewClock,
                       minutes: renderTimeViewClock,
                       seconds: renderTimeViewClock,
                     }}
                      value={(values.actualStartDate!==null?dayjs(values.actualStartDate):values.actualStartDate)}
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
                      
                      slotProps={{ textField: { size: "small" , variant:"standard",fullWidth:true } }}
                     
                      fullWidth
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("actualStartDate",dateFrom)
                          }
                        }
                      }} />
                      </DemoItem>
                 
                </LocalizationProvider>
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"} }}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Target Start Date
                </Typography>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                 
                    <DemoItem>
                    <DateTimePicker
                                  
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}

                      value={(values.targetStartDate!==null?dayjs(values.targetStartDate):values.targetStartDate)}
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
                   
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0" ,variant:"standard",fullWidth:true } }}
                     
                  
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm:ss"
                              // "YYYY-MM-DD"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("targetStartDate",dateFrom)
                          }
                        }
                      }} />
                      </DemoItem>
                  
                </LocalizationProvider>
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Actual End Date
                </Typography>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                  {/* <DemoContainer components={["DatePicker"]} > */}
                    <DemoItem>
                    <DateTimePicker
                                  
                                  ampm={false}
                                  readOnly
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                    
                      value={(values.actualEndDate!==null?dayjs(values.actualEndDate):values.actualEndDate)}
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
                   
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0" , variant:"standard",fullWidth:true } }}
                     
                      fullWidth
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("actualEndDate",dateFrom)
                          }
                        }
                      }} />
                      </DemoItem>
                  {/* </DemoContainer> */}
                </LocalizationProvider>
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Target End Date
                </Typography>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                
                    <DemoItem>
                    <DateTimePicker
                                  
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                     
                      value={(values.targetEndDate!==null?dayjs(values.targetEndDate):values.targetEndDate)}
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
                   
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0" , variant:"standard",fullWidth:true } }}
                     
                      fullWidth
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("targetEndDate",dateFrom)
                          }
                        }
                      }} />
                      </DemoItem>
            
                </LocalizationProvider>
        </Grid>
        {/* <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Stop Begin Date
                </Typography>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
             
                    <DemoItem>
                    <DateTimePicker
                                  
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                    
                      value={(values.stopBeginDate!==null?dayjs(values.stopBeginDate):values.stopBeginDate)}
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
                   
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0" , variant:"standard",fullWidth:true } }}
                     
                      fullWidth
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("stopBeginDate",dateFrom)
                          }
                        }
                      }} />
                      </DemoItem>
               
                </LocalizationProvider>
        </Grid> */}
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Planned Start Date
                </Typography>
                
                <LocalizationProvider 
                dateAdapter={AdapterDayjs}  
                >
                 
                    <DemoItem>
                    <DateTimePicker
                                 
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                    
                      value={(values.plannedStartDate!==null?dayjs(values.plannedStartDate):values.plannedStartDate)}
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
                   
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0" , variant:"standard",fullWidth:true } }}
                     
                      fullWidth
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("plannedStartDate",dateFrom)
                          }
                        }
                      }} />
                      </DemoItem>
                
                </LocalizationProvider>
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Planned End Date
                </Typography>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                  
                    <DemoItem>
                    <DateTimePicker
                                
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                    
                      value={(values.plannedEndDate!==null?dayjs(values.plannedEndDate):values.plannedEndDate)}
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
                   
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0" , variant:"standard",fullWidth:true } }}
                     
                      fullWidth
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("plannedEndDate",dateFrom)
                          }
                        }
                      }} />
                      </DemoItem>
                
                </LocalizationProvider>
        </Grid>
        <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Duration
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="duration"
                variant="standard"
                placeholder="Enter Duration"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Earliest Start Date
                </Typography>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                  
                  <DemoItem>
                    <DateTimePicker
                                   readOnly={editData.workTypeName==="PM"}
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                    
                      value={(values.earliestStartDate!==null?dayjs(values.earliestStartDate):values.earliestStartDate)}
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
                   
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0" , variant:"standard",fullWidth:true } }}
                     
                      fullWidth
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("earliestStartDate",dateFrom)
                          }
                        }
                      }} />
                      </DemoItem>
               
                </LocalizationProvider>
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  latest End Date
                </Typography>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                 
                    <DemoItem>
                    <DateTimePicker
                                   readOnly={editData.workTypeName==="PM"}
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                    
                      value={(values.latestEndDate!==null?dayjs(values.latestEndDate):values.latestEndDate)}
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
                   
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0" , variant:"standard",fullWidth:true } }}
                     
                      fullWidth
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("latestEndDate",dateFrom)
                          }
                        }
                      }} />
                      </DemoItem>
                 
                </LocalizationProvider>
        </Grid>
        </Grid>
        
        </Grid>
        <Grid item xs={5.5} sx={{boxShadow:"-1px 1px 7px 2px rgb(0 0 0 / 32%)",p:1.5,backgroundColor:"white"}}>
  
  <Box>
    <Typography
      sx={{
        color: color.masterDialogTitleColor,
        fontSize: color.masterDialogFontSize,
      }}
    > Responsibility</Typography>
    <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
    </Box>
<Grid container spacing={"1.5rem"}>

        {/* <Grid item xs={12} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Request By
                </Typography>
                
     
          <Field
            as={Select}
            
            size={"small"}
            type="text"
            name="requestById"
            variant="standard"
            fullWidth
            displayEmpty
            MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
           renderValue={values.requestById !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
            fontSize: "13px",}} >Select</Typography> }
          >
            
            
               {labourData.map((id, i) => (
              <MenuItem value={id.id}>{id.labourName}</MenuItem>
            ))}
          </Field>
      
            </Grid> */}
            <Grid item xs={12}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Created Date
                </Typography>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                  
                    <DemoItem>
                    <DateTimePicker
                                  
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                     
                      value={(values.createdDate!==null?dayjs(values.createdDate):values.earliestStartDate)}
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}
                   
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0" , variant:"standard",fullWidth:true } }}
                     
                      fullWidth
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("createdDate",dateFrom)
                          }
                        }
                      }} />
                      </DemoItem>
                
                </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={12}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
          Work Responsible
            </Typography>
         
             <Field
                       name="responsiblePersonId"
                      //  value={autoValue}
                      value={auto.workResponsible}
                           component={Autocomplete}
                           filterOptions={filterOptions}
                           loading={personData.length === 0}
                           options={personData}
                           PaperComponent={CustomPaper}
                           getOptionLabel={(option) => option.labourName || option}
                           freeSolo
                         onChange={(event,value) => {
                          
                       if(value){
                        console.log(value)
                        setFieldValue("responsiblePersonId",value.personId)
                        setAuto({...auto,workResponsible:value.labourName})
                       }
                        
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="responsiblePersonId"
                           placeholder="Search Responsible Person"
                            onChange={handleChange}
                            variant="standard"
                           
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                  {personData.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>),
                            }}
                           />
                           )}
                      >
                      
                        {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                      </Field>
        </Grid>
            {/* <Grid item xs={12} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Business Id
                </Typography>
                
     
          <Field
            as={Select}
            // label={"Select"}
            size={"small"}
            type="text"
            name="businessId"
            variant="standard"
            fullWidth
            displayEmpty
            MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
           renderValue={values.businessId !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
            fontSize: "13px",}} >Select</Typography> }
          >
            
            {businessData.map((id, i) => (
              <MenuItem value={id.id}>{id.businessName}</MenuItem>
            ))}
          </Field>
      
            </Grid> */}
        </Grid>
        
        </Grid>
            </Grid>
       
              </TabPanel>
             </Box>
            </TabContext>
            <Grid
                container
                item
                xs={11.9}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Form>
    )}
    </Formik>
    </Box>
   
  )
}

export default CreateGeneral