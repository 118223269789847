import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../dataConfig";

const JobPlanModal = ({ mode, handleCloseClick,editData }) => {

  const [loading,setLoading]=useState(false)

  

  const initialValue = {
    jobPlanName: mode==="Edit"?editData.jobPlanName: "",
    jobPlanNo:mode==="Edit"?editData.jobPlanNo: "",
    sequenceNo:mode==="Edit"?editData.sequenceNo: "",
    duration:mode==="Edit"?editData.duration: "",
    description:mode==="Edit"?editData.description: "",
    code:mode === "Edit"?editData.code:""
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true)
    const createLink = masterAPI["jobPlan"].create;
    const updateLink=masterAPI["jobPlan"].update
    if(mode==="Create"){
     await axiosPrivate.post(`${createLink}`,value).then(res=>{
        console.log(res.data)
        setLoading(false)
        handleCloseClick()
      }).catch(e=>{
        setLoading(false)
        console.log(e,"create jobPlan")
      })
    }else{
      const updateAC={
        id:editData.id,
        ...value
      }
     await axiosPrivate.put(`${updateLink}`,updateAC).then(res=>{
        console.log(res.data)
        setLoading(false)
        handleCloseClick()
      }).catch(e=>{
        setLoading(false)
        console.log(e,"update jobPlan")
      })
    }
   
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode === "Edit"?editData.code+"-"+editData.jobPlanName:"New Job Plan"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
      {({ handleChange, setFieldValue, values , resetForm }) => (
        <Form>
          <Grid container spacing={"1rem"}>
          <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Job Plan Code
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="code"
                placeholder="Enter Job Plan Code"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Job Plan No
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="jobPlanNo"
                placeholder="Enter Job Plan No"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Job Plan Name
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="jobPlanName"
                placeholder="Enter Job Plan Name"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Sequence No
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="sequenceNo"
                placeholder="Enter Sequence No"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Description
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="description"
                placeholder="Enter Description"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Duration
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="duration"
                placeholder="Enter Duration"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
          
            <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
              <Button
                onClick={handleCancel}
                variant="contained"
                sx={{
                  backgroundColor: color.TableCancelButton,
                  textTransform: "none",
                  color: color.CancelTextColor,
                  ":hover":{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type={"submit"}
                variant="contained"
                disabled={loading}
                sx={{
                      
                  textTransform: "none",
                  backgroundColor: loading ? "default" : color.TableButtonColor,
                  "&:hover": {
                    backgroundColor: loading
                      ? "default"
                      : color.buttonDisable,
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
      </Formik>
    </Box>
  );
};

export default JobPlanModal;
