import React, { useEffect, useState } from 'react'
import NormalTable from '../../../../Component/NormalTable'
import { Typography } from '@mui/material';
import { masterAPI } from '../../../Masters/dataConfig';
import { axiosPrivate } from '../../../../axios/axios';

const InventoryBalance = ({info}) => {
    const [data,setData]=useState([])

    useEffect(()=>{
        getData()
    },[])

    const getData=async()=>{
      const link = masterAPI["inventory"].search;
     await axiosPrivate
        .post(`${link}`,{partId:info.partId,stockRoomId:info.stockRoomId})
        .then((res) => {
          console.log(res.data, "allinventory result");
        
          setData(res.data.result);
        })
        .catch((e) => {
          console.log(e, "allinven");
         
          setData([])
        });
      }

    const columns = [
       {
        Header:"code",
        accessor:"code"
       },
       {
        Header:"stockRoom",
        accessor:"stockRoomName"
       },
        {
            Header: "Aisle",
            accessor: "aisleName",
            Cell:({cell})=>{
              return (
                  <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>{cell.row.original.aisleCode+" - "+cell.value}</Typography>
              )
            }
            // disableFilters: true,
          },
          {
            Header: "Row",
            accessor: "rowName",
            Cell:({cell})=>{
              return (
                  <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>{cell.row.original.rowCode+" - "+cell.value}</Typography>
              )
            }
            // disableFilters: true,
          },
          {
            Header: "Bin",
            accessor: "binName",
            Cell:({cell})=>{
              return (
                  <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>{cell.row.original.binCode+" - "+cell.value}</Typography>
              )
            }
            // disableFilters: true,
          },
          
          {
            Header: "Bin Quantity",
            accessor: "quantityOnHandInBin",
          },
       
      ];

  return (
     <NormalTable data={data} columns={columns} />
  )
}

export default InventoryBalance