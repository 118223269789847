import React, { useEffect, useState } from "react";
import { color } from "../../../StyleData/Config";
import {
  Grid,
  Typography,
  Paper,
  Select,
  MenuItem,
  TextField,
  Box,
  Divider,
  IconButton,
  Tab,
  FormControlLabel,
  Radio,
  Menu,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { axiosPrivate, siteInfo } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { Add, Circle, MoreHoriz, PlaylistAdd } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PMView = ({ direction, pmData }) => {
  const navigate = useNavigate();
  const siteInfoDetail=useSelector(state=>state.auth.loginInfo)

  console.log(pmData, "checkPM");

  const [value, setValue] = useState("1");
  const [dotBL, setDotBL] = useState(null);

  const handleDot = (event) => {
    if (!dotBL) {
      setDotBL(event.currentTarget);
    } else {
      setDotBL(null);
    }
  };

  //     useEffect(()=>{
  //         const locationLink=masterAPI["location"].link
  //         axiosPrivate.get(`${locationLink}`).then(res=>{
  //             console.log(res.data.result)
  //             setLocationId(res.data.result)
  //         }).catch(e=>{
  //             console.log(e,"createpm locaion getall")
  //         })

  //         const assetLink=masterAPI["assets"].link
  //         axiosPrivate.get(`${assetLink}`).then(res=>{
  //             console.log(res.data.result)
  //             setAssetId(res.data.result)
  //         }).catch(e=>{
  //             console.log(e,"createpm asset getall")
  //         })

  //         const siteLink=masterAPI["site"].getall
  //         axiosPrivate.get(`${siteLink}`).then(res=>{
  //             console.log(res.data.result)
  //             setSiteId(res.data.result)
  //         }).catch(e=>{
  //             console.log(e,"createpm site getall")
  //         })

  //         const pmStatusLink=masterAPI["selectList"].pmStatus
  //         axiosPrivate.get(`${pmStatusLink}`).then(res=>{
  //             console.log(res.data)
  //             setPMStatus(res.data)
  //         }).catch(e=>{
  //             console.log(e,"getpmStatus Create pm")
  //         })

  //         const jobplanLink=masterAPI["jobPlan"].link
  //         axiosPrivate.get(`${jobplanLink}`).then(res=>{
  //           console.log(res.data.result)
  //           setJobPlan(res.data.result)
  //         }).catch(e=>{
  //           console.log(e,"getJobPlan Create pm")
  //         })

  //         const getjobType=masterAPI["selectList"].pmJobType
  //         axiosPrivate.get(`${getjobType}`).then(res=>{
  //     console.log(res.data)
  //           setJobType(res.data)
  //         }).catch(e=>{
  //           console.log(e,"jobtype Create pm")
  //         })

  //         const getFreqTime=masterAPI["selectList"].frequencyunitsfortime

  //         axiosPrivate.get(`${getFreqTime}`).then(res=>{
  //           console.log(res.data)
  //                 setFrequnittime(res.data)
  //               }).catch(e=>{
  //                 console.log(e,"freq unit time Create pm")
  //               })

  //               const getFreqMeter=masterAPI["selectList"].frequencyunitsformeter

  //         axiosPrivate.get(`${getFreqMeter}`).then(res=>{
  //           console.log(res.data)
  //                 setFrequnitmeter(res.data)
  //               }).catch(e=>{
  //                 console.log(e,"freq unit meter Create pm")
  //               })

  //               const meter=masterAPI["meter"].link

  //               axiosPrivate.get(`${meter}`).then(res=>{
  //                 console.log(res.data.result)
  //                       setMeter(res.data.result)
  //                     }).catch(e=>{
  //                       console.log(e," meter Create pm")
  //                     })

  // //////////////////priority////////////////////////////////////////
  // const priLink=masterAPI["assetPriority"].link
  // axiosPrivate.get(`${priLink}`).then(res=>{
  // console.log(res.data.result)
  //   setPriority(res.data.result)
  // }).catch(e=>{
  //   console.log(e,"failureClass Create Wo")
  // })

  //         },[])

  // const initialValue={
  //     description:"",
  //     name:"",
  //     locationId:"",
  //     assetId:"",
  //     status:"",
  //     priority:"",
  //     siteId:"",
  //     jobPlanId:"",
  //     workType:"",
  //     woCounter:"",
  //     pmMeterBasedScheduler: {
  //       continourTrigger:{
  //       meterId: "",
  //       frequency: 0,
  //       startAtValue: 0,
  //       earliestTriggerValue: 0,
  //       currentReading: 0,
  //       frequencyUnitForMeter: "",
  //       fixed:false,
  //       floating:false
  //       },
  //       gaugeTrigger:{
  //        meterId:"",
  //        when:""
  //       }
  //     },
  //     pmTimeBasedScheduler: {
  //       frequency: 0,
  //       frequencyUnitForTime: "",
  //       nextDueDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
  //       targetEndDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
  //       offsetDays: 0,
  //       fixed:false,
  //       floating:false
  //     }

  //    }

  const onSubmit = (values) => {
    console.log(values);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          mb: "1rem",
          gap: "1rem",
        }}
        // sx={{mb:"1rem"}}
      >
        <Typography
          sx={{
            // color: color.masterDialogTitleColor,
            // fontSize: color.masterDialogFontSize,
            fontSize: color.AssetTitlesize,
            fontWeight: color.sideFontWeightAsset,
          }}
        >
          {pmData.code+" - "+pmData.name+" - "+pmData.workTypeName}
        </Typography>
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <Circle sx={{ fontSize: "0.5rem", color: "green" }} />
            <Typography>{pmData.status}</Typography>
          </Box>
          {/* <MoreHoriz onClick={handleDot} sx={{ cursor: "pointer" }} />
              <Menu anchorEl={dotBL} open={Boolean(dotBL)} onClose={handleDot}>
                <MenuItem>Option 1</MenuItem>
                <MenuItem>Option 2</MenuItem>
                <MenuItem>Option 3</MenuItem>
              </Menu> */}
          <Box></Box>
        </Box>
      </Box>

      <TabContext value={value}>
        <Box
          sx={{
            ".css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected": {
              color: color.TabLabelcolor,
              // p: 0.5,
            },
            display: "flex",
            justifyContent: "space-between",
            width: "98%",
          }}
        >
          <TabList
            onChange={handleChangeTab}
            aria-label="lab API tabs example"
            TabIndicatorProps={{
              sx: { backgroundColor: color.TabLabelcolor, height: "3px" },
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="General" value="1" sx={{ textTransform: "none" }} />
            <Tab label="Scheduling" value="2" sx={{ textTransform: "none" }} />
          </TabList>

          {/* <IconButton type='submit' sx={{backgroundColor:color.TableButtonColor}}>
          <PlaylistAdd sx={{color:"white",fontSize:"1.7rem"}}/>
        </IconButton> */}
        </Box>
        <Box sx={{ mt: "1rem" }}>
          <TabPanel value="1" sx={{ p: 1 }}>
            {/* <CreateGeneral jobPlanIden={handleJobPlanId}/> */}
            <Grid
              container
              sx={{
                boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                p: 1.5,
                backgroundColor: "white",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: color.masterDialogTitleColor,
                    fontSize: color.masterDialogFontSize,
                    fontWeight: color.sideWeightFont,
                    mb: "10px",
                  }}
                >
                  Details
                </Typography>
                <Divider
                  sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
                />
              </Grid>
              <Grid container item xs={12} spacing={"1rem"}>
                <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Name
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {pmData.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    PM Description
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {pmData.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Location
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {pmData.locationName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Asset
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {pmData.assetName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Site
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                   {siteInfoDetail.siteName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    PM Status
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {pmData.status}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Job Type
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {pmData.workTypeName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Job Priority
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {pmData.priority?.code+" - "+pmData.priority?.priorityName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Job Plan
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {pmData.jobPlanName}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                  <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    PM Counter
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {pmData.woCounter}
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2" sx={{ p: 1 }}>
            {/* <CreateGeneral jobPlanIden={handleJobPlanId}/> */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Grid
                container
                sx={{
                  boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                  p: 1.5,
                  backgroundColor: "white",
                }}
                alignItems={"center"}
              >
                <Grid item xs={12} md={3} lg={2}>
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                      fontWeight: color.sideWeightFont,
                      // mb: "10px",
                    }}
                  >
                    Schedule Type :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                    }}
                  >
                    {pmData.scheduleType}
                  </Typography>
                  {/* <Field
                          as={Select}
                          // label={"Select"}
                          size={"small"}
                          type="text"
                          name="scheduleType"
                          fullWidth
                          displayEmpty
                          MenuProps={{
                            PaperProps: { sx: { maxHeight: "40vh" } },
                          }}
                          renderValue={
                            values.scheduleType !== ""
                              ? undefined
                              : () => (
                                  <Typography
                                    sx={{
                                      color: color.placeholderColor,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Select
                                  </Typography>
                                )
                          }
                          // onChange={(e) => {
                          //   console.log(e.target.value);
                          //   if (e.target.value === "Time") {
                          //     // setChecked({
                          //     //   time: true,
                          //     //   continuous: false,
                          //     //   gauge: false,
                          //     // });

                          //     setFieldValue("pmMeterBasedScheduler.meterId",null);
                          //     setFieldValue("pmMeterBasedScheduler.frequency",0);
                          //     setFieldValue("pmMeterBasedScheduler.startAtValue",0);
                          //     setFieldValue("pmMeterBasedScheduler.earliestTriggerValue",0);
                          //     setFieldValue( "pmMeterBasedScheduler.currentReading",0 );
                             
                          //     setFieldValue("pmMeterBasedScheduler.frequencyUnitForMeter",null  );
                                
                                
                            
                          //     setFieldValue("pmMeterBasedScheduler.triggertype","Continuous");
                                
                          
                        
                          //   } else if (e.target.value === "Meter") {
                          //     // setChecked({ ...checked, time: false });
                          //     setFieldValue("pmTimeBasedScheduler.frequency",0);
                          //     setFieldValue("pmTimeBasedScheduler.frequencyUnitForTime",null);
                          //     setFieldValue("pmTimeBasedScheduler.nextDueDate",moment().format("YYYY-MM-DDTHH:mm:ss"));
                          //     setFieldValue("pmTimeBasedScheduler.targetEndDate",moment().format("YYYY-MM-DDTHH:mm:ss"));
                          //     setFieldValue("pmTimeBasedScheduler.offsetDays",0);
                          //   }
                          //   setFieldValue("scheduleType", e.target.value);
                          // }}
                        >
                          {scheduleType.map((id, i) => (
                            <MenuItem key={i} value={id.value}>
                              {id.text}
                            </MenuItem>
                          ))}
                        </Field> */}
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                  p: 1.5,
                  backgroundColor: "white",
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                      fontWeight: color.sideWeightFont,
                      mb: "10px",
                    }}
                  >
                    Time Schedule Trigger
                  </Typography>
                  <Divider
                    sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
                  />
                </Grid>
                <Grid container item xs={12} spacing={"1rem"}>
                  <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      Frequency
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: color.TabDatafontsize,
                        mb: color.TabDatamarginbottom,
                      }}
                    >
                      {pmData.pmTimeBasedScheduler?.frequency}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      Frequency Unit
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: color.TabDatafontsize,
                        mb: color.TabDatamarginbottom,
                      }}
                    >
                      {pmData.pmTimeBasedScheduler?.frequencyUnitForTime}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={direction === "column" ? 6 : 4}
                    sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
                  >
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      Next Due Date
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: color.TabDatafontsize,
                        mb: color.TabDatamarginbottom,
                      }}
                    >
                      {pmData.pmTimeBasedScheduler?.nextDueDate!==null&&pmData.pmTimeBasedScheduler?.nextDueDate!==undefined?moment(pmData.pmTimeBasedScheduler?.nextDueDate).format(
                        "DD/MM/YYYY"
                      ):"---"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={direction === "column" ? 6 : 4}
                    sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
                  >
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      Target End Date
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: color.TabDatafontsize,
                        mb: color.TabDatamarginbottom,
                      }}
                    >
                      {pmData.pmTimeBasedScheduler?.targetEndDate!==null&&pmData.pmTimeBasedScheduler?.targetEndDate!==undefined?moment(
                        pmData.pmTimeBasedScheduler?.targetEndDate
                      ).format("DD/MM/YYYY"):"---"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      Offset Days
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: color.TabDatafontsize,
                        mb: color.TabDatamarginbottom,
                      }}
                    >
                      {pmData.pmTimeBasedScheduler?.offsetDays}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={direction === "column" ? 6 : 4}
                    alignItems={"flex-end"}
                  >
                    <FormControlLabel
                      value="Fixed"
                      checked={pmData.pmType === "Fixed"}
                      control={<Radio />}
                      label="Fixed"
                    />
                    <FormControlLabel
                      value="Floating"
                      checked={pmData.pmType === "Floating"}
                      control={<Radio />}
                      label="Floating"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                  p: 1.5,
                  backgroundColor: "white",
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                      fontWeight: color.sideWeightFont,
                      mb: "10px",
                    }}
                  >
                    Continuous Trigger
                  </Typography>
                  <Divider
                    sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
                  />
                </Grid>
                <Grid container item xs={12} spacing={"1rem"}>
                  <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      Meter Id
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: color.TabDatafontsize,
                        mb: color.TabDatamarginbottom,
                      }}
                    >
                      {pmData.pmMeterBasedScheduler?.meterName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      Frequency Unit
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: color.TabDatafontsize,
                        mb: color.TabDatamarginbottom,
                      }}
                    >
                      {pmData.pmMeterBasedScheduler?.frequencyUnitForMeter}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      Frequency
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: color.TabDatafontsize,
                        mb: color.TabDatamarginbottom,
                      }}
                    >
                      {pmData.pmMeterBasedScheduler?.frequency}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      StartAt
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: color.TabDatafontsize,
                        mb: color.TabDatamarginbottom,
                      }}
                    >
                      {pmData.pmMeterBasedScheduler?.startAtValue}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      Earliest Trigger
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: color.TabDatafontsize,
                        mb: color.TabDatamarginbottom,
                      }}
                    >
                      {pmData.pmMeterBasedScheduler?.earliestTriggerValue}
                    </Typography>
                  </Grid>
                  {/* <Grid container item xs={12} md={direction==="column"?6:4} alignItems={"flex-end"} >
              
            <FormControlLabel value="Fixed" checked={pmData.pmType==="Fixed"} 
            control={<Radio 
           
            />} 
            label="Fixed" />
            <FormControlLabel value="Floating" checked={pmData.pmType==="Floating"} 
            control={<Radio 
             
               />} 
            label="Floating" />
           
            </Grid> */}
                </Grid>
              </Grid>
              {/* <Grid
                container
                sx={{
                  boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                  p: 1.5,
                  backgroundColor: "white",
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                      fontWeight: color.sideWeightFont,
                      mb: "10px",
                    }}
                  >
                    Gauge Trigger
                  </Typography>
                  <Divider
                    sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
                  />
                </Grid>
                <Grid container item xs={12} spacing={"1rem"}>
                  <Grid item xs={12} md={direction === "column" ? 6 : 4}>
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      Meter Id
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: color.TabDatafontsize,
                        mb: color.TabDatamarginbottom,
                      }}
                    >
                      {pmData.pmMeterBasedScheduler?.gaugeTrigger?.meterName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      When
                    </Typography>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                      <Typography
                        sx={{
                          fontSize: color.TabDatafontsize,
                          mb: color.TabDatamarginbottom,
                        }}
                      >
                        Gallons(g)
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: color.TabDatafontsize,
                          mb: color.TabDatamarginbottom,
                        }}
                      >
                        Reading less than
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: color.TabDatafontsize,
                          mb: color.TabDatamarginbottom,
                        }}
                      >
                        200
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid> */}
            </Box>
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};

export default PMView;
