import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import NormalTable from "../Component/NormalTable";
import { color } from "../StyleData/Config";
import {toast} from "react-toastify"

const GetInvoice = () => {
  const [data, setData] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [remark, setRemark] = useState("");
  const [checkValue,setCheckedValue]=useState([])

  useEffect(() => {
   getData()
  }, []);

  const getData=()=>{
    axios
    .get(`https://connector.orienseam.com/api/eInvoice/GetInvoiceList`)
    .then((res) => {
      console.log(JSON.parse(res.data));
      setData(JSON.parse(res.data));
    });
  }

  const handleCheck = (value) => {
  if(checkValue.includes(value)){
    const filterData=checkValue.filter(fil=>fil!==value)
    setCheckedValue(filterData)
  }else{
    setCheckedValue([...checkValue,value])
  }
  };

  const columns = [
    {
      accessor: "id",
      Cell: ({ cell }) => {
        return (
          <Checkbox
          checked={checkValue.includes(cell.row.original.InvoiceNo)}
            onClick={() => {
              handleCheck(cell.row.original.InvoiceNo);
            }}
          />
        );
      },
      disableFilters: true,
    },
    {
      Header: "Invoice No",
      accessor: "InvoiceNo",
    },
    {
      Header: "Supplier",
      accessor: "Supplier",
    },
    {
      Header: "Email",
      accessor: "Email",
    },
    
    {
      Header: "Buyer",
      accessor: "Buyer",
    },
    {
      Header: "Business RegNum",
      accessor: "BusinessRegNum",
    },
    {
      Header: "Company Tax Num",
      accessor: "CompanyTaxNumber",
    },
    {
      Header: "CurrencyValue",
      accessor: "CurrencyValue",
    },
    {
      Header: "CustomerSST",
      accessor: "CustomerSST",
    },
    {
      Header: "Invoice Date",
      accessor: "InvoiceDate",
    },
    {
      Header: "eInvoiceStatus",
      accessor: "eInvoiceStatus",
    },
    {
      Header: "Address1",
      accessor: "Address1",
    },
    {
      Header: "Address2",
      accessor: "Address2",
    },
    {
      Header: "Address3",
      accessor: "Address3",
    },
  ];

  const handleSubmit=()=>{
    const postData=[]
    for(let i=0;checkValue.length>i;i++){
      postData.push(
        {
          InvoiceNo:checkValue[i],
          eInvoiceStatus:selectType,
          IrbmErrordetails:remark
        
        })
    }
    console.log(postData)
    axios.post(`https://connector.orienseam.com/api/eInvoice/UpdateInvoice`,postData).then(res=>{
      console.log(res.data)
      getData()
      if(res.data){
        toast.success("Record update successfully")
      }else{
        toast.error("Record not updated")
      }
      setCheckedValue([])
    }).catch(e=>{
      console.log(e,"error")
    })
  }

  return (
    <Box>
      <Box sx={{ background: "#2362B4", padding: 1.5 }}>
        <Typography
          variant="h6"
          noWrap
          sx={{ fontSize: "1.6rem", color: "white" }}
        >
          IRBM Simulator
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center", p: 2 }}>
        <Select
          value={selectType}
          onChange={(e) => {
            setRemark("")
            setSelectType(e.target.value);
          }}
          size="small"
          sx={{ width: "15vw" }}
          displayEmpty
          renderValue={selectType !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
            fontSize: "15px",}} >Select</Typography> }
        >
          <MenuItem value="Approved"> Approved </MenuItem>
          <MenuItem value="Rejected"> Rejected </MenuItem>
        </Select>
        <TextField
          sx={{ 
            display: selectType === "Rejected" ? "default" : "none",
            width:'50vw'
         }}
         placeholder="Please Enter Error Detail"
          size="small"
          value={remark}
          onChange={(e) => {
            setRemark(e.target.value);
          }}
        />
        <Button
          variant="contained"
          disabled={
            selectType === "" || (selectType === "Rejected" && remark === "")
          }
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
      <Box>
        <NormalTable data={data} columns={columns} filt={true} />
      </Box>
    </Box>
  );
};

export default GetInvoice;
