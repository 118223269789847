import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  Paper,
  Icon,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Add, Clear, DeleteOutlined, Edit, EditOutlined } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";
import moment from "moment";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import NormalTable from "../../../Component/NormalTable";

const IssueModal = ({ mode, handleCloseClick, editData }) => {
  const [woData, setWOData] = useState([]);
  const [woSel,setWOSel]=useState("")
  const [assetData, setAssetData] = useState([]);
  const [assetSel,setAssetSel]=useState("")
  const [issueItem,setIssueItem]=useState({
    partId: null,
    stockRoomId: null,
  stockRoomRowId: null,
  stockRoomRowAisleId: null,
  stockRoomRowBinId: null,
  quantity: 0,
  unitCost: 0,
      lineCost: 0,
      issuedDate:moment().format("YYYY-MM-DDTHH:mm:ss"),
  stockAisle:[],
  stockRow:[],
  stockBin:[],
  stockRoom:[]
  })

  const [partData, setPartData] = useState([]);


  const [addIssue, setAddIssue] = useState([]);
  const [partSelect, setPartSel] = useState("");
  const [srSelect, setSRSel] = useState("");
  const [srAisleSelect, setSRAisleSel] = useState("");
  const [srRowSelect, setSRRowSel] = useState("");
  const [srBinSelect, setSRBinSel] = useState("");


  const [loading, setLoading] = useState(false);

  useEffect(() => {
   getData()

  }, []);

  const getData=async()=>{
    setLoading(true);

    //////// workorder ///////

    const link = masterAPI["workOrder"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setLoading(false);
        setWOData(res.data.result);
      })
      .catch((e) => {
        setWOData([])
        setLoading(false)
        console.log(e, "create wo");
      });

      const partLink=masterAPI["inventory"].getAll
     await axiosPrivate.get(`${partLink}`).then(res=>{
        console.log(res,"partLink")
        const partIdFilt=[]
        const partFilter=[]
        for(let i=0;res.data.result.length>i;i++){
          if(!partIdFilt.includes(res.data.result[i].partId)){
              partIdFilt.push(res.data.result[i].partId)
              partFilter.push(res.data.result[i])
          }
        }
        console.log(partFilter)
        setPartData(partFilter)
      }).catch(e=>{
        setPartData([])
         console.log(e,"error part Data")
      })
     
      // const stockRoomLink=masterAPI["stockRoom"].link
      // axiosPrivate.get(`${stockRoomLink}`).then(res=>{
      //   console.log(res,"stockRoomLink")
      //   setStockRoomData(res.data.result)
      // }).catch(e=>{
      //    console.log(e,"error stockRoom Data")
      // })

      const assetlink = masterAPI["assets"].link;
     await axiosPrivate
        .get(`${assetlink}`)
        .then((res) => {
          setLoading(false);
          setAssetData(res.data.result);
        })
        .catch((e) => {
          setLoading(false)
          setAssetData([])
          console.log(e, "create asset");
        });


  }

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const initialValue = {

    workOrderId: mode === "Edit" ? editData.workOrderId : null,
    assetId: mode === "Edit" ? editData.assetId : null,
    // invUsageCode: mode === "Edit" ? editData.invUsageCode : "",
    issuedToPersonId: mode === "Edit" ? editData.issuedToPersonId : null,
    issuedDate: mode === "Edit" ? editData.issuedDate : moment().format("YYYY-MM-DDTHH:mm:ss"),
    glCreditAccount: mode === "Edit" ? editData.glCreditAccount : "",
    glDebitAccount:mode === "Edit" ? editData.glDebitAccount : "",
   
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["issue"].create;
    const updateLink = masterAPI["issue"].update;
    if (mode === "Create") {
      const postData={
        ...value,
        issuedItemsList:addIssue
      }
      console.log(postData)
     await axiosPrivate
        .post(`${createLink}`, postData)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "create Issue");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "update Issue");
        });
    }
  };

  const handlePart=async(value)=>{
    // setCauseSel(value.causeCode+"_"+value.causeName)
    // setPartInfo(value)
    const srLink=masterAPI["selectList"].stockRoomByPart
   await axiosPrivate.get(`${srLink}/${value.id}`).then(res=>{
      const storeRI={
        ...issueItem,
        partId:value.partId,
        partCode:value.code,
        partName:value.partName,
        stockRoom:res.data
     
      }
      setIssueItem(storeRI)
    }).catch(e=>{
      console.log(e)
    })
   
   
    }

    const handleAddIssue=(e)=>{
      const {name,value,checked}=e.target
      if(name==="unitCost"){
        const storeRI={
          ...issueItem,
          [name]:value,
          lineCost:issueItem.quantity*value
       
        }
        setIssueItem(storeRI)
      }else{
      const storeRI={
        ...issueItem,
        [name]:value
     
      }
      setIssueItem(storeRI)
    }
     
    }

  const handleCancel = () => {
    handleCloseClick();
  };

  const handleStockRoom=async(value)=>{
    const getAisleLink=masterAPI["selectList"].aisleByPartAndRoom
   await axiosPrivate.get(`${getAisleLink}/${issueItem.partId}/${value.value}`).then(res=>{
      console.log(res)
      const storeStockRoom={
        ...issueItem,
        stockRoomId:value.value,
   
        stockRoomName:value.text,
        stockAisle:res.data
      }

    
      setIssueItem(storeStockRoom)
    }).catch(e=>{
      console.log(e,"error")
    })
  }
  const handleStockAisle=async(value)=>{
    const getRowLink=masterAPI["selectList"].rowByPartAndAisle
   await axiosPrivate.get(`${getRowLink}/${issueItem.partId}/${value.value}`).then(res=>{
     
      const storeStockRoom={
        ...issueItem,
        stockRoomRowAisleId:value.value,
       
        aisleName:value.text,
        stockRow:res.data
      }

    
      setIssueItem(storeStockRoom)
    }).catch(e=>{
      console.log(e,"error")
    })
  }
  const handleStockRow=async(value)=>{
    const getBinLink=masterAPI["selectList"].binByPartAndRow
   await axiosPrivate.get(`${getBinLink}/${issueItem.partId}/${value.value}`).then(res=>{
      const storeStockRoom={
        ...issueItem,
        stockRoomRowId:value.value,
        
        rowName:value.text,
        stockBin:res.data
      }

    
      setIssueItem(storeStockRoom)
    }).catch(e=>{
      console.log(e,"error")
    })
  }
  const handleStockBin=(value)=>{
   
    const storeStockRoom={
      ...issueItem,
      stockRoomRowBinId:value.value,
      
      binName:value.text
      
    }

  
    setIssueItem(storeStockRoom)
   
  }

  const handleIssueList=()=>{
    const addlist=[...addIssue,issueItem]
    console.log(addlist)
    setAddIssue(addlist)
    setIssueItem({
      partId: null,
      stockRoomId: null,
    stockRoomRowId: null,
    stockRoomRowAisleId: null,
    stockRoomRowBinId: null,
    quantity: 0,
    unitCost: 0,
        lineCost: 0,
        issuedDate:moment().format("YYYY-MM-DDTHH:mm:ss"),
    stockAisle:[],
    stockRow:[],
    stockBin:[],
    stockRoom:[]
    })
    setPartSel("")
    setSRAisleSel("")
    setSRBinSel("")
    setSRRowSel("")
    setSRSel("")
  }

  const handlePartEdit=(data)=>{
 console.log(data.row)
   const filterAddIssue=addIssue.filter((fil,i)=>i!==Number(data.row.id))

   console.log(filterAddIssue)
  
    setIssueItem({...data.row.original})
    setSRSel(data.row.original.stockRoomName)
    setPartSel(data.row.original.partCode+"-"+data.row.original.partName)
    setSRAisleSel(data.row.original.aisleName)
    setSRRowSel(data.row.original.rowName)
    setSRBinSel(data.row.original.binName)
    setAddIssue(filterAddIssue)

  }

 

  const partColumn = [
    {
      Header: "Part",
      accessor: "partCode",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              color: "#0d6efd",
              fontSize: "inherit",
              fontWeight: 600,
              cursor: "pointer",
            }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.value+" - "+cell.row.original.partName}
          </Typography>
        );
      },
      // disableFilters:true,
    },
  {
      Header: "Stock Name",
      accessor: "stockRoomName",
 
    },
    {
      Header: "Aisle",
      accessor: "aisleName",
    },
    {
      Header: "Row",
      accessor: "rowName",
    },
    {
      Header: "Bin",
      accessor: "binName",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      // disableFilters:true,
    }, 
    {
      Header: "Unit Cost",
      accessor: "unitCost",}, 
    {
      Header: "Line Cost",
      accessor: "lineCost",
 
    }, 
    {
      Header: "Issued Date",
      accessor: "issuedDate",

    },
    

    {
      Header:"Action",
      accessor:"partId",
      Cell:({cell})=>{
        return (
        <>
        <IconButton >
              <EditOutlined
                onClick={() => {
                  handlePartEdit(cell);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton>
          <IconButton
          onClick={() => {
            deleteIssue(cell.row.original.partId);
          }}
          >
          <DeleteOutlined
           
            sx={{
              fontSize: color.masterEditDelButton,
              color: color.masterEditDelButtonColor,
            }}
          />
        </IconButton>
        </>
        )
      },
      disableFilters:true,
    }
  ];

  const deleteIssue=(id)=>{
    const delIssue=addIssue.filter(fil=>fil.partId!==id)
    setAddIssue(delIssue)
  }

 



  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          New Issue
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Work Order
                </Typography>
                <Autocomplete
                // value={remedySelect}
                inputValue={woSel}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                loading={woData.length===0}
                options={woData}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.code + " - " + option.workOrderName || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    // handleWo(value);
                    console.log(value)
                    setFieldValue("workOrderId",value.id)
                  }else{
                    setWOSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setWOSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="workOrderId"
                    placeholder="Select Wo"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                          {woData.length===0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Asset
                </Typography>
                <Autocomplete
                // value={remedySelect}
                inputValue={assetSel}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                loading={assetData.length===0}
                options={assetData}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.code + " - " + option.assetName || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    // handleWo(value);
                    setFieldValue("assetId",value.id)
                  }else{
                    setAssetSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setAssetSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="assetId"
                    placeholder="Select Asset"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                          {assetData.length===0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  InvUsage Code
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="invUsageCode"
                  placeholder="Enter InvUsage Code"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid> */}
             
           
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Transaction Date
                </Typography>
               
           
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                  // views={['year', 'day', 'hours', 'minutes', 'seconds']}
                  readOnly
                  format="DD/MM/YYYY hh:mm:ss A"
                    value={values.issuedDate===null?null:dayjs(values.issuedDate)}
                   
                   
                    // sx={{".css-1xhypcz-MuiStack-root":{
                    //   padding:0
                    // }}}

                    slotProps={{
                      textField: {
                        size: "small",
                      
                        fullWidth: true,
                      },
                    }}
                    fullWidth
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                          // "YYYY-MM-DDTHH:mm:ss" date format
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                             "YYYY-MM-DD"
                          );
                          const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                           setFieldValue("issuedDate",dateFormat)
                        }
                      }
                    }}
                  />
                </DemoItem>
              </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Gl Credit Account
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="glCreditAccount"
                  placeholder="Enter GL Credit Account"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Gl Debit Account
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="glDebitAccount"
                  placeholder="Enter GL Debit Account"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
              <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          Create Issued Item
        </Typography>
        <IconButton
         sx={{backgroundColor:color.TableButtonColor}} 
        onClick={handleIssueList}
        >
          <Add sx={{color:"white"}}/>
        </IconButton>
        </Box>
        <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
              </Grid>
            
              <Grid container item xs={12} spacing={"1rem"} >
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Part
                </Typography>
                <Autocomplete
                // value={remedySelect}
                inputValue={partSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                loading={partData.length===0}
                options={partData}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.code + " - " + option.partName || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handlePart(value);
                  }else{
                    setPartSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setPartSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="partId"
                    placeholder="Select Part"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                          {partData.length===0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Stock Room
                </Typography>
                {/* <Select
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="stockRoomId"
                  value={receiptItem.stockRoomId}
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    receiptItem.stockRoomId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                  onChange={(e)=>{handleStockRoom(e.target.value)}}
                >
                  {stockRoomData.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.stockRoomCode+"_"+id.stockRoomName}
                    </MenuItem>
                  ))}
                </Select> */}
                 <Autocomplete
                // value={remedySelect}
                inputValue={srSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={issueItem.stockRoom}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handleStockRoom(value);
                  }else{
                    setSRSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomId"
                    placeholder="Select Stock Room"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />

              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Aisle
                </Typography>
                {/* <Select
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="stockRoomRowAisleId"
                  value={receiptItem.stockRoomRowAisleId}
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    receiptItem.stockRoomRowAisleId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                  onChange={(e)=>{handleStockAisle(e.target.value)}}
                >
                  {receiptItem.stockAisle.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.aisleCode+"_"+id.aisleName}
                    </MenuItem>
                  ))}
                </Select> */}
                     <Autocomplete
                // value={remedySelect}
                inputValue={srAisleSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={issueItem.stockAisle}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handleStockAisle(value);
                  }else{
                    setSRAisleSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRAisleSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomRowAisleId"
                    placeholder="Select Aisle"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Row
                </Typography>
                {/* <Select
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="stockRoomRowId"
                  value={receiptItem.stockRoomRowId}
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    receiptItem.stockRoomRowId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                  onChange={(e)=>{handleStockRow(e.target.value)}}
                >
                  {receiptItem.stockRow.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.rowCode+"_"+id.rowName}
                    </MenuItem>
                  ))}
                </Select> */}
                     <Autocomplete
                // value={remedySelect}
                inputValue={srRowSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={issueItem.stockRow}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handleStockRow(value);
                  }else{
                    setSRRowSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRRowSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomRowId"
                    placeholder="Select Row"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Bin
                </Typography>
                {/* <Select
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="stockRoomRowBinId"
                  value={receiptItem.stockRoomRowBinId}
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    receiptItem.stockRoomRowBinId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                  onChange={(e)=>{handleStockBin(e.target.value)}}
                >
                  {receiptItem.stockBin.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.binCode+"_"+id.binName}
                    </MenuItem>
                  ))}
                </Select> */}
                     <Autocomplete
                // value={remedySelect}
                inputValue={srBinSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                // loading={partData.length===0}
                options={issueItem.stockBin}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.text || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handleStockBin(value);
                  }else{
                    setSRBinSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setSRBinSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="stockRoomRowBinId"
                    placeholder="Select Bin"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      // endAdornment: (
                      //   <React.Fragment>
                      //     {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                      //     {partData.length===0 ? (
                      //       <CircularProgress color="inherit" size={20} />
                      //     ) : null}
                      //     {params.InputProps.endAdornment}
                      //   </React.Fragment>
                      // ),
                    }}
                  />
                )}
              />
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Quantity
                </Typography>
                <TextField
                  size={"small"}
                  type="number"
                  name="quantity"
                  value={issueItem.quantity}
                  placeholder="Enter Quantity"
                  fullWidth
                  onChange={handleAddIssue}
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Unit Cost
                </Typography>
                <TextField
                  size={"small"}
                  type="number"
                  name="unitCost"
                  value={issueItem.unitCost}
                  placeholder="Enter Unit Cost"
                  fullWidth
                  onChange={handleAddIssue}
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                   Line Cost
                </Typography>
                <TextField
                  size={"small"}
                  type="number"
                  name="lineCost"
                  value={issueItem.lineCost}
                  placeholder="Enter Line Cost"
                  fullWidth
                  onChange={handleAddIssue}
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
            
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Issued Date
                </Typography>
               
           
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                  // views={['year', 'day', 'hours', 'minutes', 'seconds']}
                 
                  format="DD/MM/YYYY"
                    value={issueItem.issuedDate===null?null:dayjs(issueItem.issuedDate)}
                  
                  
                    // sx={{".css-1xhypcz-MuiStack-root":{
                    //   padding:0
                    // }}}

                    slotProps={{
                      textField: {
                        size: "small",
                      
                        fullWidth: true,
                      },
                    }}
                    fullWidth
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                          // "YYYY-MM-DDTHH:mm:ss" date format
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                             "YYYY-MM-DD"
                          );
                          const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          const storeDate={
                            ...issueItem,
                            issuedDate:dateFormat
                          }
                          setIssueItem(storeDate)
                        }
                      }
                    }}
                  />
                </DemoItem>
              </LocalizationProvider>
              </Grid>
           
              </Grid>
              <Grid item xs={12}>
              <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          Issue Item List
        </Typography>
      
        </Box>
        <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
              </Grid>
              <Grid item xs={12}>
              <NormalTable
          data={addIssue}
          columns={partColumn}
          filt={true}
        />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading||addIssue.length===0}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default IssueModal;
