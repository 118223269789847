export const isolation=[
    {
      "List_pos_in_iso": 1,
      "List_pos_in_norm": 1,
      "Location1": "",
      "Asset": "19PAD13AA001",
      "Isol_meas_lock_key": "A5",
      "Lock_required": "TRUE",
      "Electrical": "FALSE",
      "Mechanical": "TRUE",
      "ISO_Condition": "Valve Closed, Chain Locked & Caution Tagged"
    },
    {
      "List_pos_in_iso": 2,
      "List_pos_in_norm": 2,
      "Location1": "",
      "Asset": "19PAD13AN001",
      "Isol_meas_lock_key": "C26",
      "Lock_required": "TRUE",
      "Electrical": "TRUE",
      "Mechanical": "FALSE",
      "ISO_Condition": "Breaker racked out, Locked & Caution Tagged"
    },
    {
      "List_pos_in_iso": 3,
      "List_pos_in_norm": 3,
      "Location1": "",
      "Asset": "19PAD13AA002",
      "Isol_meas_lock_key": "",
      "Lock_required": "FALSE",
      "Electrical": "FALSE",
      "Mechanical": "TRUE",
      "ISO_Condition": "Vent Opened & Caution Tagged"
    },
    {
      "List_pos_in_iso": 4,
      "List_pos_in_norm": 4,
      "Location1": "",
      "Asset": "19PAD13AA003",
      "Isol_meas_lock_key": "",
      "Lock_required": "FALSE",
      "Electrical": "FALSE",
      "Mechanical": "TRUE",
      "ISO_Condition": "Vent Opened & Caution Tagged"
    }
  ]

 export const permitWorkList=[
    {
      "Permit_number": "LWS00714",
      "Status": "Issued",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "",
      "Work_description": "WTP Caustic Soda tanker unloading."
    },
    {
      "Permit_number": "LWS00720",
      "Status": "Issued",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "",
      "Work_description": "B14 HP Steam WI SHOV Disturbed during Opening"
    },
    {
      "Permit_number": "LWS00723",
      "Status": "Initial",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "",
      "Work_description": "KLPP Pest Control Services."
    },
    {
      "Permit_number": "PTW00148",
      "Status": "Issued",
      "Responsible_user": "SCEENGRC",
      "Isolation_description": "To adhered PPE",
      "Work_description": "To carry out carpet cleaning."
    },
    {
      "Permit_number": "PTW00154",
      "Status": "Issued",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "19GHP07AA001 Service Inlet vlv shut and Tag\n19GHD08AA002 Outlet vlv shut and Tag\n19GHD07AA001 Inlet vlv shut and Tag\nAll air vlv shut",
      "Work_description": "MMF 1 backwash inlet valve flange (19GHP07AA002) leaking."
    },
    {
      "Permit_number": "PTW00156",
      "Status": "Initial",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "Isolations to be carry as required by CP.",
      "Work_description": "Fire Alarm System together with general auditor:\nTo conduct fire alarm test at requested area"
    },
    {
      "Permit_number": "PTW00160",
      "Status": "Issued",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "CO2 System on Mnaual and Lever Down. FAP Zone 7/14/25/16/17/42 Off and FAP Door Open & Caution Tagged.",
      "Work_description": "13CYQ - Routine Filter Cleaning & Purging"
    },
    {
      "Permit_number": "PTW00170",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "GT14 FAP Zone 7/14/15/16/17/42 Off and Panel Door Open. CO2 System selected to Manual, Lever Down and Tagged.",
      "Work_description": "14CYQ - Routine Filter Cleaning & Purging"
    },
    {
      "Permit_number": "PTW00171",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "NOx water twin filter isolated one at a time.",
      "Work_description": "Routine Inspection of Twin Filters"
    },
    {
      "Permit_number": "PTW00178",
      "Status": "Issued",
      "Responsible_user": "SCEENGRC",
      "Isolation_description": "Isolation will be carried out by CP.",
      "Work_description": "MCW Pumps underground area lighting defective."
    },
    {
      "Permit_number": "PTW00179",
      "Status": "Issued",
      "Responsible_user": "SCEENGRC",
      "Isolation_description": "Isolation will be carried out by CP during termination.",
      "Work_description": "Security - New portable cabin at spare transformer and tank farm.\nTo run new power supply cable for lighting and plug."
    },
    {
      "Permit_number": "PTW00180",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRC",
      "Isolation_description": "ASCU # 2 breaker off and fuse removed.",
      "Work_description": "GT14 ACSU #2 breaker Trip.\nECI to replace old mcb with high rating mcb"
    },
    {
      "Permit_number": "PTW00181",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRC",
      "Isolation_description": "1. 19GCR41AP001 Effluent pump # 1 breaker off and cautioned tagged.\n2. 19GCR41AA001 Suction valve closed and cautioned tagged.\n3. 19GCR41AA003 Discharge valve closed and cautioned tagged.",
      "Work_description": "ST 4 effluent pump # 1 motor cooling fan cover broken"
    },
    {
      "Permit_number": "PTW00184",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "ST 4 Effluent discharge valve stiff."
    },
    {
      "Permit_number": "PTW00186",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "19BFA03BA002_MCW Basement sump pit pump - MCB Off and tagged.",
      "Work_description": "MCW Basement sump pit pump check valve defective.To inspect and cleaning valve."
    },
    {
      "Permit_number": "PTW00199",
      "Status": "Issued",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "Air Comp 3 isolated. Refer attachment.",
      "Work_description": "To carry out auto drain servicing"
    },
    {
      "Permit_number": "PTW00201",
      "Status": "Issued",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "Caustic Measure Tank isolated and Caution Tagged.",
      "Work_description": "HF Caustic Measure Tank false high level alarm"
    },
    {
      "Permit_number": "PTW00210",
      "Status": "Issued",
      "Responsible_user": "SCEENGRC",
      "Isolation_description": "Inlet and out valve closed",
      "Work_description": "GT15 Duplex Filter Inner side (Facing  T/Block) minor air leak."
    },
    {
      "Permit_number": "PTW00213",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "ROUTINE COUPLING ALIGNMENT CHECK"
    },
    {
      "Permit_number": "PTW00214",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "ROUTINE COUPLING ALIGNMENT CHECK"
    },
    {
      "Permit_number": "PTW00215",
      "Status": "Issued",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "To replace CT3 pvc infills (Isolation needed 9/8/2016 9am)\n- WO:67249"
    },
    {
      "Permit_number": "PTW00217",
      "Status": "Issued",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "15CYQ - Routine Filter Cleaning & Purging"
    },
    {
      "Permit_number": "PTW00218",
      "Status": "Issued",
      "Responsible_user": "SCEENGRC",
      "Isolation_description": "Vent fan # 2 - breaker off and caution tagged\nT/B CO2 system manual and lever down",
      "Work_description": "GT13 T/B vent fan 2 Trip."
    },
    {
      "Permit_number": "PTW00223",
      "Status": "Issued",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "Twin filter standby drain open and cautioned.",
      "Work_description": "GT13 Fuel oil twin filter DP > max 1 alarm activated. Filter changed over. Pls carry out cleaning."
    },
    {
      "Permit_number": "PTW00224",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "GT13, 14 & 15 - Routine DP transmitter purging"
    },
    {
      "Permit_number": "PTW00225",
      "Status": "Issued",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "LWS",
      "Work_description": "MCW pump basement - to install new level switch & alarm for sump pit."
    },
    {
      "Permit_number": "PTW00228",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "MB Feedpp2 breaker and control supply isolated and Tagged.",
      "Work_description": "WTP MB PP2 - Routine Motor & Breaker PM"
    },
    {
      "Permit_number": "PTW00229",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "Regen pp2 breaker and control supply isolated and Tagged.",
      "Work_description": "WTP Regen PP2 - Routine Motor & Breaker PM"
    },
    {
      "Permit_number": "PTW00233",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "LWS",
      "Work_description": "ST Lube Oil Purifier local press gauge Faulty."
    },
    {
      "Permit_number": "PTW00234",
      "Status": "Issued",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "LWS",
      "Work_description": "KLPP Street Lighting Maintenance"
    },
    {
      "Permit_number": "PTW00235",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "",
      "Work_description": "Stream 4 sump pump not pumping"
    },
    {
      "Permit_number": "PTW00239",
      "Status": "Issued",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "B15 HP Steam Bypass SHOV and CV isolated. Sim required during stroke test.",
      "Work_description": "B15 HP Steam Bypass SHOV failed to Open."
    },
    {
      "Permit_number": "PTW00240",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "Existing Stream Effluent System isolated.",
      "Work_description": "Effluent Tank inlet line flange leak."
    },
    {
      "Permit_number": "PTW00241",
      "Status": "Issued",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "CO2 System selected to Manual and Lever Down. Caution Tagged.",
      "Work_description": "GT13 Zone 33 fault."
    },
    {
      "Permit_number": "PTW00243",
      "Status": "Issued",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "To brazing the copper tube leaking point at out door unit air con compressor."
    },
    {
      "Permit_number": "PTW00245",
      "Status": "Issued",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "Transfer pp check vlv servicing and inspection"
    },
    {
      "Permit_number": "PTW00248",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "CMS Off",
      "Work_description": "Fire equipment testing inspection and hydrant pump house testing"
    },
    {
      "Permit_number": "PTW00249",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "",
      "Work_description": "GT14 fuel oil vent valve # 2 suddenly goes to disturbed"
    },
    {
      "Permit_number": "PTW00252",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "",
      "Work_description": "KLPP Plant B Street Lighting 12 & 21 Faulty"
    },
    {
      "Permit_number": "PTW00253",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "",
      "Work_description": "B13 LP drum vent vlv indicating dist SMA SMG"
    },
    {
      "Permit_number": "PTW00258",
      "Status": "Initial",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "Pump to be isolated once request by ECI and if permission given by operation.",
      "Work_description": "WTP -  many motor cooling fan cover found in bad condition. \nTo repair"
    },
    {
      "Permit_number": "PTW00261",
      "Status": "Initial",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "WTP MB PP3 - Routine Motor & Breaker PM"
    },
    {
      "Permit_number": "PTW00269",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "CT fan 10 - Breaker outgoing cable yellow phase temperature high"
    },
    {
      "Permit_number": "PTW00273",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "",
      "Work_description": "Mix bed Feed pump 1 Check valve passing"
    },
    {
      "Permit_number": "PTW00276",
      "Status": "Issued",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "",
      "Work_description": "To istall new line piping from hypo skid to mcw line pipe.\nAll pipe line at top rack replace with new PVC pipe."
    },
    {
      "Permit_number": "PTW00277",
      "Status": "Issued",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "",
      "Work_description": "Gen 14 overhead crane 5T antenna inspection"
    },
    {
      "Permit_number": "PTW00280",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "",
      "Work_description": "WTP acid unloading"
    },
    {
      "Permit_number": "PTW00281",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "HRSG 13/14/15 on line Trevitest.",
      "Work_description": "HRSG13,14,15 Safety Valve Trevitest Prior DOSH Inspection.\n13HAD10AA001\tLP STEAM DRUM SAFETY VALVE 1\n13HAD10AA002\tLP STEAM DRUM SAFETY VALVE 2\n13HAD50AA001\tHP STEAM DRUM SAFETY VALVE 1\n13HAD50AA002\tHP STEAM DRUM SAFETY VALVE 2\n13LBA50AA001\tHP SUPER HEATER SAFETY VALVE\n13HAC15AA005      LP FEED WATER SV\n14HAD10AA001\tLP STEAM DRUM SAFETY VALVE 1\n14HAD10AA002\tLP STEAM DRUM SAFETY VALVE 2\n14HAD50AA001\tHP STEAM DRUM SAFETY VALVE 1\n14HAD50AA002\tHP STEAM DRUM SAFETY VALVE 2\n14LBA50AA001\tHP SUPER HEATER SAFETY VALVE\n14HAC15AA005      LP FEED WATER SV\n15HAD10AA001\tLP STEAM DRUM SAFETY VALVE 1\n15HAD10AA002\tLP STEAM DRUM SAFETY VALVE 2\n15HAD50AA001\tHP STEAM DRUM SAFETY VALVE 1\n15HAD50AA002\tHP STEAM DRUM SAFETY VALVE 2\n15LBA50AA001\tHP SUPER HEATER SAFETY VALVE\n15HAC15AA005      LP FEED WATER SV"
    },
    {
      "Permit_number": "PTW00284",
      "Status": "Issued",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "LWS - Lighting Maintenance.",
      "Work_description": "Lighting Maintenance All Building Area"
    },
    {
      "Permit_number": "PTW00285",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "LWS - Thermograph Survey",
      "Work_description": "Thermography Survey Priority 2"
    },
    {
      "Permit_number": "PTW00293",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "To repair dislodged nozzle & install new water spray nozzles at CT9"
    },
    {
      "Permit_number": "PTW00297",
      "Status": "Issued",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "Switch over Filter A to filter B. Filter A to Inspect.",
      "Work_description": "To open fuel oil twin filter strainer for inspection."
    },
    {
      "Permit_number": "PTW00298",
      "Status": "Issued",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "Old Stream Regen pumps outlet feed in to MB tank line leak badly.To check and retify."
    },
    {
      "Permit_number": "PTW00299",
      "Status": "Issued",
      "Responsible_user": "SCEENGRA",
      "Isolation_description": "",
      "Work_description": "17LAC20AP001"
    },
    {
      "Permit_number": "PTW00301",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "LWS_ B13/14/15 LP and HP Safety vlv recheck and re-Trevitest on line.",
      "Work_description": "LP Econ and HP drum safety valves lifting, to be retest and locked"
    },
    {
      "Permit_number": "PTW00304",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "No isolations required",
      "Work_description": "KLPP Stack light Maintenance"
    },
    {
      "Permit_number": "PTW00305",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "No isolations required.",
      "Work_description": "GT13, 14 & 15 - Routine DP transmitter purging"
    },
    {
      "Permit_number": "PTW00308",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "",
      "Work_description": "HPFW pp 1 DP suction strainer high."
    },
    {
      "Permit_number": "PTW00310",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRD",
      "Isolation_description": "LWS - Unloading supv by Ops Tech.",
      "Work_description": "WTP Sulphuric Acid delivery and unloading."
    },
    {
      "Permit_number": "PTW00311",
      "Status": "Issued",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "",
      "Work_description": "To carry put monthly servicing."
    },
    {
      "Permit_number": "PTW00315",
      "Status": "Cancelled",
      "Responsible_user": "SCEENGRB",
      "Isolation_description": "",
      "Work_description": "PUMP: LUBE OIL CHANGE"
    }
  ]

  export const normalisation=[
    {
      "List_pos_in_iso": 1,
      "List_pos_in_norm": 1,
      "Location1": "",
      "Asset": "19PAD13AA001",
      "Location2": "",
      "Asset2": "",
      "Electrical": "FALSE",
      "Mechanical": "TRUE",
      "NORM_Condition": "Removed Chain & Caution Tag",
    },
    {
      "List_pos_in_iso": 2,
      "List_pos_in_norm": 2,
      "Location1": "",
      "Asset": "19PAD13AN001",
      "Location2": "",
      "Asset2": "",
      "Electrical": "TRUE",
      "Mechanical": "FALSE",
      "NORM_Condition": "Breaker racked in, Removed Lock & Caution Tag",
    },
    {
      "List_pos_in_iso": 3,
      "List_pos_in_norm": 3,
      "Location1": "",
      "Asset": "19PAD13AA002",
      "Location2": "",
      "Asset2": "",
      "Electrical": "FALSE",
      "Mechanical": "TRUE",
      "NORM_Condition": "Vent Closed & Removed Caution Tag",
    },
    {
      "List_pos_in_iso": 4,
      "List_pos_in_norm": 4,
      "Location1": "",
      "Asset": "19PAD13AA003",
      "Location2": "",
      "Asset2": "",
      "Electrical": "FALSE",
      "Mechanical": "TRUE",
      "NORM_Condition": "Vent Closed & Removed Caution Tag",
    }
  ]

  export const contextualMenuChangeStatus=[
    "Status",
"New",
"Confirmed",
"Issued",
"Re-Issued",
"Suspended",
"Cancelled"

  ]

  export const contextualMenuWOPW=[
    {
      "workOrderCode": "0000025",
      "workOrderName": "To Replace CT3 pvc infills (Isolation needed 9/8/2016 9am)",
      "status": "In-Progresss",
      "workType": "PM"
    }
  ]