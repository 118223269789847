import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import { Box, Checkbox, Dialog, IconButton, Typography } from "@mui/material";
import { useSearchParams,useNavigate, Link } from "react-router-dom";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  ListAlt,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import {useDispatch, useSelector} from "react-redux"

import InventoryCreationModal from "./InventoryCreationModal";
import StockLevelEdit from "./StockLevelEdit";

const InventoryCreation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const actionsAllow=useSelector(state=>state.dataRed.actionsAllow)

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [stockLevelEdit,setStockLevelEdit]=useState({
    bol:false,
    data:{}
  })


  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["inventory"].getAll;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        res.data.result.sort((a, b) => {
          return a.code.localeCompare(b.code, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        console.log(res.data, "inventory result");
        const uniqueIds = []
        for(let i=0;res.data.result.length>i;i++){
          const sortListPart=uniqueIds.filter((fil=>fil.partId===res.data.result[i].partId&&fil.stockRoomId===res.data.result[i].stockRoomId))
        
          // console.log(uniqueIds,res.data.result[i].partId,res.data.result[i].stockroomId)
        
          if (sortListPart.length===0) {
          // console.log(item)
           uniqueIds.push(res.data.result[i]);
         
        }
        }
      
  

console.log(uniqueIds);
        setData(uniqueIds);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "parts");
        setIsLoading(false);
        setData([])
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setStockLevelEdit({
      bol:true,
      data:data
    });
    
  };

  const handleDelete = async(id) => {
    const deleteLink = masterAPI["inventory"].delete;
   await axiosPrivate
      .delete(`${deleteLink}/${id}`)
      .then((res) => {
        console.log(res.data);
        getData();
      })
      .catch((e) => {
        console.log(e, "delete parts");
      });
  };

  const columns = [
    {
      accessor: "id",
      disableFilters: true,
      Cell: () => {
        return (
          <Box sx={{ width: color.checkBoxWidth }}>
            <Checkbox
              // icon={<RadioButtonUncheckedOutlined />}
              // checkedIcon={<CheckCircle />}
            />
          </Box>
        );
      },
    },
    {
      Header: "Part Code",
      accessor: "code",
      Cell: ({ cell }) => {
        return (
          <Link
            to={`/inventoryview`}
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
            onClick={()=>{
              dispatch({type:"partStockRoom",payload:cell.row.original})
            }}
          >
            {cell.value}
          </Link>
        );
      },
      // disableFilters: true,
    },
    {
      Header: "Part Name",
      accessor: "partName",
      // disableFilters: true,
    },

    {
      Header: "Stock Room",
      accessor: "stockRoomName",
    },
   
    {
      Header: "Quantity",
      accessor: "totalQuantity",
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
           
            <IconButton 
            disabled={
              !actionsAllow.edit 
            }
            onClick={() => {
              
              handleEdit(cell.row.original);
            }}
            >
              <EditOutlined
                
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton>
            {/* <IconButton>
              <DeleteOutline
                onClick={() => {
                  handleDelete(cell.row.original.id);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton> */}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["inventoryCreation"].title}
        </Typography>
      </Box>

      <TableContainer
        columns={columns}
        data={data}
        buttonNew={handleButtonNew}
        loading={isLoading}
        buttonDisable={!actionsAllow.create}
      />

      <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"md"}
        children={
          <InventoryCreationModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      />
      <Dialog
        open={stockLevelEdit.bol}
        onClose={()=>{
          setStockLevelEdit({bol:false,data:{}})
          getData()
        }}
        maxWidth={"md"}
        children={
          <StockLevelEdit
            handleCancel={()=>{
              setStockLevelEdit({bol:false,data:{}})
              getData()
            }}
            
            data={stockLevelEdit.data}
          />
        }
      />
    </>
  );
};

export default InventoryCreation;
