import { Save } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { color } from "../../../../../StyleData/Config";
import { axiosPrivate } from "../../../../../axios/axios";
import { masterAPI } from "../../../../Masters/dataConfig";
import { contextualMenuChangeStatus } from "../../../../../SampleData/PermitWork";

const ChangeStatus = ({ data,getData }) => {
  const [selectList, setSelectList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDataList()
  }, []);
  
 const getDataList=async()=>{
const sampleData=contextualMenuChangeStatus
setSelectList(sampleData)

 }

  const formik = useFormik({
    initialValues: {
      // assetId:data.id,
      code: data,
      // assetName: data.assetName,
      // statusId: data.assetStatusId,
    },
    enableReinitialize: true,
    onSubmit: async(value) => {
    //   setLoading(true);
    //   const changeLink = masterAPI["assets"].changeStatus;
    //  await axiosPrivate
    //     .put(`${changeLink}/${value.assetId}/${value.statusId}`)
    //     .then((res) => {
    //       console.log(res);
    //       getData()
    //       setLoading(false);
    //     })
    //     .catch((e) => {
    //       setLoading(false);
    //       console.log(e, "changeStatus update");
    //     });
    },
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>
        <Typography
          sx={{
            color: color.sideTitleColor,
            fontSize: color.fontSizeSide,
            fontWeight: color.sideWeightFont,
          }}
        >
          Change Status
        </Typography>
      </Box>

      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          p: 1,
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: color.sideTitle2Color,
              fontSize: color.fontSizeSide,
              fontWeight: color.sideFontWeight,
            }}
          >
            Details
          </Typography>
          <IconButton type="submit" onClick={formik.handleSubmit}>
            {loading ? <CircularProgress /> : <Save sx={{ color: "grey" }} />}
          </IconButton>
        </Box> */}
        <Divider />
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Permit Code
              </Typography>
              <TextField
                variant="standard"
                size={"small"}
                type="text"
                name="code"
                value={formik.values.code}
                placeholder="Enter Asset Id"
                // fullWidth
                onChange={formik.handleChange}
                sx={{
                  width: "45%",
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Box>
            {/* <Box>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Asset Name
              </Typography>
              <TextField
                variant="standard"
                size={"small"}
                type="text"
                name="assetName"
                value={formik.values.assetName}
                placeholder="Enter Asset Name"
                onChange={formik.handleChange}
                // fullWidth
                sx={{
                  width: "45%",
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Box> */}

            <Box>
              <Typography
                sx={{
                  mb: "0.5rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Status
              </Typography>

              <Select
                // label={"Select"}
                size={"small"}
                type="text"
                name="statusId"
                variant="standard"
                value={formik.values.statusId}
                onChange={formik.handleChange}
                // fullWidth
                sx={{ width: "45%", fontSize: "13px" }}
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                renderValue={
                  formik.values.statusId !== ""
                    ? undefined
                    : () => (
                        <Typography
                          sx={{
                            color: color.placeholderColor,
                            fontSize: "13px",
                          }}
                        >
                          Select
                        </Typography>
                      )
                }
              >
                {selectList.map((id, i) => (
                  <MenuItem value={id}>{id}</MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ChangeStatus;
