import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { masterAPI } from "../Pages/Masters/dataConfig";
import { axiosPrivate } from "../axios/axios";
import { color } from "../StyleData/Config";
import { Clear, FileUploadOutlined, Save } from "@mui/icons-material";
import { toast } from "react-toastify";

const DocUpload = ({ handleUpload, handleCloseClick,page }) => {
  const [type, setType] = useState([]);
  const [category, setCategory] = useState([]);
  const [fileName, setFileName] = useState("");
  const [fileType,setFileType]=useState("")

  useEffect(() => {
    getData()
  }, []);

  const getData=async()=>{
    const typeLink = masterAPI["selectList"].documentType;
   await axiosPrivate
      .get(`${typeLink}`)
      .then((res) => {
        console.log(res);
        setType(res.data);
      })
      .catch((e) => {
        console.log(e, "error document type");
      });
    const categoryLink = masterAPI["selectList"].documentcategories;
   await axiosPrivate
      .get(`${categoryLink}`)
      .then((res) => {
        console.log(res);
        setCategory(res.data);
      })
      .catch((e) => {
        console.log(e, "error document category");
      });
  }

  const initialValue = {
    documentName: "",
    documenttype: "",
    documentcategory: "",
    documentData: "",
  };
  const onSubmit = (values,{resetForm}) => {
    handleUpload({...values,documentName:`${values.documentName}.${fileType}`});
    resetForm()
    setFileName("")
    setFileType("")
  };
  return (
    <Box sx={{ p: 1.5 }}>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1.5rem"}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                    }}
                  >
                    Document Upload
                  </Typography>
                  <Box>
                    <IconButton type="submit">
                      <Save sx={{ color: "#2362B4" }} />
                    </IconButton>
                    <IconButton onClick={handleCloseClick} sx={{display:page==="sr"?"none":"default"}}>
                      <Clear />
                    </IconButton>
                  </Box>
                </Box>

                <Divider
                  sx={{
                    border: "1px solid rgb(0 0 0 / 17%)",
                    //   mb: "15px",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Document Name
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="documentName"
                  placeholder="Enter Task Name"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Document Type
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="documenttype"
                  fullWidth
                  displayEmpty
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: "40vh" } },
                  }}
                  renderValue={
                    values.documenttype !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {type.map((id, i) => (
                    <MenuItem value={id.value}>{id.text}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Document Category
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="documentcategory"
                  fullWidth
                  displayEmpty
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: "40vh" } },
                  }}
                  renderValue={
                    values.documentcategory !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {category.map((id, i) => (
                    <MenuItem value={id.value}>{id.text}</MenuItem>
                  ))}
                </Field>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Document File
                </Typography>
                <Button component="label" variant="contained">
                  <FileUploadOutlined />{" "}
                  <Typography sx={{ fontSize: "0.9rem" }}>Add File</Typography>
                  <input
                    type="file"
                    // accept={"image/png, image/gif, image/jpeg"}
                    hidden
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      if (e.target.files[0] !== undefined) {
                        const file = e.target.files[0];
                        console.log(file.name);
                        const fileType = file.type.split("/")[1];
                        console.log(fileType);
                        // if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {

                        //   toast.error("Invalid format");
                        // } else {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                          // console.log(reader.result);
                          const dataSplit = reader.result.split("base64,")[1];
                          // console.log(dataSplit)
                          setFileName(file.name);
                          setFileType(e.target.files[0].type.split("/")[1])
                  
                          setFieldValue("documentData", dataSplit);
                        };
                        reader.onerror = function (error) {};
                        // }
                      } else {
                        setFileName("");
                        setFieldValue("documentData", "");
                      }
                    }}
                  />
                </Button>
                <Typography
                  sx={{
                    // mb: color.labelmb,

                    fontSize: color.labelFont,
                    // color: color.labelColor,
                  }}
                >
                  {fileName}
                </Typography>
              </Grid>

              {/* <Grid container item xs={12} justifyContent={"flex-end"}>
                      <Button
                        onClick={() => {
                         handleClose()
                        }}
                      >
                        cancel
                      </Button>
                      <Button type="submit">submit</Button>
                    </Grid> */}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DocUpload;
