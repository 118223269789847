const Reducer = (
  state = {
    // loginInfo:false
    loginInfo: {},
    //////////Drawer///////////
    drawerOpen: {
      bol: false,
      open: false,
    },
  },
  actions
) => {
  switch (actions.type) {
    case "loginInfo":
      return { ...state, loginInfo: actions.payload };
    case "updateAccessToken":
      return {...state,loginInfo:{...state.loginInfo,accessToken:actions.payload.accessToken,refreshToken:actions.payload.refreshToken}}
    case "logout":
      return {
        ...state,
        loginInfo: actions.payload,
        drawerOpen: {
          bol: false,
          open: false,
        },
      };

    case "drawerOpen":
      return { ...state, drawerOpen: actions.payload };

    default:
      return state;
  }
};

export const authReducer = Reducer;
