import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Grid, IconButton, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import { color } from "../../../../../StyleData/Config";
// import Doc from "./Doc";
import {
  AssignmentInd,
  Clear,
  DisplaySettings,
  Diversity3Outlined,
  HomeWork,
  Insights,
  ReceiptLong,
  Speed,
  VerifiedUser,
  VerifiedUserOutlined,
} from "@mui/icons-material";

import { useDispatch } from "react-redux";
import Workorder from "./Workorder";
// import ChangeStatus from "./ChangeStatus";
// import Meter from "./Meter";
// import Transfer from "./Transfer";
// import AssignedTo from "./AssignedTo";
// import AssetWarrantyModal from "../../Warranty/WarrantyModal";
// import Log from "./Log";
// import WarrantyModal from "./WarrantyModal";

const ListMenuPermitRequest = ({ contentPosition, data, getData }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("0");
  const handleChange = (event, newValue) => {
    dispatch({type:"drawerOpen",payload:{bol:true,open:false}})
    setValue(newValue);
    contentPosition("column");
    // getValue(newValue)
  };

  const OnCancel = () => {
    setValue("0");
    contentPosition("row");
    dispatch({ type: "drawerOpen", payload: { bol: false, open: false } });
    // getValue("0")
  };
  return (
    <TabContext value={value}>
      {/* <Box sx={{display:"flex",gap:"1rem"}}> */}

      <Grid container spacing={{ xs: "3rem", lg: "2rem" }} sx={{ p: 0 }}>
        <Grid item xs={8} md={9} lg={10} sx={{ display: value === "0" ? "none" : "visible" }}
        >
          {/* <Box sx={{boxShadow:"-1px 1px 7px 2px rgb(0 0 0 / 32%)",minHeight:"85vh",display:value!=="0"?"block":"none", backgroundColor:"transparent"}}> */}
          <TabPanel value="1" sx={{ p: 1 }}>
            <Workorder/>
          </TabPanel>

          <TabPanel value="0" sx={{ display: "none" }}></TabPanel>
          {/* </Box> */}
        </Grid>
        <Grid
          container
          item
          xs={value !== "0" ? 4 : 12}
          lg={value !== "0" ? 2 : 12}
          md={value !== "0" ? 3 : 12}
          justifyContent={"flex-end"}
        >
          <Box
            sx={{
              ".css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                color: color.TabLabelcolor,
                // p: 0.5,
              },
              ".css-hg18dx-MuiButtonBase-root-MuiTab-root": {
                alignItems: "flex-start",
              },
              // display: "flex",
              // flexDirection:"column",
              // justifyContent: "flex-end",
              // alignItems: "center",
              // height: value!=="0"?"75.5vh":"100vh",

              gap: "2rem",
              mt: { xs: "3rem", md: "1rem" },
            }}
          >
            <Box
              sx={{ display: value !== "0" ? "block" : "none", ml: "1.5rem",}}
            >
              <IconButton sx={{ backgroundColor: "white" }} onClick={OnCancel}>
                <Clear />
              </IconButton>
            </Box>
            <TabList
              orientation="vertical"
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: color.TabLabelcolor,
                  height: "3px",
                  left: 0,
                },
              }}
              sx={{
                backgroundColor: "#D3E6FC",
                mt: { xs: 0, md: "4rem", lg: "3rem" },
              }}
            >
              <Tab
                icon={
                  <HomeWork
                    sx={{ color: color.sideTabColor, fontSize: color.iconSize }}
                  />
                }
                label="Work Order"
                value="1"
                sx={{
                  textTransform: "none",
                  color: color.sideTabColor,
                  fontSize: color.label,
                  mb: "0.15rem",
                }}
              />
               {/* <Tab
                icon={
                  <Insights
                    sx={{ color: color.sideTabColor, fontSize: color.iconSize }}
                  />
                }
                label="Change Status"
                value="3"
                sx={{
                  textTransform: "none",
                  color: color.sideTabColor,
                  fontSize: color.label,
                  mb: "0.15rem",
                }}
              /> */}
            </TabList>
          </Box>
        </Grid>
      </Grid>
      {/* </Box> */}
    </TabContext>
  );
};

export default ListMenuPermitRequest;
