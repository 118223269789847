import { Box, Divider, Grid, IconButton, Paper, TextField, Typography,Select,MenuItem, Tab, Menu } from '@mui/material'
import React, { useEffect, useState } from 'react'



import { useSearchParams } from 'react-router-dom'
import ListMenuPM from './SideNav.js/ListMenuPM'
import PMView from './PMView'
import moment from 'moment'
import { pmSample } from '../../../SampleData/PMSample'
import { axiosPrivate } from '../../../axios/axios'
import { masterAPI } from '../../Masters/dataConfig'

const PM = () => {
  
  const [direction, setDirection] = useState("row");
  const [searchParams,setSearchParams]=useSearchParams()
   const [pmData,setPmData]=useState({})
   const [isLoading,setIsLoading]=useState(false)
 

  

  useEffect(()=>{
    getData()
    },[])

    const getData=async()=>{
      setIsLoading(true)
 
      const id=searchParams.get("id")
      const pmLink=masterAPI["PM"].getByid
     await axiosPrivate.get(`${pmLink}/${id}`).then(res=>{
       console.log(res,"pm id")
       setPmData(res.data.result)
      }).catch(e=>{
       setPmData([])
      console.log(e,"error pm view")
      })
      
   
    
   setIsLoading(false)
    }



  console.log(pmData,"dkkddk")
  const contentPosition = (flex) => {
    setDirection(flex);
  };
  return (
   <Grid container spacing={"1rem"}>
     <Grid
          item
          xs={direction === "column" ? 6 : 9.5}
          md={direction ==="column"? 6:10.5}
          lg={direction === "column" ? 6 : 11}
        >
           <PMView direction={direction} pmData={pmData}/>
        
            </Grid>
            <Grid
          container
          justifyContent={"flex-end"}
          item
          xs={direction === "column" ? 6 : 2.5}
          md={direction === "column" ? 6 : 1.5}
          lg={direction === "column" ? 6 : 1}
          sx={{boxShadow:direction ==="column"?"-1px 1px 7px 2px rgb(0 0 0 / 32%)":0,minHeight:"92vh", backgroundColor:"transparent"}}
        >
          <ListMenuPM contentPosition={contentPosition} data={pmData} direction={direction} 
          // getData={getData}
          />
        </Grid>
            </Grid>
  )
}

export default PM