import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  createFilterOptions,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear, FileUploadOutlined } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../dataConfig";

const MenusModal = ({ mode, handleCloseClick,editData }) => {
  const [parentId, setParentId] = useState([]);
  const [loading,setLoading]=useState(false)
  const [masterName,setMasterName]=useState(mode==="Edit"?editData.parentMenuName:"")

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
   getData()
  }, []);

  const getData=async()=>{
    setLoading(true)
    const link = masterAPI["menus"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setLoading(false)
        setParentId(res.data.result);
      })
      .catch((e) => {
        setParentId([])
        console.log(e, "createMenusgetAll");
      });
  }

  const initialValue = {
    name: mode==="Edit"?editData.name: "",
    href:mode==="Edit"?editData.href: "",
    description:mode==="Edit"?editData.description: "",
    displayOrder:mode==="Edit"?editData.displayOrder: "",
    parentMenuId:mode==="Edit"?editData.parentMenuId: null,
    icon: mode === "Edit"? editData.icon:"",
    apiRoute : mode === "Edit" ? editData.apiRoute:"",
    module : mode === "Edit" ? editData. module:""
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true)
    const createLink = masterAPI["menus"].create;
    const updateLink=masterAPI["menus"].update
    if(mode==="Create"){
     await axiosPrivate.post(`${createLink}`,value).then(res=>{
        console.log(res.data)
        setLoading(false)
        handleCloseClick()
      }).catch(e=>{
        setLoading(false)
        console.log(e,"createMenus")
      })
    }else{
      const updateAC={
        id:editData.id,
        ...value
      }
     await axiosPrivate.put(`${updateLink}`,updateAC).then(res=>{
        console.log(res.data)
        setLoading(false)
        handleCloseClick()
      }).catch(e=>{
        setLoading(false)
        console.log(e,"updateMenus")
      })
    }
   
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode === "Edit"?editData.name:"New Menu"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
      {({ handleChange, setFieldValue, values , resetForm }) => (
        <Form>
          <Grid container spacing={"1rem"}>
            
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                   Name
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="name"
                placeholder="Enter Name"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Href
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="href"
                placeholder="Enter Href"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Description
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="description"
                placeholder="Enter Description"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Display Order
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="displayOrder"
                placeholder="Enter Display Order"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Api Route
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="apiRoute"
                placeholder="Enter API Route"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Parent Menu
                </Typography>
              {/* <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="parentMenuId"
                placeholder="Enter Asset Category Name"
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.parentMenuId !== null ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {parentId.map((id, i) => (
                  <MenuItem value={id.id} disabled={editData.id===id.id&&mode==="Edit"}>{id.name}</MenuItem>
                ))}
              </Field> */}
               <Field
                       name="parentMenuId"
                           inputValue={masterName}
                           component={Autocomplete}
                           filterOptions={filterOptions}
                           loading={parentId.length === 0}
                           options={parentId} 
                           PaperComponent={CustomPaper}
                          
                           getOptionLabel={(option) =>option.name || option}
                           getOptionDisabled={(option) => editData.id===option.id&&mode==="Edit"}
                           freeSolo
                         onChange={(event,value) => {
                          
                       if(value){
                       console.log(value)
                       
                        setFieldValue("parentMenuId",value.id)
                        setMasterName(value.name)
                      
                    
                       }else{
                        setFieldValue("parentMenuId",null)
                        setMasterName("")
                       }
                        
                        }}
                        onInputChange={(e,v)=>{
                          setMasterName(v)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="parentMenuId"
                           placeholder="Search Parent Menu"
                            onChange={handleChange}
                            size="small"
                            // variant="standard"
                           
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                  {parentId.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>),
                            }}
                           />
                           )}
                      >
                      
                        {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                      </Field>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Icon
                </Typography>
                
                <Field
                as={TextField}
                size={"small"}
                type="text"
                name="icon"
                placeholder="Enter Icon Path"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Module
                </Typography>
                
                <Field
                as={TextField}
                size={"small"}
                type="text"
                name="module"
                placeholder="Enter Module"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
              </Grid>
            <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
              <Button
                onClick={handleCancel}
                variant="contained"
                sx={{
                  backgroundColor: color.TableCancelButton,
                  textTransform: "none",
                  color: color.CancelTextColor,
                  ":hover":{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type={"submit"}
                variant="contained"
                disabled={loading}
                sx={{
                      
                  textTransform: "none",
                  backgroundColor: loading ? "default" : color.TableButtonColor,
                  "&:hover": {
                    backgroundColor: loading
                      ? "default"
                      : color.buttonDisable,
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
      </Formik>
    </Box>
  );
};

export default MenusModal;
