import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";

import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../dataConfig";
import { DesktopDatePicker } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers";

const CriticalityModal = ({ mode, handleCloseClick, editData }) => {
  
  const [loading, setLoading] = useState(false);

  
  const initialValue = {
    criticalityName: mode === "Edit" ? editData.criticalityName : "",
    criticalityType: mode === "Edit" ? editData.criticalityType : "",
    code: mode === "Edit" ? editData.code : "",
    rpnFrom: mode === "Edit" ? editData.rpnFrom : 0,
    rpnTo: mode === "Edit" ? editData.rpnTo : 0,
    maxQuantity:mode === "Edit" ? editData.maxQuantity : 0,
    reOrderPoint:mode === "Edit" ? editData.reOrderPoint : 0
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["criticality"].create;
    const updateLink = masterAPI["criticality"].update;
    if (mode === "Create") {
     await axiosPrivate
        .post(`${createLink}`, value)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "createACrit");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
      console.log(updateAC)
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "updateACrit");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode === "Edit"?editData.code+"-"+editData.criticalityName:"New Criticality"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
            <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Criticality Code
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="code"
                  placeholder="Enter Criticality Code"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Criticality Name
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="criticalityName"
                  placeholder="Enter Criticality Name"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Criticality Type
                </Typography>
                <RadioGroup
                  row
                  name="criticalityType"
                  value={values.criticalityType}
                  onChange={(e) => {
                    setFieldValue("criticalityType", e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Asset"
                    control={<Radio />}
                    label="Asset"
                  />
                  <FormControlLabel
                    value="Parts"
                    control={<Radio />}
                    label="Parts"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={6} sx={{display:values.criticalityType==="Asset"?"visible":"none"}}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Rpn From
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="rpnFrom"
                  placeholder="Enter Rpn From"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={{display:values.criticalityType==="Asset"?"visible":"none"}}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Rpn To
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="rpnTo"
                  placeholder="Enter Rpn To"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={{display:values.criticalityType==="Parts"?"visible":"none"}}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >  
                 Max Quantity
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="number"
                  name="maxQuantity"
                  placeholder="Enter Max Quantity"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={{display:values.criticalityType==="Parts"?"visible":"none"}}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 ReOrder Point
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="number"
                  name="reOrderPoint"
                  placeholder="Enter ReOrder Point"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CriticalityModal;
