import { Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { color } from '../../../../../StyleData/Config'
import NormalTable from '../../../../../Component/NormalTable'
import { normalisation } from '../../../../../SampleData/PermitWork'

const Normalisation = ({data}) => {
    const [sampleData,setSampleData]=useState([])

    useEffect(()=>{
       setSampleData(normalisation)
    },[])

    const columns = [
      {
        Header: "List pos.in iso",
        accessor: "List_pos_in_iso",
      },
      {
        Header: "List pos.in norm",
        accessor: "List_pos_in_norm",
      },
      {
        Header: "Location1",
        accessor: "Location1",
      },
      {
        Header: "Asset",
        accessor: "Asset",
      },
      {
        Header: "Location2",
        accessor: "Location2",
      },
      {
        Header: "Asset2",
        accessor: "Asset2",
      },
      {
        Header: "Electrical",
        accessor: "Electrical",
      },
      {
        Header: "Mechanical",
        accessor: "Mechanical",
      },
      {
        Header: "NORM Condition",
        accessor: "NORM_Condition",
      },
    ];
  return (
    <Paper elevation={3} sx={{p:1}}>
        <Grid container spacing={"1rem"}>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={4} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Permit Code :
                </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                }}
                >
                  {data}  
                </Typography>
            </Grid>
             </Grid>
             </Grid>
          

        </Grid>
        
        <Grid item xs={12}>
            <NormalTable data={sampleData} columns={columns}  />
        </Grid>

        </Grid>

    </Paper>
  )
}

export default Normalisation