import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
    Checkbox,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Field, Form, Formik } from "formik";
  import * as Yup from "yup";
  import { Clear, DeleteOutline } from "@mui/icons-material";
  import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import dayjs from "dayjs";
  import moment from "moment";
  
  import { color } from "../../../StyleData/Config";
  import { axiosPrivate } from "../../../axios/axios";
  import { masterAPI } from "../dataConfig";
  import { DesktopDatePicker } from "@mui/lab";
  import { DateTimePicker } from "@mui/x-date-pickers";
import NormalTable from "../../../Component/NormalTable";
  
  const BusinessModal = ({ mode, handleCloseClick, editData }) => {
    
    const [loading,setLoading]=useState(false)
    const [busType,setBusType]=useState([])
    const [craftData,setCraft]=useState([])
    const [selectCraft,setSelCraft]=useState([])
    const [craftIden,setCraftIden]=useState([])
    const [aldreadySelCraft,setAldreadySelCraft]=useState([])
    const [businessCraft,setBusinessCraft]=useState([])
  
    useEffect(() => {
  getDataList()
      
    }, []);

    const getDataList=async()=>{
      const link = masterAPI["selectList"].businessType;
     await axiosPrivate
        .get(`${link}`)
        .then((res) => {
          setBusType(res.data);
        })
        .catch((e) => {
          setBusType([])
          console.log(e, "createACgetAll");
        });
        const craftLink=masterAPI["crafts"].link
       await axiosPrivate
        .get(`${craftLink}`)
        .then((res) => {
          setCraft(res.data.result);
        })
        .catch((e) => {
          setCraft([])
          console.log(e, "create craft PersonAll");
        });
        if(mode==="Edit"){
          getData()
        }
    }

    const getData=async()=>{
      // console.log(editData)
      const labCraftlink=masterAPI["businessCraft"].craftByBusiness
     await axiosPrivate.get(`${labCraftlink}/${editData.id}`).then(res=>{
        const craftSel=[]
        for(let i=0;res.data.result.length>i;i++){
          craftSel.push(res.data.result[i].craftId)
        }
        setAldreadySelCraft(craftSel)
        setSelCraft(craftSel)
        setBusinessCraft(res.data.result)
      }).catch(e=>{
        console.log(e)
        setAldreadySelCraft([])
        setSelCraft([])
        setBusinessCraft([])
      })
    }
  
    const initialValue = {
      businessName: mode === "Edit" ? editData.businessName : "",
      code: mode === "Edit" ? editData.code : "",
      businessDesc: mode === "Edit" ? editData.businessDesc : "",
      email: mode === "Edit" ? editData.email : "",
      address: mode === "Edit" ? editData.address : "",
      mobileNo: mode === "Edit" ? editData.mobileNo : "",
      url: mode === "Edit" ? editData.url : "",
      businessType : mode === "Edit" ? editData.businessType:""
    };
  
    const onSubmit = async(value) => {
      console.log(value);
      setLoading(true)
      const createLink = masterAPI["businessCraft"].create;
      const updateCraftLink = masterAPI["businessCraft"].update;
      const updateLink = masterAPI["business"].update;
      if (mode === "Create") {
        const postData={
          ...value,
          craftList:craftIden
        }
        console.log(postData)
     await axiosPrivate
        .post(`${createLink}`, postData)
        .then((res) => {
          console.log(res.data);
          setLoading(false)
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false)
          console.log(e, "createBusiness");
        });
    } else {
      const updateAC = {
        businessId: editData.id,
        craftList:craftIden,
        
      };
      const updateBus={
        id:editData.id,
        ...value
      }
     console.log(updateBus,updateAC)
     
     await axiosPrivate
      .put(`${updateLink}`, updateBus)
      .then((res) => {
        console.log(res.data);
        setLoading(false)
        // handleCloseClick();
      })
      .catch((e) => {
        setLoading(false)
        console.log(e, "updateBus");
      });
   
    if(craftIden.length!==0){
     await axiosPrivate
        .put(`${updateCraftLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false)
          // handleCloseClick();
        })
        .catch((e) => {
          setLoading(false)
          console.log(e, "updateBus");
        });
      }
      handleCloseClick();
    }
    };
  
    const handleCancel = () => {
      handleCloseClick();
    };

    
    const handleCraft=(e,id)=>{
      if(e.target.checked){
       setSelCraft([...selectCraft,id])
       setCraftIden([...craftIden,{craftId:id,isNewRecord:true}])
      }else{
       const filterData=selectCraft.filter(fil=>fil!==id)
       setSelCraft(filterData)
       const filterCraft=craftIden.filter(fil=>fil.craftId!==id)
       setCraftIden(filterCraft)
      }
 
     }

     const handleDelete = async(id) => {
      console.log(id)
      const getBusinessCraftId=businessCraft.filter(fil=>fil.businessId===editData.id&&fil.craftId===id)
    
      const deleteLink = masterAPI["businessCraft"].delete;
     await axiosPrivate
        .delete(`${deleteLink}/${getBusinessCraftId[0].id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        }) 
        .catch((e) => {
          console.log(e, "deleteBus");
        });
    };

    const craftColumns=[
      {
        accessor: "id",
        disableFilters: true,
        Cell: ({cell}) => {
          return (
           
            <Checkbox
            disabled={aldreadySelCraft.includes(cell.value)}
         
            checked={selectCraft.includes(cell.value)}
            onClick={(e)=>{
              handleCraft(e,cell.value)
            }}
            sx={{
           
                "&.Mui-disabled":{
                   color:"#1976d2"
                }
         
            
              
            }}
            />
         
          );
        },
      },
      {
        Header: "Crafts Name",
        accessor: "craftName",
        // disableFilters: true,
      },
      
      {
        Header: "Action",
        enableHiding:mode==="Create"?true:false,
        accessor: "isDeleted",
        // isVisible:false,
        Cell:({cell})=>{
          return(
            <IconButton>
            <DeleteOutline
              onClick={() => {
                handleDelete(cell.row.original.id);
              }}
              sx={{
                fontSize: color.masterEditDelButton,
                color: color.masterEditDelButtonColor,
              }}
            />
          </IconButton>
          )
        }
        // disableFilters: true,
      },
    ]
   
  
    return (
      <Box sx={{ p: color.masterPageDialogPadding }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
           {mode === "Edit"?editData.businessCode+"-"+editData.businessName:"New Business"}
          </Typography>
          <IconButton onClick={handleCancel}>
            <Clear />
          </IconButton>
        </Box>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        <Formik initialValues={initialValue} onSubmit={onSubmit}>
          {({ handleChange, setFieldValue, values, resetForm }) => (
            <Form>
              <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Business Code
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="code"
                    placeholder="Enter Business Code"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Business Name
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="businessName"
                    placeholder="Enter Business Name"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Business Description
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="businessDesc"
                    placeholder="Enter Business Desc"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Email
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="email"
                    placeholder="Enter Email"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
               <Grid item xs={12} md ={6}>
               <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Business Type
                </Typography>
                <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="businessType"
               
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.businessType !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {busType.map((id, i) => (
                  <MenuItem value={id.value}>{id.text}</MenuItem>
                ))}
              </Field>
               </Grid>
               <Grid item xs={12} md={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  URL
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="url"
                    placeholder="Enter URL"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Address
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                    fullWidth
                    multiline
                    maxRows={5}
                    minRows={3}
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
               
                <Grid item xs={12} md={12} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Mobile No
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="mobileNo"
                    placeholder="Enter MobileNo"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Craft
                </Typography>
                  <NormalTable columns={craftColumns} data={craftData} filt={true} />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"flex-end"}
                  gap="1rem"
                >
                  <Button
                    onClick={handleCancel}
                    variant="contained"
                    sx={{
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                      ":hover": {
                        backgroundColor: color.TableCancelButton,
                        textTransform: "none",
                        color: color.CancelTextColor,
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    variant="contained"
                    disabled={loading}
                    sx={{
                      
                      textTransform: "none",
                      backgroundColor: loading ? "default" : color.TableButtonColor,
                      "&:hover": {
                        backgroundColor: loading
                          ? "default"
                          : color.buttonDisable,
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };
  
  export default BusinessModal;
  