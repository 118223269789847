export const permitCodes = [
    "PRQ00004",
    "PRQ00006",
    "PRQ00013",
    "PRQ00015",
    "PRQ00016",
    "PRQ00019",
    "PRQ00021",
    "PRQ00029",
    "PRQ00031",
    "PRQ00033",
    "PRQ00035",
    "PRQ00037",
    "PRQ00042",
    "PRQ00044",
    "PRQ00047",
    "PRQ00054",
    "PRQ00056",
    "PRQ00057",
    "PRQ00062",
    "PRQ00063",
    "PRQ00064",
    "PRQ00065",
    "PRQ00066",
    "PRQ00073",
    "PRQ00074",
    "PRQ00075",
    "PRQ00076",
  ];

  export const statuses = [
    "Active",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
    "Passive",
  ];

  export const responsibleUsers = [
    "ECIENG",
    "SCEENGRA",
    "SCEENGRA",
    "SCEENGRC",
    "SCEENGRC",
    "Testing",
    "ECIENG",
    "shidy",
    "Shidy",
    "haziq",
    "Lim KH",
    "Robot",
    "Shidy",
    "Shidy",
    "Shidy",
    "S.Thinesh",
    "Zamri",
    "zamri",
    "zamri",
    "SCEENGRB",
    "SCEENGRB",
    "SCEENGRB",
    "SCEENGRB",
    "SCEENGRC",
    "SCEENGRC",
    "SCEENGRC",
    "SCEENGRC",
  ];

  export const workdescriptiones = [
    "ST excitation room ASCU # 1",
    "GT15 T/B temp high.",
    "Spot Light at River Intake faulty",
    "3rd Floor Server Room not cold",
    "YEARLY PUMP MAINTENANCE",
    "Chiller Monthly Servicing",
    "RAW wtr pp 1 motor abnormal noise",
    "routine maintenance",
    "routine maintenance",
    "13MKF10AP001 - ccw pump routine maintenance",
    "To replace defective split unit air con",
    "blalalallalallaa... testing 1, 2 3",
    "19PAD06CL001 - Level average MCW pp 2 < min 1. Basin screen cleaning",
    "19PAC10 - MCW pump # 1level drop screen. Basin cleaning and inspection",
    "14MBL30 - To replace intake fine filter",
    "To erect scaffolding for CT3& CT4 infill replacement preparation (No isolation needed)",
    "19GCP51CF001 Routine PM: 1. TO CHECK MECHANICAL SEAL FOR LEAKAGE. 2. TO CHECK BOLTS TIGHTNESS. 3. TO CHECK COUPLING/ALIGNMENT IF POSSIBLE. 4. TO CHECK FOR ABNORMAL NOISES WHEN RUNNING. 5. TO CHECK VICINITY FOR ANY LEAKAGES.",
    "plant wide service air line. 1) Apply snobby on the service air connections & fittings. 2) Check on the instrument air connections as well 3) Report any defect in MMS and plan for repair. Day1: ST hall Day2: GT hall/ HRSG Day3: WTP/WSC/BOP",
    "Routine Work Lube oil change motor and pump side Realignment motor and pump side",
    "WTP Demin PP2 - Routine Motor & Breaker PM",
    "WTP Raw Water PP2 - Routine Motor & Breaker PM",
    "Lighting Maintenance All Building Area",
    "ST Lube Oil Purifier - Routine Motor PM & Instrument Check",
    "Various spot light and street light defective.",
    "WTP Demin PP3 - Routine Motor & Breaker PM",
    "WTP Raw Water PP3 - Routine Motor & Breaker PM",
    "GT Hall AHU no.4. Routine maintenance works.Filter change and regreasing bearing.",
  ];

  export const contextualMenuWO=[
    {
      "workOrderCode": "0000001",
      "workOrderName": "ST Excitation room ASCU #1",
      "status": "In-Progresss",
      "workType": "PM"
    }
  ]