import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear, Save } from "@mui/icons-material";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";

import { color } from "../../../../StyleData/Config";
import { axiosPrivate } from "../../../../axios/axios";
import { masterAPI } from "../../../Masters/dataConfig";
import { DesktopDatePicker } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers";

const WarrantyModal = ({ data,getData }) => {
  const [uomId, setUomId]=useState([]);
  const [loading,setLoading]=useState(false)
  const [assetId,setAssetId]=useState([])

  console.log(data,"modal")
  useEffect(() => {

    // const link = masterAPI["uom"].link;
    // axiosPrivate
    //   .get(`${link}`)
    //   .then((res) => {
    //     setUomId(res.data.result);
    //   })
    //   .catch((e) => {
    //     console.log(e, "createWarrantygetAll");
    //   });
getDataList()
     
  }, []);

  const getDataList=async()=>{
    const assetLink=masterAPI["assets"].link
   await axiosPrivate.get(`${assetLink}`).then(res=>{
      setAssetId(res.data.result)
    }).catch(e=>{
      setAssetId([])
      console.log(e,"assetWar Error")
    })
  }

  const initialValue = {
    warrantyName:data.warrantyName ,
    startDate: data.startDate ,
    expirationDate: data.expirationDate ,
    threshHoldCalendar: data.threshHoldCalendar    ,
    startingUsage: data.startingUsage ,
    expirationUsage: data.expirationUsage ,
    // threshHoldUOMId:data.threshHoldUOMId ,
    warrantyType:data.warrantyType==="Basic"?false:true ,
    // assetId:mode === "Edit" ? editData.assetId : "",
  };

  const onSubmit =async (value) => {
    console.log(value);
    setLoading(true)
    
    const updateLink = masterAPI["assets"].update;
    // if (mode === "Create") {
        
    //   axiosPrivate
    //     .post(`${createLink}`, value)
    //     .then((res) => { 
    //       console.log(res.data);
    //       setLoading(false)
    //       // handleCloseClick();
    //     })
    //     .catch((e) => {
    //       setLoading(false)
    //       console.log(e, "createassetWarranty");
    //     });
    // } else {
      const updateAC = {
        ...data,
        assetImage:data.assetImage.split(".com")[1],
        ...value,
        warrantyType:value.warrantyType?"Extended":"Basic",
       
      };
      console.log(updateAC)
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false)
          getData() 
          // handleCloseClick();
        })
        .catch((e) => {
          setLoading(false)
          console.log(e, "updateassetWarranty");
        });
   // }
  };

  // const handleCancel = () => {
  //   handleCloseClick();
  // };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
    <Box>
      <Typography
        sx={{
          color: color.sideTitleColor,
          fontSize: color.fontSizeSide,
          fontWeight: color.sideWeightFont,
        }}
      >
       Asset Warranty
      </Typography>
    </Box>

    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        p: 1,
      }}
    >
    <Box sx={{ p: color.masterPageDialogPadding }}>
    <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
           Asset Warranty
        </Typography>
        <IconButton type="submit">
          <Save />
        </IconButton>
      </Box>
      <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
      
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6}>
              <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Warranty Name
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="warrantyName"
                  placeholder="Enter Asset Warranty Name"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Asset Id
                </Typography>
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="assetId"
           
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.assetId !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {assetId.map((id, i) => (
                  <MenuItem value={id.id}>{id.assetName}</MenuItem>
                ))}
              </Field>
            </Grid> */}
              <Grid item xs={12} md={6} sx={{".css-1xhypcz-MuiStack-root":{paddingTop:0}}}>
              <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Threshhold Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DemoContainer components={["DatePicker"]} >
                    <DatePicker  format="DD/MM/YYYY"
                      value={dayjs(values.threshHoldCalendar)}
                    //   sx={{".css-1xhypcz-MuiStack-root":{
                    //     padding:0
                    //   }}}
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0"  } }}
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("threshholdDate",dateFormat)
                          }
                        }
                      }} />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} >
              <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Starting Usage
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="startingUsage"
                  placeholder="Enter Starting Usage"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} >
              <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Expiration Usage
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="expirationUsage"
                  placeholder="Enter expiration Usage"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{".css-1xhypcz-MuiStack-root":{paddingTop:0}}}>
              <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Start Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DemoContainer components={["DatePicker"]} >
                    <DatePicker  format="DD/MM/YYYY"
                      value={dayjs(values.startDate)}
                    //   sx={{".css-1xhypcz-MuiStack-root":{
                    //     padding:0
                    //   }}}
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0"  } }}
                      onChange={(newValue) => {
                     
                        // console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            console.log(dateFrom)
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("startDate",dateFormat)
                          }
                        }
                      }} />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} sx={{".css-1xhypcz-MuiStack-root":{paddingTop:0}}}>
              <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Expiration Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DemoContainer components={["DatePicker"]} >
                    <DatePicker  format="DD/MM/YYYY"
                      value={dayjs(values.expirationDate)}
                    //   sx={{".css-1xhypcz-MuiStack-root":{
                    //     padding:0
                    //   }}}
                      slotProps={{ textField: { size: "small",margin:"-8px 0 0 0"  } }}
                      onChange={(newValue) => {
                     
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                         setFieldValue("expirationDate",dateFormat)
                          }
                        }
                      }} />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              {/* <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 ThreshHold UOM
                </Typography>
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="threshHoldUOMId"
                placeholder="Enter Asset Category Name"
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.threshHoldUOMId !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {uomId.map((id, i) => (
                  <MenuItem value={id.id}>{id.uomName}</MenuItem>
                ))}
              </Field>
            </Grid> */}
            <Grid item xs={12} md={6} lg={6}>
                            <Typography
                              sx={{
                                fontWeight: color.labelFontWeight,
                                mb: color.labelmb,
                                fontSize: color.labelFont,
                                color: color.labelColor,
                              }}
                            >
                            Type
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                                // flexWrap: "wrap",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: color.TabDatafontsize,
                                  mb: color.TabDatamarginbottom,
                                }}
                              >
                               Basic
                              </Typography>
                              <Switch
                              value={values.warrantyType}
                              checked={values.warrantyType}
                              onChange={(e)=>{
                                console.log(e.target.checked)
                                if(e.target.checked){
                                  setFieldValue("warrantyType",true)
                                }else{
                                  setFieldValue("warrantyType",false)
                                }
                                
                              }}
                                sx={{
                                  width: 48,
                                  height: 26,
                                  padding: 0,
                                  
                                  "& .MuiSwitch-switchBase": {
                                    padding: 0,
                                    margin: 0.2,
                                    transitionDuration: "300ms",
                                    "&.Mui-checked": {
                                      transform: "translateX(22px)",
                                      color: "#fff",
                                      "& + .MuiSwitch-track": {
                                        backgroundColor: (theme) =>
                                          theme.palette.mode === "dark"
                                            ? "#2ECA45"
                                            : "#217EDE",
                                        opacity: 1,
                                        border: 0,
                                      },
                                      "&.Mui-disabled + .MuiSwitch-track": {
                                        opacity: 0.5,
                                      },
                                    },
                                    "&.Mui-focusVisible .MuiSwitch-thumb": {
                                      color: "#33cf4d",
                                      border: "6px solid #fff",
                                    },
                                    "&.Mui-disabled .MuiSwitch-thumb": {
                                      color: (theme) =>
                                        theme.palette.mode === "light"
                                          ? theme.palette.grey[100]
                                          : theme.palette.grey[600],
                                    },
                                    "&.Mui-disabled + .MuiSwitch-track": {
                                      opacity: (theme) =>
                                        theme.palette.mode === "light"
                                          ? 0.7
                                          : 0.3,
                                    },
                                  },
                                  "& .MuiSwitch-thumb": {
                                    boxSizing: "border-box",
                                    width: 22,
                                    height: 22,
                                  },
                                  "& .MuiSwitch-track": {
                                    borderRadius: 26 / 2,
                                    backgroundColor: (theme) =>
                                      theme.palette.mode === "light"
                                        ? "#E9E9EA"
                                        : "#39393D",
                                    opacity: 1,
                                    transition: (theme) =>
                                      theme.transitions.create(
                                        ["background-color"],
                                        {
                                          duration: 500,
                                        }
                                      ),
                                  },
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: color.TabDatafontsize,
                                  mb: color.TabDatamarginbottom,
                                }}
                              >
                             Extended
                              </Typography>
                            </Box>
                          </Grid>
              {/* <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                      
                    textTransform: "none",
                    backgroundColor: loading ? "default" : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid> */}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
    </Paper>
    </Box>
  );
};

export default WarrantyModal;
