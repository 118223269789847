import React, { useState } from "react";
import { masterAPI } from "../Masters/dataConfig";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { color } from "../../StyleData/Config";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { axiosPrivate } from "../../axios/axios";

const LineChartValue = ({ handleCloseClick }) => {
  const [data, setData] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
  const [loading, setLoading] = useState(false);

  const handleOnChange=(e,i)=>{
    const UpdateData=[...data]
    UpdateData[i]=e.target.value
    console.log(UpdateData)
    setData(UpdateData)
  }


  const onSubmit = () => {
    const updateData=data.map((d)=>{
      return {
        eventTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
         eventValue: Number(d)
      }
    })
    console.log(updateData)
    const createLink=masterAPI["LineChart"].iotInsertData
    axiosPrivate.post(`${createLink}`,updateData).then(res=>{
      console.log(res)
      handleCloseClick()
    }).catch(e=>{
      console.log(e)
    })
  };

  return (
    <Box sx={{ p: "1rem" }}>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          Frequency
        </Typography>

      
         
           
              <Grid container spacing={"1rem"}>
                {data.map((val, i) => (
                  <Grid item xs={4} key={i}>
                    <Typography
                      sx={{
                        fontWeight: color.labelFontWeight,
                        mb: color.labelmb,
                        fontSize: color.labelFont,
                        color: color.labelColor,
                      }}
                    >
                      Value {i+1}
                    </Typography>
                   <TextField
                      size={"small"}
                      type="text"
                      name="eventValue"
                      value={data[i]}
                      placeholder="Enter Status Name"
                      fullWidth
                      onChange={(e)=>{
                         handleOnChange(e,i)
                      }}
                      sx={{
                        ".MuiInputBase-input": {
                          // letterSpacing: "0.2rem",

                          "&::placeholder": {
                            // color: "green",
                            opacity: 1,
                            color: color.placeholderColor,
                            fontSize: "13px",
                          },
                        },
                      }}
                    />
                  </Grid>
                ))}

                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"flex-end"}
                  gap="1rem"
                >
                  <Button
                    onClick={handleCloseClick}
                    variant="contained"
                    sx={{
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                      ":hover": {
                        backgroundColor: color.TableCancelButton,
                        textTransform: "none",
                        color: color.CancelTextColor,
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    variant="contained"
                    disabled={loading}
                    onClick={onSubmit}
                    sx={{
                      textTransform: "none",
                      backgroundColor: loading
                        ? "default"
                        : color.TableButtonColor,
                      "&:hover": {
                        backgroundColor: loading
                          ? "default"
                          : color.buttonDisable,
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
           
      </Box>
    </Box>
  );
 };

 export default LineChartValue;
