import React from "react";


export default function ErrorProps(props) {
  return (
    <div
      style={{
        textAlign: "left",
        color: "red",
        fontSize: "0.8rem",
        // marginTop: "0.6rem",
      }}
    >
      {props.children}
    </div>
  );
}
