import { Save } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select, 
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { color } from "../../../../StyleData/Config";
import { axiosPrivate } from "../../../../axios/axios";
import { masterAPI } from "../../../Masters/dataConfig";

const AssignedTo = ({ data,refreshData }) => {
  const [selectList, setSelectList] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(data,"assto")

    useEffect(() => {
      getData()
    }, []);

    const getData=async()=>{
      const selectLink = masterAPI["person"].link
     await axiosPrivate
        .get(`${selectLink}`)
        .then((res) => {
          console.log(res.data.result);
          setSelectList(res.data.result);
        })
        .catch((e) => {
          setSelectList([])
          console.log(e, "assign to Asset");
        });
    }
  //  console.log(data,"console")
  const formik = useFormik({
    initialValues: {
      ownerId: data.ownerId!==null&&data.ownerId!==undefined&&data.ownerId!==""?data.ownerId:"",
      production_responsible_personid: data.production_responsible_personid!==null&&data.production_responsible_personid!==undefined&&data.production_responsible_personid!==""?data.production_responsible_personid:"",
      maintenance_responsible_personid: data.maintenance_responsible_personid!==null&&data.maintenance_responsible_personid!==undefined&&data.maintenance_responsible_personid!==""?data.maintenance_responsible_personid:"",
    },
    enableReinitialize: true,
    onSubmit:async (value) => {
      console.log(value);
      //   setLoading(true)
        const changeLink=masterAPI["assets"].update
        const putData={
          ...data,
          assetImage:data.assetImage.split(".com")[1],
          ...value,
          
        }
        console.log(putData,"assc")
       await axiosPrivate.put(`${changeLink}`,putData).then(res=>{
          console.log(res)
          refreshData()
          // setLoading(false)
        }).catch(e=>{
          // setLoading(false)
          console.log(e,"changeStatus update")
        })
    },
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>
        <Typography
          sx={{
            color: color.sideTitleColor,
            fontSize: color.fontSizeSide,
            fontWeight: color.sideWeightFont,
          }}
        >
          Assigned To
        </Typography>
      </Box>

      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: color.sideTitle2Color,
              fontSize: color.fontSizeSide,
              fontWeight: color.sideFontWeight,
            }}
          >
            Details
          </Typography>
          <IconButton type="submit" onClick={formik.handleSubmit}>
            {loading ? <CircularProgress /> : <Save sx={{ color: "grey" }} />}
          </IconButton>
        </Box>
        <Divider />
        <Box>
         
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {/* <Box>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Owner Id
              </Typography>
              <TextField
                variant="standard"
                size={"small"}
                type="text"
                name="ownerId"
                value={formik.values.ownerId}
                placeholder="Enter Owner Id"
                // fullWidth
                onChange={formik.handleChange}
                sx={{
                  // width: "45%",
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Box> */}
  <Box>
              <Typography
                sx={{
                  mb: "0.5rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Owner Id
              </Typography>

              <Select
                // label={"Select"}
                size={"small"}
                type="text"
                name="ownerId"
                variant="standard"
                value={formik.values.ownerId}
                onChange={formik.handleChange}
                // fullWidth
                sx={{ width:"70%", fontSize: "13px" }}
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                renderValue={
                  formik.values.ownerId !== ""
                    ? undefined
                    : () => (
                        <Typography
                          sx={{
                            color: color.placeholderColor,
                            fontSize: "13px",
                          }}
                        >
                          Select
                        </Typography>
                      )
                }
              >
                {selectList.map((id, i) => (
                  <MenuItem value={id.id}>{id.personName}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Typography
                sx={{
                  mb: "0.5rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Production Responsible Id
              </Typography>

              <Select
                // label={"Select"}
                size={"small"}
                type="text"
                name="production_responsible_personid"
                variant="standard"
                value={formik.values.production_responsible_personid}
                onChange={formik.handleChange}
                // fullWidth
                sx={{width:"70%", fontSize: "13px" }}
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                renderValue={
                  formik.values.production_responsible_personid !== ""
                    ? undefined
                    : () => (
                        <Typography
                          sx={{
                            color: color.placeholderColor,
                            fontSize: "13px",
                          }}
                        >
                          Select
                        </Typography>
                      )
                }
              >
                {selectList.map((id, i) => (
                  <MenuItem value={id.id}>{id.personName}</MenuItem>
                ))}
              </Select>
            </Box>

            <Box>
              <Typography
                sx={{
                  mb: "0.5rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Maintenance Responsible Id
              </Typography>

              <Select
                // label={"Select"}
                size={"small"}
                type="text"
                name="maintenance_responsible_personid"
                variant="standard"
                value={formik.values.maintenance_responsible_personid}
                onChange={formik.handleChange}
                // fullWidth
                sx={{ width:"70%",fontSize: "13px" }}
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                renderValue={
                  formik.values.maintenance_responsible_personid !== ""
                    ? undefined
                    : () => (
                        <Typography
                          sx={{
                            color: color.placeholderColor,
                            fontSize: "13px",
                          }}
                        >
                          Select
                        </Typography>
                      )
                }
              >
                {selectList.map((id, i) => (
                  <MenuItem value={id.id}>{id.personName}</MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AssignedTo;
