import { CheckBox, Clear, Save } from '@mui/icons-material'
import { Box, Checkbox, Divider, Grid, Icon, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NormalTable from '../../../../Component/NormalTable'
import { masterAPI } from '../../../Masters/dataConfig'
import { axiosPrivate } from '../../../../axios/axios'
import { color } from '../../../../StyleData/Config'
import TableContainerComp from '../../../../Component/TableContainer'

const Labour = ({taskData,handleCloseClick,type,dataDetail}) => {
    const [labourData,setLabourData]=useState([])
    const [labourSelect,setLabourSelect]=useState([])
    const [businessSelect,setBusinessSelect]=useState([])
    const [businessData,setBusinessData]=useState([])
    const [typeData,setTypeData]=useState("1")
    const [quantity,setQuantity]=useState("")
    const [craftSelect,setCraftSelect]=useState([])
    const [craftData,setCraftData]=useState([])
    const [labourCompleteDetial,setlabourCompleteDetail]=useState([])
    const [businessCompleteDetial,setbusinessCompleteDetail]=useState([])
    const [craftCompleteDetail,setCraftCompleteDetail]=useState([])
    const [planHours,setPlanHours]=useState("")


    useEffect(()=>{
     getData()
    },[])

    const getData=async()=>{
      const labourLink=masterAPI["labourCraft"].link
    await axiosPrivate.get(`${labourLink}`).then(res=>{
        console.log(res.data.result)
        setLabourData(res.data.result)
     }).catch(e=>{
        setLabourData([])
        console.log(e,"labourCraft WorkOrder")
     })
     const businessLink=masterAPI["businessCraft"].link
    await axiosPrivate.get(`${businessLink}`).then(res=>{
        console.log(res.data.result)
        setBusinessData(res.data.result)
     }).catch(e=>{
        setBusinessData([])
        console.log(e,"business WorkOrder")
     })
     const craftLink=masterAPI["crafts"].link
    await axiosPrivate.get(`${craftLink}`).then(res=>{
      console.log(res.data.result)
      setCraftData(res.data.result)
   }).catch(e=>{
      setCraftData([])
      console.log(e,"business WorkOrder")
   })
    }

    const columns=[
        {
        
            accessor:"id",
            disableFilters: true,
            Cell:({cell})=>{
                return (
                  <Checkbox checked={labourSelect.includes(cell.row.original.id)} onClick={()=>{handleCheck(cell.row.original)}} />
                )
            }
        },
        {
            Header:"Labour Name",
            accessor:"labourName",
            // disableFilters: true,
        },
        {
            Header:"Craft Name",
            accessor:"craftName",
            // disableFilters: true,
           
        }
    ]

    const columnsBus=[
        {
           
            accessor:"id",
            Cell:({cell})=>{
                return (
                  <Checkbox checked={businessSelect.includes(cell.row.original.id)} onClick={()=>{handleBusCheck(cell.row.original)}} />
                )
            }
        },
        {
            Header:"Business Name",
            accessor:"businessName"
        },
        // {
        //     Header:"Business Description",
        //     accessor:"businessDesc"
        // },

        // {
        //     Header:"Email",
        //     accessor:"email"
        // },
        {
            Header:"Craft Name",
            accessor:"craftName"
        },
    ]

    const columnsCraft=[
      {
         
          accessor:"id",
          Cell:({cell})=>{
              return (
                <Checkbox checked={craftSelect.includes(cell.row.original.id)} onClick={()=>{handleCraft(cell.row.original)}} />
              )
          }
      },
    
      {
          Header:"Craft Name",
          accessor:"craftName"
      },
  ]

    const listColumns=[
       
          // {
          //         Header: "ID",
          //         accessor: "id",
                 
          //     },
              {
                Header:"Task No",
                accessor:"taskNo"
              },
              {
                Header:"Task Name",
                accessor:"taskName"
              },
              {
                Header:"Description",
                accessor:"description"
              },
              {
                Header:"Sequence No",
                accessor:"sequenceNo"
              },
              {
                Header:"Task Duration",
                accessor:"taskDuration"
              },
           
          
      ]
      const comColumns=[
       
        // {
        //         Header: "ID",
        //         accessor: "id",
               
        //     },
            {
              Header:"Task No",
              accessor:"taskNo"
            },
            {
              Header:"Task Name",
              accessor:"taskName"
            },
            
     
        
    ]

    const handleCheck=(value)=>{
      if(labourSelect.includes(value.id)){
        const removeData=labourSelect.filter(l=>l!==value.id)
        const removeCompleteData=labourCompleteDetial.filter(lab=>lab.id!==value.id)
        setLabourSelect(removeData)
        setlabourCompleteDetail(removeCompleteData)
      }else{
        setLabourSelect([...labourSelect,value.id])
        setlabourCompleteDetail([...labourCompleteDetial,value])
      }
    }

    const handleBusCheck=(value)=>{
        if(businessSelect.includes(value.id)){
          const removeData=businessSelect.filter(l=>l!==value.id)
          const removeCompleteData=businessCompleteDetial.filter(bus=>bus.id!==value.id)
          setbusinessCompleteDetail(removeCompleteData)
          setBusinessSelect(removeData)
        }else{
          setBusinessSelect([...businessSelect,value.id])
          setbusinessCompleteDetail([...businessCompleteDetial,value])
        }
      }
      const handleCraft=(value)=>{
        if(craftSelect.includes(value.id)){
          const removeData=craftSelect.filter(l=>l!==value.id)
          const removeCompleteData=craftCompleteDetail.filter(cra=>cra.id!==value.id)
          setCraftSelect(removeData)
          setCraftCompleteDetail(removeCompleteData)
        }else{
          setCraftSelect([...craftSelect,value.id])
          setCraftCompleteDetail([...craftCompleteDetail,value])

        }
      }



    const handleLabourSubmit=async()=>{
        console.log(labourSelect,taskData)
      
         
        const wotlink=masterAPI["workOrderTaskLabour"].bulkinsert
        const jptlink=masterAPI["jobPlanTaskLabour"].bulkinsert
        let decimalPlanHours=Number(planHours.split(":")[0])+(Number(planHours.split(":")[1])/60)
        if(type==="wot"||type==="completion"){
            const wo=[]

            for (let i = 0; i < taskData.length; i++) {
                for (let j = 0; j < labourCompleteDetial.length; j++) {
                  console.log(labourCompleteDetial[i])
                 const wotData={
                     workOrderTaskId:taskData[i],
                     labourId:labourCompleteDetial[j].labourId,
                     craftId:labourCompleteDetial[j].craftId,
                     quantity:quantity,
                     plannedHours:decimalPlanHours,
                     workedHours:decimalPlanHours,
                     rate:labourCompleteDetial[j].rateForRegularHors,
                     cost:labourCompleteDetial[j].rateForRegularHors*decimalPlanHours
                 }
                 console.log(wotData,i,j)
                wo.push(wotData)
                 
                }
                 
              }
              console.log(wo)
             await axiosPrivate.post(`${wotlink}`,wo).then(res=>{
                console.log(res)
                handleCloseClick()
            }).catch(e=>{
                console.log(e,"createwotaskLabour")
            })
        }else{
            const jplan=[]
            for (let i = 0; i < taskData.length; i++) {
                for (let j = 0; j < labourCompleteDetial.length; j++) {
                 const jptData={
                     jobPlanTaskId:taskData[i],
                     labourId:labourCompleteDetial[j].labourId,
                     craftId:labourCompleteDetial[j].craftId,
                     quantity:quantity,
                     plannedHours:decimalPlanHours,
                     workedHours:decimalPlanHours,
                     rate:labourCompleteDetial[i].rateForRegularHors,
                     cost:labourCompleteDetial[i].rateForRegularHors*decimalPlanHours
                 }
                 console.log(jptData,i,j)
              jplan.push(jptData)
                 
                }
                 
              }
              console.log(jplan)
             await axiosPrivate.post(`${jptlink}`,jplan).then(res=>{
                console.log(res,"jpt")
                handleCloseClick()
            }).catch(e=>{
                console.log(e,"createjptaskLabour")
            })
        }
     
    }
    const handleBusSubmit=async()=>{
        console.log(labourSelect,taskData)
        let decimalPlanHours=Number(planHours.split(":")[0])+(Number(planHours.split(":")[1])/60)
         
        const wotlink=masterAPI["workOrderTaskLabour"].bulkinsert
        const jptlink=masterAPI["jobPlanTaskLabour"].bulkinsert
        if(type==="wot"){
          const busData=[]
            for (let i = 0; i < taskData.length; i++) {
                for (let j = 0; j < businessCompleteDetial.length; j++) {
                 const businesData={
                     workOrderTaskId:taskData[i],
                     businessId:businessCompleteDetial[j].businessId,
                     craftId:businessCompleteDetial[j].craftId,
                     quantity:quantity,
                     plannedHours:decimalPlanHours,
                     workedHours:decimalPlanHours,
                     rate:businessCompleteDetial[j].rateForRegularHors,
                     cost:businessCompleteDetial[j].rateForRegularHors*decimalPlanHours
                 }
                 console.log(businesData,i,j)
                 busData.push(businesData)
               
                 
                }
                 
              }
             await axiosPrivate.post(`${wotlink}`,busData).then(res=>{
                console.log(res)
                handleCloseClick()
            }).catch(e=>{
                console.log(e,"createwotaskLabour")
            })
           
        }else{
          console.log(businessCompleteDetial)
          const jpBusData=[]
            for (let i = 0; i < taskData.length; i++) {
                for (let j = 0; j < businessCompleteDetial.length; j++) {
                 const jptData={
                     jobPlanTaskId:taskData[i],
                     businessId:businessCompleteDetial[j].businessId,
                     craftId:businessCompleteDetial[j].craftId,
                     quantity:quantity,
                     plannedHours:decimalPlanHours,
                     workedHours:decimalPlanHours,
                     rate:businessCompleteDetial[j].rateForRegularHors,
                     cost:businessCompleteDetial[j].rateForRegularHors*decimalPlanHours
                 }

                 jpBusData.push(jptData)
           
                 
                }
                 
              }
              console.log(jpBusData)
             await axiosPrivate.post(`${jptlink}`,jpBusData).then(res=>{
                console.log(res,"jpt")
                handleCloseClick()
            }).catch(e=>{
                console.log(e,"createjptaskLabour")
            })
        }
     
    }

    const handleCraftSubmit=async()=>{
      console.log(craftSelect,taskData)
    
      let decimalPlanHours=Number(planHours.split(":")[0])+(Number(planHours.split(":")[1])/60)
      const wotlink=masterAPI["workOrderTaskLabour"].bulkinsert
      const jptlink=masterAPI["jobPlanTaskLabour"].bulkinsert
      if(type==="wot"){
        console.log(craftCompleteDetail)
        const wotCraftData=[]
          for (let i = 0; i < taskData.length; i++) {
              for (let j = 0; j < craftCompleteDetail.length; j++) {
               const wotData={
                   workOrderTaskId:taskData[i],
                   craftId:craftCompleteDetail[j].id,
                   rate:craftCompleteDetail[j].rateForRegularHors,
                   cost:craftCompleteDetail[j].rateForRegularHors*decimalPlanHours,
                   quantity:quantity,
                   plannedHours:decimalPlanHours,
                   workedHours:decimalPlanHours,

               }
               console.log(wotData,i,j)
             wotCraftData.push(wotData)
              }
               
            }
            console.log(wotCraftData)
           await axiosPrivate.post(`${wotlink}`,wotCraftData).then(res=>{
              console.log(res)
              handleCloseClick()
          }).catch(e=>{
              console.log(e,"createwotaskLabour")
          })
           
         
      }else{
        const jpCraftData=[]
          for (let i = 0; i < taskData.length; i++) {
              for (let j = 0; j < craftSelect.length; j++) {
               const jptData={
                   jobPlanTaskId:taskData[i],
                   craftId:craftCompleteDetail[j].id,
                   rate:craftCompleteDetail[j].rateForRegularHors,
                   cost:craftCompleteDetail[j].rateForRegularHors*decimalPlanHours,
                   quantity:quantity,
                   plannedHours:decimalPlanHours,
                   workedHours:decimalPlanHours
               }
               console.log(jptData,i,j)
               jpCraftData.push(jptData)
             
               
              }
               
            }
            console.log(jpCraftData)
           await axiosPrivate.post(`${jptlink}`,jpCraftData).then(res=>{
              console.log(res,"jpt")
              handleCloseClick()
          }).catch(e=>{
              console.log(e,"createjptaskLabour")
          })
          
      }
   
  }

    const handleType=(e,v)=>{
       console.log(e.target.value)
       setTypeData(e.target.value)
    }

  return (
    <Grid container sx={{p:1}} spacing={"1.5rem"}>
        <Grid item xs={12}  >
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: "1.1rem",
            fontWeight:500
          }}
        >
         Select Task
        </Typography>
         <Box>
            {/* <IconButton onClick={()=>{
                if(typeData==="1"){
                    handleLabourSubmit()
                }else{
                    handleBusSubmit()
                }
            }}>
                <Save sx={{color:"#2362B4"}} />
            </IconButton> */}
            <IconButton onClick={()=>{handleCloseClick()}}>
                <Clear/>
            </IconButton>
         </Box>
         </Box>
         <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)"}}/>
        </Grid>
        <Grid item container xs={12} >
        {/* <Grid item xs={12}  >
           
            <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: "1.1rem",
            fontWeight:500
          }}
        >
         Select Task
        </Typography>
        
     
         <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)"}}/>
        </Grid> */}
       
            <NormalTable data={dataDetail} columns={type==="completion"?comColumns:listColumns}/>
        </Grid>
        <Grid item xs={12}  >
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: "1.1rem",
            fontWeight:500
          }}
        >
         Labour
        </Typography>
         <Box>
            <IconButton onClick={()=>{
                if(typeData==="1"){
                    handleLabourSubmit()
                }else if(typeData==="2"){
                    handleBusSubmit()
                }else{
                  handleCraftSubmit() 
                }
            }}>
                <Save sx={{color:"#2362B4"}} />
            </IconButton>
         
         </Box>
         </Box>
         <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)"}}/>
        </Grid>
        <Grid container item xs={12}  spacing={"1.5rem"} >
            <Grid item xs={12} md={4}>
           
            <Box sx={{display:"flex",alignItems:"center" ,gap:"1rem"}}>
        <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    // color: color.labelColor,
                    whiteSpace:"nowrap"
                  }}
                >
                Select :
                </Typography>
       <Select value={typeData} onChange={handleType} size='small' fullWidth readOnly={type==="completion"}>
          
           <MenuItem value="1">Labour</MenuItem>
           <MenuItem value="2">Business</MenuItem>
           <MenuItem value="3">Craft</MenuItem>
       </Select>
</Box>
            </Grid>
            <Grid item xs={12} md={4}>
            <Box sx={{display:"flex",alignItems:"center" ,gap:"1rem"}}>
        <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    // color: color.labelColor,
                    whiteSpace:"nowrap"
                  }}
                >
                Quantity :
                </Typography>
                <TextField name="qauntity" size='small' placeholder='Enter Quantity' value={quantity} onChange={(e)=>{setQuantity(e.target.value)}}/>
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
            <Box sx={{display:"flex",alignItems:"center" ,gap:"1rem"}}>
        <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    // color: color.labelColor,
                    whiteSpace:"nowrap"
                  }}
                >
               {type==="completion"?"Worked":"Planned"} Hours :
                </Typography>
                <TextField name="plannedHours" size='small' placeholder='Enter Planned Hours' value={planHours} onChange={(e)=>{setPlanHours(e.target.value)}}/>
                </Box>
            </Grid>
          
        </Grid>
     
        <Grid item xs={12} sx={{display:typeData==="1"?"grid":"none"}}>
            <NormalTable data={labourData} columns={columns} filt={true}/>
        </Grid>
        <Grid item xs={12} sx={{display:typeData==="2"?"grid":"none"}}>
            <NormalTable data={businessData} columns={columnsBus}/>
        </Grid>
        <Grid item xs={12} sx={{display:typeData==="3"?"grid":"none"}}>
            <NormalTable data={craftData} columns={columnsCraft}/>
        </Grid>
    </Grid>
  )
}

export default Labour