import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import { Box, Checkbox, Dialog, IconButton, Switch, Typography } from "@mui/material";
import { useSearchParams,useNavigate } from "react-router-dom";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import {
  ArrowBack,
  CheckCircle,
  DeleteOutline,
  DeleteOutlined,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";

import { color } from "../../../StyleData/Config";
import ReceiptModal from "./RTModal";
import {useSelector} from "react-redux"



const RTItem = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate=useNavigate()
  const actionsAllow=useSelector(state=>state.dataRed.actionsAllow)
  const returnDetail=useSelector(state=>state.dataRed.returnItem)

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [filterValue, setFilterValue] = useState("enableData");
  const [backUpData, setBackUpData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["issueReturn"].returnList;
   await axiosPrivate
      .get(`${link}/${searchParams.get("id")}`)
      .then((res) => {
        if (filterValue === "enableData") {
          const filterDataResult = res.data.result.filter(
            (fil) => fil.isActive
          );
          console.log(filterDataResult, "filterData");
          setData(filterDataResult);
          setBackUpData(res.data.result);
        } else {
          setData(res.data.result);
          setBackUpData(res.data.result);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "issueReturn");
        setData([])
        setBackUpData([])
        setIsLoading(false);
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  // const handleDelete = (id) => {
  //   const deleteLink = masterAPI["issueReturn"].delReturnList;
  //   axiosPrivate
  //     .delete(`${deleteLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "delete issueReturn");
  //     });
  // };

  const handleDelete = async(e, id) => {
    const enableLink = masterAPI["issueReturn"].enableItem;
    const disableLink = masterAPI["issueReturn"].disableItem;
    if (e.target.checked) {
     await axiosPrivate
        .put(`${enableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "enable return");
        });
    } else {
     await axiosPrivate
        .put(`${disableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "disable return");
        });
    }
  };

  const columns = [
    {
      Header: "Part",
      accessor: "code",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              color: "#0d6efd",
              fontSize: "inherit",
              fontWeight: 600,
              cursor: "pointer",
            }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.value+"-"+cell.row.original.partName}
          </Typography>
        );
      },
      // disableFilters:true,
    },
  {
      Header: "Stock Name",
      accessor: "stockRoomName",
      Cell: ({ cell }) => {
        return (
          <Typography
           sx={{
            fontSize:"inherit",
            fontWeight:"inherit"
           }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.row.original.stockRoomCode+"-"+cell.value}
          </Typography>
        );
      },
 
    },
    {
      Header: "Aisle",
      accessor: "aisleName",
      Cell: ({ cell }) => {
        return (
          <Typography
           sx={{
            fontSize:"inherit",
            fontWeight:"inherit"
           }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.row.original.aisleCode+"-"+cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Row",
      accessor: "rowName",
      Cell: ({ cell }) => {
        return (
          <Typography
           sx={{
            fontSize:"inherit",
            fontWeight:"inherit"
           }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.row.original.rowCode+"-"+cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Bin",
      accessor: "binName",
      Cell: ({ cell }) => {
        return (
          <Typography
           sx={{
            fontSize:"inherit",
            fontWeight:"inherit"
           }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.row.original.binCode+"-"+cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      // disableFilters:true,
    }, 
   
    {
      Header: "Unit Cost",
      accessor: "unitCost",
 
    }, 
    {
      Header: "Quality Checked",
      accessor: "isQualityChecked",
      Cell:({cell})=>{
        return (
          <Typography sx={{fontSize:"inherit",color:"inherit",fontWeight:"inherit"}}>
           {cell.value?"Yes":"No"}
          </Typography>
        )
      }
    }, 
  
  
    {
      Header: "Returned Date",
      accessor: "itemReturnedDate",

    },
    {
      Header:"Action",
      accessor:"partId",
      Cell:({cell})=>{
        return (
        //   <IconButton
        //   disabled={!actionsAllow.delete}
        //   onClick={() => {
        //     handleDelete(cell.row.original.partId);
        //   }}
        //   >
        //   <DeleteOutlined
           
        //     sx={{
        //       fontSize: color.masterEditDelButton,
        //       color:actionsAllow.delete? color.masterEditDelButtonColor:"default",
        //     }}
        //   />
        // </IconButton>
        <Switch
              disabled={
                !actionsAllow.delete 
              }
              checked={cell.row.original.isActive}
              onChange={(e) => {
                handleDelete(e, cell.row.original.id);
              }}
            />
        )
      },
      disableFilters:true,
    }
  ];

  const handleChangeFilter = (e) => {
    if (e === "showAll") {
      setData(backUpData);
    } else {
      const filterDataResult = backUpData.filter((fil) => fil.isActive);

      setData(filterDataResult);
    }
    setFilterValue(e);
  };

  return (
    <>
      <Box sx={{display:"flex",alignItems:"center"}}>
        <IconButton onClick={()=>{navigate("/inventory/returntrans")}}>
          <ArrowBack/>
        </IconButton>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            // mb: color.masterPageTitlemd,
          }}
        >
          {returnDetail.assetId!==null&&returnDetail.assetId!==undefined?returnDetail.assetCode+"-"+returnDetail.assetName:null}
          {returnDetail.workOrderId!==null&&returnDetail.workOrderId!==undefined?returnDetail.workOrderCode+"-"+returnDetail.workOrderName:null}
        </Typography>
      </Box>

      <TableContainer
        columns={columns}
        data={data}
        buttonNew={handleButtonNew}
        loading={isLoading}
        buttonDisable={!actionsAllow.create}
        filterValue={filterValue}
        handleChange={handleChangeFilter}
        filterShow={true}
      />

      {/* <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"xl"}
        children={
          <ReceiptModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      /> */}
    </>
  );
};

export default RTItem
