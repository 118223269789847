import {
    Box,
    Button,
    Checkbox,
    Divider,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
    Autocomplete,
    CircularProgress,
    createFilterOptions,
    Paper,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Field, Form, Formik } from "formik";
  import * as Yup from "yup";
  import { Clear, Delete, DeleteOutline, RadioButtonUncheckedOutlined } from "@mui/icons-material";
  import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import dayjs from "dayjs";
  import moment from "moment";
  
  import { color } from "../../../StyleData/Config";
  import { axiosPrivate } from "../../../axios/axios";
  import { masterAPI } from "../dataConfig";
  import { DesktopDatePicker } from "@mui/lab";
  import { DateTimePicker } from "@mui/x-date-pickers";
import NormalTable from "../../../Component/NormalTable";
  
  const LabourModal = ({ mode, handleCloseClick, editData }) => {
    const [personId, setPersonId] = useState([]);
    const [loading,setLoading]=useState(false);
    const [craftData,setCraft]=useState([])
    const [selectCraft,setSelCraft]=useState([])
    const [craftIden,setCraftIden]=useState([])
    const [aldreadySelCraft,setAldreadySelCraft]=useState([])
    const [labourCraft,setLabourCraft]=useState([])
    const [personDetail,setPersonDetail]=useState({})

    const OPTIONS_LIMIT = 10;
    const defaultFilterOptions = createFilterOptions();
  
    const filterOptions = (options, state) => {
      return defaultFilterOptions(options, state);
    };
    const CustomPaper = (props) => {
      return <Paper elevation={8} {...props} />;
    };
  
    useEffect(() => {
    getDataList()
     
    }, []);

    const getDataList=async()=>{
      const link = masterAPI["person"].link;
     await axiosPrivate
        .get(`${link}`)
        .then((res) => {
          setPersonId(res.data.result);
        })
        .catch((e) => {
          setPersonId([])
          console.log(e, "createlabourPersonAll");
        });

        if(mode === "Edit"){
          const personLink=masterAPI["person"].getByid
         await axiosPrivate
          .get(`${personLink}/${editData.personId}`)
          .then((res) => {
            console.log(res)
            setPersonDetail(res.data.result);
          })
          .catch((e) => {
            setPersonDetail({})
            console.log(e, "person detail");
          });
        }
     

        const craftLink=masterAPI["crafts"].link
       await axiosPrivate
        .get(`${craftLink}`)
        .then((res) => {
          setCraft(res.data.result);
        })
        .catch((e) => {
          setCraft([])
          console.log(e, "create craft PersonAll");
        });
        if(mode==="Edit"){
          getData()
        }
    }

    const getData=async()=>{
      // console.log(editData)
      const labCraftlink=masterAPI["labourCraft"].craftBylabour
     await axiosPrivate.get(`${labCraftlink}/${editData.id}`).then(res=>{
        const craftSel=[]
        for(let i=0;res.data.result.length>i;i++){
          craftSel.push(res.data.result[i].craftId)
        }
        setAldreadySelCraft(craftSel)
        setSelCraft(craftSel)
        setLabourCraft(res.data.result)
      }).catch(e=>{
        console.log(e)
        setAldreadySelCraft([])
        setSelCraft([])
        setLabourCraft([])
      })
    }
  
    const initialValue = {
      personId: mode === "Edit" ? editData.personId : "",
      code: mode === "Edit" ? editData.code : "",
      labourName:mode === "Edit" ? editData.labourName:""
      
    };
  
    const onSubmit = async(value) => {
   
      setLoading(true)
      const createLink = masterAPI["labourCraft"].create;
      const updateLink = masterAPI["labourCraft"].update;
      const updateCode=masterAPI["labour"].update
      if (mode === "Create") {
          const postData={
            ...value,
            craftList:craftIden
          }
          console.log(postData)
       await axiosPrivate
          .post(`${createLink}`, postData)
          .then((res) => {
            console.log(res.data);
            setLoading(false)
            handleCloseClick();
          })
          .catch((e) => {
            setLoading(false)
            console.log(e, "createlabour");
          });
      } else {
        const updateAC = {
          labourId: editData.id,
          craftList:craftIden,
          
        };
        const updateLabCode={
          id:editData.id,
          code:value.code
        }
        console.log(updateAC,updateLabCode)
        if(editData.labourCode!==value.code){
       await axiosPrivate
        .put(`${updateCode}`, updateLabCode)
        .then((res) => {
          console.log(res.data);
          setLoading(false)
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false)
          console.log(e, "updatelabour");
        });
      }
      if(craftIden.length!==0){
       await axiosPrivate
          .put(`${updateLink}`, updateAC)
          .then((res) => {
            console.log(res.data);
            setLoading(false)
            handleCloseClick();
          })
          .catch((e) => {
            setLoading(false)
            console.log(e, "updatelabour");
          });
        }
      }
    };

    const handleCraft=(e,id)=>{
     if(e.target.checked){
      setSelCraft([...selectCraft,id])
      setCraftIden([...craftIden,{craftId:id,isNewRecord:true}])
     }else{
      const filterData=selectCraft.filter(fil=>fil!==id)
      setSelCraft(filterData)
      const filterCraft=craftIden.filter(fil=>fil.craftId!==id)
      setCraftIden(filterCraft)
     }

    }
  
    const handleCancel = () => {
      handleCloseClick();
    };

    const handleDelete = async(id) => {
      const getLabourCraftId=labourCraft.filter(fil=>fil.labourId===editData.labourId&&fil.craftId===id)
    
      const deleteLink = masterAPI["labourCraft"].delete;
     await axiosPrivate
        .delete(`${deleteLink}/${getLabourCraftId[0].id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        }) 
        .catch((e) => {
          console.log(e, "deletelabour");
        });
    };

    const craftColumns=[
      {
        accessor: "id",
        disableFilters: true,
        Cell: ({cell}) => {
          return (
           
            <Checkbox
            disabled={aldreadySelCraft.includes(cell.value)}
         
            checked={selectCraft.includes(cell.value)}
            onClick={(e)=>{
              handleCraft(e,cell.value)
            }}
            sx={{
           
                "&.Mui-disabled":{
                   color:"#1976d2"
                }
         
            
              
            }}
            />
         
          );
        },
      },
      {
        Header: "Crafts Name",
        accessor: "craftName",
        // disableFilters: true,
      },
      
      {
        Header: "Action",
        enableHiding:mode==="Create"?true:false,
        accessor: "isDeleted",
        // isVisible:false,
        Cell:({cell})=>{
          return(
            <IconButton>
            <DeleteOutline
              onClick={() => {
                handleDelete(cell.row.original.id);
              }}
              sx={{
                fontSize: color.masterEditDelButton,
                color: color.masterEditDelButtonColor,
              }}
            />
          </IconButton>
          )
        }
        // disableFilters: true,
      },
    ]
  
    return (
      <Box sx={{ p: color.masterPageDialogPadding }}>
        
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
            {mode === "Edit"?editData.code+"-"+editData.labourName:"New Labour"}
          </Typography>
          <IconButton onClick={handleCancel}>
            <Clear />
          </IconButton>
        </Box>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        <Formik initialValues={initialValue} onSubmit={onSubmit}>
          {({ handleChange, setFieldValue, values, resetForm }) => (
            <Form>
              <Grid container spacing={"1rem"}>
              <Grid item xs={12} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Labour Code
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="code"
                    placeholder="Enter Labour Code"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
               
                </Grid>
                <Grid item xs={12} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Labour Name
                </Typography>
                  {/* <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="labourName"
                    placeholder="Enter Labour Name"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  /> */}
                    {/* <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="personId"
               
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.personId !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {personId.map((id, i) => (
                  <MenuItem key={i} value={id.id}>{id.personName}</MenuItem>
                ))}
              </Field> */}
                <Autocomplete
                // value={remedySelect}
                inputValue={values.labourName}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                loading={personId.length===0}
                options={personId}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.personName
                }
                freeSolo
                readOnly={mode === "Edit"}
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    setFieldValue("labourName",value.personName)
                    setFieldValue("personId",value.id)
                  }else{
                    setFieldValue("labourName","")
                    setFieldValue("personId","")
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  setFieldValue("labourName",newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="causes"
                    placeholder="Select Cause"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
                  
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                          {personId.length===0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
                </Grid>
                <Grid item xs={6} sx={{display:mode === "Edit"?"default":"none"}} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Title
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    value={personDetail.title}
                    // name="code"
                    // placeholder="Enter Labour Code"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
               
                </Grid>
                <Grid item xs={6} sx={{display:mode === "Edit"?"default":"none"}} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Person
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    value={personDetail.code+"-"+personDetail.personName}
                    // name="code"
                    // placeholder="Enter Labour Code"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
               
                </Grid>
                <Grid item xs={6} sx={{display:mode === "Edit"?"default":"none"}} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Email
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    value={personDetail.email}
                    // name="code"
                    // placeholder="Enter Labour Code"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
               
                </Grid>
                <Grid item xs={6} sx={{display:mode === "Edit"?"default":"none"}} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Mobile Number
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    value={personDetail.mobileNo}
                    // name="code"
                    // placeholder="Enter Labour Code"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
               
                </Grid>
                

                <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Craft
                </Typography>
                  <NormalTable columns={craftColumns} data={craftData} filt={true} />
                </Grid>
              
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"flex-end"}
                  gap="1rem"
                >
                  <Button
                    onClick={handleCancel}
                    variant="contained"
                    sx={{
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                      ":hover": {
                        backgroundColor: color.TableCancelButton,
                        textTransform: "none",
                        color: color.CancelTextColor,
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    variant="contained"
                    disabled={loading}
                    sx={{
                      
                      textTransform: "none",
                      backgroundColor: loading ? "default" : color.TableButtonColor,
                      "&:hover": {
                        backgroundColor: loading
                          ? "default"
                          : color.buttonDisable,
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };
  
  export default LabourModal;
  