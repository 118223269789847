import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Field, Form, Formik } from "formik";
  import * as Yup from "yup";
  import { Clear } from "@mui/icons-material";
  import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import dayjs from "dayjs";
  import moment from "moment";
  
  import { color } from "../../../StyleData/Config";
  import { axiosPrivate } from "../../../axios/axios";
  import { masterAPI } from "../dataConfig";
  import { DesktopDatePicker } from "@mui/lab";
  import { DateTimePicker } from "@mui/x-date-pickers";
  
  const PriorityModal = ({ mode, handleCloseClick, editData }) => {
    
    const [loading,setLoading]=useState(false)
  
    
  
    const initialValue = {
      priorityName: mode === "Edit" ? editData.priorityName : "",
      code: mode === "Edit" ? editData.code : "",
      colorId: mode === "Edit" ? editData.colorId:""
    
    };
  
    const onSubmit = async(value) => {
      console.log(value);
      setLoading(true)
      const createLink = masterAPI["priority"].create;
      const updateLink = masterAPI["priority"].update;
      if (mode === "Create") {
          
       await axiosPrivate
          .post(`${createLink}`, value)
          .then((res) => {
            console.log(res.data);
            setLoading(false)
            handleCloseClick();
          })
          .catch((e) => {
            setLoading(false)
            console.log(e, "createPriority");
          });
      } else {
        const updateAC = {
          id: editData.id,
          ...value,
        };
       await axiosPrivate
          .put(`${updateLink}`, updateAC)
          .then((res) => {
            console.log(res.data);
            setLoading(false)
            
            handleCloseClick();
          })
          .catch((e) => {
            setLoading(false)
            console.log(e, "updatePriority");
          });
      }
    };
  
    const handleCancel = () => {
      handleCloseClick();
    };
  
    return (
      <Box sx={{ p: color.masterPageDialogPadding }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
            {mode === "Edit"?editData.code+"-"+editData.priorityName:"New Priority"}
          </Typography>
          <IconButton onClick={handleCancel}>
            <Clear />
          </IconButton>
        </Box>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        <Formik initialValues={initialValue} onSubmit={onSubmit}>
          {({ handleChange, setFieldValue, values, resetForm }) => (
            <Form>
              <Grid container spacing={"1rem"}>
              <Grid item xs={12} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Priority Code
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="code"
                    placeholder="Enter Priority Code"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
             
                <Grid item xs={12} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Priority Name
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="text"
                    name="priorityName"
                    placeholder="Enter Priority Name"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3} md={2} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Priority Color
                </Typography>
                  <Field
                    as={TextField}
                    size={"small"}
                    type="color"
                    name="colorId"
                    placeholder="Enter Priority Color"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": {
                        // letterSpacing: "0.2rem",
  
                        "&::placeholder": {
                          // color: "green",
                          opacity: 1,
                          color: color.placeholderColor,
                          fontSize: "13px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"flex-end"}
                  gap="1rem"
                >
                  <Button
                    onClick={handleCancel}
                    variant="contained"
                    sx={{
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                      ":hover": {
                        backgroundColor: color.TableCancelButton,
                        textTransform: "none",
                        color: color.CancelTextColor,
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    variant="contained"
                    disabled={loading}
                    sx={{
                      
                      textTransform: "none",
                      backgroundColor: loading ? "default" : color.TableButtonColor,
                      "&:hover": {
                        backgroundColor: loading
                          ? "default"
                          : color.buttonDisable,
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };
  
  export default PriorityModal;
  