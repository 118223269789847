import React, { useEffect, useState } from 'react'
import { color } from '../../../StyleData/Config'
import { Grid, Typography,Paper,Select,MenuItem,TextField,Box,Divider, IconButton, Tab, } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { axiosPrivate, siteInfo } from '../../../axios/axios'
import { masterAPI } from '../../Masters/dataConfig'
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { Add, PlaylistAdd } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const WOView = ({woData,direction}) => {
 
  
  const navigate=useNavigate()
  const siteInfoDetail=useSelector(state=>state.auth.loginInfo)




  

   
     
     

  return (
    <Box sx={{p:1}}>
    
              <Box sx={{mt:"1rem"}}>
              {/* <TabPanel value="1" sx={{ p: 1 }}> */}
               {/* <CreateGeneral jobPlanIden={handleJobPlanId}/> */}
    
       
      <Grid container gap={"1.5rem"}>
    
        <Grid item xs={12}  md={direction==="column"?12:5.5} sx={{boxShadow:"-1px 1px 7px 2px rgb(0 0 0 / 32%)",p:1.5,backgroundColor:"white"}}  >
    
        <Box>
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        > Basic </Typography>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        </Box>
    <Grid container spacing={"1rem"}>
    <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Work Order Name
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.workOrderName}
              </Typography>
             
            </Grid>
            <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Description
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.description}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Location Id
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.locationName}
              </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Site
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
              {siteInfoDetail.siteName}
              </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                    GL Account
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                ---
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Asset Name
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.assetName}
              </Typography>
            </Grid>
            </Grid>
         
            </Grid>
            <Grid item xs={12}  md={direction==="column"?12:5.5} sx={{boxShadow:"-1px 1px 7px 2px rgb(0 0 0 / 32%)",p:1.5,backgroundColor:"white"}}>
  
      <Box>
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        > Job Info</Typography>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        </Box>
    <Grid container spacing={"1rem"}>
    <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               Job Status
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.status}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               Work Type
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.workTypeName}
              </Typography>
            </Grid>
           
            {/* <Grid item xs={6} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               Job Priority
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.priority}
              </Typography>
            </Grid> */}
            <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Failure Class
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.failureClassName}
              </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Problem Code
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.problemName?woData.problemName:"---"}
              </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Priority
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.priorityName}
              </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  SYSPRO Job No
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.notes}
              </Typography>
            </Grid>
            </Grid>
            
            </Grid>
            <Grid item xs={12}  md={direction==="column"?12:5.5} sx={{boxShadow:"-1px 1px 7px 2px rgb(0 0 0 / 32%)",p:1.5,backgroundColor:"white"}}>
  
  <Box>
    <Typography
      sx={{
        color: color.masterDialogTitleColor,
        fontSize: color.masterDialogFontSize,
      }}
    > Planning</Typography>
    <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
    </Box>
<Grid container spacing={"1.5rem"}>
<Grid item xs={12} md={6}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              
              }}
            >
           Job Plan ID
            </Typography>
            <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.jobPlanName}
              </Typography>
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Actual Start Date
                </Typography>
                
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.actualStartDate!==null&&woData.actualStartDate!==undefined?moment(woData.actualStartDate).format("DD/MM/YYYY HH:mm:ss"):"---"}
              </Typography>
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"} }}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Target Start Date
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.targetStartDate!==null&&woData.targetStartDate!==undefined?moment(woData.targetStartDate).format("DD/MM/YYYY HH:mm:ss"):"---"}
              </Typography>
               
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Actual End Date
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.actualEndDate!==null&&woData.actualEndDate!==undefined?moment(woData.actualEndDate).format("DD/MM/YYYY HH:mm:ss"):"---"}
              </Typography>
              
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Target End Date
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.targetEndDate!==null&&woData.targetEndDate!==undefined?moment(woData.targetEndDate).format("DD/MM/YYYY HH:mm:ss"):"---"}
              </Typography>
              
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Stop Begin Date
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.stopBeginDate!==null&&woData.stopBeginDate!==undefined?moment(woData.stopBeginDate).format("DD/MM/YYYY HH:mm:ss"):"---"}
              </Typography>   
              
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Planned Start Date
                </Typography>
                
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.plannedStartDate!==null&&woData.plannedStartDate!==undefined?moment(woData.plannedStartDate).format("DD/MM/YYYY HH:mm:ss"):"---"}
              </Typography>
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Planned End Date
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.plannedEndDate!==null&&woData.plannedEndDate!==undefined?moment(woData.plannedEndDate).format("DD/MM/YYYY HH:mm:ss"):"---"}
              </Typography>
              
        </Grid>
        <Grid item xs={6} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Duration
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.duration}
              </Typography>
            </Grid>
            <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Earliest Start Date
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.earliestStartDate!==null&&woData.earliestStartDate!==undefined?moment(woData.earliestStartDate).format("DD/MM/YYYY HH:mm:ss"):"---"}
              </Typography>
               
        </Grid>
        <Grid item xs={6}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  latest End Date
                </Typography>
                
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.latestEndDate!==null&&woData.latestEndDate!==undefined?moment(woData.latestEndDate).format("DD/MM/YYYY HH:mm:ss"):"---"}
              </Typography>
        </Grid>
        </Grid>
        
        </Grid>
        <Grid item xs={12}  md={direction==="column"?12:5.5} sx={{boxShadow:"-1px 1px 7px 2px rgb(0 0 0 / 32%)",p:1.5,backgroundColor:"white"}}>
  
  <Box>
    <Typography
      sx={{
        color: color.masterDialogTitleColor,
        fontSize: color.masterDialogFontSize,
      }}
    > Responsibility</Typography>
    <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
    </Box>
<Grid container spacing={"1.5rem"}>

        {/* <Grid item xs={12} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Request By
                </Typography>
                
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
               ---
              </Typography>
        
      
            </Grid> */}
            <Grid item xs={12}  sx={{".css-1pduc5x-MuiStack-root":{paddingTop:0,overflowX:{xs:"auto",lg:"hidden"},overflowY:"hidden"}}}>
        <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
                  Created Date
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData.createdDate!==null&&woData.createdDate!==undefined?moment(woData.createdDate).format("DD/MM/YYYY HH:mm:ss"):"---"}
              </Typography>
               
        </Grid>
        <Grid item xs={12} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Work Responsible
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {woData?.responsiblePersonName}
              </Typography>
     
        
      
            </Grid>
            {/* <Grid item xs={12} >
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Business Id
                </Typography>
                
     
          
      
            </Grid> */}
        </Grid>
        
        </Grid>
            </Grid>
       
              {/* </TabPanel> */}
             </Box>
   
    </Box>
   
  )
}

export default WOView