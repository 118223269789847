import { CheckBox, Clear, Save } from '@mui/icons-material'
import { Box, Checkbox, Divider, Grid, Icon, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NormalTable from '../../../../Component/NormalTable'
import { masterAPI } from '../../../Masters/dataConfig'
import { axiosPrivate } from '../../../../axios/axios'
import { color } from '../../../../StyleData/Config'
import { toast } from 'react-toastify'

const PartList = ({taskData,handleCloseClick,type,dataDetail,source,jobNo}) => {
    const [partData,setPartData]=useState([])
    const [partSelect,setPartSelect]=useState([])
    const [partSelectDetail,setPartSelectDetail]=useState([])
    const [quantity,setQuantity]=useState("")

    useEffect(()=>{
  
     getData()
    },[])

   const getData=async()=>{
    const partLink=masterAPI["parts"].link
   await axiosPrivate.get(`${partLink}`).then(async(res)=>{
       console.log(res.data.result)
     
       if(type==="wot"&&source==="syspro"){
         const partList=[
           "BR0001",
           "C600600",
           "SW0001",
           "TC0001",
           "PABETE-695977",
           "PABETE-823443",
           "PABETE-821205",
           "PABETE-340703",
           "PABETE-465419",
           "PABETE-723807"
           
       ]
         // for(let i=0;res.data.result.length>i;i++){
        
         //   partList.push(res.data.result[i].code)
         // }
         
         console.log(partList)
         // const insertQuantity=[]
         // const checkFlow=[]
         // for(let i=0;res.data.result.length>i;i++){
         //   insertQuantity.push({...res.data.result[i],sysproQty:0})
         //   console.log(res.data.result[i].code,"i loop")
           
         // }
         // for(let i=0;insertQuantity.length>i;i++){
         //   for(let j=0;dataCh.length>j;j++){
         //     if(insertQuantity[i].code===dataCh[j].Name){
         //       insertQuantity[i]={...insertQuantity[i],sysproQty:dataCh[j].Qty}
         //       break;
         //     }
         //   }

         // }

         // insertQuantity.sort((a,b)=>b.sysproQty-a.sysproQty)
         // setPartData(insertQuantity)
      
       await axiosPrivate.post("https://connector.orienseam.com/api/SysProXml/Inventory",partList).then(respo=>{
         console.log(res)
         // const insertQuantity=[]
       
         if(respo.data.length!==0){
           const insertQuantity=[]
         // const checkFlow=[]
         for(let i=0;res.data.result.length>i;i++){
           insertQuantity.push({...res.data.result[i],sysproQty:0})
           console.log(res.data.result[i].code,"i loop")
           
         }
         for(let i=0;insertQuantity.length>i;i++){
           for(let j=0;respo.data.length>j;j++){
             if(insertQuantity[i].code===respo.data[j].Name){
               insertQuantity[i]={...insertQuantity[i],sysproQty:respo.data[j].Qty}
               break;
             }
           }

         }

         insertQuantity.sort((a,b)=>b.sysproQty-a.sysproQty)
         setPartData(insertQuantity)
      
         // for(let i=0;res.data.result.length>i;i++){
         //   for(let j=0;respo.data.length>j;j++){
         //     if(respo.data[j].Name===res.data.result[i].code){
         //       insertQuantity.push({...res.data.result[i],sysproQty:respo.data[j].Qty})
         //     }else{
         //       insertQuantity.push(res.data.result[i])
         //     }
         //   }
         // }
         // setPartData(insertQuantity)
       }else{
         setPartData([])
       }
        }).catch(e=>{
         setPartData([])
         console.log(e,"error syspro")
        })
       
       }else{
         setPartData(res.data.result)
       }
    }).catch(e=>{
       setPartData([])
       console.log(e,"Part WorkOrder")
    })
   }


    const columns=[
        {
           
            accessor:"id",
            Cell:({cell})=>{
                return (
               <Checkbox checked={partSelect.includes(cell.row.original.id)} onClick={()=>{handleCheck(cell.row.original)}} />
                )
            }
        },
        {
          Header: "Part Code",
          accessor: "code",
        },
        {
            Header:"Part Name",
            accessor:"partName"
        },
       
    ]
    const columnsyspro=[
      {
         
          accessor:"id",
          Cell:({cell})=>{
              return (
             <Checkbox checked={partSelect.includes(cell.row.original.id)} onClick={()=>{handleCheck(cell.row.original)}} />
              )
          }
      },
      {
        Header: "Part Code",
        accessor: "code",
      },
      {
          Header:"Part Name",
          accessor:"partName"
      },
      {
        Header:"Quantity",
        accessor:"sysproQty"
    },
     
  ]

    const handleCheck=(value)=>{
      if(partSelect.includes(value.id)){
        const removeData=partSelect.filter(l=>l!==value.id)
        const removeDetail=partSelectDetail.filter(l=>l.id!==value.id)
        setPartSelectDetail(removeDetail)
        setPartSelect(removeData)
      }else{
        setPartSelect([...partSelect,value.id])
        setPartSelectDetail([...partSelectDetail,value])
      }
    }



    const handlePartSubmit=async()=>{
        console.log(partSelect,taskData,jobNo)
      
         
        const wotlink=masterAPI["workOrderTaskPart"].bulkinsert
        const jptlink=masterAPI["jobPlanTaskParts"].bulkinsert

        if(type==="wot"){
            const wopart=[]
            const woSypro=[]

            for(let i=0;i<partSelectDetail.length;i++){
              woSypro.push(
                {
                  JobNo: jobNo,
                  PartCode: partSelectDetail[i].code,
                  Quantity: quantity
                  }
              )
            }
            for (let i = 0; i < taskData.length; i++) {
                for (let j = 0; j < partSelect.length; j++) {
                 const wotData={
                     workOrderTaskId:taskData[i],
                     partId:partSelect[j],
                     quantity:quantity
                 }
                 
                 console.log(wotData,i,j)
               wopart.push(wotData)
                 
                }
                 
              }
              console.log(wopart)
            
             await axiosPrivate.post(`${wotlink}`,wopart).then(async(res)=>{
               await axiosPrivate.post("https://connector.orienseam.com/api/SysProXml/WOPlannedParts",woSypro).then(res=>{
                  if(Number(res.data)>0){
                    toast.success("Successfully Assigned")

                  }else{
                    toast.error("Error")
                  }
                  
                  handleCloseClick()
            }).catch(e=>{
              console.log(e)
              toast.error("Error")
            })
                
            }).catch(e=>{
                console.log(e,"createwotaskpart")
                toast.error("Error")
            })
            
        }else{
            console.log("ch")
            const joPart=[]
            for (let i = 0; i < taskData.length; i++) {
                for (let j = 0; j < partSelect.length; j++) {
                 const jptData={
                     jobPlanTaskId:taskData[i],
                     partId:partSelect[j],
                     quantity:quantity

                 }
                 console.log(jptData,i,j)
                 joPart.push(jptData)
                 
                }
                 
              }
              console.log(joPart)
             await axiosPrivate.post(`${jptlink}`,joPart).then(res=>{
                console.log(res,"jpt")
                handleCloseClick()
            }).catch(e=>{
                console.log(e,"createjptaskpart")
            })
        }
     
    }

    const listColumns=[
       
      // {
      //         Header: "ID",
      //         accessor: "id",
             
      //     },
          {
            Header:"Task No",
            accessor:"taskNo"
          },
          {
            Header:"Task Name",
            accessor:"taskName"
          },
          {
            Header:"Description",
            accessor:"description"
          },
          {
            Header:"Sequence No",
            accessor:"sequenceNo"
          },
          {
            Header:"Task Duration",
            accessor:"taskDuration"
          },
       
      
  ]

  return (
    <Grid container sx={{p:1}} spacing={"1rem"}>
       <Grid item xs={12}  >
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
         <Box>
         <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: "1.1rem",
            fontWeight:500
          }}
        >
        Select Task
        </Typography>
         </Box>
         <Box>
            {/* <IconButton onClick={handlePartSubmit}>
            <Save sx={{color:"#2362B4"}} />
            </IconButton> */}
            <IconButton onClick={()=>{handleCloseClick()}}>
                <Clear/>
            </IconButton>
         </Box>
         </Box>
         <Divider/>
        </Grid>
        <Grid item xs={12}>
            <NormalTable data={dataDetail} columns={listColumns}/>
        </Grid>
        <Grid item xs={12}  >
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
         <Box>
         <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: "1.1rem",
            fontWeight:500
          }}
        >
        Part
        </Typography>
         </Box>
         <Box>
            <IconButton onClick={handlePartSubmit}>
            <Save sx={{color:"#2362B4"}} />
            </IconButton>
            {/* <IconButton onClick={()=>{handleCloseClick()}}>
                <Clear/>
            </IconButton> */}
         </Box>
         </Box>
         <Divider/>
        </Grid>
        <Grid container item xs={12} >
            <Box sx={{display:"flex",alignItems:"center" ,gap:"1rem"}}>
        <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    // color: color.labelColor,
                  }}
                >
                Quantity :
                </Typography>
                <TextField name="qauntity" size='small' placeholder='Enter Quantity' value={quantity} onChange={(e)=>{setQuantity(e.target.value)}}/>
                </Box>
        </Grid>
        <Grid item xs={12}>
            <NormalTable data={partData} columns={source==="syspro"?columnsyspro:columns}/>
        </Grid>
    </Grid>
  )
}

export default PartList