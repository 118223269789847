import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import { Box, Checkbox, Dialog, IconButton, Switch, Typography } from "@mui/material";
import { useSearchParams,useNavigate, Link } from "react-router-dom";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import {toast} from "react-toastify"
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  ListAlt,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux"

import { color } from "../../../StyleData/Config";

import ReturnTransModal from "./ReturnTransModal";
import { actions } from "react-table";
import FeedBack from "../../../Component/FeedBack";




const ReturnTrans = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate=useNavigate()
  const actionsAllow=useSelector(state=>state.dataRed.actionsAllow)
  const dispatch=useDispatch()

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [usageStatus,setUsageStatus]=useState([])
  const [returnId,setReturnId]=useState("")

  const [feedBack, setFeedBack] = useState(false);
  const [filterValue, setFilterValue] = useState("enableData");
  const [backUpData, setBackUpData] = useState([]);

  const [statusMenuDetail, setStatusDetail] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["issueReturn"].getAll;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        res.data.result.sort((a, b) => {
          return a.code.localeCompare(b.code, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        if (filterValue === "enableData") {
          const filterDataResult = res.data.result.filter(
            (fil) => fil.isActive
          );
          console.log(filterDataResult, "filterData");
          setData(filterDataResult);
          setBackUpData(res.data.result);
        } else {
          setData(res.data.result);
          setBackUpData(res.data.result);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "issueReturn");
        setIsLoading(false);
        setData([])
        setBackUpData([])
      });

      const usageLink=masterAPI["selectList"].inventoryusagestatus
     await axiosPrivate.get(`${usageLink}`).then(res=>{
      console.log(res)
        setUsageStatus(res.data)
        setIsLoading(false)
      }).catch(e=>{
        console.log(e,"error")
        setUsageStatus([])
        setIsLoading(false)
      })
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  // const handleDelete = (id) => {
  //   const deleteLink = masterAPI["issueReturn"].delete;
  //   axiosPrivate
  //     .delete(`${deleteLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "delete receipt");
  //     });
  // };

  const handleDelete = async(e, id) => {
    const enableLink = masterAPI["issueReturn"].enable;
    const disableLink = masterAPI["issueReturn"].disable;
    if (e.target.checked) {
     await axiosPrivate
        .put(`${enableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "enable return");
        });
    } else {
     await axiosPrivate
        .put(`${disableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "disable return");
        });
    }
  };

  const handleCheck=(e,value)=>{
    // if(e.target.checked){
    //   setIssueId([...issueId,value])
    // }else{
    //   const filIss=issueId.filter(fil=>fil!==value)
    //   setIssueId(filIss)
    // }
    if(e.target.checked){
      setReturnId(value)
    }else{
      setReturnId("")
    }
  }

  const columns = [
    {
      accessor: "id",
      disableFilters: true,
      Cell: ({cell}) => {
        return (
          <Box sx={{ width: color.checkBoxWidth }}>
            <Checkbox
              // icon={<RadioButtonUncheckedOutlined />}
              // checkedIcon={<CheckCircle />}
              checked={returnId===cell.value}
              onClick={(e)=>{handleCheck(e,cell.value)}}
            />
          </Box>
        );
      },
    },
    {
      Header:"Code",
      accessor:"code",
      Cell: ({ cell }) => {
        return (
          <Link
            to={`/issueReturn/issueReturnItem?id=${cell.row.original.id}`}
            onClick={()=>{
              dispatch({type:"storeDisplayListName",payload:{name:"returnItem",data:cell.row.original}})
            }}
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: "Asset",
      accessor: "assetName",
      // disableFilters: true,
    },
    {
      Header: "WO",
      accessor: "workOrderName",
      // disableFilters: true,
    },

    // {
    //   Header: "InvUsage Code",
    //   accessor: "invUsageCode",
    // },
    {
      Header: "GL Credit Account",
      accessor: "glCreditAccount",
    },
    {
      Header: "GL Debit Account",
      accessor: "glDebitAccount",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Returned Date",
      accessor: "returnedDate",
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            {/* <IconButton>
              <EditOutlined
                onClick={() => {
                  handleEdit(cell.row.original);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton> */}
              {/* <IconButton>
              <ListAlt
                onClick={() => {
                 navigate(`/issueReturn/issueReturnItem?id=${cell.row.original.id}`)
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton> */}
            {/* <IconButton
            disabled={!actionsAllow.delete}
            onClick={() => {
              handleDelete(cell.row.original.id);
            }}>
              <DeleteOutline
              
                sx={{
                  fontSize: color.masterEditDelButton,
                  color:actionsAllow.delete? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton> */}
            <Switch
              disabled={
                !(!actionsAllow.delete &&cell.row.original.status.toUpperCase()==="NEW")
              }
              checked={cell.row.original.isActive}
              onChange={(e) => {
                handleDelete(e, cell.row.original.id);
              }}
            />
          </Box>
        );
      },
    },
  ];

  const menuStatusHandle=(value)=>{
    // const getReturnStatus=masterAPI["issueReturn"].updateStatus
    // console.log({id:returnId,status:value.value})
    // axiosPrivate.put(`${getReturnStatus}`,{id:returnId,status:value.value}).then(res=>{
    //  toast.success("Updated")
    //  getData()
    //  setReturnId("")
    // }).catch(e=>{
    //  console.log(e,"error")
    // })

 
      console.log(value);
      setStatusDetail(value);
      // const putData=[]
      // for(let i=0;selectSR.length>i;i++){
      //   putData.push({
      //     id:selectSR[i],
      //     status:menuData.value
      //   })
      // }
      if (value.value === "Cancelled") {
        setFeedBack(true);
      } else {
        handleMenuSelect(value,"");
      }
    
   }

   const handleMenuSelect = async(menuData,reason) => {
    setFeedBack(false);
    const putData = {
      id: returnId,
      status: menuData.value,
      reasontoCancel: reason,
    };
    console.log(putData);
    const statusLink = masterAPI["issueReturn"].updateStatus;
   await axiosPrivate
      .put(`${statusLink}`, putData)
      .then((res) => {
        console.log(res);
        toast.success("Updated")
        getData()
        setReturnId("")
      
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const handleChangeFilter = (e) => {
    if (e === "showAll") {
      setData(backUpData);
    } else {
      const filterDataResult = backUpData.filter((fil) => fil.isActive);

      setData(filterDataResult);
    }
    setFilterValue(e);
  };

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["issueReturn"].title}
        </Typography>
      </Box>

      <TableContainer
        columns={columns}
        data={data}
        buttonNew={handleButtonNew}
        loading={isLoading}
        statusList={usageStatus}
        menuStatusHandle={menuStatusHandle}
        buttonDisable={!actionsAllow.create}
        changeStatusOption={returnId!==""}
        filterValue={filterValue}
        handleChange={handleChangeFilter}
        filterShow={true}
      />

      <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"xl"}
        children={
          <ReturnTransModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      />
       <Dialog
        open={feedBack}
        onClose={() => setFeedBack(false)}
        maxWidth={"lg"}
        children={
          <FeedBack
            handleCloseClick={(e) => handleMenuSelect(statusMenuDetail,e)}
        
            onCancel={() => {
              setFeedBack(false);
            }}
          />
        }
      />
    </>
  );
};

export default ReturnTrans;
