import { CheckBox, Clear, Save } from '@mui/icons-material'
import { Box, Checkbox, Divider, Grid, Icon, IconButton, MenuItem, Radio, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NormalTable from '../../../../Component/NormalTable'
import { masterAPI } from '../../../Masters/dataConfig'
import { axiosPrivate } from '../../../../axios/axios'
import { color } from '../../../../StyleData/Config'
import TableContainerComp from '../../../../Component/TableContainer'
import { json } from 'react-router-dom'

const PartEdit = ({editData,handleCloseClick,type}) => {
    const [partData,setPartData]=useState([])
    const [partSelect,setPartSelect]=useState({...editData,id:editData.partId})
  

    const [quantity,setQuantity]=useState(editData.quantity)





    useEffect(()=>{
     getData()
 
    },[])

    const getData=async()=>{
      const labourLink=masterAPI["parts"].link
      await axiosPrivate.get(`${labourLink}`).then(res=>{
         console.log(res.data.result)
         setPartData(res.data.result)
      }).catch(e=>{
         setPartData([])
         console.log(e,"Part WorkOrder")
      })
    }

    const columns=[
        {
        
            accessor:"id",
            disableFilters: true,
            Cell:({cell})=>{
                return (
                  <>
             
                  <Radio checked={partSelect.id===cell.row.original.id} onClick={()=>{handleCheck(cell.row.original)}} />
                </>
                )
            }
        },
        {
          Header:"Part Code",
          accessor:"code",
          // disableFilters: true,
      },
        {
            Header:"Part Name",
            accessor:"partName",
            // disableFilters: true,
        },
        
    ]

  

  

    const handleCheck=(value)=>{
    setPartSelect(value)
    }

   
  



    const handlePartSubmit=async()=>{
      
      if(type==="wo"){
         
        const wotlink=masterAPI["workOrderTaskPart"].update
      
         const putData={
            id:editData.id,
            partId:partSelect.id,
            
            quantity:quantity,
            workOrderTaskId:editData.workOrderTaskId
         }
         console.log(putData)
         
             await axiosPrivate.put(`${wotlink}`,putData).then(res=>{
                console.log(res)
                handleCloseClick()
            }).catch(e=>{
                console.log(e,"createwotaskLabour")
            })
       
          }else{
              
        const jptlink=masterAPI["jobPlanTaskParts"].update
      
        const putData={
           id:editData.id,
           partId:partSelect.id,
           
           quantity:quantity,
           jobPlanTaskId:editData.jobPlanTaskId
        }
        console.log(putData)
        
            await axiosPrivate.put(`${jptlink}`,putData).then(res=>{
               console.log(res)
               handleCloseClick()
           }).catch(e=>{
               console.log(e,"createjptaskLabour")
           })
          }
    }


  

  return (
    <Grid container sx={{p:1}} spacing={"1.5rem"}>
      
       
        <Grid item xs={12}  >
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: "1.1rem",
            fontWeight:500
          }}
        >
         Labour
        </Typography>
         <Box>
            <IconButton onClick={()=>{
               
                    handlePartSubmit()
               
            }}>
                <Save sx={{color:"#2362B4"}} />
            </IconButton>
            <IconButton onClick={()=>{
               
              handleCloseClick()
          
       }}>
           <Clear sx={{color:"#2362B4"}} />
       </IconButton>
         
         </Box>
         </Box>
         <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)"}}/>
        </Grid>
        <Grid container item xs={12} spacing={"1.5rem"} >
           
            <Grid item xs={6}>
            <Box sx={{display:"flex",alignItems:"center" ,gap:"1rem"}}>
        <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    // color: color.labelColor,
                    whiteSpace:"nowrap"
                  }}
                >
                Quantity :
                </Typography>
                <TextField name="qauntity" size='small' placeholder='Enter Quantity' value={quantity} onChange={(e)=>{setQuantity(e.target.value)}}/>
                </Box>
            </Grid>
          
        </Grid>
     
        <Grid item xs={12} >
            <NormalTable data={partData} columns={columns} filt={true}/>
        </Grid>
       
    </Grid>
  )
}

export default PartEdit