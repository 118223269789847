import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../dataConfig";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import dayjs from "dayjs";

const TechnicalAttrModal = ({ mode, handleCloseClick, editData }) => {
  const [specId, setSpecId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uomId, setUomId] = useState([]);
  // const [radio, setRadio] = useState("");

  useEffect(() => {
    getData()
  }, []);

const getData=async()=>{
  setLoading(true);
    // const link = masterAPI["technicalSpecification"].link;
    // axiosPrivate
    //   .get(`${link}`)
    //   .then((res) => {
    //     setLoading(false);
    //     setSpecId(res.data.result);
    //   })
    //   .catch((e) => {
    //     console.log(e, "create technicalSpecificationgetAll");
    //   });

    const uomLink = masterAPI["selectList"].uomByCat;
   await axiosPrivate
      .get(`${uomLink}/TechAttribute`)
      .then((res) => {
        setLoading(false);
        setUomId(res.data);
      })
      .catch((e) => {
        setUomId([])
        console.log(e, "create TA uom getAll");
      });
}

  const initialValue = {
    attributeName: mode === "Edit" ? editData.attributeName : "",
    // techSpecId: mode === "Edit" ? editData.techSpecId : "",
    uomId: mode === "Edit" ? editData.uomId : null,
    attributeNumericValue:
      mode === "Edit" ? editData.attributeNumericValue : "",
    attributeTextValue: mode === "Edit" ? editData.attributeTextValue : "",
    attributeDateValue: mode === "Edit" ? editData.attributeDateValue : null,
    code: mode === "Edit" ? editData.code : "",
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["technicalAttributes"].create;
    const updateLink = masterAPI["technicalAttributes"].update;
    if (mode === "Create") {
     await axiosPrivate
        .post(`${createLink}`, value)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "create technicalAttributes");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "update technicalAttributes");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode === "Edit"?editData.code+"-"+editData.attributeName:"New Technical Attribute"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Attribute Code
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="code"
                  placeholder="Enter Attribute Code"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Attribute Name
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="attributeName"
                  placeholder="Enter Attribute Name"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Technical Specification
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="techSpecId"
                  // placeholder="Enter Asset Category Name"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.techSpecId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {specId.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.code + " - " + id.techSpecName}
                    </MenuItem>
                  ))}
                </Field>
              </Grid> */}
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  UOM
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="uomId"
                  // placeholder="Enter Asset Category Name"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.uomId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {uomId.map((id, i) => (
                    <MenuItem key={i} value={id.value}>
                      {id.text}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              {/* <Grid container item xs={12} md={4.5} alignItems={"center"}>
                <RadioGroup
                row
                  value={radio}
                  onChange={(e) => {
                    setRadio(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Number"
                    control={<Radio />}
                    label="Number"
                    sx={{
                      ".MuiFormControlLabel-label":  {
                        // Change color here
                        color: color.labelColor
                        }
                    }}
                  />
                  <FormControlLabel
                    value="Text"
                    control={<Radio />}
                    label="Text"
                    sx={{
                      ".MuiFormControlLabel-label":  {
                        // Change color here
                        color: color.labelColor
                        }
                    }}
                  />
                  <FormControlLabel
                    value="Date"
                    control={<Radio />}
                    label="Date"
                    sx={{
                      ".MuiFormControlLabel-label":  {
                        // Change color here
                        color: color.labelColor
                        }
                    }}
                  />
                </RadioGroup>
              </Grid> */}
              {/* <Grid
                item
                xs={12}
                md={7.5}
                sx={{ display: radio === "Number" ? "default" : "none" }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Attribute Numeric Value
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="attributeNumericValue"
                  placeholder="Enter Attribute Numeric"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={7.5}
                sx={{ display: radio === "Text" ? "default" : "none" }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Attribute Text Value
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="attributeTextValue"
                  placeholder="Enter Attribute Text"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={7.5}
                sx={{
                  display: radio === "Date" ? "default" : "none",
                  ".css-1pduc5x-MuiStack-root": {
                    paddingTop: 0,
                    overflowX: { xs: "auto", lg: "hidden" },
                    overflowY: "hidden",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Attribute Date Value
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={
                        values.attributeDateValue !== null
                          ? dayjs(values.attributeDateValue)
                          : values.attributeDateValue
                      }
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}

                      slotProps={{
                        textField: { size: "small", fullWidth: true },
                      }}
                      fullWidth
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour =
                              new Date().getHours() < 10
                                ? "0" + new Date().getHours()
                                : new Date().getHours();
                            const min =
                              new Date().getMinutes() < 10
                                ? "0" + new Date().getMinutes()
                                : new Date().getMinutes();
                            const sec =
                              new Date().getSeconds() < 10
                                ? "0" + new Date().getSeconds()
                                : new Date().getSeconds();
                            const dateFormat =
                              dateFrom + "T" + hour + ":" + min + ":" + sec;
                            setFieldValue("attributeDateValue", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid> */}

              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default TechnicalAttrModal;
