import { Box, Button, Divider, Grid, IconButton, TextField, Typography } from '@mui/material'
import React from 'react'
import { color } from '../../../../StyleData/Config'
import { Clear } from '@mui/icons-material'
import { masterAPI } from '../../../Masters/dataConfig'
import { axiosPrivate } from '../../../../axios/axios'
import { Field, Form, Formik } from 'formik'

const Remark = ({data,handleCancel}) => {
    
    const initialValue={
        remarks:""
    }

    const handleSubmit=async(value)=>{
     const updateLink=masterAPI["workOrderTask"].update
     const updateData={
        ...data,
        comments:value.remarks
     }
    await axiosPrivate.put(`${updateLink}`,updateData).then(res=>{
        console.log(res)
        handleCancel()
     }).catch(e=>{
        console.log(e)
     })
    }
    

  return (
    <Box sx={{ p: color.masterPageDialogPadding}}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          color: color.masterDialogTitleColor,
          fontSize: color.masterDialogFontSize,
        }}
      >
       Remark
      </Typography>
      <IconButton onClick={handleCancel}>
        <Clear />
      </IconButton>
    </Box>
    <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
    <Formik initialValues={initialValue} onSubmit={handleSubmit}>
    {({ handleChange, setFieldValue, values , resetForm }) => (
       <Form>
       <Grid container spacing={"1rem"}>
    
         <Grid item xs={12} md={12}>
         
           <Field
             as={TextField}
             size={"small"}
             type="text"
             name="remarks"
             placeholder="Enter Remarks"
             fullWidth
             sx={{
               ".MuiInputBase-input": {
                 // letterSpacing: "0.2rem",

                 "&::placeholder": {
                   // color: "green",
                   opacity: 1,
                   color: color.placeholderColor,
                   fontSize: "13px",
                 },
               },
             }}
           />
         </Grid>
         
         <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
           
           <Button
             type={"submit"}
             variant="contained"
             sx={{
                   
               textTransform: "none",
               backgroundColor:color.TableButtonColor,
               "&:hover": {
                 backgroundColor:  color.buttonDisable,
               },
             }}
           >
             Submit
           </Button>
         </Grid>
       </Grid>
     </Form>
    )}
    </Formik>
    </Box>
  )
}

export default Remark