import { Box, CssBaseline, Toolbar } from "@mui/material";
import React, { useState } from "react";
import Nav from "../layout/Nav";
import SideNav from "../layout/SideNav";
import { useDispatch, useSelector } from "react-redux";
import { Navigate,useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {
  const drawerWidth = 240;
  const [open, setOpen] = useState(true);
  const login = useSelector((state) => state.auth.loginInfo.accessToken);
  const menuArray = useSelector((state) => state.dataRed.menuArray);
  const drawerOpen = useSelector((state) => state.auth.drawerOpen);
  const navigate=useNavigate()
  const dispatch=useDispatch()
  // const login=useSelector(state=>state.auth.loginInfo)

  const handleDrawerOpen = () => {
    if(drawerOpen.bol){
      dispatch({type:"drawerOpen",payload:{bol:drawerOpen.bol,open:!drawerOpen.open}})
    }else{
      setOpen(!open);
    }
    
    
  };
  const handleauthentication = () => {
       if(props.data.module==="notauthenticate"){
      return true
     }else{

    
      const checkmenu = menuArray.filter((fil) => fil.module === props.data.module);
      console.log(props.data,checkmenu)
      dispatch({type:"actionsAllow",payload:checkmenu[0]})
      if (checkmenu.length === 0) {
      
        return false;
      } else {
       
        if (props.data.action === "view") {
        
          return true;
        } else if (props.data.action === "create" && checkmenu[0].create) {
          return true;
        } else {
          return false;
        }
      }
    }
    
  };
  return (
    <>
    {login !== undefined && login !== null && login !== ""?
    <>
    {/* {JSON.stringify({test:handleauthentication()})} */}
      {handleauthentication() ? (
        <Box sx={{ backgroundColor: "#FAFAFA", minHeight: "100vh" }}>
          <CssBaseline />
          <Nav drawerOpen={handleDrawerOpen} />
          <SideNav open={open} drawerWidth={drawerWidth} res={"main"} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              // p: 2,
              p: {xs:"1rem 0.3rem",md:"0.5rem"},
              ml: { xs: 0, md: (drawerOpen.bol?drawerOpen.open: open) ? `${drawerWidth}px` : 0 },
              transition: (theme) =>
                theme.transitions.create("margin", {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            }}
          >
            <Toolbar />

            {props.children}
          </Box>
        </Box>
      ) : (
        <Navigate to="/notauthenticate" replace />
      )}
    </>
    :<Navigate to="/" replace />
    }
    </>
  );
};

export default ProtectedRoute;
