import { Autocomplete, Box, Button, CircularProgress, Collapse, createFilterOptions, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { color } from '../../../StyleData/Config'
import { masterAPI } from '../../Masters/dataConfig'
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import axios from 'axios';
import { axiosPrivate } from '../../../axios/axios';
import NormalTable from '../../../Component/NormalTable';

const InvenAdj = () => {
    const [open,setOpen]=useState(true)
    const [stockRoomData,setStockRoomData]=useState([])
    const [aisleData,setAisleData]=useState([])
    const [rowData,setRowData]=useState([])
    const [binData,setBinData]=useState([])
    const [selectData,setSelectData]=useState({
        stockRoom:{
            id:null,
            autoText:""
        },
        aisle:{
            id:null,
            autoText:""
        },
        row:{
            id:null,
            autoText:""
        },
        bin:{
            id:null,
            autoText:""
        },
    })
    const [partData,setPartData]=useState([])

    const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(()=>{
    getData()
  },[])

  const getData=async()=>{
    const getStockRoom=masterAPI["selectList"].stockRoom
    await axiosPrivate.get(`${getStockRoom}`).then(res=>{
        setStockRoomData(res.data)
    }).catch(e=>{
        console.log(e,"error")
    })
  }

  const handleStockRoom=async(id)=>{
    const getAisle=masterAPI["selectList"].stockRoomAisle
     await axiosPrivate.get(`${getAisle}/${id}`).then(res=>{
        setAisleData(res.data)
      }).catch(e=>{
        console.log(e)
      })
  }

  const handleAisle=async(id)=>{
    const getRow=masterAPI["selectList"].stockRoomAisleRow
     await axiosPrivate.get(`${getRow}/${id}`).then(res=>{
        setRowData(res.data)
      }).catch(e=>{
        console.log(e)
      })
  }

  const handleRow=async(id)=>{
    const getBin=masterAPI["selectList"].stockRoomAisleRowBin
     await axiosPrivate.get(`${getBin}/${id}`).then(res=>{
        setBinData(res.data)
      }).catch(e=>{
        console.log(e)
      })
  }


  const handleSearch=async()=>{
    const searchData={
        stockRoomId:selectData.stockRoom.id,
        stockRoomAisleId:selectData.aisle.id,
        stockRoomRowId:selectData.row.id,
        stockRoomBinId:selectData.bin.id
    }
    console.log(searchData)
    const searchLink=masterAPI["inventory"].search
    await axiosPrivate.post(`${searchLink}`,searchData).then(res=>{
        console.log(res)
        setPartData(res.data.result)
    }).catch(e=>{
        console.log(e)
    })
    
  }

  const columns=[
    {
      Header:"Code",
      accessor:"code"
    },
    {
      Header:"Part Name",
      accessor:"partName"
    },
    {
      Header:"Quantity",
      accessor:"totalQuantity"
    },

  ]

  return (
    <>
    <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          Inventory Adjustment
        </Typography>
      </Box>
      <Grid container spacing={"1rem"}>
      <Grid item xs={12}>
        <Paper sx={{ padding: "1rem" }}>
          <Grid container alignItems={"center"}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#2362B4",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  {open ? (
                    <KeyboardArrowUpIcon
                      sx={{ fontSize: "1.7rem", color: "#2362B4" }}
                    />
                  ) : (
                    <KeyboardArrowDown
                      sx={{ fontSize: "1.7rem", color: "#2362B4" }}
                    />
                  )}{" "}
                </IconButton>
                Filter
              </Typography>
            </Grid>
            <Grid container item xs={6} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#2362B4", color: "white" }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Collapse in={open} timeout={"auto"} unmountOnExit>
            <Grid container spacing={"1.5rem"}>
             

              <Grid item xs={12} md={3}>
                <Typography
                  sx={{
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: 600,
                    color: "#656565",
                    mb: "1rem",
                  }}
                >
                  Stock Room
                </Typography>
                <Autocomplete
                  name="stockRoomId"
                  //  value={autoValue}
                  // value={values.assetName.code+"_"+values.assetName.assetName}
                  inputValue={selectData.stockRoom.autoText}
                  filterOptions={filterOptions}
                  loading={stockRoomData.length === 0}
                  options={stockRoomData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) =>
                    option.text || option 
                  }
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      console.log(value);
                      setSelectData({ ...selectData, stockRoom:{
                        id:value.value,
                        autoText:value.text
                      } });
                      handleStockRoom(value.value)
                    } else {
                        setSelectData({ ...selectData, stockRoom:{
                            id:null,
                            autoText:""
                          } });
                      // setFieldValue("failureClassId",null)
                    }
                  }}
                  onInputChange={(e, v) => {
                    setSelectData({ ...selectData, stockRoom:{...selectData.stockRoom,autoText:v} });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Stockroom"
                      // onChange={handleChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {stockRoomData.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                >
                  {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                </Autocomplete>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  sx={{
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: 600,
                    color: "#656565",
                    mb: "1rem",
                  }}
                >
                 Aisle
                </Typography>
                <Autocomplete
                  name="aisleId"
                  //  value={autoValue}
                  // value={values.assetName.code+"_"+values.assetName.assetName}
                  inputValue={selectData.aisle.autoText}
                  filterOptions={filterOptions}
                //   loading={aisleData.length === 0}
                  options={aisleData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) =>
                    option.text || option
                  }
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      console.log(value);
                      setSelectData({ ...selectData, aisle:{
                        id:value.value,
                        autoText:value.text
                      } });
                      handleAisle(value.value)
                    } else {
                        setSelectData({ ...selectData, aisle:{
                            id:null,
                            autoText:""
                          } });
                      // setFieldValue("failureClassId",null)
                    }
                  }}
                  onInputChange={(e, v) => {
                    setSelectData({ ...selectData, aisle:{...selectData.aisle,autoText:v} });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Aisle"
                      // onChange={handleChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {/* {aisleData.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null} */}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                >
                  {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                </Autocomplete>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  sx={{
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: 600,
                    color: "#656565",
                    mb: "1rem",
                  }}
                >
                  Row
                </Typography>
                <Autocomplete
                  name="rowId"
                  //  value={autoValue}
                  // value={values.assetName.code+"_"+values.assetName.assetName}
                  inputValue={selectData.row.autoText}
                  filterOptions={filterOptions}
                //   loading={rowData.length === 0}
                  options={rowData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) =>
                     option.text || option
                  }
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      console.log(value);
                      setSelectData({ ...selectData, row:{
                        id:value.value,
                        autoText:value.text
                      } });
                      handleRow(value.value)
                    } else {
                        setSelectData({ ...selectData, row:{
                            id:null,
                            autoText:""
                          } });
                      // setFieldValue("failureClassId",null)
                    }
                  }}
                  onInputChange={(e, v) => {
                    setSelectData({ ...selectData, row:{...selectData.row,autoText:v} });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Row"
                      // onChange={handleChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {/* {rowData.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null} */}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                >
                  {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                </Autocomplete>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  sx={{
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: 600,
                    color: "#656565",
                    mb: "1rem",
                  }}
                >
                  Bin
                </Typography>
                <Autocomplete
                  name="rowId"
                  //  value={autoValue}
                  // value={values.assetName.code+"_"+values.assetName.assetName}
                  inputValue={selectData.bin.autoText}
                  filterOptions={filterOptions}
                //   loading={binData.length === 0}
                  options={binData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) =>
                     option.text || option
                  }
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      console.log(value);
                      setSelectData({ ...selectData, bin:{
                        id:value.value,
                        autoText:value.text
                      } });
                    } else {
                        setSelectData({ ...selectData, bin:{
                            id:null,
                            autoText:""
                          } });
                      // setFieldValue("failureClassId",null)
                    }
                  }}
                  onInputChange={(e, v) => {
                    setSelectData({ ...selectData, bin:{...selectData.bin,autoText:v} });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Bin"
                      // onChange={handleChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {/* {binData.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null} */}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                >
                  {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                </Autocomplete>
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{p:'1.5rem'}}>
          <NormalTable data={partData} columns={columns}/>
        </Paper>
      </Grid>
      </Grid>
    </>
  )
}

export default InvenAdj