import { Clear } from '@mui/icons-material';
import { Box, Button, Divider, Grid, IconButton, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react'
import { color } from '../../../StyleData/Config';
import { axiosPrivate } from '../../../axios/axios';
import { masterAPI } from '../../Masters/dataConfig';

const StockLevelEdit = ({data,handleCancel}) => {

    const initialValue={
        safetyStockLevel: data.safetyStockLevel,
        economicReOrderQuantity: data.economicReOrderQuantity,
        reOrderPoint: data.reOrderPoint,
        maxQuantity: data.maxQuantity,
        stockcategory: data.stockcategory,
        isReOrder: data.isReOrder
      }

      const onSubmit = async(value) => {
      const putData={
        id:data.id,
        partId:data.partId,
        ...value
      }
      console.log(putData)
      const updateLink=masterAPI["inventory"].update
     await axiosPrivate.put(`${updateLink}`,putData).then(res=>{
        console.log(res)
        handleCancel()
      }).catch(e=>{
        console.log(e)
      })
      };
  return (
   <Box sx={{p:"1rem"}}>
    <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
            {data.code+"-"+data.partName+" - "+data.stockRoomCode+"-"+data.stockRoomName}
          </Typography>
          <IconButton onClick={handleCancel}>
            <Clear />
          </IconButton>
        </Box>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
      {({ handleChange, setFieldValue, values , resetForm }) => (
        <Form>
          <Grid container spacing={"1rem"}>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Safety Stock Level
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="safetyStockLevel"
                placeholder="Enter Safety Stock Level"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Economic Reorder Quantity
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="economicReOrderQuantity"
                placeholder="Enter Economic Reorder Quantity"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Reorder Point
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="reOrderPoint"
                placeholder="Enter Reorder Point"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Max Quantity
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="maxQuantity"
                placeholder="Enter Max Quantity"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Stock Category
                </Typography>
                
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="stockcategory"
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.stockcategory !== null ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
               
               
                  <MenuItem value={"STK"} >
                    STK
                    </MenuItem>
                    <MenuItem value={"NSTK"} >
                    NSTK
                    </MenuItem>
               
              </Field>
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Is Reorder
                </Typography>
                
                <Switch
              
              checked={values.isReOrder}
              onChange={(e) => {
                setFieldValue("isReOrder",e.target.checked)
              }}
            />
            </Grid>

           
            <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
              <Button
                onClick={handleCancel}
                variant="contained"
                sx={{
                  backgroundColor: color.TableCancelButton,
                  textTransform: "none",
                  color: color.CancelTextColor,
                  ":hover":{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type={"submit"}
                variant="contained"
                
                sx={{
                      
                  textTransform: "none",
                  backgroundColor:color.TableButtonColor,
                  "&:hover": {
                    backgroundColor: color.buttonDisable,
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
      </Formik>
   </Box>
  )
}

export default StockLevelEdit