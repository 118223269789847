import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";

const LocationModal = ({ mode, handleCloseClick,editData }) => {
  const [parentLocation, setParentLocation] = useState([]);
  const [locationType, setLocationType] = useState([]);
  const [loading,setLoading]=useState(false)

  useEffect(() => {
     getData()
  }, []);

  const getData=async()=>{
    setLoading(true)
    const link = masterAPI["location"].link;
    const locationTypeLink=masterAPI["locationType"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setLoading(false)
        setParentLocation(res.data.result);
      })
      .catch((e) => {
        setLoading(false)
        setParentLocation([])
        console.log(e, "createLocation");
      });
     await axiosPrivate
      .get(`${locationTypeLink}`)
      .then((res) => {
        setLoading(false)
        setLocationType(res.data.result);
      })
      .catch((e) => {
        setLocationType([])
        console.log(e, "createLocation(LT)");
      });
  }

  const initialValue = {
    code: mode==="Edit"?editData.code: "",
    locationName: mode==="Edit"?editData.locationName: "",
    locationDesc: mode==="Edit"?editData.locationDesc: "",
    parentLocationId:mode==="Edit"?editData.parentLocationId: null,
    locationTypeId:mode==="Edit"?editData.locationTypeId: "",
  };



  const onSubmit =async (value) => {
    console.log(value);
    setLoading(true)
    const createLink = masterAPI["location"].create;
    const updateLink=masterAPI["location"].update
    if(mode==="Create"){
     await axiosPrivate.post(`${createLink}`,value).then(res=>{
        console.log(res.data)
        setLoading(false)
        handleCloseClick()
      }).catch(e=>{
        setLoading(false)
        console.log(e,"createLocation")
      })
    }else{
      const updateAC={
        id:editData.id,
        ...value
      }
     await axiosPrivate.put(`${updateLink}`,updateAC).then(res=>{
        console.log(res.data)
        setLoading(false)
        handleCloseClick()
      }).catch(e=>{
        setLoading(false)
        console.log(e,"updatelocation")
      })
    }
   
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode==="Edit"?editData.code+"-"+editData.locationName:"New Location"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
      {({ handleChange, setFieldValue, values , resetForm }) => (
        <Form>
          <Grid container spacing={"1rem"}>
          <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Location Code
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="code"
                placeholder="Enter Location Code"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Location Name
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="locationName"
                placeholder="Enter Location Name"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Location Desc
                </Typography>
              <Field
                as={TextField}
                size={"small"}
                type="text"
                name="locationDesc"
                placeholder="Enter Location Desc"
                fullWidth
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Parent Location
                </Typography>
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="parentLocationId"
        
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.parentLocationId !== null ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {parentLocation.map((id, i) => (
                  <MenuItem value={id.id}
                  disabled={editData.id===id.id&&mode==="Edit"}
                  >
                    {id.code+" - "+id.locationName}</MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Location Type
                </Typography>
              <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="locationTypeId"
        
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.locationTypeId !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {locationType.map((id, i) => (
                  <MenuItem value={id.id}>{id.locationTypeName}</MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
              <Button
                onClick={handleCancel}
                variant="contained"
                sx={{
                  backgroundColor: color.TableCancelButton,
                  textTransform: "none",
                  color: color.CancelTextColor,
                  ":hover":{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type={"submit"}
                variant="contained"
                disabled={loading}
                sx={{
                      
                  textTransform: "none",
                  backgroundColor: loading ? "default" : color.TableButtonColor,
                  "&:hover": {
                    backgroundColor: loading
                      ? "default"
                      : color.buttonDisable,
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
      </Formik>
    </Box>
  );
};

export default LocationModal;
