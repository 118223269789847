import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Autocomplete,
  Paper,
  createFilterOptions,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../dataConfig";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const TechnicalSpecAttrModal = ({ mode, handleCloseClick, editData }) => {
  console.log(editData)
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputValue,setInputValue]=useState(mode==="Edit"?editData?.technicalAttributes?.attributeName:"")
  const [specId, setSpecId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attrId, setAttrId] = useState([]);
  const [uom,setUom]=useState(mode==="Edit"?editData.technicalAttributes.uomName:"")
  const [radio, setRadio] = useState(mode === "Edit"? editData.attributeNumericValue!==""?"Number":editData.attributeTextValue!==""?"Text":editData.attributeDateValue!==undefined?"Date":"":"");

console.log(editData)
  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
   getData()
  }, []);

  const getData=async()=>{
    setLoading(true);
    const attrLink = masterAPI["technicalAttributes"].link;
   await axiosPrivate
      .get(`${attrLink}`)
      .then((res) => {
        setLoading(false);
        setAttrId(res.data.result);
      })
      .catch((e) => {
        setAttrId([])
        console.log(e, "create technicalAttributegetAll");
      });
      const link = masterAPI["technicalSpecification"].link;
     await axiosPrivate
        .get(`${link}`)
        .then((res) => {
          setLoading(false);
          setSpecId(res.data.result);
        })
        .catch((e) => {
          setSpecId([])
          console.log(e, "create technicalSpecificationgetAll");
        });

     
  }

  const initialValue = {
    
    techSpecId: searchParams.get("id"),
    technicalAttributesId: mode === "Edit" ? editData.technicalAttributes.technicalAttributesId : null,
    attributeNumericValue:
      mode === "Edit" ? editData.attributeNumericValue : 0,
    attributeTextValue: mode === "Edit" ? editData.attributeTextValue : "",
    attributeDateValue: mode === "Edit" ? editData.attributeDateValue : null,
    
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["techSpecAttr"].create;
    const updateLink = masterAPI["techSpecAttr"].update;
    if (mode === "Create") {
     await axiosPrivate
        .post(`${createLink}`, value)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "create technicalSPecAttributes");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "update technicalSpecAttributes");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
         {mode==="Edit"?"Technical Specification Attributes": "New Technical Specification Attribute"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container rowSpacing={"1rem"} columnSpacing={"2rem"}>
            
              {/* <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Technical Specification
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="techSpecId"
                  // placeholder="Enter Asset Category Name"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.techSpecId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {specId.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.code + " - " + id.techSpecName}
                    </MenuItem>
                  ))}
                </Field>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Technical Attribute
                </Typography>
                <Autocomplete
                // value={remedySelect}
                inputValue={inputValue}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                loading={attrId.length}
                options={attrId}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.code+"-"+option.attributeName
                }
                freeSolo
                
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                 
                    setFieldValue("technicalAttributesId",value.id)
                    setUom(value.uomName)
                  }else{
                    
                    setFieldValue("technicalAttributesId","")
                    setUom("")
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  setInputValue(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="technicalAttributesId"
                    placeholder="Select Attributes"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
                  
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                          {attrId.length===0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid container item xs={12} md={6} alignItems={"flex-end"}>
                <RadioGroup
                row
                  value={radio}
                  onChange={(e) => {
                    setRadio(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Number"
                    control={<Radio />}
                    label="Number"
                    sx={{
                      ".MuiFormControlLabel-label":  {
                        // Change color here
                        color: color.labelColor
                        }
                    }}
                  />
                  <FormControlLabel
                    value="Text"
                    control={<Radio />}
                    label="Text"
                    sx={{
                      ".MuiFormControlLabel-label":  {
                        // Change color here
                        color: color.labelColor
                        }
                    }}
                  />
                  <FormControlLabel
                    value="Date"
                    control={<Radio />}
                    label="Date"
                    sx={{
                      ".MuiFormControlLabel-label":  {
                        // Change color here
                        color: color.labelColor
                        }
                    }}
                  />
                </RadioGroup>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: radio === "Number" ? "default" : "none" }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Attribute Numeric Value
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="attributeNumericValue"
                  placeholder="Enter Attribute Numeric"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{uom}</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: radio === "Text" ? "default" : "none" }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Attribute Text Value
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="attributeTextValue"
                  placeholder="Enter Attribute Text"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: radio === "Date" ? "default" : "none",
                  ".css-1pduc5x-MuiStack-root": {
                    paddingTop: 0,
                    overflowX: { xs: "auto", lg: "hidden" },
                    overflowY: "hidden",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Attribute Date Value
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={
                        values.attributeDateValue !== null
                          ? dayjs(values.attributeDateValue)
                          : values.attributeDateValue
                      }
                      // sx={{".css-1xhypcz-MuiStack-root":{
                      //   padding:0
                      // }}}

                      slotProps={{
                        textField: { size: "small", fullWidth: true },
                      }}
                      fullWidth
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour =
                              new Date().getHours() < 10
                                ? "0" + new Date().getHours()
                                : new Date().getHours();
                            const min =
                              new Date().getMinutes() < 10
                                ? "0" + new Date().getMinutes()
                                : new Date().getMinutes();
                            const sec =
                              new Date().getSeconds() < 10
                                ? "0" + new Date().getSeconds()
                                : new Date().getSeconds();
                            const dateFormat =
                              dateFrom + "T" + hour + ":" + min + ":" + sec;
                            setFieldValue("attributeDateValue", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>

              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default TechnicalSpecAttrModal;
