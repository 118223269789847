import { Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { color } from '../../../../../StyleData/Config'

const Activities = ({data}) => {

    const  sampleData=[
        {
            label:"Boiler and Steam",
        },
        {
            label:"Electrical Work",
        },
        {
            label:"High Pressure Fluid",
        },
        {
            label:"Scaffoldings",
        },
        {
            label:"Civil Works",
        },
        {
            label:"Excavation/Trenching",
        },
        {
            label:"Maintenance",
        },
        {
            label:"Use of ladder",
        },
        {
            label:"Crane Hoist"
        },
        {
            label:"Forklift/Prower Ind Trucks",
        },
        {
            label:"Mechanical Work",
        },
        {
            label:"Confined space/Sewega/Hazardous work ",
        },
        {
            label:"Work at height",
        },
        {
            label:"Diving Work",
        },
        {
            label:"Heavy Manual Lifting",
        },
        {
            label:"Pnuematic/Hydraulic",
        },
        {
            label:"Work Under Tx Lines",
        },
        {
            label:"Other Works",
        },
       

    ]
  return (
    <Paper elevation={3} sx={{p:1}}>
        <Grid container spacing={"1rem"}>
        <Grid item xs={12}>
          <Grid container spacing={"1rem"}>
             <Grid item container xs={4} spacing={"1rem"}>
             <Grid item xs={4}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    textAlign:"right"
                  }}
                >
               Permit Code :
                </Typography>
            </Grid>
            <Grid item xs={6} >
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                }}
                >
                  {data}  
                </Typography>
            </Grid>
             </Grid>
             </Grid>
          

        </Grid>
        
        <Grid item container xs={12}>
            {
                sampleData.map((samData,i)=>(
                    <Grid item xs={3} key={i}>
                        <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                    mt:-1
                  }}
                >
               <FormControlLabel  control={<Checkbox  />} label={samData.label} />
                </Typography>
                        </Grid>
                ))
            }
        </Grid>

        </Grid>

    </Paper>
  )
}

export default Activities