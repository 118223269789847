import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { AccountTree, Clear, FileUploadOutlined } from "@mui/icons-material";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";

import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";
import { DesktopDatePicker } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers";

import { json, useNavigate, useSearchParams } from "react-router-dom";
import LocationTreeView from "./locationTreeView";
import {useDispatch, useSelector} from "react-redux"


const AssetModal = () => {

  const navigate=useNavigate()
  const dispatch=useDispatch()
 
 const [searchParams,setSearchParams]=useSearchParams()
 
 const mode=useSelector(state=>state.editData.assetEditData.edit)
 const editData=useSelector(state=>state.editData.assetEditData.data)

  const [assetCategory, setAssetCategory] = useState([]);
  const [assetType, setAssetType] = useState([]);
  //const [uom, setUom] = useState([]);
  const [location, setLocation] = useState(mode?editData.locationName:'');

  const [failureClasses, setFailureClasses] = useState([]);
  const [assetPriority, setAssetPriority] = useState([]);
  const [assetCriticality, setAssetCriticality] = useState([]);
  const [assetStatus, setAssetStatus] = useState([]);
  const [parentAsset, setParentAsset] = useState([]);
  const [locationDialog, setLocationDialog] = useState(false);
  const [locationId, setLocationId] = useState(mode?editData.locationId:'');
  const [auto,setAuto]=useState({parentAsset:""})
  const [fileName,setFileName]=useState("")
  const [fileType,setFileType]=useState("")
  const [suppiler,setSuppiler]=useState([])
  const [manufacturer,setManufacturer]=useState([])

  const [loading, setLoading] = useState(false);

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };


  useEffect(() => {
   getData()
  }, []);

  const getData =async() => {
    //////Criticality///////
    const link = masterAPI["selectList"].criticalitybyType;
   await axiosPrivate
      .get(`${link}/Asset`)
      .then((res) => {
        setAssetCriticality(res.data);
      })
      .catch((e) => {
        setAssetCriticality([])
        console.log(e, "createAcritigetAll");
      });
    /////////Category//////
    const assetCat = masterAPI["assetCategory"].link;
    await axiosPrivate
      .get(`${assetCat}`)
      .then((res) => {
        console.log(res)
        setAssetCategory(res.data.result);
      })
      .catch((e) => {
        setAssetCategory([])
        console.log(e, "createACgetAll");
      });
    //////Asset Type/////
    const assetTyp = masterAPI["assetType"].link;
    await axiosPrivate
      .get(`${assetTyp}`)
      .then((res) => {
        setAssetType(res.data.result);
      })
      .catch((e) => {
        setAssetType([])
        console.log(e, "createAssetTypegetAll");
      });
    // //////Technical Specfication/////////
    // const techspec = masterAPI["technicalSpecification"].link;
    // axiosPrivate
    //   .get(`${techspec}`)
    //   .then((res) => {
    //     setTechSpecId(res.data.result);
    //   })
    //   .catch((e) => {
    //     console.log(e, "createTechnicalSpecgetAll");
    //   });
    /////////UOM//////////
    // const uomid = masterAPI["uom"].link;
    // axiosPrivate
    //   .get(`${uomid}`)
    //   .then((res) => {
    //     setUom(res.data.result);
    //   })
    //   .catch((e) => {
    //     console.log(e, "createUOMgetAll");
    //   });

    ////////failure Classes/////
    const failClass = masterAPI["failureClass"].link;
    await axiosPrivate
      .get(`${failClass}`)
      .then((res) => {
        setFailureClasses(res.data.result);
      })
      .catch((e) => {
        setFailureClasses([])
        console.log(e, "createfailureClassgetAll");
      });
    /////////asset Priority//////////
    const assetPri = masterAPI["priority"].link;
    await axiosPrivate
      .get(`${assetPri}`)
      .then((res) => {
        setAssetPriority(res.data.result);
      })
      .catch((e) => {
        setAssetPriority([])
        console.log(e, "createAssetPrigetAll");
      });
    //////assetStatus///////
    const assetSta = masterAPI["assetStatus"].link;
    await axiosPrivate
      .get(`${assetSta}`)
      .then((res) => {
        setAssetStatus(res.data.result);
      })
      .catch((e) => {
        setAssetStatus([])
        console.log(e, "createStatusgetAll");
      });
    ///////////parent Asset///////
    const assets = masterAPI["assets"].link;
    await axiosPrivate
      .get(`${assets}`)
      .then((res) => {
        setParentAsset(res.data.result);
      })
      .catch((e) => {
        setParentAsset([])
        console.log(e, "createParentAssetgetAll");
      });
      const typeLink=masterAPI["selectList"].businessByType       

      await axiosPrivate.get(`${typeLink}?businessType=Supplier`).then(res=>{
          console.log(res)
            setSuppiler(res.data)
        }).catch(e=>{
          setSuppiler([])
          console.log(e,"error")
        })

        await axiosPrivate.get(`${typeLink}?businessType=Manufacturer`).then(res=>{
          setManufacturer(res.data)
      }).catch(e=>{
        setManufacturer([])
        console.log(e,"error")
      })
  }

  const locationHandle = () => {
    setLocationDialog(true);
  };

  const handlelocationClick = (name, id) => {
    if (
      name !== undefined &&
      name !== null &&
      id !== undefined &&
      id !== null
    ) {
      setLocation(name);
      setLocationId(id);
    }

    setLocationDialog(false);
  };

  const handleCreateClose = () => {
    setLocationDialog(false);
  };

  const initialValue = {
    assetName: mode ? editData.assetName : "",
    code: mode  ? editData.code : "",
    serialNumber: mode ? editData.serialNumber : "",
    model: mode  ? editData.model : "",
    purchasePrice: mode  ? editData.purchasePrice : 0,
    warrantyName:mode? editData.warrantyName:"",
    warrantyType:mode? editData.warrantyType==="Basic"?false:true:false,
    installationDate:
    mode 
      ? editData.installationDate
      : moment().format("YYYY-MM-DDTHH:mm:ss"),
      startDate:
      mode 
        ? editData.startDate
        : moment().format("YYYY-MM-DDTHH:mm:ss"),
    expirationDate:
      mode 
        ? editData.expirationDate
        : moment().format("YYYY-MM-DDTHH:mm:ss"),
    threshHoldCalendar:
      mode 
        ? editData.threshHoldCalendar
        : moment().format("YYYY-MM-DDTHH:mm:ss"),
    startingUsage: mode  ? editData.startingUsage : "",
    expirationUsage: mode  ? editData.expirationUsage : "",
    assetTypeId: mode  ? editData.assetTypeId : null,
    locationId: mode  ? editData.locationId : null,
    uomId: mode  ? editData.uomId : null,
    assetCategoryId: mode  ? editData.assetCategoryId : null,
    failureClassesId: mode  ? editData.failureClassesId : null,
    priorityId: mode  ? editData.priorityId : null,
    criticalityId: mode  ? editData.criticalityId : null,
    assetStatusId: mode  ? editData.assetStatusId : null,
    parentAssetId: mode  ? editData.parentAssetId :null,
    comments: mode  ? editData.comments : "",
    notes: mode  ? editData.notes : "",
    // techSpecId: mode === "Edit" ? editData.techSpecId : "",
    qrCodeData:mode  ? editData.qrCodeData:"",
    assetImage:mode ? editData.assetImage.split(".com")[1]:"",
    imageData: "",
    supplierId: mode?editData.supplierId:null,
      manufacturerId:mode? editData.manufacturerId:null

   
   
  };

  const onSubmit = async (value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["assets"].create;
    const updateLink = masterAPI["assets"].update;
    if (!mode) {
      const postData = {
        ...value,
        ["warrantyType"]:value.warrantyType?"Extended":"Basic",
        ["locationId"]: locationId,
      };
      console.log(postData)
      await axiosPrivate
        .post(`${createLink}`, postData)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setLocation("");
          setLocationId("");
          // handleCloseClick();
          navigate("/asset/assets")
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "createAssets");
        });
    } 
    else {
      const updateAC = {
        id: editData.id,

        ...value,
        ["warrantyType"]:value.warrantyType?"Extended":"Basic",
        ["locationId"]: locationId,
      };

      console.log(updateAC)
      await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setLocation("");
          setLocationId("");
          // handleCloseClick();
          // localStorage.setItem('editData',"")
          dispatch({type:"assetEditData",payload:{edit:false,data:{}}})
          navigate("/asset/assets")
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "updateAssets");
        });
    }
  };

  const handleCancel = () => {
    // handleCloseClick();
    navigate("/asset/assets")
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: "1.3rem",
            fontWeight:500,
            mb:"1rem"
          }}
        >
         {mode?`${editData.code+"_"+editData.assetName}`:"New Assets"} 
         
        </Typography>
        {/* <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton> */}
      </Box>
      {/* <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} /> */}
      {/* <Paper sx={{p:2}}> */}
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Box sx={{display:"flex",gap:"1rem",flexWrap:"noWrap",width:"100%",flexDirection:{xs:"column",sm:"row"}}}>
               <Paper sx={{width:{xs:"100%",sm:"49%"},flex: "0 0 49%",p:1.5}}>
              <Grid container  
              spacing={"0.5rem"}
              >
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Asset Code
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  value={values.code}
                  name="code"
                  placeholder="Enter Asset Code"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Asset Name
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="assetName"
                  placeholder="Enter Asset  Name"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Asset Type
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="assetTypeId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.assetTypeId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {assetType.map((id, i) => (
                    <MenuItem value={id.id}>{id.code+" - "+id.assetTypeName}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
              
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Parent Asset
                </Typography>
                {/* <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="parentAssetId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                
                  renderValue={
                    values.parentAssetId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {parentAsset.map((id, i) => (
                    <MenuItem key={i} value={id.id} 
                    onClick={()=>{
                    
                      setFieldValue("locationId",id.locationId)
                      setLocation(id.locationName)
                    }} 
                    >{id.code+" - "+id.assetName}</MenuItem>
                  ))}
                </Field> */}
                 <Field
                       name="parentAssetId"
                      //  value={autoValue}
                      // value={values.assetName.code+"_"+values.assetName.assetName}
                           inputValue={auto.parentAsset}
                           disabled={mode}
                           component={Autocomplete}
                           filterOptions={filterOptions}
                           loading={parentAsset.length === 0}
                           options={parentAsset} 
                           PaperComponent={CustomPaper}
                          
                           getOptionLabel={(option) => option.code+" - "+option.assetName || option}
                           getOptionDisabled={(option) => editData.id===option.id&&mode}
                           freeSolo
                         onChange={(event,value) => {
                          
                       if(value){
                       console.log(value)
                        setLocationId(value.locationId)
                        setFieldValue("parentAssetId",value.id)
                        setLocation(value.locationName)
                        setAuto({...auto,parentAsset:value.code+ " - "+value.assetName})
                      
                    
                       }else{
                        setFieldValue("locationId",null)
                        setFieldValue("parentAsset",null)
                        setLocation("")
                        setAuto({...auto,parentAsset:""})
                        // getData()
                        // setFieldValue("failureClassId",null)
                       }
                        
                        }}
                        onInputChange={(e,v)=>{
                          setAuto({...auto,parentAsset:v})
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="parentAsset"
                           placeholder="Search Parent Asset"
                            onChange={handleChange}
                            size="small"
                            // variant="standard"
                           
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                  {parentAsset.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>),
                            }}
                           />
                           )}
                      >
                      
                        {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                      </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Location
                </Typography>
                <Field
                  value={location}
                  as={TextField}
                  inputProps={{readOnly:mode}}
                  size={"small"}
                  type="text"
                  name="locationId"
                  placeholder="Enter Location"
                  fullWidth
                  onChange={(e) => {
                    // setFieldValue("locationId",e.target.value)
                    setLocation(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={locationHandle} disabled={mode}>
                          <AccountTree
                            sx={{ color: color.loginIcon, fontWeight: 1800 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Failure Classes
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="failureClassesId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.failureClassesId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {failureClasses.map((id, i) => (
                    <MenuItem value={id.id}>{id.code+" - "+id.failureClassName}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Asset Priority
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="priorityId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.priorityId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {assetPriority.map((id, i) => (
                    <MenuItem value={id.id}>{id.code+" - "+id.priorityName}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                   Criticality
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="criticalityId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.criticalityId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {assetCriticality.map((id, i) => (
                    <MenuItem value={id.value}>{id.text}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Asset Category
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="assetCategoryId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.assetCategoryId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {assetCategory.map((id, i) => (
                    <MenuItem value={id.id}>{id.code+" - "+id.assetCategoryName}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Asset Status
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="assetStatusId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.assetStatusId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {assetStatus.map((id, i) => (
                    <MenuItem value={id.id}>{id.statusName}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 QR Code Data
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="qrCodeData"
                  placeholder="Enter Qr Code Data"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
               <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Document File
                </Typography>
                <Button component="label" variant="contained">
                  <FileUploadOutlined />{" "}
                  <Typography sx={{ fontSize: "0.9rem" }}>Add File</Typography>
                  <input
                    type="file"
                    accept={"image/*"}
                    hidden
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      if (e.target.files[0] !== undefined) {
                        const file = e.target.files[0];
                        console.log(file.name);
                        const fileType = file.type.split("/")[1];
                        console.log(fileType);
                        // if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {

                        //   toast.error("Invalid format");
                        // } else {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                          // console.log(reader.result);
                          const dataSplit = reader.result.split("base64,")[1];
                          // console.log(dataSplit)
                        
                          setFieldValue("assetImage",file.name)
                  
                          setFieldValue("imageData", dataSplit);
                        };
                        reader.onerror = function (error) {};
                        // }
                      } else {
                        setFieldValue("assetImage","");
                        setFieldValue("imageData", "");
                      }
                    }}
                  />
                </Button>
                <Typography
                  sx={{
                    // mb: color.labelmb,

                    fontSize: color.labelFont,
                    // color: color.labelColor,
                  }}
                >
                  {values.assetImage}
                </Typography>
              </Grid>
              </Grid>
              </Paper>
              <Paper sx={{width:{xs:"100%",sm:"49%"}, flex: "0 0 49%",p:1.5}}>
              <Grid container spacing={"0.5rem"} 
              sx={{
                // boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                // p:1.5,
                // backgroundColor: "white",
              }}
              >
                <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Serial Number
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="serialNumber"
                  placeholder="Enter Serial Number"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Model
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="model"
                  placeholder="Enter Model"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Purchase Price
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="purchasePrice"
                  placeholder="Enter Purchase Price"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Installation Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={dayjs(values.installationDate)}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0" },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("installationDate", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Suppiler
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="supplierId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.supplierId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {suppiler.map((id, i) => (
                    <MenuItem value={id.value}>{id.text}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Manufacturer
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="manufacturerId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.manufacturerId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {manufacturer.map((id, i) => (
                    <MenuItem value={id.value}>{id.text}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Comments
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="comments"
                  placeholder="Enter Comments"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Notes
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="notes"
                  placeholder="Enter Notes"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              </Grid>
              </Paper>
             

            </Box>
            <Box sx={{display:"flex",gap:"1rem",flexWrap:"noWrap",width:"100%",flexDirection:{xs:"column",md:"row"},mt:"1rem"}}>
            <Paper sx={{width:{xs:"100%",sm:"49%"}, flex: "0 0 49%",p:1.5}}>
              <Grid container spacing={"0.5rem"} 
              sx={{
                // boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                // p:1.5,
                // backgroundColor: "white",
              }}
              >
                <Grid item xs={12} md={6}>
              <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Warranty Name
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="warrantyName"
                  placeholder="Enter Asset Warranty Name"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  ThreshHold Calendar
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={dayjs(values.threshHoldCalendar)}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0" },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("threshHoldCalendar",dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} >
              <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Starting Usage
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="startingUsage"
                  placeholder="Enter Starting Usage"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} >
              <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Expiration Usage
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="expirationUsage"
                  placeholder="Enter expiration Usage"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Start Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={dayjs(values.startDate)}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0" },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("startDate", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Expiration Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={dayjs(values.expirationDate)}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0" },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("expirationDate", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                            <Typography
                              sx={{
                                fontWeight: color.labelFontWeight,
                                mb: color.labelmb,
                                fontSize: color.labelFont,
                                color: color.labelColor,
                              }}
                            >
                            Type
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                                // flexWrap: "wrap",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: color.TabDatafontsize,
                                  mb: color.TabDatamarginbottom,
                                }}
                              >
                               Basic
                              </Typography>
                              <Switch
                              value={values.warrantyType}
                              checked={values.warrantyType}
                              onChange={(e)=>{
                                console.log(e.target.checked)
                                if(e.target.checked){
                                  setFieldValue("warrantyType",true)
                                }else{
                                  setFieldValue("warrantyType",false)
                                }
                                
                              }}
                                sx={{
                                  width: 48,
                                  height: 26,
                                  padding: 0,
                                  
                                  "& .MuiSwitch-switchBase": {
                                    padding: 0,
                                    margin: 0.2,
                                    transitionDuration: "300ms",
                                    "&.Mui-checked": {
                                      transform: "translateX(22px)",
                                      color: "#fff",
                                      "& + .MuiSwitch-track": {
                                        backgroundColor: (theme) =>
                                          theme.palette.mode === "dark"
                                            ? "#2ECA45"
                                            : "#217EDE",
                                        opacity: 1,
                                        border: 0,
                                      },
                                      "&.Mui-disabled + .MuiSwitch-track": {
                                        opacity: 0.5,
                                      },
                                    },
                                    "&.Mui-focusVisible .MuiSwitch-thumb": {
                                      color: "#33cf4d",
                                      border: "6px solid #fff",
                                    },
                                    "&.Mui-disabled .MuiSwitch-thumb": {
                                      color: (theme) =>
                                        theme.palette.mode === "light"
                                          ? theme.palette.grey[100]
                                          : theme.palette.grey[600],
                                    },
                                    "&.Mui-disabled + .MuiSwitch-track": {
                                      opacity: (theme) =>
                                        theme.palette.mode === "light"
                                          ? 0.7
                                          : 0.3,
                                    },
                                  },
                                  "& .MuiSwitch-thumb": {
                                    boxSizing: "border-box",
                                    width: 22,
                                    height: 22,
                                  },
                                  "& .MuiSwitch-track": {
                                    borderRadius: 26 / 2,
                                    backgroundColor: (theme) =>
                                      theme.palette.mode === "light"
                                        ? "#E9E9EA"
                                        : "#39393D",
                                    opacity: 1,
                                    transition: (theme) =>
                                      theme.transitions.create(
                                        ["background-color"],
                                        {
                                          duration: 500,
                                        }
                                      ),
                                  },
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: color.TabDatafontsize,
                                  mb: color.TabDatamarginbottom,
                                }}
                              >
                             Extended
                              </Typography>
                            </Box>
                          </Grid>
              

              </Grid>
              </Paper>
              </Box>
              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
          </Form>
        )}
      </Formik>
      {/* </Paper> */}
      <Dialog
        open={locationDialog}
        onClose={handleCreateClose}
        children={
          <LocationTreeView
            handlelocationClick={(name, id) => {
              handlelocationClick(name, id);
            }}
            handleCreateClose={handleCreateClose}
          />
        }
      />
    </Box>
  );
};

export default AssetModal;
