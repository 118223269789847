import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { masterAPI } from "../dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import { color } from "../../../StyleData/Config";
import { Clear } from "@mui/icons-material";
import NormalTable from "../../../Component/NormalTable";

const BusinessView = ({data, handleCloseClick}) => {

  const [craftDetail, setCraftDetail] = useState([]);

  useEffect(() => {
    getData()
   
  }, []);

  const getData=async()=>{
    const craftLink = masterAPI["businessCraft"].craftByBusiness;
   await axiosPrivate(`${craftLink}/${data.id}`)
      .then((res) => {
        console.log(res.data)
        setCraftDetail(res.data.result);
      })
      .catch((e) => {
        setCraftDetail([]);
        console.log(e);
      });
  }

  const columns = [
    {
      Header: "Code",
      accessor: "craftCode",
    },
    {
      Header: "Craft Name",
      accessor: "craftName",
    },
  ];

  return (
    <Box sx={{p: color.masterPageDialogPadding}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {data.code + "-" + data.businessName}
        </Typography>
        <IconButton onClick={handleCloseClick}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Grid container spacing={"1rem"}>
              <Grid item xs={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Business Code
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.code}
              </Typography>
               
                </Grid>
                <Grid item xs={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Business Name
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.businessName}
              </Typography>
               
                </Grid>
                <Grid item xs={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Email
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.email}
              </Typography>
               
                </Grid>
                <Grid item xs={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Description
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.businessDesc}
              </Typography>
               
                </Grid>
                <Grid item xs={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Business Type
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.businessType}
              </Typography>
               
                </Grid>
                <Grid item xs={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 URL
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.url}
              </Typography>
               
                </Grid>
                <Grid item xs={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Mobile No
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.mobileNo}
              </Typography>
               
                </Grid>
                <Grid item xs={6} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Address
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.address}
              </Typography>
               
                </Grid>
                <Grid item xs={12} >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Crafts
                </Typography>
               
                <NormalTable data={craftDetail} columns={columns}/>
               
                </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessView;
