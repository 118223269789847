import { Box, Dialog, Divider, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NormalTable from "../../../../Component/NormalTable";
import { color } from "../../../../StyleData/Config";
import { masterAPI } from "../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import Labour from "./Labour";

const WorkAssignment = ({ jobPlanId, workOrderId }) => {
  const [dataList, setDataList] = useState([]);
  const [editLabour,setEditLabour]=useState(false)
  const [editData,setEditData]=useState({})

  useEffect(() => {
getData()
  }, []);

  const getData=async()=>{
    const woLink=masterAPI["workOrder"].completeDetail
   await axiosPrivate.get(`${woLink}/${workOrderId}`).then(res=>{
    console.log(res.data.result)
    setDataList(res.data.result.workOrderTaskLabourList)
}).catch(e=>{
  setDataList([])
    console.log(e,"error wo assignment")
})
  }

  const handleEdit=(value)=>{
  setEditLabour(true)
  setEditData(value)
  }

  const handleClose=()=>{
    setEditData({})
    setEditLabour(false)
    getData()
  }

  const handleDelete=async(id)=>{
    const labourDelete=masterAPI["workOrderTaskLabour"].delete
   await axiosPrivate.delete(`${labourDelete}/${id}`).then(res=>{
     console.log(res,"labour success")
     getData()
    }).catch(e=>{
      console.log(e,"error labour delete")
    })
  }

  const columns = [
    {
      Header: "Task No",
      accessor: "taskNo",
    },
    {
      Header: "Craft",
      accessor: "craftName",
    },
    {
      Header: "Labour",
      accessor: "labourName",
    },
    {
        Header: "Business",
        accessor: "businessName",
      },
    {
      Header: "Planned Hours",
      accessor: "plannedHours",
    },
    {
      Header: "Planned Start",
      accessor: "plannedStart",
    },
    {
        Header: "Actions",
        Cell: ({ cell }) => {
          // console.log(cell)
          return (
            <Box sx={{ whiteSpace: "nowrap" }}>
              <IconButton>
                <EditOutlined
                  onClick={() => {
                    handleEdit(cell.row.original);
                  }}
                  sx={{
                    fontSize: color.masterEditDelButton,
                    color: color.masterEditDelButtonColor,
                  }}
                />
              </IconButton>
              <IconButton>
                          <DeleteOutline
                            onClick={() => {
                              handleDelete(cell.row.original.id);
                            }}
                            sx={{
                              fontSize: color.masterEditDelButton,
                              color: color.masterEditDelButtonColor,
                            }}
                          />
                        </IconButton>
            </Box>
          );
        },
      },
  ];
  return (
    <Box
      sx={{
        boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
        p: 1.5,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box>
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
            fontWeight: 400,
          }}
        >
          Details
        </Typography>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)" }} />
      <Box>
        <NormalTable data={dataList} columns={columns} />
      </Box>
      <Dialog
            open={editLabour}
            onClose={handleClose}
            fullWidth
            maxWidth={"md"}
            children={
              <Labour
                handleCloseClick={handleClose}
               type={"wo"}
                editData={editData}
               
              />
            }
          />
    </Box>
  );
};

export default WorkAssignment;
