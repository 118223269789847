import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import {
  Box,
  Checkbox,
  Dialog,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  ModeEdit,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import CreateWO from "./WO";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// import AssetModal from "./AssetModal";
// import CreateAssetCategory from "./AssetCategoryModal";

const WorkOrder = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const actionsAllow = useSelector((state) => state.dataRed.actionsAllow);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [filterValue, setFilterValue] = useState("enableData");
  const [backUpData, setBackUpData] = useState([]);
  const [woScheduleType,setWOScheduleType]=useState("WO")

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    getData();
    dispatch({ type: "woEditData", payload: { edit: false, data: {} } });
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const link = masterAPI["workOrder"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        const updatedResult = res.data.result.map(woResult => ({ ...woResult, permitCode: "PTW00148" }));

console.log(updatedResult);
        updatedResult.sort((a, b) => {
          return b.code.localeCompare(a.code, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        if (filterValue === "enableData") {
          const filterDataResult = updatedResult.filter(
            (fil) => fil.isActive
          );
          console.log(filterDataResult, "filterData");
          setData(filterDataResult);
          setBackUpData(updatedResult);
        } else {
          setData(updatedResult);
          setBackUpData(updatedResult);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "workorder");
        setIsLoading(false);
        setData([])
        setBackUpData([])
      });
  };

  const getInspData=async()=>{
    setIsLoading(true);
    const link = masterAPI["inspWo"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        res.data.result.sort((a, b) => {
          return b.code.localeCompare(a.code, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        if (filterValue === "enableData") {
          const filterDataResult = res.data.result.filter(
            (fil) => fil.isActive
          );
          console.log(filterDataResult, "filterData");
          setData(filterDataResult);
          setBackUpData(res.data.result);
        } else {
          setData(res.data.result);
          setBackUpData(res.data.result);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "insp");
        setIsLoading(false);
        setData([])
        setBackUpData([])
      });
  }

  const handleButtonNew = () => {
    // setMode("Create");
    // setCreate(true);
    navigate("/workordercreate");
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    dispatch({ type: "woEditData", payload: { edit: true, data: data } });
    navigate("/workordercreate");
  };

  // const handleDelete = (id) => {
  //   const deleteLink = masterAPI["workOrder"].delete;
  //   axiosPrivate
  //     .delete(`${deleteLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "delete assets");
  //     });
  // };

  const handleDelete = async(e, id) => {
    const enableLink = woScheduleType==="WO"? masterAPI["workOrder"].enable : masterAPI["inspWo"].enable;
    const disableLink = woScheduleType==="WO"? masterAPI["workOrder"].disable : masterAPI["inspWo"].disable;
    if (e.target.checked) {
     await axiosPrivate
        .put(`${enableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          if(woScheduleType==="WO"){
            getData();
          }else{
            getInspData()
          }
         
        })
        .catch((e) => {
          console.log(e, "enable wo");
        });
    } else {
     await axiosPrivate
        .put(`${disableLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          if(woScheduleType==="WO"){
            getData();
          }else{
            getInspData()
          }
        })
        .catch((e) => {
          console.log(e, "disable wo");
        });
    }
  };

  const columns = [
    {
      accessor: "duration",
      disableFilters: true,
      Cell: () => {
        return (
          <Box sx={{ width: color.checkBoxWidth }}>
            <Checkbox
              // icon={<RadioButtonUncheckedOutlined />}
              // checkedIcon={<CheckCircle />}
            />
          </Box>
        );
      },
    },
    {
      Header: "WO Code",
      accessor: "code",
      // disableFilters: true,
      Cell: ({ cell }) => {
        return (
          <Link
            to={`/workorderview?id=${cell.row.original.id}`}
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: "WO Name",
      accessor: "workOrderName",
    },
    {
      Header: "Asset",
      accessor: "assetName",
    },
    {
      Header: "Job No",
      accessor: "notes",
    },
    {
      Header: "Sevice Request Code",
      accessor: "serviceRequestCode",
    },
    {
      Header: "PM",
      accessor: "pmCode",
    },
    {
      Header: "Permit Code",
      accessor: "permitCode",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Planned Start Date",
      accessor: "plannedStartDate",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>
            {cell.value !== null && cell.value !== undefined
              ? moment(cell.value).format("DD/MM/YYYY")
              : "---"}
          </Typography>
        );
      },
    },
    {
      Header: "Planned End Date",
      accessor: "plannedEndDate",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>
            {cell.value !== null && cell.value !== undefined
              ? moment(cell.value).format("DD/MM/YYYY")
              : "---"}
          </Typography>
        );
      },
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton
              disabled={
                !actionsAllow.edit || cell.row.original.status === "INPRG"
              }
            >
              <EditOutlined
                onClick={() => {
                  handleEdit(cell.row.original);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color:
                    actionsAllow.edit && !cell.row.original.status === "INPRG"
                      ? color.masterEditDelButtonColor
                      : "default",
                }}
              />
            </IconButton>
            {/* <IconButton disabled={!actionsAllow.delete|| cell.row.original.status!=="NEW"}>
              <DeleteOutline
                onClick={() => {
                  handleDelete(cell.row.original.id);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: actionsAllow.delete&&cell.row.original.status==="NEW"? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton> */}
            <Switch
              disabled={
                !actionsAllow.delete || cell.row.original.status !== "NEW"
              }
              checked={cell.row.original.isActive}
              onChange={(e) => {
                handleDelete(e, cell.row.original.id);
              }}
            />
          </Box>
        );
      },
    },
  ];
  const inspColumns = [
    {
      accessor: "duration",
      disableFilters: true,
      Cell: () => {
        return (
          <Box sx={{ width: color.checkBoxWidth }}>
            <Checkbox
              // icon={<RadioButtonUncheckedOutlined />}
              // checkedIcon={<CheckCircle />}
            />
          </Box>
        );
      },
    },
    {
      Header: "Inspection Code",
      accessor: "inspectionCode",
      // disableFilters: true,
      Cell: ({ cell }) => {
        return (
          <Link
             to={`/inspwoview?id=${cell.row.original.id}`}
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: "Inspection Name",
      accessor: "inspectionName",
    },
   
    {
      Header: "Planned Start Date",
      accessor: "plannedStartDate",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>
            {cell.value !== null && cell.value !== undefined
              ? moment(cell.value).format("DD/MM/YYYY")
              : "---"}
          </Typography>
        );
      },
    },
    {
      Header: "Planned End Date",
      accessor: "plannedEndDate",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>
            {cell.value !== null && cell.value !== undefined
              ? moment(cell.value).format("DD/MM/YYYY")
              : "---"}
          </Typography>
        );
      },
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            {/* <IconButton
              disabled={
                !actionsAllow.edit 
              }
            >
              <EditOutlined
                onClick={() => {
                  handleEdit(cell.row.original);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color:
                    actionsAllow.edit 
                      ? color.masterEditDelButtonColor
                      : "default",
                }}
              />
            </IconButton> */}
            {/* <IconButton disabled={!actionsAllow.delete|| cell.row.original.status!=="NEW"}>
              <DeleteOutline
                onClick={() => {
                  handleDelete(cell.row.original.id);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: actionsAllow.delete&&cell.row.original.status==="NEW"? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton> */}
            <Switch
              disabled={
                !actionsAllow.delete 
              }
              checked={cell.row.original.isActive}
              onChange={(e) => {
                handleDelete(e, cell.row.original.id);
              }}
            />
          </Box>
        );
      },
    },
  ];

  const handleChangeFilter = (e) => {
    if (e === "showAll") {
      setData(backUpData);
    } else {
      const filterDataResult = backUpData.filter((fil) => fil.isActive);

      setData(filterDataResult);
    }
    setFilterValue(e);
  };

  const woScheduleTypeChange=(type)=>{
    setWOScheduleType(type)
   if(type==="WO"){
    getData()
   }else{
    getInspData()
   }
  }

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["workOrder"].title}
        </Typography>
      </Box>

      <TableContainer
        columns={woScheduleType==="WO"?columns:inspColumns}
        data={data}
        buttonNew={handleButtonNew}
        loading={isLoading}
        buttonDisable={!actionsAllow.create}
        filterValue={filterValue}
        handleChange={handleChangeFilter}
        filterShow={true}
        iconBadge={true}
        iconWOCount={0}
        iconInspCount={2}
        woScheduleTypeChange={woScheduleTypeChange}
      />
      <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"md"}
        children={
          <CreateWO
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      />
    </>
  );
};

export default WorkOrder;
