import {
  Box,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { color } from "../../../../StyleData/Config";
import { AccountTree, Save } from "@mui/icons-material";
import { move, useFormik } from "formik";

import AssetIdFilter from "../../../Tree/AssetIdTree";
import { axiosPrivate } from "../../../../axios/axios";
import { masterAPI } from "../../../Masters/dataConfig";
import LocationTreeView from "../locationTreeView";
import { useSelector } from "react-redux";

const Transfer = ({data,getData}) => {
  const [loading, setLoading] = useState(false);
  const [asset, setAsset] = useState({ name: "", id: data.parentAssetId||"",code:"" });
  // const [currentLocation, setCurrentLocation] = useState({ name: data.locationName||"", id: data.locationId||"",code:data.locationCode||"" });
  const [toLocation, setToLocation] = useState({ name: "", id: "",code:"" });
  const [locationType, setLocationType] = useState("");
  const [locationDialog, setLocationDialog] = useState(false);
  const [assetDialog, setAssetDialog] = useState(false);

  const loginInfo=useSelector(state=>state.auth.loginInfo.siteId)

  const formik = useFormik({
    initialValues: {
      fromParentAssetId: data.parentAssetId||"",
      fromParentAssetName: data.parentAssetName||"",
      fromParentAssetCode: data.parentAssetCode||"",
      toParentAssetId:asset.id,
      toParentAssetName:asset.name,
      toParentAssetCode:asset.code,
      fromLocationCode:data.locationCode||"",
      fromLocationName:data.locationName||"",
      fromLocationId: data.locationId||null,
      toLocationCode:toLocation.code,
      toLocationId: toLocation.id,
      toLocationName:toLocation.name,
      // row: "",
      // binNo: "",
      // asile: "",
      remarks: "",
    },
    enableReinitialize: true,
    onSubmit: async (value) => {
      const postData = {
        assetId:data.id,
        siteId:loginInfo,
        ...value,
        // ["toLocationId"]: toLocation.id,
        // ["fromLocationId"]: currentLocation.id,
      };
      const movementLink = masterAPI["assetMovement"].create;
      console.log(postData);
     await axiosPrivate
        .post(`${movementLink}`, postData)
        .then((res) => {
          console.log(res);
          getData()
        })
        .catch((e) => {
          console.log(e, "side Menu Transfer error");
        });
    },
  });

  const locationHandle = (type) => {
    setLocationType(type);
    setLocationDialog(true);
  };

  const locationAsset = () => {
    // setLocationType(type)
    setAssetDialog(true);
  };

  const handlelocationClick = (name, id,code) => {
    console.log(name,id,code)
    if (
      name !== undefined &&
      name !== null &&
      id !== undefined &&
      id !== null&&
      code !== undefined &&
      code !== null
    ) {
      // if (locationType === "from") {
      //   setCurrentLocation({ name: name, id: id,code:code });
      //   setLocationType("");
      // } else {
        setToLocation({ name: name, id: id, code:code });
      //   setLocationType("");
      // }
    }

    setLocationDialog(false);
  };
  const handleAssetClick = (name, id,code) => {
    console.log(name,id,code)
    if (
      name !== undefined &&
      name !== null &&
      id !== undefined &&
      id !== null &&
      code !== undefined &&
      code !== null
    ) {
      setAsset({ name: name, id: id, code:code });
    }

    setAssetDialog(false);
  };
  const handleCreateClose = () => {
    setLocationDialog(false);
  };
  const handleCreateAssetClose = () => {
    setAssetDialog(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>
        <Typography
          sx={{
            color: color.sideTitleColor,
            fontSize: color.fontSizeSide,
            fontWeight: color.sideWeightFont,
          }}
        >
          Transfer
        </Typography>
      </Box>

      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: color.sideTitle2Color,
              fontSize: color.fontSizeSide,
              fontWeight: color.sideFontWeight,
            }}
          >
            Details
          </Typography>
          <IconButton
            type="submit"
            // onClick={formik.handleSubmit}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Save sx={{ color: "grey" }} onClick={formik.handleSubmit} />
            )}
          </IconButton>
        </Box>
        <Divider />

        <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
       
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
               Current Parent Asset
              </Typography>

              <TextField
                size={"small"}
                type="text"
                name="fromParentAssetId"
                variant="standard"
                placeholder="Enter Asset"
                value={formik.values.fromParentAssetCode+"-"+formik.values.fromParentAssetName}
                inputProps={{readOnly:true}}
                fullWidth
                // onChange={(e) => {
                //   // setFieldValue("locationId",e.target.value)
                //   setAsset({ ...asset, ["id"]: e.target.value });
                // }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled onClick={() => locationAsset()}>
                        <AccountTree
                          sx={{
                            color: color.loginIcon,
                            fontWeight: 1800,
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    // "&::placeholder": {
                    //   // color: "green",
                    //   opacity: 1,
                    //   color: color.placeholderColor,
                    //   fontSize: "13px",
                    // },
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
               To Parent Asset
              </Typography>

              <TextField
                size={"small"}
                type="text"
                name="toParentAssetId"
                variant="standard"
                placeholder="Enter To Asset"
                value={formik.values.toParentAssetCode+"-"+formik.values.toParentAssetName}
                inputProps={{readOnly:true}}
                fullWidth
                // onChange={(e) => {
                //   // setFieldValue("locationId",e.target.value)
                //   setAsset({ ...asset, ["id"]: e.target.value });
                // }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => locationAsset()}>
                        <AccountTree
                          sx={{
                            color: color.loginIcon,
                            fontWeight: 1800,
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    // "&::placeholder": {
                    //   // color: "green",
                    //   opacity: 1,
                    //   color: color.placeholderColor,
                    //   fontSize: "13px",
                    // },
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Current Location
              </Typography>
              <TextField
                size={"small"}
                type="text"
                name="fromLocationId"
                variant="standard"
                placeholder="Enter Current Location"
                value={formik.values.fromLocationCode+"-"+formik.values.fromLocationName}
                inputProps={{readOnly:true}}
                fullWidth
                // onChange={(e) => {
                //   // setFieldValue("locationId",e.target.value)
                //   setCurrentLocation({
                //     ...currentLocation,
                //     ["name"]: e.target.value,
                //   });
                // }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled onClick={() => locationHandle("from")}>
                        <AccountTree
                          sx={{
                            color: color.loginIcon,
                            fontWeight: 1800,
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    // "&::placeholder": {
                    //   // color: "green",
                    //   opacity: 1,
                    //   color: color.placeholderColor,
                    //   fontSize: "13px",
                    // },
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                To Location
              </Typography>
              <TextField
                size={"small"}
                type="text"
                name="toLocationId"
                variant="standard"
                placeholder="Enter To Location"
                value={formik.values.toLocationCode+"-"+formik.values.toLocationName}
                inputProps={{readOnly:true}}
                fullWidth
                onChange={(e) => {
                  // setFieldValue("locationId",e.target.value)
                  setToLocation({ ...toLocation, ["name"]: e.target.value });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => locationHandle("to")}>
                        <AccountTree
                          sx={{
                            color: color.loginIcon,
                            fontWeight: 1800,
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    // "&::placeholder": {
                    //   // color: "green",
                    //   opacity: 1,
                    //   color: color.placeholderColor,
                    //   fontSize: "13px",
                    // },
                  },
                }}
              />
            </Box>
          </Box>
          {/* <Box sx={{ display: "flex", gap: "1rem" }}>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Row
              </Typography>
              <TextField
                variant="standard"
                size={"small"}
                type="text"
                name="row"
                value={formik.values.row}
                placeholder="Enter Row"
                onChange={formik.handleChange}
                fullWidth
                sx={{
                  // width: "100%",
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    // "&::placeholder": {
                    //   // color: "green",
                    //   opacity: 1,
                    //   color: color.placeholderColor,
                    //   fontSize: "13px",
                    // },
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Asile
              </Typography>
              <TextField
                variant="standard"
                size={"small"}
                type="text"
                name="asile"
                value={formik.values.asile}
                placeholder="Enter Asile"
                onChange={formik.handleChange}
                fullWidth
                sx={{
                  // width: "100%",
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    // "&::placeholder": {
                    //   // color: "green",
                    //   opacity: 1,
                    //   color: color.placeholderColor,
                    //   fontSize: "13px",
                    // },
                  },
                }}
              />
            </Box>
          </Box> */}
          <Box sx={{ display: "flex", gap: "1rem" }}>
            {/* <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Bin No
              </Typography>
              <TextField
                variant="standard"
                size={"small"}
                type="text"
                name="binNo"
                value={formik.values.binNo}
                placeholder="Enter Bin No"
                onChange={formik.handleChange}
                fullWidth
                sx={{
                  // width: "100%",
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    // "&::placeholder": {
                    //   // color: "green",
                    //   opacity: 1,
                    //   color: color.placeholderColor,
                    //   fontSize: "13px",
                    // },
                  },
                }}
              />
            </Box> */}
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Remark
              </Typography>
              <TextField
                variant="standard"
                size={"small"}
                type="text"
                name="remarks"
                value={formik.values.remarks}
                placeholder="Enter Remarks"
                onChange={formik.handleChange}
                fullWidth
                sx={{
                  // width: "100%",
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    // "&::placeholder": {
                    //   // color: "green",
                    //   opacity: 1,
                    //   color: color.placeholderColor,
                    //   fontSize: "13px",
                    // },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
      <Dialog
        open={locationDialog}
        onClose={handleCreateClose}
        children={
          <LocationTreeView
            handlelocationClick={(name, id, code) => {
              handlelocationClick(name, id, code);
            }}
            handleCreateClose={handleCreateClose}
          />
        }
      />
      <Dialog
        open={assetDialog}
        onClose={handleCreateAssetClose}
        children={
          <AssetIdFilter
            handleAssetClick={(name, id, code) => {
              handleAssetClick(name, id, code);
            }}
            handleCreateClose={handleCreateAssetClose}
          />
        }
      />
    </Box>
  );
};

export default Transfer;
