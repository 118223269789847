import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import { color } from "../StyleData/Config";
import { Clear } from "@mui/icons-material";

const FeedBack = ({  handleCloseClick, onCancel }) => {
  const [feedback, setFeedback] = useState("");
  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          Feedback
        </Typography>
        <IconButton onClick={onCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Grid container spacing={"1rem"}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            multiline
            minRows={1}
            maxRows={12}
            placeholder="Enter Reason"
            onChange={(e) => {
              setFeedback(e.target.value);
            
            }}
          />
        </Grid>
        <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
          <Button
            onClick={onCancel}
            variant="contained"
            sx={{
              backgroundColor: color.TableCancelButton,
              textTransform: "none",
              color: color.CancelTextColor,
              ":hover": {
                backgroundColor: color.TableCancelButton,
                textTransform: "none",
                color: color.CancelTextColor,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            type={"submit"}
            onClick={()=>handleCloseClick(feedback)}
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: color.TableButtonColor,
              "&:hover": {
                backgroundColor: color.buttonDisable,
              },
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeedBack;
