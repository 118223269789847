import { Box, Divider, Paper, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { color } from "../../../../StyleData/Config";
import NormalTable from "../../../../Component/NormalTable";
import { masterAPI } from "../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import EditMeterDialog from "./EditMeterDialog";
import { EditOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import moment from "moment";

const Meter = ({ data }) => {
  const [meterData, setMeterData] = useState([]);
  const [meterGroupData, setMeterGroupData] = useState([]);
  const [meterHistoryData, setMeterHistoryData] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedMeterId, setSelectedMeterId] = useState(null);
  const [selectedMeter, setSelectedMeter] = useState(null);
  const actionsAllow = useSelector((state) => state.dataRed.actionsAllow);

  useEffect(() => {
   getData()
  }, [data.id]);

  const getData=async()=>{
    const meterLink = masterAPI["meter"].getMeterByAsset;
   await axiosPrivate
      .get(`${meterLink}/${data.id}`)
      .then((res) => {
        console.log(res.data);
        setMeterData(res.data.result);
      })
      .catch((e) => {
        setMeterData([]);
        console.log(e, "meterSide Menu");
      });
  }

  const handleMeterGroup = async(meterId) => {
    const meterGroupLink = masterAPI["meterGroup"].getMGByMeter;
   await axiosPrivate
      .get(`${meterGroupLink}/${meterId}`)
      .then((res) => {
        console.log(res.data.result);
        setMeterGroupData([res.data.result]);
      })
      .catch((e) => {
        console.log(e, "getmetergroup");
      });
  };

  const handleMeterHistory =async (meterId) => {
    const searchLink = masterAPI.assetMeter.search;
   await axiosPrivate
      .post(`${searchLink}`, {
        assetId: data.id,
        meterId: meterId,
      })
      .then((res) => {
        console.log(res.data);
        setMeterHistoryData(res.data.result);
        setSelectedMeter(meterId);
      })
      .catch((e) => {
        setMeterHistoryData([]);
        console.log(e, "meter history");
      });
  };

  const handleEdit = (meterId) => {
    setSelectedMeterId(meterId);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const columns = [
    {
      Header: "Meter code",
      accessor: "meterCode",
      Cell: ({ cell }) => (
        <Typography
          sx={{
            fontSize: "inherit",
            fontWeight: "inherit",
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => {
            handleMeterGroup(cell.row.original.meterId);
            handleMeterHistory(cell.row.original.meterId);
          }}
        >
          {cell.value}
        </Typography>
      ),
    },
    {
      Header: "Meter Name",
      accessor: "meterName",
    },
    {
      Header: "Meter Type",
      accessor: "metertype",
    },
    {
      Header: "UOM",
      accessor: "uomName",
    },
    {
      Header: "Action",
      Cell: ({ cell }) => (
        <IconButton disabled={!actionsAllow.edit}>
          <EditOutlined
            onClick={() => handleEdit(cell.row.original.meterId)}
            sx={{
              fontSize: color.masterEditDelButton,
              color: actionsAllow.edit
                ? color.masterEditDelButtonColor
                : "default",
            }}
          />
        </IconButton>
      ),
    },
  ];

  const columnHistory = [
    {
      Header: "Reading Value",
      accessor: "readingValue",
    },
    {
      Header: "Date of Reading",
      accessor: "dateOfReading",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>
            {cell.value !== null && cell.value !== undefined
              ? moment(cell.value).format("DD/MM/YYYY HH:mm")
              : "---"}
          </Typography>
        );
      },
    },
  ];

  const columnGroup = [
    {
      Header: "Meter Group Code",
      accessor: "code",
      Cell: ({ cell }) => (
        <Typography
          sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue" }}
        >
          {cell.value}
        </Typography>
      ),
    },
    {
      Header: "Meter Group Name",
      accessor: "meterGroupName",
    },
  ];

  const selectedMeterData = meterData.find(
    (meter) => meter.meterId === selectedMeterId
  );

  return (
    <Box>
      <Typography
        sx={{
          color: color.sideTitleColor,
          fontSize: color.fontSizeSide,
          fontWeight: color.sideWeightFont,
        }}
      >
        Meter
      </Typography>
      <Paper sx={{ p: 1, mt: "0.5rem" }}>
        <Typography
          sx={{
            color: color.sideTitle2Color,
            fontSize: color.fontSizeSide,
            fontWeight: color.sideWeightFont,
          }}
        >
          Meters
        </Typography>
        <Divider />
        <NormalTable columns={columns} data={meterData} />
      </Paper>
      <Paper sx={{ mt: "1rem", p: 1 }}>
        <Typography
          sx={{
            color: color.sideTitle2Color,
            fontSize: color.fontSizeSide,
            fontWeight: color.sideWeightFont,
          }}
        >
          Meter Group
        </Typography>
        <Divider />
        <NormalTable columns={columnGroup} data={meterGroupData} />
      </Paper>
      <Paper sx={{ mt: "1rem", p: 1 }}>
        <Typography
          sx={{
            color: color.sideTitle2Color,
            fontSize: color.fontSizeSide,
            fontWeight: color.sideWeightFont,
          }}
        >
          Meter History
        </Typography>
        <Divider />
        <NormalTable columns={columnHistory} data={meterHistoryData} />
      </Paper>
      {selectedMeterData && (
        <EditMeterDialog
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          assetId={data.id}
          meterId={selectedMeterId}
          editData={selectedMeterData}
          meterCode={selectedMeterData.meterCode}
          meterName={selectedMeterData.meterName}
        />
      )}
    </Box>
  );
};

export default Meter;
