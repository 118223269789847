import React, { useEffect, useState } from "react";
import { color } from "../../../StyleData/Config";
import {
  Grid,
  Typography,
  Paper,
  Select,
  MenuItem,
  TextField,
  Box,
  Divider,
  IconButton,
  Tab,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Autocomplete,
  createFilterOptions,
  CircularProgress,
} from "@mui/material";

import { axiosPrivate} from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";


import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";


const InspView = () => {
  const navigate = useNavigate();
  const [searchParams,setSearchParams]=useSearchParams()


  const [loading, setLoading] = useState(false);
  const [data,setData]=useState({})
  useEffect(() => {
    getData()
  }, []);

  const getData=async()=>{
    setLoading(true);
    const inspectionById=masterAPI["inspSchedule"].getByid
   await axiosPrivate.get(`${inspectionById}/${searchParams.get("id")}`).then(res=>{
      setData(res.data.result)
    }).catch(e=>{
      console.log(e)
      setData([])
    })

  

    
    
    setLoading(false);
  }

  const handleCancel = () => {
    navigate("/inspection");
  };

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  

  

 

  return (
    <Box sx={{ p: 1 }}>
      <Box
        // sx={{
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "space-between",
        //   width: "100%",
        // }}
        sx={{ mb: "1rem" }}
      >
        <Typography
          sx={{
            // color: color.masterDialogTitleColor,
            // fontSize: color.masterDialogFontSize,
            fontSize: color.AssetTitlesize,
            fontWeight: color.sideFontWeightAsset,
          }}
        >
         {`${data.code+" - "+data.name}`}
        </Typography>
      </Box>
     
   
              <Paper sx={{ mt: "1rem",p:"1.5rem" }}>
             
                  <Grid
                    container
                   
                    spacing={"1rem"}
                  >
                    
                   
                    <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Code
                        </Typography>
                        <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.code}
                  </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Name
                        </Typography>
                        <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.name}
                  </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                           Description
                        </Typography>
                        <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.description}
                  </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Work Type
                        </Typography>
                        <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.workTypeName}
                  </Typography>
                      </Grid>
                      
                      {/* <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          PM Counter
                        </Typography>
                        <Field
                          as={TextField}
                          size={"small"}
                          type="text"
                          name="woCounter"
                          placeholder="Enter PM Counter"
                          fullWidth
                          sx={{
                            ".MuiInputBase-input": {
                              // letterSpacing: "0.2rem",

                              "&::placeholder": {
                                // color: "green",
                                opacity: 1,
                                color: color.placeholderColor,
                                fontSize: "13px",
                              },
                            },
                          }}
                        />
                      </Grid> */}

                    
                    <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Route
                        </Typography>
                        
                        <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.routeCode+"-"+data.routeName}
                  </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            fontWeight: color.labelFontWeight,
                            mb: color.labelmb,
                            fontSize: color.labelFont,
                            color: color.labelColor,
                          }}
                        >
                          Inspection Category
                        </Typography>
                        
                        <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.inspectionCategoryName}
                  </Typography>
                      </Grid>
                       <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Time Frequency
                          </Typography>
                          <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.timeFrequency}
                  </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Frequency Unit
                          </Typography>
                          <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    {data.frequencyUnitForTime}
                  </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sx={{
                            ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              // mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Next Due Date
                          </Typography>
                          <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                   {moment(data.nextDueDate).format(
                        "DD/MM/YYYY"
                      )}
                  </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sx={{
                            ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              // mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Target End Date
                          </Typography>
                          <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                   {moment(data.targetEndDate).format(
                        "DD/MM/YYYY"
                      )}
                  </Typography>
                        </Grid>
                      {/* <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: color.labelFontWeight,
                              mb: color.labelmb,
                              fontSize: color.labelFont,
                              color: color.labelColor,
                            }}
                          >
                            Offset Days
                          </Typography>
                          <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                   {data.offsetDays}
                  </Typography>
                        </Grid>
                        */}
                      
                    </Grid>
                    
                  </Paper>
               
             
           
           
          
      
    </Box>
  );
};

export default InspView;
