import { Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { color } from '../../../../../StyleData/Config'

const CompeletionInfo = ({data}) => {
  return (
    <Paper elevation={3} sx={{p:1}}>
        <Grid container spacing={"1rem"}>
            <Grid item xs={12}>
         
          <Grid container  sx={{pl:"1rem"}}>
            <Grid item xs={1.2}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               Permit Code :
                </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography
                 sx={{
                  fontSize: color.TabDatafontsize,
                 
                }}
                >
               {data}
                </Typography>
            </Grid>
          </Grid>
          

        
            </Grid>
            <Grid item   xs={12}>
            <Grid  container  spacing={"1rem"}>
             <Grid item xs={3}>
             <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    ml:"1rem",
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               <FormControlLabel  control={<Checkbox />} label="Work accepted" />
                </Typography>
            </Grid>
            <Grid item xs={3}>
             <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    ml:"1rem",
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               <FormControlLabel  control={<Checkbox />} label="Ready for operation" />
                </Typography>
            </Grid>
            <Grid item xs={3}>
             <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    ml:"1rem",
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               <FormControlLabel  control={<Checkbox />} label="Locks and label removed" />
                </Typography>
            </Grid>
            <Grid item xs={3}>
             <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    ml:"1rem",
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               <FormControlLabel  control={<Checkbox />} label="Work are clean" />
                </Typography>
            </Grid>
            
             </Grid>
            </Grid>
            <Grid item xs={12}>
         
         <Grid container  sx={{pl:"1rem"}}>
           <Grid item xs={1.2}>
           <Typography
                 sx={{
                   fontWeight: color.labelFontWeight,
                   
                   fontSize: color.labelFont,
                   color: color.labelColor,
                   
                 }}
               >
              Done by :
               </Typography>
           </Grid>
           <Grid item xs={4} sx={{borderBottom:"1px solid black"}}>
           <Typography
                sx={{
                 fontSize: color.TabDatafontsize,
                
               }}
               >
             
               </Typography>
           </Grid>
         </Grid>
         

       
           </Grid>

        </Grid>

    </Paper>
  )
}

export default CompeletionInfo