import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Paper,
  Button,
  InputAdornment,
  Divider,
  IconButton,
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";


// import logo from "../../images/logo.jpg"
import logo from "../../images/EamLogo1.png"
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import ErrorProps from "../../Components/Errorprops";
import { axiosPrivate } from "../../axios/axios";
import {toast} from "react-toastify"

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { color } from "../../StyleData/Config";
import ErrorProps from "../../Component/Errorprops";
import { useDispatch, useSelector } from "react-redux";
import { masterAPI } from "../Masters/dataConfig";
import { menulist } from "../../SampleData/PMSample";
// import { login } from "../../Redux/auth";

const Login = () => {
  const [isLoading,setLoading]=useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const menuArrayData=useSelector(state=>state.dataRed.menuArray)

  const [searchParams, setSearchParams] = useSearchParams();
  const dataId = searchParams.get("id");


  const navigate=useNavigate()
  const dispatch=useDispatch()


  useEffect(()=>{
    dispatch({type:"loginInfo",payload:{}})
    // dispatch({type:"loginInfo",payload:false})
  },[])

  const initialValues = {
    email: "",
    password: "",
  };
const menuArray=[]
  const menuStructure=(arrayData)=>{
    const childData=[]
    if(arrayData.childMenus!==undefined&&arrayData.childMenus!==null){
     
      arrayData.childMenus.map((dt,i)=>{
        // console.log(dt,i)
        const getChildData= menuStructure(dt)
        childData.push(getChildData)
      })
    }
    
    let structureWithoutChild={
    id:arrayData.id,
    // locationId:dataTree.id,
    module:arrayData.module,
    href:arrayData.href,
    name:arrayData.name,
    icon:arrayData.icon,
    children:arrayData.childMenus!==undefined&&arrayData.childMenus!==null&&arrayData.childMenus.length!==0?childData:null,
    apiRoute:arrayData.apiRoute,
    displayOrder:arrayData.displayOrder,
    view:arrayData.view,
    create:arrayData.create,
    edit:arrayData.edit,
    delete:arrayData.delete
   }
   menuArray.push(structureWithoutChild)

   return structureWithoutChild
  
  
  }
  const onSubmit = async(values, { resetForm }) => {
    setLoading(true)
    const loginLink=masterAPI["user"].signin
    // const email=["store@orienseam.com","tech@orienseam.com","tech1@orienseam.com","tech2@orienseam.com","tech3@orienseam.com","tech4@orienseam.com" ,'siteadmin@orienseam.com']
  
    // const password="123@EAMADmin"
    // if(email.includes(values.email)&&values.password===password){
    //   dispatch({type:"loginInfo",payload:{token:"jfkd"}})
    //   const sampleData=menulist
    //   const menuData=[]
    //   for(let i =0;sampleData.length>i;i++){
    //     const finalData=menuStructure(sampleData[i])
    //     menuData.push(finalData)
    //   }
    //   console.log(menuData)
      
    //   dispatch({type:"menuList",payload:menuData})
      
    //   navigate("/dashboard")
    // }else{
    //   setLoading(false)
    //   toast.error("Email Or Password is incorrect")
    // }
   await axiosPrivate.post(`${loginLink}`,values).then(res=>{
    console.log(res)
   dispatch({type:"loginInfo",payload:res.data.result})
   const sampleData=res.data.result.menuList
   const menuData=[]
   for(let i =0;sampleData.length>i;i++){
     const finalData=menuStructure(sampleData[i])
     menuData.push(finalData)
   }
  //  console.log(menuData)
  //  console.log(menuArray)
   
   dispatch({type:"menuList",payload:menuData})
   dispatch({type:"menuArray",payload:menuArray})
   setLoading(false)
   navigate("/dashboard")
   }).catch(e=>{
    setLoading(false)
    // toast.error(e.response.data.failureMessage)
    console.log(e,"login Error")
   })

 
  
   
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required"),
      // .matches(
      //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      //   "Invalild Email"
      // )
    password: Yup.string().required("Password is required"),
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Box
      sx={{
        padding:{xs:"3rem 1rem",md:"0"},
        display:{xs:"block",md:"flex"},
        height: "100vh",
        // width: "100%",
        justifyContent: {xs:"none",md:"center"},
        alignItems:  {xs:"none",md:"center"},
        backgroundColor: "#F3F4F7",
      }}
    >
   
      <Box sx={{backgroundColor:"white",borderRadius:"0.3rem"}}>
        <Grid container >
          <Grid
            container
            item
            xs={12}
            md={6}
            sx={{ padding: {xs:"2rem",md:"2rem 5rem 3rem 3rem"} }}
            gap="1.5rem"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1.8rem",
               
              }}
            >
              {/* <Box sx={{display:"flex",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
              <Link to="/">
                <Typography
                  sx={{
                    color: "#2362B4",
                    fontSize:"1.3rem",
                    fontWeight: 600,
                  }}
                >
                  ORIENS EAM
                </Typography>
              </Link>
              <img src={logo} alt="logo" style={{width:"100px",height:"80px"}}/>
              </Box> */}
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
              >
                <Typography sx={{ fontWeight: 400, fontSize: "1.1rem" }}>
                  Welcome back!
                </Typography>
                <Typography sx={{ fontSize: "0.9rem", color: "#6C7585" }}>
                  Enter your email address and password to access the system.
                </Typography>
              </Box>
            </Box>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.7rem",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#6C757D",fontWeight:600}}>
                  Email
                </Typography>
                <Field
                  as={TextField}
                  type="email"
                  fullWidth
                  size={"large"}
                  name="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailIcon
                          sx={{ color: color.loginIcon, fontWeight: 1800 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage name="email">
                  {(error) => <ErrorProps>{error}</ErrorProps>}
                </ErrorMessage>
                <Box
                  sx={{
                    color: "#6C757D",
                    // fontWeight: 200,
                  
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{  fontWeight:600}}>Password</Typography>
                 <Link to="/" style={{textDecoration:"none",color:"inherit"}}> <Typography sx={{fontSize:"0.9rem",  fontWeight:600,}}>Forget Password?</Typography></Link>
                </Box>
                <Field
                  as={TextField}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  name="password"
                  size={"medium"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon
                          sx={{ color: color.loginIcon, fontWeight: 1800 }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility  /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    ),
                  
                  }}
                />
                <ErrorMessage name="password">
                  {(error) => <ErrorProps>{error}</ErrorProps>}
                </ErrorMessage>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  fullWidth
                  sx={{
                    padding: "0.5rem",
                    mt: "0.7rem",
                    backgroundColor: isLoading?"default": color.buttonColor,
                    "&:hover": {
                      backgroundColor: isLoading?"default":color.buttonhover,
                    },
                  }}
                >
                
                  Login
                </Button>
              </Form>
            </Formik>
          </Grid>
          <Grid
            item
            xs={0}
            md={6}
            sx={{
                
              backgroundColor: "#2362B4",
              display: {xs:"none",md:"flex"},
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                color: "white",
                fontSize: "1.5rem",
                fontWeight: 750,
              }}
            >
              <img src={logo} alt="logo" style={{width:"180px",height:"100px"}}/>
            {/* <span style={{border:"1px solid white",color:"white",padding:"1rem"}}>Oriens EAM</span>  */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Login;
