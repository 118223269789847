import React, { useEffect, useState } from "react";
import { color } from "../../../StyleData/Config";
import { Grid, Typography, Paper, Box } from "@mui/material";

import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";

import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

const General = ({ data }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Box sx={{ p: 1 }}>
      <Paper elevation={7} sx={{ p: "1.5rem" }}>
        <Grid container spacing={"1rem"}>
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
              Code
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {data.code}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
              Name
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {data.inspectionName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
              Work Type
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {data.workTypeName}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
              Route
            </Typography>

            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {data.routeCode + "-" + data.routeName}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            sx={{
              ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
            }}
          >
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
              Target Start Date
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {moment(data.targetStartDate).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
            }}
          >
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
              Target End Date
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {moment(data.targetEndDate).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
            }}
          >
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
              Planned Start Date
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {moment(data.plannedStartDate).format("DD/MM/YYYY HH:mm")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              ".css-1xhypcz-MuiStack-root": { paddingTop: 0 },
            }}
          >
            <Typography
              sx={{
                fontWeight: color.labelFontWeight,
                mb: color.labelmb,
                fontSize: color.labelFont,
                color: color.labelColor,
              }}
            >
              Planned End Date
            </Typography>
            <Typography
              sx={{
                fontSize: color.TabDatafontsize,
                mb: color.TabDatamarginbottom,
              }}
            >
              {moment(data.plannedEndDate).format("DD/MM/YYYY HH:mm")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default General;
