import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { masterAPI } from "../../Masters/dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import { AccountTree, Clear, LocationCity } from "@mui/icons-material";
import SortableTree, {
  toggleExpandedForAll,
} from "@nosferatu500/react-sortable-tree";
import FileExplorerTheme from "@nosferatu500/theme-file-explorer";
import { color } from "../../../StyleData/Config";

const LocationTreeView = ({ handlelocationClick, handleCreateClose }) => {
  const [location, setLocation] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [searchFocusIndex, setSearchFocusIndex] = useState("");

  useEffect(() => {
   getData()
  }, []);

  const getData=async()=>{
    const locLink = masterAPI["location"].link;

   await axiosPrivate
      .get(`${locLink}`)
      .then((res) => {
        setLocation(res.data.result);
        console.log(res.data.result)
        var results = res.data.result;
        const LocationList = [];
        Object.keys(results).map((index) => {
          var locationItem = {
            title: results[index].locationName,
            code:results[index].code,
            dragDisabled: true,
            expanded: true,
            id: results[index].id,
          };
          // console.log(locationItem)
          LocationList.push(locationItem);
        });
        const treeLocationDefs = [
          {
            title: "Location",
            isDirectory: true,
            expanded: true,
            dragDisabled: true,
            children: LocationList,
          },
        ];
        setTreeData(treeLocationDefs);
      })
      .catch((e) => {
        const treeLocationDefs = [
          {
            title: "Location",
            isDirectory: true,
            expanded: true,
            dragDisabled: true,
            children: null,
          },
        ];
        setTreeData(treeLocationDefs);
        setLocation([])
        console.log(e, "assetTreeLocationGetAll");
      });
  }

  // const treeLocationDefs = [

  //     {
  //       title: 'Helsinki',
  //       isDirectory: true,
  //       expanded: true,
  //       dragDisabled: true,
  //       children: LocationList,
  //     },

  //   ];

  //   const [treeData, setTreeData] = useState(treeLocationDefs);

  const updateTreeData = (newTreeData) => {
    setTreeData(newTreeData);
  };

  const onSelect = (name, id,code) => {
    console.log(name, id, code);
    handlelocationClick(name, id, code);
  };

  const handleCancel = () => {
    handleCreateClose();
  };

  return (
    <Box sx={{ p: 2 }}>
     
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          Filter
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Box sx={{ display: "flex", justifyContent: "center", mb: "1rem" }}>
        <TextField
          size="small"
          type="text"
          placeholder="Enter the Location Name "
          value={searchString}
          onChange={(event) => setSearchString(event.target.value)}
          fullWidth
        />
      </Box>
      {/* {JSON.stringify(treeData)} */}
     
      <Box
        sx={{
          flex: "1 0 50%",
          padding: "0 0 0 0",
          width: "60vw",
          height: "60vh",
        }}
      >
        <SortableTree
          theme={FileExplorerTheme}
          treeData={treeData}
          onChange={updateTreeData}
          //onChange={treeData => updateTreeData({ treeData })}
          
          searchQuery={searchString}
          searchFocusOffset={searchFocusIndex}
          searchFinishCallback={(matches) => {
            setSearchFocusIndex(
              matches.length > 0 ? searchFocusIndex % matches.length : 0
            );
          }}
          canDrag={({ node }) => !node.dragDisabled}
          canDrop={({ nextParent }) => !nextParent || nextParent.isDirectory}
          generateNodeProps={(rowInfo) => ({
            title: (
              <Button
                sx={{
                  textTransform: "none",
                  border: "2px solid black",
                  backgroundColor: "#F0F0F0",
                  color: "black",
                  ml: "0.5rem",
                }}
                variant="outlined"
                onClick={() => onSelect(rowInfo.node.title, rowInfo.node.id,rowInfo.node.code)}
                paramId={rowInfo.node.title}
              >
                {rowInfo.node.title}
              </Button>
            ),
            icons: rowInfo.node.isDirectory
              ? [<AccountTree sx={{ color: "blue" }} />]
              : [<LocationCity sx={{ color: "gray" }} />],
          })}
        />
      </Box>
    </Box>
  );
};

export default LocationTreeView;
