import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";

import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../dataConfig";
import { DesktopDatePicker } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers";

const UomModal = ({ mode, handleCloseClick, editData }) => {
  const [uomCat, setUomCat] = useState([]);
  const [loading, setLoading] = useState(false);
 

  useEffect(() => {

    getData()
  }, []);

  const getData=async()=>{
    const link = masterAPI["selectList"].uomCategory;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setUomCat(res.data);
      })
      .catch((e) => {
        setUomCat([])
        console.log(e, "create uom");
      });
  }

  const initialValue = {
    uomName: mode === "Edit" ? editData.uomName : "",
    code: mode === "Edit" ? editData.code : "",
    meter: mode === "Edit" ? editData.meter : false,
    parts: mode === "Edit" ? editData.parts : false,
    techAttribute: mode === "Edit" ? editData.techAttribute : false,
    tools: mode === "Edit" ? editData.tools : false,
    services: mode === "Edit" ? editData.services : false,
    // UOMCategory: mode === "Edit" ? editData.UOMCategory :""
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["uom"].create;
    const updateLink = masterAPI["uom"].update;
    if (mode === "Create") {
     await axiosPrivate
        .post(`${createLink}`, value)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "createuom");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "updateuom");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
         {mode === "Edit"?editData.code+"-"+editData.uomName:"New UOM"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  UOM Code
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="code"
                  placeholder="Enter UOM Code"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  UOM Name
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="uomName"
                  placeholder="Enter UOM Name"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Meter
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="meter"
                  placeholder="Enter Meter"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Parts
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="parts"
                  placeholder="Enter Parts"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Technical Attribute
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="techAttribute"
                  placeholder="Enter Technical Attribute"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Tools
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="tools"
                  placeholder="Enter Tools"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Services
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="services"
                  placeholder="Enter Services"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 UOM Category
                </Typography>
                <Field
                as={Select}
                // label={"Select"}
                size={"small"}
                type="text"
                name="UOMCategory"
                fullWidth
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
               renderValue={values.UOMCategory !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                fontSize: "13px",}} >Select</Typography> }
              >
                
                {uomCat.map((id, i) => (
                  <MenuItem value={id.value}>{id.text}</MenuItem>
                ))}
              </Field>
              </Grid> */}
                <Grid container item xs={12} md={12} alignItems={"center"}>
                <Box
                sx={{display:"flex",gap:"1rem"}}
                  // value={radio}
                  // onChange={(e) => {
                  //   setRadio(e.target.value);
                  // }}
                >
                  <FormControlLabel
                    value="meter"
                    checked={values.meter}
                    control={<Checkbox onChange={(e)=>{
                      setFieldValue("meter",e.target.checked)
                      // setFieldValue("parts",false)
                      // setFieldValue("techAttribute",false)
                      // setFieldValue("tools",false)
                      // setFieldValue("services",false)
                    }} />}
                    label="Meter"
                    sx={{
                      ".MuiFormControlLabel-label":  {
                        // Change color here
                        color: color.labelColor
                        }
                    }}
                  />
                  <FormControlLabel
                    value="parts"
                    checked={values.parts}
                    control={<Checkbox onChange={(e)=>{
                      setFieldValue("parts",e.target.checked)
                      // setFieldValue("meter",false)
                      // setFieldValue("techAttribute",false)
                      // setFieldValue("tools",false)
                      // setFieldValue("services",false)
                    }} />}
                    label="Parts"
                    sx={{
                      ".MuiFormControlLabel-label":  {
                        // Change color here
                        color: color.labelColor
                        }
                    }}
                  />
                  <FormControlLabel
                    value="techAttribute"
                    checked={values.techAttribute}
                    control={<Checkbox onChange={(e)=>{
                      setFieldValue("techAttribute",e.target.checked)
                      // setFieldValue("parts",false)
                      // setFieldValue("meter",false)
                      // setFieldValue("tools",false)
                      // setFieldValue("services",false)
                    }} />}
                    label="Technical Attribute"
                    sx={{
                      ".MuiFormControlLabel-label":  {
                        // Change color here
                        color: color.labelColor
                        }
                    }}
                  />
                     <FormControlLabel
                    value="tools"
                    checked={values.tools}
                    control={<Checkbox onChange={(e)=>{
                      setFieldValue("tools",e.target.checked)
                      // setFieldValue("parts",false)
                      // setFieldValue("meter",false)
                      // setFieldValue("techAttribute",false)
                      // setFieldValue("services",false)
                    }} />}
                    label="Tools"
                    sx={{
                      ".MuiFormControlLabel-label":  {
                        // Change color here
                        color: color.labelColor
                        }
                    }}
                  />
                     <FormControlLabel
                    value="services"
                    checked={values.services}
                    control={<Checkbox onChange={(e)=>{
                      setFieldValue("services",e.target.checked)
                      // setFieldValue("parts",false)
                      // setFieldValue("meter",false)
                      // setFieldValue("tools",false)
                      // setFieldValue("techAttribute",false)
                    }} />}
                    label="Services"
                    sx={{
                      ".MuiFormControlLabel-label":  {
                        // Change color here
                        color: color.labelColor
                        }
                    }}
                  />
                </Box>
              </Grid>
            
              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default UomModal;
