import {
    Box,
    Button,
    Checkbox,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Field, Form, Formik } from "formik";
  import * as Yup from "yup";
  import { Clear, DeleteOutline, Save } from "@mui/icons-material";
  import { color } from "../../../StyleData/Config";
  import { axiosPrivate } from "../../../axios/axios";
  import { masterAPI } from "../../Masters/dataConfig";
import NormalTable from "../../../Component/NormalTable";
import { useSearchParams } from "react-router-dom";
import Loading from "../../../Component/Loading";
  
  const AssetPart = ({ mode, handleCloseClick,editData }) => {
    const [part, setPart] = useState([]);
    const [loading,setLoading]=useState(false)
    const [checkedList,setCheckedList]=useState([])
    const [searchParams,setSearchParams]=useSearchParams()
    const[quantity,setQuantity]=useState("")
  
    useEffect(() => {
     setLoading(true)
     getData()
    }, []);

    const getData=async()=>{
      const link = masterAPI["parts"].link;
     await axiosPrivate
        .get(`${link}`)
        .then((res) => {
          setLoading(false)
          setPart(res.data.result);
        })
        .catch((e) => {
            setPart([])
            setLoading(false)
          console.log(e, "part getall");
        });
    }
  
  
  
    const onSubmit =async () => {
      
      setLoading(true)
      const createLink = masterAPI["assetPart"].bulkinsert;
      const data=[]
    checkedList.map((d,i)=>{
      const postData={
        assetId:searchParams.get("id"),
        partId:d,
        quantity:quantity
      }
     data.push(postData)
    })
 
     console.log(data)
    await axiosPrivate.post(`${createLink}`,data).then(res=>{
      console.log(res)
      handleCloseClick()
    }).catch(e=>{
      console.log(e,"bulkPart")
    })
    };
  
   

    const handleChecked=(value)=>{
      
      if(checkedList.includes(value)){
        const removeData=checkedList.filter(l=>l!==value)
        setCheckedList(removeData)
      }else{
       setCheckedList([...checkedList,value])
      }
    }

    const columns=[
      {
        
        accessor:"id",
        Cell:({cell})=>{
          return (
            <Checkbox checked={checkedList.includes(cell.row.original.id)} onClick={()=>{handleChecked(cell.row.original.id)}}  />
          )
        },
        disableFilters:true
      },
      {
        Header:"Part Code",
        accessor:"code",
      },
      {
        Header:"Part Name",
        accessor:"partName"
      },
      
    ]
  
    return (
      <Box sx={{ p: color.masterPageDialogPadding}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
            Part
          </Typography>
           <Box>
            <IconButton onClick={onSubmit}>
                <Save />
            </IconButton>
            <IconButton onClick={()=>{handleCloseClick()}}>
                <Clear/>
            </IconButton>
         </Box>
        </Box>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        <Grid container>
        <Grid item xs={12} md={6}>
            <Box sx={{display:"flex",alignItems:"center" ,gap:"1rem"}}>
        <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    // color: color.labelColor,
                    whiteSpace:"nowrap"
                  }}
                >
                Quantity :
                </Typography>
                <TextField name="qauntity" size='small' placeholder='Enter Quantity' value={quantity} onChange={(e)=>{setQuantity(e.target.value)}}/>
                </Box>
            </Grid>
        </Grid>
        {loading?     <Box>
            <Loading/>
        </Box>:
                <NormalTable data={part} columns={columns} filt={true}/>
        }
   

       
      </Box>
    );
  };
  
  export default AssetPart;
  