import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Field, Form, Formik } from "formik";
  import * as Yup from "yup";
  import { Clear } from "@mui/icons-material";
  import { color } from "../../../StyleData/Config";
  import { axiosPrivate } from "../../../axios/axios";
  import { masterAPI } from "../dataConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
  
  const CloneTechSpec = ({ handleCloseClick }) => {
    const [parentId, setParentId] = useState([]);
    const [technicalType,setTechnicalType]=useState([])
    const [loading,setLoading]=useState(false)
    const cloneData=useSelector(state=>state.dataRed.techspecInfo)

    const navigate=useNavigate()
  
  useEffect(()=>{
    getData()
  },[])

  const getData=async()=>{
    const typelink=masterAPI["selectList"].technicalSpecificationType
     await axiosPrivate
      .get(`${typelink}`)
      .then((res) => {
        setLoading(false)
        console.log(res)
        setTechnicalType(res.data);
      })
      .catch((e) => {
       setLoading(false)
       setTechnicalType([])
        console.log(e, "create technicalSpecificationType");
      });
  }
  
    const initialValue = {
      techSpecName: cloneData.techSpecName,
      technicalSpecificationType: cloneData.technicalSpecificationType,
      // parentTechId:mode==="Edit"?editData.parentTechId: null,
      code:""
    };
  
    const onSubmit = async(value) => {
      console.log(value);
      setLoading(true)
      const cloneLink = masterAPI["techSpecAttr"].clone;
     await axiosPrivate.post(`${cloneLink}/${cloneData.id}/${value.code}`).then(res=>{
        console.log(res)
        navigate("/masters/technicalSpecification")
        handleCloseClick()
      }).catch(e=>{
        console.log(e)
      })
     
      
     
    };
  
    const handleCancel = () => {
      handleCloseClick();
    };
  
    return (
      <Box sx={{ p: color.masterPageDialogPadding}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: color.masterDialogTitleColor,
              fontSize: color.masterDialogFontSize,
            }}
          >
            {cloneData.code+"-"+cloneData.techSpecName+"(Clone)"}
          </Typography>
          <IconButton onClick={handleCancel}>
            <Clear />
          </IconButton>
        </Box>
        <Divider sx={{border:"1px solid rgb(0 0 0 / 17%)",mb:"15px"}} />
        <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values , resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
            <Grid item xs={12} md={6}>
              <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Technical Specification Code
                  </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="code"
                  placeholder="Enter Tech Specification Code"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",
  
                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Technical Specification Name
                  </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="techSpecName"
                  placeholder="Enter Tech Specification Name"
                  inputProps={{readOnly:true}}
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",
  
                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
              <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                   Parent Technical Specification
                  </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="parentTechId"
                  // placeholder="Enter Asset Category Name"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                 renderValue={values.parentTechId !== null ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                  fontSize: "13px",}} >Select</Typography> }
                >
                 
                  {parentId.map((id, i) => (
                    <MenuItem value={id.id} disabled={editData.id===id.id&&mode==="Edit"}>{id.code+" - "+id.techSpecName}</MenuItem>
                  ))}
              
                </Field>
              </Grid> */}
              <Grid item xs={12} md={6}>
              <Typography
                    sx={{
                      fontWeight: color.labelFontWeight,
                      mb: color.labelmb,
                      fontSize: color.labelFont,
                      color: color.labelColor,
                    }}
                  >
                    Technical Specification Type
                  </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="technicalSpecificationType"
                  // placeholder="Enter Asset Category Name"
                  fullWidth
                  displayEmpty
                  
                  inputProps={{readOnly:true}}
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                 renderValue={values.technicalSpecificationType !== "" ? undefined : () =><Typography sx={{  color: color.placeholderColor,
                  fontSize: "13px",}} >Select</Typography> }
                >
                 
                  {technicalType.map((id, i) => (
                    <MenuItem value={id.value}>{id.text}</MenuItem>
                  ))}
              
                </Field>
              </Grid>
              <Grid container item xs={12} justifyContent={"flex-end"} gap="1rem">
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover":{
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                        
                    textTransform: "none",
                    backgroundColor: loading ? "default" : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
        </Formik>
        
      </Box>
    );
  };
  
  export default CloneTechSpec;
  