import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Box,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { color } from "../StyleData/Config";
import { menulist } from "../SampleData/PMSample";
import { useDispatch, useSelector } from "react-redux";

const SideNav = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [listOpen, setListOpen] = useState({});
  const data = useSelector((state) => state.dataRed.menuList);
  const drawerOpen = useSelector((state) => state.auth.drawerOpen);


  const handleClick = (listType) => {
    setListOpen({ ...listOpen, [`${listType}`]: !listOpen[`${listType}`] });
  };

  const handleNavigate = (link) => {
    navigate(link);
  };

  const renderTree = (nodes) => {
    // console.log(nodes)
    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              p: 1,
              gap: "0.6rem",
            }}
            onClick={() => {
              if (nodes.children === null) {
                if(nodes.module.toUpperCase()==="ASSETS_EXPL"){
                  dispatch({type:"drawerOpen",payload:{bol:true,open:false}})
                }else{
                  dispatch({type:"drawerOpen",payload:{bol:false,open:false}})
                }
                navigate(`${nodes.href}`);
                // dispatch({type:"actionsAllow",payload:nodes})
              }
            }}
          >
            <img
              src={`${nodes.icon}`}
              style={{
                width: "1.4rem",
                height: "1.4rem",
                display: nodes.children == null ? "default" : "none",
              }}
            />
            <Typography
              sx={{
                fontSize: "0.82rem",
                fontWeight: color.sideFontWeight,
                fontFamily: color.sideFontFamily,
                color: color.sideFontColor,
              }}
            >
              {nodes.name}
            </Typography>
          </Box>
        }
        sx={{ mt: 0.7, p: 0, ml:nodes.children === null? -3.5:0 }}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={drawerOpen.bol?drawerOpen.open:props.open}
      sx={{
        width: props.drawerWidth,
        flexShrink: 0,
        display: {
          xs: props.res === "main" ? "none" : "block",
          md: props.res === "main" ? "block" : "none",
        },
        [`& .MuiDrawer-paper`]: {
          width: props.drawerWidth,
          boxSizing: "border-box",
          border: "none",
          backgroundColor: props.res === "main" ? "transparent" : "default",
          mt: {xs:props.res === "main" ? 0 : "9vh"},
          boxShadow: props.res === "main" ? 0 : 3,
        },
      }}
    >
      <Toolbar
        sx={{ display: props.res === "main" ? "block" : "none" }}
      ></Toolbar>
      <Box sx={{ overflow: "auto" }}>
        {/* <List
          sx={{
            width: "100%",
            // bgcolor: "background.paper",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            gap: color.sideNavWholeGap,
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
        
       </List> */}
        <TreeView
          aria-label="Location Tree"
          defaultCollapseIcon={<ExpandMoreIcon sx={{ color: "#828079" }} />}
          //  defaultExpanded={expanded}
          defaultExpandIcon={<ChevronRightIcon sx={{ color: "#828079" }} />}
          sx={{
            "& .MuiTreeItem-root": {
              "& .MuiTreeItem-content": {
                "& .MuiTreeItem-label": {
                  paddingLeft: "1em",
                },
                "& .MuiTreeItem-iconContainer": {
                  paddingLeft: "0.5em",
                },
              },
            },
          }}
        >
          {data.map((list, i) => (
            <Box key={i}>{renderTree(list)}</Box>
          ))}
        </TreeView>
      </Box>
    </Drawer>
  );
};

export default SideNav;
