import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear, FileUploadOutlined } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";

const PartsModal = ({ mode, handleCloseClick, editData }) => {
  const [partGroupData, setPartGroupId] = useState([]);
  const [stockCatData, setStockCatData] = useState([]);
  const [uomData, setUomData] = useState([]);
  const [criticalityData, setCriticality] = useState([]);
  const [stockRoomData, setStockRoomData] = useState([]);
  const [techSpecData, setTechSpecData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData()
  }, []);


  const getData=async()=>{
    setLoading(true);

    //////// Part Group ///////

    const link = masterAPI["partsGroup"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        // setLoading(false);
        setPartGroupId(res.data.result);
      })
      .catch((e) => {
        setPartGroupId([])
        console.log(e, "createPartGroupgetAll");
      });

    ////// Stock Category////////

    const stockCat = masterAPI["stockCategory"].link;
   await axiosPrivate
      .get(`${stockCat}`)
      .then((res) => {
        // setLoading(false);
        setStockCatData(res.data.result);
      })
      .catch((e) => {
        setStockCatData([])
        console.log(e, "createStockCategorygetAll");
      });

    ////// UOM ID ///////////////////////

    const UOMId = masterAPI["selectList"].uomByCat;
   await axiosPrivate
      .get(`${UOMId}/Parts`)
      .then((res) => {
        // setLoading(false);
        setUomData(res.data);
      })
      .catch((e) => {
        setUomData([])
        console.log(e, "createUOMgetAll");
      });

    ///////////// Criticality //////////////////////

    const criti = masterAPI["selectList"].criticalitybyType;
   await axiosPrivate
      .get(`${criti}/Parts`)
      .then((res) => {
        // setLoading(false);
        setCriticality(res.data);
      })
      .catch((e) => {
        setCriticality([])
        console.log(e, "createCriticalitygetAll");
      });

    //////////////  Stock Room ///////////////////////

    const stRoom = masterAPI["stockRoom"].link;
   await axiosPrivate
      .get(`${stRoom}`)
      .then((res) => {
        // setLoading(false);
        setStockRoomData(res.data.result);
      })
      .catch((e) => {
        setStockRoomData([])
        console.log(e, "createStockRoomgetAll");
      });

    ////////////// Technical Specification ////////////////////////

    const tech = masterAPI["selectList"].techspecbyType;
   await axiosPrivate
      .get(`${tech}/Parts`)
      .then((res) => {
        setLoading(false);
        setTechSpecData(res.data);
      })
      .catch((e) => {
        setTechSpecData([])
        console.log(e, "createTechSpecgetAll");
      });
  }

  const initialValue = {
    partName: mode === "Edit" ? editData.partName : "",
    code: mode === "Edit" ? editData.code : "",
    partDescription: mode === "Edit" ? editData.partDescription : "",
    isRotating: mode === "Edit" ? editData.isRotating : false,
    isQCPassed: mode === "Edit" ? editData.isQCPassed : false,
    qrCodeData: mode === "Edit" ? editData.qrCodeData : "",
    partGroupId: mode === "Edit" ? editData.partGroupId : "",
    // stockCategoryId: mode === "Edit" ? editData.stockCategoryId : "",
    purchasedUomId: mode === "Edit" ? editData.purchasedUomId : "",
    criticalityId: mode === "Edit" ? editData.criticalityId : "",
    issuedUomId: mode === "Edit" ? editData.issuedUomId : "",
    // stockRoomId: mode === "Edit" ? editData.stockRoomId : "",
    techSpecId: mode === "Edit" ? editData.techSpecId : "",
    partImage:mode==="Edit" ? editData.partImage.split(".com")[1]:"",
    imageData: "",
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["parts"].create;
    const updateLink = masterAPI["parts"].update;
    if (mode === "Create") {
     await axiosPrivate
        .post(`${createLink}`, value)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "createparts");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "updateParts");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
         {mode==="Edit"?editData.code+"-"+editData.partName:"New Part"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Parts Name
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="partName"
                  placeholder="Enter Name"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Part Code
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="code"
                  placeholder="Enter Code"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Part Description
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="partDescription"
                  placeholder="Enter Description"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Part Group
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="partGroupId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.partGroupId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {partGroupData.map((id, i) => (
                    <MenuItem value={id.id}>{id.code+" - "+id.partGroupName}</MenuItem>
                  ))}
                </Field>
              </Grid>

              {/* <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Stock Category
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="stockCategoryId"
                  
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.stockCategoryId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {stockCatData.map((id, i) => (
                    <MenuItem value={id.id}>{id.stockCategoryName}</MenuItem>
                  ))}
                </Field>
              </Grid> */}

              {/* <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Stock Room Id
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="stockRoomId"
                  
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.stockRoomId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {stockRoomData.map((id, i) => (
                    <MenuItem value={id.id}>{id.stockRoomName}</MenuItem>
                  ))}
                </Field>
              </Grid> */}

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Purchase UOM
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="purchasedUomId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.purchasedUomId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {uomData.map((id, i) => (
                    <MenuItem value={id.value}>{id.text}</MenuItem>
                  ))}
                </Field>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Issued UOM
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="issuedUomId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.issuedUomId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {uomData.map((id, i) => (
                    <MenuItem value={id.value}>{id.text}</MenuItem>
                  ))}
                </Field>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Criticality
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="criticalityId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.criticalityId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {criticalityData.map((id, i) => (
                    <MenuItem value={id.value}>{id.text}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Technical Specification
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="techSpecId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.techSpecId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {techSpecData.map((id, i) => (
                    <MenuItem key={i} value={id.value}>{id.text}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Qr Code Data
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="qrCodeData"
                  placeholder="Enter Qr Code Data"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Rotaing
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    // flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    In Active
                  </Typography>

                  <Switch
                    checked={values.isRotating}
                    value={values.isRotating}
                    onChange={(e) => {
                      console.log(e.target.checked);
                      setFieldValue("isRotating", e.target.checked);
                    }}
                    sx={{
                      width: 48,
                      height: 26,
                      padding: 0,

                      "& .MuiSwitch-switchBase": {
                        padding: 0,
                        margin: 0.2,
                        transitionDuration: "300ms",
                        "&.Mui-checked": {
                          transform: "translateX(22px)",
                          color: "#fff",
                          "& + .MuiSwitch-track": {
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#2ECA45"
                                : "#65C466",
                            opacity: 1,
                            border: 0,
                          },
                          "&.Mui-disabled + .MuiSwitch-track": {
                            opacity: 0.5,
                          },
                        },
                        "&.Mui-focusVisible .MuiSwitch-thumb": {
                          color: "#33cf4d",
                          border: "6px solid #fff",
                        },
                        "&.Mui-disabled .MuiSwitch-thumb": {
                          color: (theme) =>
                            theme.palette.mode === "light"
                              ? theme.palette.grey[100]
                              : theme.palette.grey[600],
                        },
                        "&.Mui-disabled + .MuiSwitch-track": {
                          opacity: (theme) =>
                            theme.palette.mode === "light" ? 0.7 : 0.3,
                        },
                      },
                      "& .MuiSwitch-thumb": {
                        boxSizing: "border-box",
                        width: 22,
                        height: 22,
                      },
                      "& .MuiSwitch-track": {
                        borderRadius: 26 / 2,
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#E9E9EA"
                            : "#39393D",
                        opacity: 1,
                        transition: (theme) =>
                          theme.transitions.create(["background-color"], {
                            duration: 500,
                          }),
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    Active
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  QC Passed
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    // flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    In Active
                  </Typography>
                  <Switch
                    checked={values.isQCPassed}
                    value={values.isQCPassed}
                    onChange={(e) => {
                      console.log(e.target.checked);
                      setFieldValue("isQCPassed", e.target.checked);
                    }}
                    sx={{
                      width: 48,
                      height: 26,
                      padding: 0,

                      "& .MuiSwitch-switchBase": {
                        padding: 0,
                        margin: 0.2,
                        transitionDuration: "300ms",
                        "&.Mui-checked": {
                          transform: "translateX(22px)",
                          color: "#fff",
                          "& + .MuiSwitch-track": {
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#2ECA45"
                                : "#65C466",
                            opacity: 1,
                            border: 0,
                          },
                          "&.Mui-disabled + .MuiSwitch-track": {
                            opacity: 0.5,
                          },
                        },
                        "&.Mui-focusVisible .MuiSwitch-thumb": {
                          color: "#33cf4d",
                          border: "6px solid #fff",
                        },
                        "&.Mui-disabled .MuiSwitch-thumb": {
                          color: (theme) =>
                            theme.palette.mode === "light"
                              ? theme.palette.grey[100]
                              : theme.palette.grey[600],
                        },
                        "&.Mui-disabled + .MuiSwitch-track": {
                          opacity: (theme) =>
                            theme.palette.mode === "light" ? 0.7 : 0.3,
                        },
                      },
                      "& .MuiSwitch-thumb": {
                        boxSizing: "border-box",
                        width: 22,
                        height: 22,
                      },
                      "& .MuiSwitch-track": {
                        borderRadius: 26 / 2,
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#E9E9EA"
                            : "#39393D",
                        opacity: 1,
                        transition: (theme) =>
                          theme.transitions.create(["background-color"], {
                            duration: 500,
                          }),
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    Active
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Document File
                </Typography>
                <Button component="label" variant="contained">
                  <FileUploadOutlined />{" "}
                  <Typography sx={{ fontSize: "0.9rem" }}>Add File</Typography>
                  <input
                    type="file"
                    accept={"image/*"}
                    hidden
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      if (e.target.files[0] !== undefined) {
                        const file = e.target.files[0];
                        console.log(file.name);
                        const fileType = file.type.split("/")[1];
                        console.log(fileType);
                        // if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {

                        //   toast.error("Invalid format");
                        // } else {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                          // console.log(reader.result);
                          const dataSplit = reader.result.split("base64,")[1];
                          // console.log(dataSplit)
                        
                          setFieldValue("partImage",file.name)
                  
                          setFieldValue("imageData", dataSplit);
                        };
                        reader.onerror = function (error) {};
                        // }
                      } else {
                        setFieldValue("partImage","");
                        setFieldValue("imageData", "");
                      }
                    }}
                  />
                </Button>
                <Typography
                  sx={{
                    // mb: color.labelmb,

                    fontSize: color.labelFont,
                    // color: color.labelColor,
                  }}
                >
                  {values.partImage}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PartsModal;
