import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Add, Clear, DeleteOutlined,EditOutlined } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";
import moment from "moment";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import NormalTable from "../../../Component/NormalTable";

const VendorReturnTransModal = ({ mode, handleCloseClick, editData }) => {
  const [receiptData, setReceiptData] = useState([]);
  const [uom,setUom]=useState([])
  const [receiptItem,setReceiptItem]=useState({
    receiptItemId:null,
    partId: null,
    uomId:null,
    stockRoomId: null,
  stockRoomRowId: null,
  stockRoomRowAisleId: null,
  stockRoomRowBinId: null,
  returnedQuantity: 0,
  
  unitPrice: 0,
  isQualityChecked: true,
  conversionFactor: 1,
  itemReturnedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
  aisleName:"",
  rowName:"",
  binName:"",
  stockRoomName:""
  })

  const [partData, setPartData] = useState([]);


  const [addRI, setAddRI] = useState([]);
  const [partSelect, setPartSel] = useState("");
  const [receiptSelect, setReceiptSel] = useState("");
  const [srSelect, setSRSel] = useState("");
  const [srAisleSelect, setSRAisleSel] = useState("");
  const [srRowSelect, setSRRowSel] = useState("");
  const [srBinSelect, setSRBinSel] = useState("");
  const [personData,setPersonData]=useState([])
  const [uomValue,setUomValue]=useState({})

  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData()
  }, []);

  const getData=async()=>{
    setLoading(true);

    

    const link = masterAPI["receipt"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setLoading(false);
        setReceiptData(res.data.result);
      })
      .catch((e) => {
        setLoading(false)
        console.log(e, "create receipt");
      });

      const personLink=masterAPI["person"].link
     await axiosPrivate.get(`${personLink}`).then(res=>{
      
        setPersonData(res.data.result)
      }).catch(e=>{
         console.log(e,"error person Data")
      })
      const uomLink=masterAPI["selectList"].uomByCat

     await axiosPrivate.get(`${uomLink}/Parts`).then(res=>{
        setUom(res.data)
      }).catch(e=>{
        console.log(e,"error")
      })
     
  

  }

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const initialValue = {
    businessId:  null,
    receiptId: null,
    invUsageCode: "",
    returnedById:null,
    returnedDate:  moment().format("YYYY-MM-DDTHH:mm:ss"),
   
    
   
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["vendorReturn"].create;
   // const updateLink = masterAPI["receipt"].update;
    // if (mode === "Create") {
      const postData={
        ...value,
        vendorRetunItemVMList:addRI
      }
      console.log(postData)
     await axiosPrivate
        .post(`${createLink}`, postData)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "create Receipt");
        });
    // } else {
    //   const updateAC = {
    //     id: editData.id,
    //     ...value,
    //   };
    //   axiosPrivate
    //     .put(`${updateLink}`, updateAC)
    //     .then((res) => {
    //       console.log(res.data);
    //       setLoading(false);
    //       handleCloseClick();
    //     })
    //     .catch((e) => {
    //       setLoading(false);
    //       console.log(e, "update receipt");
    //     });
    // }
  };

  const handlePart=(value)=>{
    // setCauseSel(value.causeCode+"_"+value.causeName)
    // setPartInfo(value)
    setSRAisleSel(value.aisleName)
    setSRSel(value.stockRoomName)
    setSRRowSel(value.rowName)
    setSRBinSel(value.binName)
    setPartSel(value.v+" - "+value.partName)
    const itemData={
      ...receiptItem,
      receiptItemId:value.id,
      partId:value.partId,
      partCode:value.code,
      partName:value.partName,
      stockRoomId: value.stockRoomId,
      stockRoomRowId: value.stockRoomRowId,
      stockRoomRowAisleId: value.stockRoomRowAisleId,
      stockRoomRowBinId: value.stockRoomRowBinId,
      stockRoomName:value.stockRoomName,
      aisleName:value.aisleName,
      rowName:value.rowName,
      binName:value.binName,
      
      unitCost: 0,
      isQualityChecked: value.isQualityChecked,
    }
   setReceiptItem(itemData)
   
    }

    const handleAddRI=(e,totalReceivedPrice)=>{
      const {name,value,checked}=e.target
      // if(name==="receivedQuantity"){
      //   const storeRI={
      //     ...receiptItem,
      //     [name]:name==="isQualityChecked"?checked:value,
      //     inventoryQuantity:value,
      //     unitPrice:totalReceivedPrice/value
       
      //   }
      //   setReceiptItem(storeRI)

      // }else{
      //   const storeRI={
      //     ...receiptItem,
      //     [name]:name==="isQualityChecked"?checked:value,
        
       
      //   }
      
      const storeRI={
        ...receiptItem,
        returnedQuantity:value
      }
      
      setReceiptItem(storeRI)
    }
     
    

  const handleCancel = () => {
    handleCloseClick();
  };



  const handleRIList=()=>{
    const addlist=[...addRI,receiptItem]
    console.log(addlist)
    setAddRI(addlist)
    setReceiptItem({
      receiptItemId:null,
      partId: null,
      stockRoomId: null,
    stockRoomRowId: null,
    stockRoomRowAisleId: null,
    stockRoomRowBinId: null,
    returnedQuantity: 0,
    uomId:null,
    uomName:"",
    unitPrice: 0,
    isQualityChecked: true,
    
    itemReturnedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    aisleName:"",
    rowName:"",
    binName:"",
    stockRoomName:""
    })
    setPartSel("")
    setSRAisleSel("")
    setSRBinSel("")
    setSRRowSel("")
    setSRSel("")
  }

  const handlePartEdit=(data)=>{
    const filterAddIssue=addRI.filter((fil,i)=>i!==Number(data.row.id))
    setReceiptItem({...data.row.original})
    setSRSel(data.row.original.stockRoomName)
    setPartSel(data.row.original.partCode+"-"+data.row.original.partName)
    setSRAisleSel(data.row.original.aisleName)
    setSRRowSel(data.row.original.rowName)
    setSRBinSel(data.row.original.binName)
    setAddRI(filterAddIssue)
  }

 

  const partColumn = [
    {
      Header: "Part",
      accessor: "partCode",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              color: "#0d6efd",
              fontSize: "inherit",
              fontWeight: 600,
              cursor: "pointer",
            }}
            // onClick={() => {
            //   handleCause(cell.row.original.id);
            // }}
          >
            {cell.value+" - "+cell.row.original.partName}
          </Typography>
        );
      },
      // disableFilters:true,
    },
  {
      Header: "Stock Name",
      accessor: "stockRoomName",
 
    },
    {
      Header: "Aisle",
      accessor: "aisleName",
    },
    {
      Header: "Row",
      accessor: "rowName",
    },
    {
      Header: "Bin",
      accessor: "binName",
    },
    {
      Header:"UOM",
      accessor:"uomName"
    },
    {
      Header: "Returned Quantity",
      accessor: "returnedQuantity",
      // disableFilters:true,
    }, 
  
    {
      Header: "Unit Price",
      accessor: "unitPrice",
 
    }, 
    {
      Header: "Quality Checked",
      accessor: "isQualityChecked",
      Cell:({cell})=>{
        return (
          <Typography sx={{fontSize:"inherit",color:"inherit",fontWeight:"inherit"}}>
           {cell.value?"Yes":"No"}
          </Typography>
        )
      }
    },
    {
      Header: "Returned Date",
      accessor: "itemReturnedDate",

    },
    


    
    {
      Header:"Action",
      accessor:"partId",
      Cell:({cell})=>{
        return (
          <>
           <IconButton>
              <EditOutlined
                onClick={() => {
                  handlePartEdit(cell);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton>
          <IconButton
          onClick={() => {
            deleteRI(cell.row.original.partId);
          }}
          >
          <DeleteOutlined
           
            sx={{
              fontSize: color.masterEditDelButton,
              color: color.masterEditDelButtonColor,
            }}
          />
        </IconButton>
        </>
        )
      },
      disableFilters:true,
    }
  ];

  const deleteRI=(id)=>{
    const delRI=addRI.filter(fil=>fil.partId!==id)
    setAddRI(delRI)
  }



  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          New Vendor Return
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
            <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Receipt
                </Typography>
                <Autocomplete
                // value={remedySelect}
                inputValue={receiptSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                loading={receiptData.length===0}
                options={receiptData}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.doNumber+ " - "+ option.poNumber || option
                }
                freeSolo
                onChange={async(event, value) => {
                  console.log(value)
                  if(value!==null){
                   setFieldValue("receiptId",value.id)
                   setFieldValue("businessId",value.businessId)
                   const partReceipt=masterAPI["receipt"].receiptList
                  await axiosPrivate.get(`${partReceipt}/${value.id}`).then(res=>{
                    setPartData(res.data.result)
                   }).catch(e=>{
                    console.log(e,"error")
                   })
                  }else{
                    setFieldValue("receiptId",null)
                    setFieldValue("businessId",null)
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setReceiptSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="receiptId"
                    placeholder="Select Receipt"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                          {receiptData.length===0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  InvUsage Code
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="invUsageCode"
                  placeholder="Enter InvUsage Code"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Returned By
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="returnedById"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.returnedById !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {personData.map((id, i) => (
                    <MenuItem key={i} value={id.id}>
                      {id.personName}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
             
          
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Returned Date
                </Typography>
               
           
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                  // views={['year', 'day', 'hours', 'minutes', 'seconds']}
               
                  format="DD/MM/YYYY hh:mm:ss A"
                    value={values.returnedDate===null?null:dayjs(values.returnedDate)}
                   
                   
                     readOnly
                    slotProps={{
                      textField: {
                        size: "small",
                        
                        fullWidth: true,
                      },
                    }}
                    fullWidth
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                          // "YYYY-MM-DDTHH:mm:ss" date format
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                             "YYYY-MM-DD"
                          );
                          const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                           setFieldValue("returnedDate",dateFormat)
                        }
                      }
                    }}
                  />
                </DemoItem>
              </LocalizationProvider>
              </Grid>
              
              <Grid item xs={12}>
              <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          Create Vendor Return Item
        </Typography>
        <IconButton
         sx={{backgroundColor:color.TableButtonColor}} 
        onClick={handleRIList}
        >
          <Add sx={{color:"white"}}/>
        </IconButton>
        </Box>
        <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
              </Grid>
            
              <Grid container item xs={12} spacing={"1rem"} >
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Part
                </Typography>
                <Autocomplete
                // value={remedySelect}
                inputValue={partSelect}
                //value={causeInfo.id===null?null:causeInfo}
                fullWidth
                filterOptions={filterOptions}
                loading={partData.length===0}
                options={partData}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) =>
                  option.code + " - " + option.partName || option
                }
                freeSolo
                onChange={(event, value) => {
                  console.log(value)
                  if(value!==null){
                    handlePart(value);
                  }else{
                    setPartSel("")
                    // setPartInfo({})
                  }
                 
                }}
                onInputChange={(event,newInputValue)=>{
                  console.log(newInputValue)
                  setPartSel(newInputValue)
                  
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="partId"
                    placeholder="Select Part"
                    //  onChange={handleChange}
                    variant="outlined"
                    size="small"
               
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                          {partData.length===0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Stock Room
                </Typography>
                <TextField 
                 value={srSelect}
                 size={"small"} 
                 InputProps={{readOnly:true}}
                 fullWidth
                 />

              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Aisle
                </Typography>
                <TextField 
                 value={srAisleSelect}
                 size={"small"} 
                 InputProps={{readOnly:true}}
                 fullWidth
                 />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Row
                </Typography>
                <TextField 
                 value={srRowSelect}
                 size={"small"} 
                 InputProps={{readOnly:true}}
                 fullWidth
                 />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                Bin
                </Typography>
                <TextField 
                 value={srBinSelect}
                 size={"small"} 
                 InputProps={{readOnly:true}}
                 fullWidth
                 />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
               UOM
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="uomId"
                  value={uomValue}
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    receiptItem.uomId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                  onChange={(e)=>{
                    console.log(e.target.value)
                    setUomValue(e.target.value)
                    setReceiptItem({...receiptItem,uomId:e.target.value.value,uomName:e.target.value.text})
                  }}
                >
                  {uom.map((id, i) => (
                    <MenuItem key={i} value={id}>
                      {id.text}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Returned Quantity
                </Typography>
                <TextField
                  size={"small"}
                  type="number"
                  name="returnedQuantity"
                  value={receiptItem.returnedQuantity}
                  placeholder="Enter Returned Quantity"
                  fullWidth
                  onChange={handleAddRI}
                  // InputProps={{readOnly:true}}
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                    Unit Price
                </Typography>
                <TextField
                  size={"small"}
                  type="number"
                  name="unitPrice"
                  InputProps={{readOnly:true}}
                  value={receiptItem.unitPrice}
                  placeholder="Enter Unit Price"
                  fullWidth
                  onChange={handleAddRI}
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                    Quality Check
                </Typography>
                <Switch readOnly checked={receiptItem.isQualityChecked} name="isQualityChecked" 
                // onChange={(e)=>{handleAddRI(e)}} 
                 />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Item Returned Date
                </Typography>
               
           
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                  // views={['year', 'day', 'hours', 'minutes', 'seconds']}
                 
                  format="DD/MM/YYYY hh:mm:ss A"
                    value={receiptItem.itemReturnedDate===null?null:dayjs(receiptItem.itemReturnedDate)}
                    
                    

                    slotProps={{
                      textField: {
                        size: "small",
                      
                        fullWidth: true,
                      },
                    }}
                    fullWidth
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                          // "YYYY-MM-DDTHH:mm:ss" date format
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                             "YYYY-MM-DD"
                          );
                          const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          const storeDate={
                            ...receiptItem,
                            itemReturnedDate:dateFormat
                          }
                          setReceiptItem(storeDate)
                        }
                      }
                    }}
                  />
                </DemoItem>
              </LocalizationProvider>
              </Grid>
           
              </Grid>
              <Grid item xs={12}>
              <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          Receipt Item List
        </Typography>
      
        </Box>
        <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
              </Grid>
              <Grid item xs={12}>
              <NormalTable
          data={addRI}
          columns={partColumn}
          filt={true}
        />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading||addRI.length===0}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default VendorReturnTransModal;
