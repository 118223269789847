import {
  ArrowDropDown,
  ArrowDropUp,
  DeleteOutline,
  Domain,
  EditOutlined,
  GroupAdd,
  LibraryAdd,
  Add
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Collapse,
  Checkbox,
  Dialog,
  Tab,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Field, Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { color } from "../../../../StyleData/Config";
import NormalTable from "../../../../Component/NormalTable";
import { masterAPI } from "../../../Masters/dataConfig";
import { axiosPrivate } from "../../../../axios/axios";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider,renderTimeViewClock,DateTimePicker  } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Labour from "./Labour";
import PartList from "./Parts";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TaskWithId from "./jobPlanTaskList";
import PartEdit from "./PartEdit";
import dayjs from "dayjs";

const WorkPlan = ({ workOrderId,jobPlanId }) => {
  const [taskCreate, setCreateTask] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [taskTypeList, setTaskTypeList] = useState([]);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [edit, setEdit] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [labour, setLabour] = useState(false);
  const [part, setPart] = useState(false);
  const [value, setValue] = useState("1");
  const [labourList, setLabourList] = useState([]);
  const [checkedDetail, setCheckedDetail] = useState([]);
  const [partList, setPartList] = useState([]);
  const [comDetail, setComDetail] = useState({});
  const [loading,setLoading]=useState(false)
  const [partEditData,setPartEditData]=useState({})
  const [partEdit,setPartEdit]=useState(false)
  const [checkBol,setCheckBol]=useState(false)
  const [source,setSource]=useState("eam")
  const [taskTypeSelect,setTaskTypeSelect]=useState("")
  const [meter,setMeter]=useState([])
  const [multipleChoice,setMultipleChoice]=useState([])
  

  useEffect(() => {
    console.log(workOrderId, "cehcj");
  
    getTaskList();
    console.log(jobPlanId,"jpid")

   
  }, []);

  const getTaskList = async() => {
    setLoading(true)
    const taskTypeListLink = masterAPI["selectList"].taskTypes;
   await axiosPrivate
      .get(`${taskTypeListLink}`)
      .then((res) => {
        console.log(res);
        setTaskTypeList(res.data);
      })
      .catch((e) => {
        setTaskTypeList([])
        console.log(e, "createWoTask error");
      });
     
    const completeDetail = masterAPI["workOrder"].completeDetail;

   await axiosPrivate
      .get(`${completeDetail}/${workOrderId}`)
      .then(async(res) => {
        console.log(res,"check");
        const sortTaskList = res.data.result.workOrderTaskList;
       sortTaskList.sort((a, b) => {
      if (a.sequenceNo === '') {
        return 1;
      }
      if (b.sequenceNo === '') {
        return -1;
      }
      return a.sequenceNo-b.sequenceNo;
    });
        setTaskData(sortTaskList);
        setLabourList(res.data.result.workOrderTaskLabourList);
        setPartList(res.data.result.workOrderTaskPartsList);
        setComDetail(res.data.result)
    
        const assetMeter=masterAPI["meter"].getMeterByAsset
     await axiosPrivate.get(`${assetMeter}/${res.data.result.assetId}`).then(res=>{
       
        setMeter(res.data.result)
      }).catch(e=>{
        setMeter([])
        console.log(e,"error")
      })
        setLoading(false)
      })
      .catch((e) => {
        setTaskData([])
        setLabourList([])
        setPartList([])
        setComDetail({})
        console.log(e, "work complete details error");
      });

      

  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddTask = () => {
    setEdit(false);
    setMultipleChoice([])
    setCreateTask(true);
    setEditValue({});
    setTaskTypeSelect("")
  };

  const onSubmit =async(values, { resetForm }) => {
    const createLink = masterAPI["workOrderTask"].create;
    const updateLink = masterAPI["workOrderTask"].update;
    if (edit) {
      const putData = {
        id: editValue.id,
        listOfChoices:multipleChoice,
        listOfSelectedChoices:multipleChoice,
        isMultipleChoice:true,
        ...values,
      };
     await axiosPrivate
        .put(`${updateLink}`, putData)
        .then((res) => {
          console.log(res);
          getTaskList();
          resetForm();
          setEdit(false);
          setCreateTask(false);
          setEditValue({});
          setTaskTypeSelect("")
          setMultipleChoice([])
        })
        .catch((e) => {
          console.log(e, "update Task");
        });
    } else {
      const postData={
        ...values,
        listOfChoices:multipleChoice,
        listOfSelectedChoices:multipleChoice,
        isMultipleChoice:multipleChoice.length===0?false:true,

      }
      console.log(postData)
     await axiosPrivate
        .post(`${createLink}`, postData)
        .then((res) => {
          console.log(res);
          getTaskList();
          setTaskTypeSelect("")
          setMultipleChoice([])
          resetForm();
        })
        .catch((e) => {
          console.log(e, "create Task");
        });
    }
  };

  const handleDelete=async(id)=>{
    const wotaskLink=masterAPI["workOrderTask"].delete
   await axiosPrivate.delete(`${wotaskLink}/${id}`).then(res=>{
      console.log(res)
      getTaskList()
    }).catch(e=>{
      console.log(e,"error task delete")
    })
  }

  const listColumns = [
    {
      Header:"checkBox",
      accessor: "id",
      Cell: ({ cell }) => {
        return (
          <Checkbox
            checked={checkedList.includes(cell.row.original.id)}
            onClick={() => {
              handleChecked(cell.row.original);
            }}
          />
        );
      },
    },
    // {
    //         Header: "ID",
    //         accessor: "id",

    //     },
    {
      Header: "Task No",
      accessor: "taskNo",
    },
    {
      Header: "Task Name",
      accessor: "taskName",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Sequence No",
      accessor: "sequenceNo",
    },
    {
      Header: "Task Duration",
      accessor: "taskDuration",
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton>
              <EditOutlined
                onClick={() => {
               
                  handleEdit(cell.row.original);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton>
            <IconButton>
                        <DeleteOutline
                          onClick={() => {
                            handleDelete(cell.row.original.id);
                          }}
                          sx={{
                            fontSize: color.masterEditDelButton,
                            color: color.masterEditDelButtonColor,
                          }}
                        />
                      </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleEdit = (value) => {
    console.log(value);
    setTaskTypeSelect(value.taskTypeName)
    setMultipleChoice(value.listOfChoices!==undefined?value.listOfChoices:[""])
    setEdit(true);
    setCreateTask(true);
    setEditValue(value);
  };

  const handleChecked = (e) => {
    setCheckBol(false)
    if (checkedList.includes(e.id)) {
      console.log(e, "no");
      const removeList = checkedList.filter((l) => l !== e.id);
      setCheckedList(removeList);
      const removeDetail = checkedDetail.filter((l) => l.id !== e.id);
      setCheckedDetail(removeDetail);
    } else {
      console.log("yes");
      setCheckedList([...checkedList, e.id]);
      setCheckedDetail([...checkedDetail, e]);
    }
  };

  const handleCheckAll=(e)=>{
    if(e.target.checked){
      setCheckBol(true)
      const gettaskId=[]
      for(let i=0;taskData.length>i;i++){
        console.log(taskData[i])
        gettaskId.push(taskData[i].id)
      }
      setCheckedDetail(taskData)
      setCheckedList(gettaskId)

    }else{
      setCheckBol(false)
      setCheckedList([])
      setCheckedDetail([])
    }
 
   }

  const initialValue = {
    taskName: edit ? editValue.taskName : "",
    taskNo: edit ? editValue.taskNo : "",
    sequenceNo: edit ? editValue.sequenceNo : "",
    description: edit ? editValue.description : "",
    taskDuration: edit ? editValue.taskDuration : "",
    taskTypeId: edit ? editValue.taskTypeId : "",
  
    actualStartDate: edit ? editValue.actualStartDate : null,
    actualEndDate: edit ? editValue.actualEndDate : null,
    targetStartDate: edit ? editValue.targetStartDate : comDetail.targetStartDate,
    targetEndDate: edit ? editValue.targetEndDate : comDetail.targetEndDate,
    plannedStartDate: edit ? editValue.plannedStartDate : null,
    plannedEndDate: edit ? editValue.plannedEndDate : null,
    workOrderId: workOrderId,
    meterId:edit ? editValue.meterId:null
  };

  const handleLabourClose = () => {
    setLabour(false);
    setCheckedDetail([])
    setCheckedList([])
    getTaskList()
  };

  const handlePartClose = () => {
    setPart(false);
    setCheckedDetail([])
    setCheckedList([])
    getTaskList()
  };

  const handlePartEditClose = () => {
    setPartEdit(false);
    setPartEditData({})
    getTaskList()
  };

  const handlePartDelete=async(id)=>{
  const partDelLink=masterAPI["workOrderTaskPart"].delete
 await axiosPrivate.delete(`${partDelLink}/${id}`).then(res=>{
    console.log(res)
    getTaskList()
  }).catch(e=>{
    console.log(e,"delete part error")
  })
  }
  const handlePartEdit=(value)=>{
  setPartEdit(true)
  setPartEditData(value)
 
  }

  const labourListColumn = [
    {
      Header: "Task No",
      accessor: "taskNo",
    },
    {
      Header: "Labour",
      accessor: "labourName",
    },
    {
      Header: "Craft",
      accessor: "craftName",
    },
    {
      Header: "Business",
      accessor: "businessName",
    },
    {
      Header: "Qty",
      accessor: "quantity",
    },

    {
      Header: "Planned Hours",
      accessor: "plannedHours",
    },
    {
      Header: "Rate",
      accessor: "rate",
    },
    {
      Header: "Line Cost",
      accessor:"cost"
    },
  ];
  const partsColumns = [
    {
      Header: "Task No",
      accessor: "taskNo",
      // Cell: ({ cell }) => {
      //   return (
      //    <Typography sx={{

      //             color: "#0d6efd",
      //             fontSize:"inherit",
      //             fontWeight:600

      //           }}

      //     >
      //       {cell.value}
      //     </Typography>
      //   );
      // },
    },
    // {
    //   Header: "Part ID",
    //   accessor: "partId",
    // },
    {
      Header: "Part Name",
      accessor: "partName",
    },
    {
      Header: "Issued Qty",
      accessor: "quantity",
    },
    {
      Header: "Unit",
      accessor: "unit",
    },
    {
      Header: "Cost",
      accessor: "cost",
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton>
              <EditOutlined
                onClick={() => {
                  handlePartEdit(cell.row.original);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton>
            <IconButton>
                        <DeleteOutline
                          onClick={() => {
                            handlePartDelete(cell.row.original.id);
                          }}
                          sx={{
                            fontSize: color.masterEditDelButton,
                            color: color.masterEditDelButtonColor,
                          }}
                        /> 
                      </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleRemoveItem = (index) => {
    const newItems = [...multipleChoice];
    newItems.splice(index, 1);
    setMultipleChoice(newItems);
  };



  return (
    <Box>
      
      {loading?<Box sx={{width:"100%",display:"flex",justifyContent:"center"}}><CircularProgress/></Box>:<>
      {/* {taskData.length === 0&&jobPlanId!==undefined ? (
        
        
        <TaskWithId jobPlanId={jobPlanId} workOrderId={workOrderId} getComplete={getTaskList} />
       
      ) : ( */}
        <Grid container gap={"1rem"}>
         
          <Grid
            item
            xs={12}
            sx={{
              boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
              p: 1.5,
              backgroundColor: "white",
            }}
          >
            <Grid container alignItems={"center"} sx={{ mb: "0.5rem" }}>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: color.masterDialogTitleColor,
                    fontSize: color.masterDialogFontSize,
                  }}
                >
                  {" "}
                  Task List{" "}
                </Typography>
              </Grid>
              <Grid
                container
                items
                xs={9}
                justifyContent={"flex-end"}
                gap={"1rem"}
              > 
              <Box sx={{display:"flex",alignItems:"center",gap:"1rem"}}>
              <Typography>Source:</Typography>
                <Select size="small" fullWidth value={source} onChange={(e)=>{setSource(e.target.value)}} sx={{width:"10vw"}} >
                  <MenuItem value="eam">EAM</MenuItem>
                  <MenuItem value="syspro">SysPro</MenuItem>
                </Select>
                </Box>
                <Tooltip title="Labour">
                  <IconButton
                    disabled={checkedList.length === 0}
                    onClick={() => {
                      setLabour(true);
                    }}
                  >
                    <GroupAdd sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Parts">
                  <IconButton
                    disabled={checkedList.length === 0}
                    onClick={() => {
                      setPart(true);
                    }}
                  >
                    <Domain sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                </Tooltip>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton onClick={handleAddTask}>
                    <LibraryAdd />
                  </IconButton>
                  <Typography>Task</Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider
              sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }}
            />

            <NormalTable columns={listColumns} data={taskData} handleCheckAll={handleCheckAll} checkBol={checkBol} />
            {/* <NormalTableEdit columns={listColumns} data={taskData} handleEditValue={handleEditValue} /> */}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
              p: 1.5,
              backgroundColor: "white",
              display: taskCreate ? "grid" : "none",
            }}
          >
            <Formik
              initialValues={initialValue}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ handleChange, setFieldValue, values, resetForm }) => (
                <Form>
                 
                  <Grid container spacing={"1.5rem"}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: color.masterDialogTitleColor,
                          fontSize: color.masterDialogFontSize,
                        }}
                      >
                      {edit?"Edit Task":" Create Task"}
                      </Typography>
                      <Divider
                        sx={{
                          border: "1px solid rgb(0 0 0 / 17%)",
                          mb: "15px",
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography
                        sx={{
                          fontWeight: color.labelFontWeight,
                          mb: color.labelmb,
                          fontSize: color.labelFont,
                          color: color.labelColor,
                        }}
                      >
                        Task Name
                      </Typography>
                      <Field
                        as={TextField}
                        size={"small"}
                        type="text"
                        name="taskName"
                        placeholder="Enter Task Name"
                        fullWidth
                        sx={{
                          ".MuiInputBase-input": {
                            // letterSpacing: "0.2rem",

                            "&::placeholder": {
                              // color: "green",
                              opacity: 1,
                              color: color.placeholderColor,
                              fontSize: "13px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        sx={{
                          fontWeight: color.labelFontWeight,
                          mb: color.labelmb,
                          fontSize: color.labelFont,
                          color: color.labelColor,
                        }}
                      >
                        Task No
                      </Typography>
                      <Field
                        as={TextField}
                        size={"small"}
                        type="text"
                        name="taskNo"
                        placeholder="Enter Task No"
                        fullWidth
                        sx={{
                          ".MuiInputBase-input": {
                            // letterSpacing: "0.2rem",

                            "&::placeholder": {
                              // color: "green",
                              opacity: 1,
                              color: color.placeholderColor,
                              fontSize: "13px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        sx={{
                          fontWeight: color.labelFontWeight,
                          mb: color.labelmb,
                          fontSize: color.labelFont,
                          color: color.labelColor,
                        }}
                      >
                        Sequence No
                      </Typography>
                      <Field
                        as={TextField}
                        size={"small"}
                        type="text"
                        name="sequenceNo"
                        placeholder="Enter Sequence No"
                        fullWidth
                        sx={{
                          ".MuiInputBase-input": {
                            // letterSpacing: "0.2rem",

                            "&::placeholder": {
                              // color: "green",
                              opacity: 1,
                              color: color.placeholderColor,
                              fontSize: "13px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                            <Typography
                              sx={{
                                fontWeight: color.labelFontWeight,
                                mb: color.labelmb,
                                fontSize: color.labelFont,
                                color: color.labelColor,
                              }}
                            >
                              Task Duration
                            </Typography>
                            <Field
                              as={TextField}
                              size={"small"}
                              type="text"
                              name="taskDuration"
                              placeholder="Enter Task Duration"
                              fullWidth
                              sx={{
                                ".MuiInputBase-input": {
                                  // letterSpacing: "0.2rem",

                                  "&::placeholder": {
                                    // color: "green",
                                    opacity: 1,
                                    color: color.placeholderColor,
                                    fontSize: "13px",
                                  },
                                },
                              }}
                            />
                          </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        sx={{
                          fontWeight: color.labelFontWeight,
                          mb: color.labelmb,
                          fontSize: color.labelFont,
                          color: color.labelColor,
                        }}
                      >
                        Task Type
                      </Typography>
                      <Field
                        as={Select}
                        // label={"Select"}
                        size={"small"}
                        type="text"
                        name="taskTypeId"
                        fullWidth
                        displayEmpty
                        MenuProps={{
                          PaperProps: { sx: { maxHeight: "40vh" } },
                        }}
                     
                        renderValue={
                          values.taskTypeId !== ""
                            ? undefined
                            : () => (
                                <Typography
                                  sx={{
                                    color: color.placeholderColor,
                                    fontSize: "13px",
                                  }}
                                >
                                  Select
                                </Typography>
                              )
                        }
                      >
                        {taskTypeList.map((id, i) => (
                          <MenuItem value={id.value} 
                          onClick={()=>{
                            setTaskTypeSelect(id.text)
                            if(id.text.toLowerCase()==="multiple choice"){
                              setMultipleChoice([""])
                            }
                          }}
                          >
                            {id.text}
                            </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{display:taskTypeSelect.toLowerCase()==="meter reading"?"default":"none"}}>
                      <Typography
                        sx={{
                          fontWeight: color.labelFontWeight,
                          mb: color.labelmb,
                          fontSize: color.labelFont,
                          color: color.labelColor,
                        }}
                      >
                        Meter
                      </Typography>
                      <Field
                        as={Select}
                        // label={"Select"}
                        size={"small"}
                        type="text"
                        name="meterId"
                        fullWidth
                        displayEmpty
                        MenuProps={{
                          PaperProps: { sx: { maxHeight: "40vh" } },
                        }}
                     
                        renderValue={
                          values.meterId !== null
                            ? undefined
                            : () => (
                                <Typography
                                  sx={{
                                    color: color.placeholderColor,
                                    fontSize: "13px",
                                  }}
                                >
                                  Select
                                </Typography>
                              )
                        }
                      >
                        {meter.map((id, i) => (
                          <MenuItem value={id.meterId} >{id.meterName}</MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid container item xs={12} md={6} sx={{display:taskTypeSelect.toLowerCase()==="multiple choice"?"default":"none"}}>
                      <Box sx={{display:"flex",width:"100%",justifyContent:"space-between"}}>
                      <Typography
                        sx={{
                          fontWeight: color.labelFontWeight,
                          mb: color.labelmb,
                          fontSize: color.labelFont,
                          color: color.labelColor,
                        }}
                      >
                        Choice
                      </Typography>
                    
                          <Add sx={{cursor:"pointer",color: color.labelColor,}} onClick={()=>{
                          setMultipleChoice([...multipleChoice,""])
                         }}/>
                      
                      </Box>
                        
                    
                      <Grid item xs={12}>
                      {multipleChoice.map((choice,i)=>(
                      
                  <Box sx={{display:"flex",gap:"0.2rem",width:"100%"}}>
                      <Field
                        as={TextField}
                        size={"small"}
                        type="text"
                        value={choice}
                        placeholder="Enter Choice"
                        fullWidth
                        onChange={(e)=>{
                          const mcData=[...multipleChoice]
                          mcData[i]=e.target.value
                          console.log(mcData)
                          setMultipleChoice(mcData)
                        }}
                        sx={{
                          ".MuiInputBase-input": {
                            // letterSpacing: "0.2rem",

                            "&::placeholder": {
                              // color: "green",
                              opacity: 1,
                              color: color.placeholderColor,
                              fontSize: "13px",
                            },
                          },
                        }}
                      />
                      <IconButton onClick={()=>{
                        if(multipleChoice.length!==1){
                          handleRemoveItem(i)
                          }
                          }}>
                        <DeleteOutline/>
                      </IconButton>
                      </Box>
                      ))}
                      </Grid>
                    
                </Grid>
                 
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: color.masterDialogTitleColor,
                          fontSize: color.masterDialogFontSize,
                        }}
                      >
                        {" "}
                        Details{" "}
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpen(!open)}
                        >
                          {open ? <ArrowDropUp /> : <ArrowDropDown />}
                        </IconButton>
                      </Typography>
                      <Divider
                        sx={{
                          border: "1px solid rgb(0 0 0 / 17%)",
                          mb: "15px",
                        }}
                      />
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <Grid container spacing={"1.5rem"}>
                          <Grid item xs={12} md={6}>
                            <Typography
                              sx={{
                                fontWeight: color.labelFontWeight,
                                mb: color.labelmb,
                                fontSize: color.labelFont,
                                color: color.labelColor,
                              }}
                            >
                              Description
                            </Typography>
                            <Field
                              as={TextField}
                              size={"small"}
                              type="text"
                              name="description"
                              placeholder="Enter Description"
                              fullWidth
                              sx={{
                                ".MuiInputBase-input": {
                                  // letterSpacing: "0.2rem",

                                  "&::placeholder": {
                                    // color: "green",
                                    opacity: 1,
                                    color: color.placeholderColor,
                                    fontSize: "13px",
                                  },
                                },
                              }}
                            />
                          </Grid>
                      
                          <Grid
                            item
                            xs={6}
                            sx={{
                              ".css-1pduc5x-MuiStack-root": {
                                paddingTop: 0,
                                overflowX: { xs: "auto", lg: "hidden" },
                                overflowY: "hidden",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: color.labelFontWeight,
                                mb: color.labelmb,
                                fontSize: color.labelFont,
                                color: color.labelColor,
                              }}
                            >
                              Actual Start Date
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <DateTimePicker
                                  
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                                  value={values.actualStartDate!==null?dayjs(values.actualStartDate):values.actualStartDate}
                                  // sx={{".css-1xhypcz-MuiStack-root":{
                                  //   padding:0
                                  // }}}

                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      margin: "-8px 0 0 0",
                                      fullWidth: true,
                                    },
                                  }}
                                  fullWidth
                                  onChange={(newValue) => {
                                    console.log(newValue);
                                    if (newValue !== null) {
                                      if (`${newValue.$d}` !== "Invalid Date") {
                                        const dateFrom = moment(
                                          newValue.$d
                                        ).format("YYYY-MM-DDTHH:mm:ss");
                                        // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                        // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                        // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                        // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                        setFieldValue(
                                          "actualStartDate",
                                          dateFrom
                                        );
                                      }
                                    }
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              ".css-1pduc5x-MuiStack-root": {
                                paddingTop: 0,
                                overflowX: { xs: "auto", lg: "hidden" },
                                overflowY: "hidden",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: color.labelFontWeight,
                                mb: color.labelmb,
                                fontSize: color.labelFont,
                                color: color.labelColor,
                              }}
                            >
                              Target Start Date
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <DateTimePicker
                                  format="DD/MM/YYYY HH:mm"
                                  ampm={false}
                                  value={dayjs(values.targetStartDate)}
                              
                                  // sx={{".css-1xhypcz-MuiStack-root":{
                                  //   padding:0
                                  // }}}

                                  readOnly

                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      margin: "-8px 0 0 0",
                                      fullWidth: true,
                                    },
                                  }}
                                  onChange={(newValue) => {
                                    console.log(newValue);
                                    if (newValue !== null) {
                                      if (`${newValue.$d}` !== "Invalid Date") {
                                        const dateFrom = moment(
                                          newValue.$d
                                        ).format("YYYY-MM-DDTHH:mm:ss");
                                        const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                        const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                        const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                        const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                        setFieldValue(
                                          "targetStartDate",
                                          dateFormat
                                        );
                                      }
                                    }
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              ".css-1pduc5x-MuiStack-root": {
                                paddingTop: 0,
                                overflowX: { xs: "auto", lg: "hidden" },
                                overflowY: "hidden",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: color.labelFontWeight,
                                mb: color.labelmb,
                                fontSize: color.labelFont,
                                color: color.labelColor,
                              }}
                            >
                              Actual End Date
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <DateTimePicker
                                  
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                                  
                                   value={values.actualEndDate!==null?dayjs(values.actualEndDate):values.actualEndDate}
                                  // sx={{".css-1xhypcz-MuiStack-root":{
                                  //   padding:0
                                  // }}}

                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      margin: "-8px 0 0 0",
                                      fullWidth: true,
                                    },
                                  }}
                                  fullWidth
                                  onChange={(newValue) => {
                                    console.log(newValue);
                                    if (newValue !== null) {
                                      if (`${newValue.$d}` !== "Invalid Date") {
                                        const dateFrom = moment(
                                          newValue.$d
                                        ).format("YYYY-MM-DDTHH:mm:ss");
                                        // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                        // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                        // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                        // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                        setFieldValue(
                                          "actualEndDate",
                                          dateFrom
                                        );
                                      }
                                    }
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              ".css-1pduc5x-MuiStack-root": {
                                paddingTop: 0,
                                overflowX: { xs: "auto", lg: "hidden" },
                                overflowY: "hidden",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: color.labelFontWeight,
                                mb: color.labelmb,
                                fontSize: color.labelFont,
                                color: color.labelColor,
                              }}
                            >
                              Target End Date
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <DateTimePicker
                                
                                format="DD/MM/YYYY HH:mm"
                                ampm={false}
                                 
                                  value={dayjs(values.targetStartDate)}
                                  // sx={{".css-1xhypcz-MuiStack-root":{
                                  //   padding:0
                                  // }}}
                                  readOnly

                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      margin: "-8px 0 0 0",
                                      fullWidth: true,
                                    },
                                  }}
                                  fullWidth
                                  onChange={(newValue) => {
                                    console.log(newValue);
                                    if (newValue !== null) {
                                      if (`${newValue.$d}` !== "Invalid Date") {
                                        const dateFrom = moment(
                                          newValue.$d
                                        ).format("YYYY-MM-DDTHH:mm:ss");
                                        const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                        const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                        const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                        const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                        setFieldValue(
                                          "targetEndDate",
                                          dateFormat
                                        );
                                      }
                                    }
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              ".css-1pduc5x-MuiStack-root": {
                                paddingTop: 0,
                                overflowX: { xs: "auto", lg: "hidden" },
                                overflowY: "hidden",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: color.labelFontWeight,
                                mb: color.labelmb,
                                fontSize: color.labelFont,
                                color: color.labelColor,
                              }}
                            >
                              Planned Start Date
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <DateTimePicker
                                  
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                                 
                                  value={values.plannedStartDate!==null?dayjs(values.plannedStartDate):values.plannedStartDate}
                                  // sx={{".css-1xhypcz-MuiStack-root":{
                                  //   padding:0
                                  // }}}

                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      margin: "-8px 0 0 0",
                                      fullWidth: true,
                                    },
                                  }}
                                  fullWidth
                                  onChange={(newValue) => {
                                    console.log(newValue);
                                    if (newValue !== null) {
                                      if (`${newValue.$d}` !== "Invalid Date") {
                                        const dateFrom = moment(
                                          newValue.$d
                                        ).format("YYYY-MM-DDTHH:mm:ss");
                                        // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                        // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                        // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                        // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                        setFieldValue(
                                          "plannedStartDate",
                                         dateFrom
                                        );
                                      }
                                    }
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              ".css-1pduc5x-MuiStack-root": {
                                paddingTop: 0,
                                overflowX: { xs: "auto", lg: "hidden" },
                                overflowY: "hidden",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: color.labelFontWeight,
                                mb: color.labelmb,
                                fontSize: color.labelFont,
                                color: color.labelColor,
                              }}
                            >
                              Planned End Date
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DateTimePicker"]}>
                                <DateTimePicker
                                  
                                  ampm={false}
                                   format="DD/MM/YYYY HH:mm"
                                   viewRenderers={{
                                     hours: renderTimeViewClock,
                                     minutes: renderTimeViewClock,
                                     seconds: renderTimeViewClock,
                                   }}
                                  
                                   value={values.plannedEndDate!==null?dayjs(values.plannedEndDate):values.plannedEndDate}
                                  // sx={{".css-1xhypcz-MuiStack-root":{
                                  //   padding:0
                                  // }}}

                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      margin: "-8px 0 0 0",
                                      fullWidth: true,
                                    },
                                  }}
                                  fullWidth
                                  onChange={(newValue) => {
                                    console.log(newValue);
                                    if (newValue !== null) {
                                      if (`${newValue.$d}` !== "Invalid Date") {
                                        const dateFrom = moment(
                                          newValue.$d
                                        ).format("YYYY-MM-DDTHH:mm:ss");
                                        // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                        // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                        // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                        // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                        setFieldValue(
                                          "plannedEndDate",
                                         dateFrom
                                        );
                                      }
                                    }
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                    <Grid container item xs={12} justifyContent={"flex-end"}>
                      <Button
                        onClick={() => {
                          setCreateTask(false);
                          setEdit(false)
                          setEditValue({})
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="submit">submit</Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
              p: 1.5,
              backgroundColor: "white",
            }}
          >
            <Typography
              sx={{
                color: color.masterDialogTitleColor,
                fontSize: color.masterDialogFontSize,
              }}
            >
              {" "}
              Labour{" "}
            </Typography>
            <Divider
              sx={{
                border: "1px solid rgb(0 0 0 / 17%)",
                mb: "15px",
                mt: "5px",
              }}
            />

            <NormalTable data={labourList} columns={labourListColumn} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
              p: 1.5,
              backgroundColor: "white",
            }}
          >
            <Typography
              sx={{
                color: color.masterDialogTitleColor,
                fontSize: color.masterDialogFontSize,
              }}
            >
              {" "}
              Part{" "}
            </Typography>
            <Divider
              sx={{
                border: "1px solid rgb(0 0 0 / 17%)",
                mb: "15px",
                mt: "5px",
              }}
            />
            <NormalTable data={partList} columns={partsColumns} />
          </Grid>
          <Dialog
            open={labour}
            onClose={handleLabourClose}
            fullWidth
            maxWidth={"lg"}
            children={
              <Labour
                handleCloseClick={handleLabourClose}
                type={"wot"}
                taskData={checkedList}
                dataDetail={checkedDetail}
              />
            }
          />
          <Dialog
            open={part}
            onClose={handlePartClose}
            fullWidth
            maxWidth={"lg"}
            children={
              <PartList
                handleCloseClick={handlePartClose}
                type={"wot"}
                source={source}
                taskData={checkedList}
                dataDetail={checkedDetail}
                jobNo={comDetail.notes}
              />
            }
          />
           <Dialog
            open={partEdit}
            onClose={handlePartEditClose}
            fullWidth
            maxWidth={"md"}
            children={
              <PartEdit
                handleCloseClick={handlePartEditClose}
               type={"wo"}
              
                editData={partEditData}
               
              />
            }
          />
        </Grid>
      {/* )} */}
      </>}
      
    </Box>
  );
};

export default WorkPlan;
